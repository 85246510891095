import * as React from 'react';
import {ITemplateColors} from '../../../@types/templateColors';

interface ILoadingProps {
  templateColors: ITemplateColors;
  text?: string;
  style?: React.CSSProperties;
}

function loading(props: ILoadingProps) {
  return (
    <div className="loader-overlay" style={props.style || {}}>
      <div className="loader">
        <div style={{borderColor: `${props.templateColors.highlight} transparent transparent transparent`}} />
        <div style={{borderColor: `${props.templateColors.highlight} transparent transparent transparent`}} />
        <div style={{borderColor: `${props.templateColors.highlight} transparent transparent transparent`}} />
        <div style={{borderColor: `${props.templateColors.highlight} transparent transparent transparent`}} />
      </div>
      {props.text && (
        <div className="loader-text" style={{color: props.templateColors.main}}>
          {props.text}
        </div>
      )}
    </div>
  );
}

export default React.memo(loading);
