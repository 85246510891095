import * as React from 'react';
import {Tt1} from '../../../../../../../../../../components/Typography';
import {Column, Row} from '../../../../../../../Grid';
import {IEnum} from '../../../../../../../../../../../@types/enum';
import {defineChannelCheckboxLabel} from '../../../../../../../../../../utils/utils';

interface IAudioTrackDetailsProps {
  data: any;
  channelMapEnums: Array<IEnum>;
}

export class AudioConfigurationTrack extends React.PureComponent<IAudioTrackDetailsProps> {
  getChannelMap = () => {
    const channelMap =
      this.props.data.channelMap && Array.isArray(this.props.data.channelMap) ? this.props.data.channelMap : [];
    return channelMap.length ? defineChannelCheckboxLabel(channelMap, this.props.channelMapEnums) : 'No Channel Map';
  };

  render() {
    return (
      <div className="audio-config-container">
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Track" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.track} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Channel Map" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 className="audio-detail-row_label" content={this.getChannelMap()} />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Average Bit Rate" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.averageBitRate} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Bit Depth" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.bitDepth} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Bit Rate Mode" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.bitRateMode} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="db Level" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.dbLevel} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Element Type" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.elementType} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Audio Codec" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.audioCodec} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Peak Bit Rate" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.peakBitRate} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Sample Rate" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.sampleRate} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Sub Type" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.subType} className="audio-detail-row_label" />
          </Column>
        </Row>
        <Row className="audio-config-container_row">
          <Column className="audio-config-container_row_title">
            <Tt1 content="Actual Length" className="audio-detail-row_label" />
          </Column>
          <Column className="audio-config-container_row_value">
            <Tt1 content={this.props.data.actualLength} className="audio-detail-row_label" />
          </Column>
        </Row>
        <div className="audio-config-container_row audio-section-divider" />
      </div>
    );
  }
}
