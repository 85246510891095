import * as React from 'react';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ArrowButtons} from 'tt-components/src/Buttons/ArrowButtons';
import {
  secondsToHHmmss,
  getTimeComponents,
  fromSMPTETokensToSeconds,
  secondsToMillisecondsTimecode,
  roundTwoDecimal
} from '../../utils/utils';
import {IFrameRate} from 'tt-components';
import {Smpte} from '../../models/Smpte/Smpte';
import {random} from 'tt-components/src/Utils/random';

interface IPlayerTimePickerProps {
  time: number;
  min?: number;
  max?: number;
  isSelected?: boolean;
  disabled?: boolean;
  frameRate?: IFrameRate;
  milliseconds?: boolean;
  triggerOnBlur?: boolean;
  onChangeTime: (time: number) => void;
  onRef?: (ref: PlayerTimePicker) => void;
}

interface IPlayerTimePickerState {
  hours: string;
  minutes: string;
  seconds: string;
  frames: string;
  milliseconds: string;
  focused: boolean;
  uniqueId: string;
}

type componentsType = 'hours' | 'minutes' | 'seconds' | 'frames' | 'milliseconds';

interface IInputUpdate {
  value: string;
  type: componentsType;
}

type IArrowPositions = 'up' | 'down';

export class PlayerTimePicker extends React.Component<IPlayerTimePickerProps, IPlayerTimePickerState> {
  onTimeComponentUpdated$: Subject<IInputUpdate>;
  $onTimeComponentUpdated: Subscription;

  onArrowTimeComponentUpdated$: Subject<IArrowPositions>;
  $onArrowTimeComponentUpdated: Subscription;

  focusBlurToggleTimeout: any;

  playerTimePickerRef: HTMLDivElement;

  hoursTextInput: React.RefObject<HTMLInputElement>;
  minutesTextInput: React.RefObject<HTMLInputElement>;
  secondsTextInput: React.RefObject<HTMLInputElement>;
  frameTextInput: React.RefObject<HTMLInputElement>;
  millisecondsTextInput: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.state = {
      hours: '00',
      minutes: '00',
      seconds: '00',
      frames: '00',
      milliseconds: '000',
      focused: false,
      uniqueId: random.string(10, 'player-picker-')
    };

    this.hoursTextInput = React.createRef();
    this.minutesTextInput = React.createRef();
    this.secondsTextInput = React.createRef();
    this.frameTextInput = React.createRef();
    this.millisecondsTextInput = React.createRef();
  }

  componentDidMount() {
    this.onTimeComponentUpdated$ = new Subject();
    this.$onTimeComponentUpdated = this.onTimeComponentUpdated$.pipe(debounceTime(450)).subscribe(this.onTimeUpdated);
    this.onArrowTimeComponentUpdated$ = new Subject();
    this.$onArrowTimeComponentUpdated = this.onArrowTimeComponentUpdated$
      .pipe(debounceTime(700))
      .subscribe(this.onArrowsClick);
    this.onTimeComponentsUpdate(this.props.time);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.playerTimePickerRef) {
      this.playerTimePickerRef.addEventListener('keydown', this.onPlayerTimePickerKeyDown);
    }
  }

  componentDidUpdate(prevProps: IPlayerTimePickerProps, prevState: IPlayerTimePickerState) {
    if (prevProps.time !== this.props.time) {
      this.onTimeComponentsUpdate(this.props.time);
    }
    if (prevProps.isSelected !== this.props.isSelected && this.props.isSelected === true) {
      console.log(this.props.isSelected);
      this.hoursTextInput.current.focus();
    }
    if (prevState.focused !== this.state.focused && !this.state.focused && this.props.triggerOnBlur) {
      this.props.onChangeTime(this.getSecondsFromComponents());
    }
  }

  componentWillUnmount() {
    if (this.$onTimeComponentUpdated) {
      this.$onTimeComponentUpdated.unsubscribe();
    }
    if (this.$onArrowTimeComponentUpdated) {
      this.$onArrowTimeComponentUpdated.unsubscribe();
    }
    if (this.playerTimePickerRef) {
      this.playerTimePickerRef.removeEventListener('keydown', this.onPlayerTimePickerKeyDown);
    }
    if (this.focusBlurToggleTimeout) {
      clearTimeout(this.focusBlurToggleTimeout);
    }
  }

  roundMilliseconds = (milliseconds: number) => {
    return roundTwoDecimal(milliseconds / 1000) * 1000;
  };

  onTimeUpdated = (data: IInputUpdate) => {
    // Don't allow limits for minutes and seconds to be reached
    let numericValue = !isNaN(parseInt(data.value)) ? parseInt(data.value) : null;
    if (
      (data.type === 'minutes' || data.type === 'seconds' || data.type === 'frames' || data.type === 'milliseconds') &&
      numericValue
    ) {
      let minLimit = 59;
      if (data.type === 'milliseconds') {
        minLimit = 999;
        // NOTE: Workaround added because of the behavior of Bitmovin player
        // that rounds its current time with 2 numbers after the floating point
        numericValue = this.roundMilliseconds(numericValue);
      } else if (data.type === 'frames') {
        minLimit = this.getFrameRateValue() ? Math.round(this.getFrameRateValue()) - 1 : 23;
      }
      data.value = Smpte.padNum(Math.min(minLimit, numericValue), data.type === 'milliseconds');
    }
    const updateStateObject: any = {};
    updateStateObject[`${data.type}`] = data.value;
    this.onTimeComponentsUpdate(this.getSecondsFromComponents(updateStateObject), () => {
      if (!this.props.triggerOnBlur) {
        this.props.onChangeTime(this.getSecondsFromComponents());
      }
    });
  };

  onComponentUpdated = (value: string, type: componentsType) => {
    console.log(value, type);
    let pattern = /^[0-9]{2}$/;
    let defaultValue = '00';
    let digitLimit = 2;

    if (type === 'milliseconds') {
      pattern = /^[0-9]{3}$/;
      defaultValue = '000';
      digitLimit = 3;
    }
    // Update inserted value to match the provided format
    if (!pattern.test(value)) {
      // Remove every non-numeric value from the provided value
      value = value.replace(/\D/g, (match: string) => '');
      value = !value
        ? defaultValue
        : value.length > digitLimit
        ? value.substring(value.length - digitLimit, value.length)
        : Smpte.padNum(Number(value), type === 'milliseconds');
    }
    const updateStateObject: any = {};
    updateStateObject[`${type}`] = value;
    this.setState(updateStateObject, () => this.onTimeComponentUpdated$.next({value, type}));
  };

  getFrameRateValue = (): number => {
    return this.props.frameRate ? this.props.frameRate.frameRate || 24 : null;
  };

  getSecondsFromComponents = (comps?: {[x: string]: string}): number => {
    const components = {
      ...{
        hours: this.state.hours,
        minutes: this.state.minutes,
        seconds: this.state.seconds,
        frames: this.state.frames,
        milliseconds: this.state.milliseconds
      },
      ...(comps || {})
    };
    if (this.props.milliseconds) {
      // Calculate seconds if we have milliseconds timecode
      return [components.hours, components.minutes, components.seconds, components.milliseconds]
        .map(component => (!isNaN(parseInt(component)) ? parseInt(component) : 0))
        .reduce((sum, component, index) => {
          return sum + component * (index === 0 ? 3600 : index === 1 ? 60 : index === 3 ? 0.001 : 1);
        }, 0);
    }
    if (this.props.frameRate) {
      // Calculate seconds if we have SMPTE timecode
      return fromSMPTETokensToSeconds(
        components.hours,
        components.minutes,
        components.seconds,
        components.frames,
        this.props.frameRate.frameRate,
        this.props.frameRate.dropFrame
      );
    }
    // Calculate seconds if we have standard timecode
    return [components.hours, components.minutes, components.seconds]
      .map(component => (!isNaN(parseInt(component)) ? parseInt(component) : 0))
      .reduce((sum, component, index) => {
        return sum + component * (index === 0 ? 3600 : index === 1 ? 60 : 1);
      }, 0);
  };

  secondsToTimestamp = (time: number): string => {
    if (this.props.milliseconds) {
      return secondsToMillisecondsTimecode(time);
    } else if (this.props.frameRate) {
      return Smpte.fromTimeWithAdjustments(time, {
        frameRate: this.props.frameRate.frameRate,
        dropFrame: this.props.frameRate.dropFrame
      }).toString();
    } else {
      return secondsToHHmmss(time);
    }
  };

  onTimeComponentsUpdate = (time: number, callback?: () => void): void => {
    const min = typeof this.props.min !== 'undefined' ? this.props.min : null;
    if (min !== null && time < min) {
      time = min;
    }

    const max = typeof this.props.max !== 'undefined' ? this.props.max : null;
    if (max !== null && time > max) {
      time = max;
    }
    const components = getTimeComponents(
      this.secondsToTimestamp(time),
      this.props.milliseconds ? null : this.getFrameRateValue()
    );
    const partialState: any = {
      hours: components[0],
      minutes: components[1],
      seconds: components[2]
    };
    if (this.props.milliseconds) {
      partialState.milliseconds = Smpte.padNum(this.roundMilliseconds(+components[3]), true);
    } else {
      partialState.frames = components[3];
    }
    this.setState({...partialState}, callback || null);
  };

  onArrowsClick = (type: IArrowPositions) => {
    let duration = this.getSecondsFromComponents();
    const scaleUnit = this.props.milliseconds ? 0.01 : this.props.frameRate ? 1 / this.getFrameRateValue() : 1;

    if (type === 'up') {
      duration += scaleUnit;
    } else {
      duration -= scaleUnit;
    }

    const min = typeof this.props.min !== 'undefined' ? this.props.min : null;
    if (min !== null && duration < min) {
      duration = min;
    }

    const max = typeof this.props.max !== 'undefined' ? this.props.max : null;
    if (max !== null && duration > max) {
      duration = max;
    }

    console.log('Arrows', min, max, duration);

    this.onTimeComponentsUpdate(duration, () => this.props.onChangeTime(this.getSecondsFromComponents()));
  };

  onInputFocus = (e: any) => {
    e.target.select();
    if (this.focusBlurToggleTimeout) {
      clearTimeout(this.focusBlurToggleTimeout);
    }
    this.setState({focused: true});
  };

  onInputBlur = () => {
    if (this.focusBlurToggleTimeout) {
      clearTimeout(this.focusBlurToggleTimeout);
    }
    this.focusBlurToggleTimeout = setTimeout(() => this.setState({focused: false}), this.props.triggerOnBlur ? 250 : 0);
  };

  handleInputBackwardFocus = () => {
    const elementId = [parseInt(document.activeElement.id)].reduce((acc: number, parsedId: number) => {
      if (!isNaN(parsedId)) {
        return parsedId;
      }
      return null;
    }, null);
    const inputsArr = [
      this.hoursTextInput,
      this.minutesTextInput,
      this.secondsTextInput,
      this.props.milliseconds ? this.millisecondsTextInput : this.frameTextInput
    ];
    // In case the elementId is not parsed correctly we cannot define the order
    if (elementId === null) {
      return;
    }
    if (elementId !== 0) {
      const elem = inputsArr[elementId - 1];
      elem.current.focus();
    } else {
      inputsArr[inputsArr.length - 1].current.focus();
    }
  };

  handleInputForwardFocus = () => {
    const elementId = [parseInt(document.activeElement.id)].reduce((acc: number, parsedId: number) => {
      if (!isNaN(parsedId)) {
        return parsedId;
      }
      return acc;
    }, null);
    const inputsArr = [
      this.hoursTextInput,
      this.minutesTextInput,
      this.secondsTextInput,
      this.props.milliseconds ? this.millisecondsTextInput : this.frameTextInput
    ];
    // In case the elementId is not parsed correctly we cannot define the order
    if (elementId === null) {
      return;
    }
    if (elementId !== 3) {
      const elem = inputsArr[elementId + 1];
      elem.current.focus();
    } else {
      inputsArr[0].current.focus();
    }
  };

  onPlayerTimePickerKeyDown = (e: any) => {
    // Listener for the 'keydown' event should be managed only if picker is focused
    if (!this.state.focused) {
      return;
    }
    const key = e.keyCode;
    const shiftKey = e.shiftKey;
    if (key !== 9) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (shiftKey) {
      this.handleInputBackwardFocus();
    } else {
      this.handleInputForwardFocus();
    }
  };

  getFramesMillisecondsInputInput = () => {
    const delimiter = this.props.milliseconds ? '.' : ':';
    const value = this.props.milliseconds ? this.state.milliseconds : this.state.frames;
    const field = this.props.milliseconds ? 'milliseconds' : 'frames';
    const props = this.props.milliseconds ? {className: 'milliseconds-input'} : {};

    const input = (
      <>
        <span className="player-time-picker-container_fields_delimiter"> {delimiter} </span>
        <input
          tabIndex={-1}
          {...props}
          disabled={this.props.disabled}
          type="text"
          id={`3-${this.state.uniqueId}`}
          value={value}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          onChange={e => this.onComponentUpdated(e.target.value, field)}
          ref={this.props.milliseconds ? this.millisecondsTextInput : this.frameTextInput}
        />
      </>
    );

    return input;
  };

  render() {
    const {hours, minutes, seconds, focused} = this.state;
    return (
      <div
        className={`player-time-picker-container${focused ? ` focused` : ``}`}
        ref={node => (this.playerTimePickerRef = node)}
      >
        <div className="player-time-picker-container_fields">
          <input
            tabIndex={-1}
            disabled={this.props.disabled}
            type="text"
            id={`0-${this.state.uniqueId}`}
            value={hours}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onChange={e => this.onComponentUpdated(e.target.value, 'hours')}
            ref={this.hoursTextInput}
          />
          <span className="player-time-picker-container_fields_delimiter"> : </span>
          <input
            tabIndex={-1}
            disabled={this.props.disabled}
            type="text"
            id={`1-${this.state.uniqueId}`}
            value={minutes}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onChange={e => this.onComponentUpdated(e.target.value, 'minutes')}
            ref={this.minutesTextInput}
          />
          <span className="player-time-picker-container_fields_delimiter"> : </span>
          <input
            tabIndex={-1}
            disabled={this.props.disabled}
            id={`2-${this.state.uniqueId}`}
            type="text"
            value={seconds}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            onChange={e => this.onComponentUpdated(e.target.value, 'seconds')}
            ref={this.secondsTextInput}
          />
          {!!(this.props.frameRate || this.props.milliseconds) ? this.getFramesMillisecondsInputInput() : null}
        </div>
        <div className="player-time-picker-container_arrows">
          <ArrowButtons
            handleUp={() => this.onArrowTimeComponentUpdated$.next('up')}
            handleDown={() => this.onArrowTimeComponentUpdated$.next('down')}
            hidden={this.props.disabled}
          />
        </div>
      </div>
    );
  }
}
