import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';

import {HourGlassIcon} from '../../../../assets/Icons/HourGlass';
import {CloseIcon} from '../../../../assets/Icons/Close';

interface ITabsInfoProps {
  title?: string;
  icon?: 'Loading' | 'Error';
}

export class TabsInfo extends React.PureComponent<ITabsInfoProps> {
  render() {
    return (
      <div className="tabs-info-container">
        {['Loading', 'Error'].indexOf(this.props.icon || '') !== -1 && (
          <div className="tabs-info-container_icon-container">
            <Icon icon={this.props.icon === 'Loading' ? HourGlassIcon : CloseIcon} size="30px" color="#ffffff" />
          </div>
        )}
        {this.props.title && <div className="tabs-info-container_title-container">{this.props.title}</div>}
      </div>
    );
  }
}
