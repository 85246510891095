import * as React from 'react';
import {OverlayWrapper} from '../OverlayWrapper';
import {Dropdown, IDropdownOption} from '../../Dropdown';
import {Toggle} from '../../Toggle';
import {Button} from '../../Button';
import {Tt2b} from '../../Typography/Tt2b';
import {IEnum} from '../../../../@types/enum';
import {ITTManFrameRateOverlay} from '../../../../@types/ttManFrameRateOverlay';

interface ITTmanFrameRateOverlayProps {
  ttManFrameRateOverlayState: ITTManFrameRateOverlay;
  frameRateEnums: Array<IEnum>;
  assetHrId?: string;
  closestBody?: HTMLElement;
  onCancel: () => void;
  onUpdateTTmanFrameRate: (assetId: string, frameRate: string, dropFrame: boolean) => void;
}

interface ITTmanFrameRateOverlayState {
  selectedFrameRate: string;
  isDropFrame: boolean;
}

export class TTmanFrameRateOverlay extends React.PureComponent<
  ITTmanFrameRateOverlayProps,
  ITTmanFrameRateOverlayState
> {
  static defaultProps = {
    frameRateEnums: []
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedFrameRate: '',
      isDropFrame: !!props.ttManFrameRateOverlayState.ttManIsDropFrame
    };
  }

  onSelectedFrameRate = (selectedFrameRate: string) => {
    this.setState({selectedFrameRate});
  };

  onToggledFormat = (isDropFrame: boolean) => {
    this.setState({isDropFrame});
  };

  onSubmit = () => {
    this.props.onUpdateTTmanFrameRate(
      this.props.ttManFrameRateOverlayState.ttManFrameRateUpdateAssetId,
      this.state.selectedFrameRate,
      this.state.isDropFrame
    );
  };

  onCancel = () => {
    if (!this.props.ttManFrameRateOverlayState.updatingTTmanFrameRate) {
      this.props.onCancel();
    }
  };

  getDescription = () => {
    const assetHrId = this.props.assetHrId ? ` (${this.props.assetHrId})` : ``;
    return `
      In order to successfully request TTman transformation for the selected subtitle${assetHrId}, 
      please select the Frame Rate from the below dropdown and submit the value:
    `;
  };

  getContent = () => {
    const updating = this.props.ttManFrameRateOverlayState.updatingTTmanFrameRate;
    return (
      <div className="ttman-overlay-container_content">
        <Tt2b content={this.getDescription()} className="ttman-overlay-container_content_description" />
        <div className="ttman-overlay-container_content_frame-rate">
          <div className="ttman-overlay-container_content_frame-rate_dropdown">
            <Dropdown
              disabled={updating}
              label="Frame Rate"
              options={this.props.frameRateEnums.map(
                (record: IEnum) => ({label: record.name, value: record.value} as IDropdownOption)
              )}
              selected={this.state.selectedFrameRate}
              onSelected={this.onSelectedFrameRate}
              contentListLimit={4}
              search
              portalNode={this.props.closestBody}
            />
          </div>
          <div className="ttman-overlay-container_content_frame-rate_non-drop">
            <Toggle
              disabled={updating}
              label="Timecode Format"
              toggled={this.state.isDropFrame}
              labels={{enabled: 'Drop', disabled: 'Non-Drop'}}
              onToggle={this.onToggledFormat}
            />
          </div>
        </div>
        <div className="ttman-overlay-container_content_footer">
          <Button secondary content="Cancel" disabled={updating} onClick={this.props.onCancel} />
          <Button
            content={updating ? 'Updating...' : 'Submit'}
            disabled={!this.state.selectedFrameRate || updating}
            onClick={this.onSubmit}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <OverlayWrapper
        className="ttman-overlay-container"
        isOpen={!!this.props.ttManFrameRateOverlayState.ttManFrameRateUpdateAssetId}
        title="TTman Frame Rate"
        width={400}
        onCancel={this.onCancel}
      >
        {this.getContent()}
      </OverlayWrapper>
    );
  }
}
