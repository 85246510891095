import * as React from 'react';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IErrorLog, IAssetImages} from '../../../../../@types/assetDetails';
import {IEnums} from '../../../../state/IEnums';
import {deepCopy} from '../../utils/helpers';
import {Tt2b} from '../../../../components/Typography';
import {ImageRecord} from './components/ImageRecord';
import {IMetadataError} from '../../../../../@types/metadataErrors';

interface IImageProps {
  selectedAsset: PlaylistAsset;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  imagesMetadataErrors: Array<IMetadataError>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

interface IImageState {
  openCollapsibleContent: boolean;
}

export class Image extends React.PureComponent<IImageProps, IImageState> {
  constructor(props) {
    super(props);

    this.state = {
      openCollapsibleContent: this.props.tabsDataInEditMode
    };
  }
  getAssetData = () => {
    const currentAssetDetails =
      this.props.selectedAsset && this.props.selectedAsset.assetDetails ? this.props.selectedAsset.assetDetails : {};
    const updatedAssetDetails = this.props.updatedAssetDetails || {};
    return deepCopy({...currentAssetDetails, ...updatedAssetDetails});
  };

  handlePropUpdate = (imageId: string, prop: string, value: string | number) => {
    const currentAssetDetails = this.getAssetData();
    const images = (currentAssetDetails.images || []).reduce((acc: Array<IAssetDetails>, image: IAssetImages) => {
      if (image.id === imageId) {
        image = {...image, [prop]: value};
      }
      return [...acc, image];
    }, []);
    this.props.updatePartialAssetDetails({...this.props.updatedAssetDetails, images});
  };

  getContent = () => {
    const images = this.getAssetData().images || [];
    if (!images.length) {
      return (
        <Tt2b className="image-details-container_empty" content="No Image configurations are defined for this asset" />
      );
    }
    return images.reduce((acc: Array<JSX.Element>, image: IAssetImages, index: number) => {
      const imageElement = (
        <ImageRecord
          openCollapsibleContent={this.state.openCollapsibleContent}
          key={index}
          image={image}
          enums={this.props.enums}
          closestBody={this.props.closestBody}
          tabsDataInEditMode={this.props.tabsDataInEditMode}
          errorLogs={this.props.errorLogs}
          updateProp={this.handlePropUpdate}
          imagesMetadataErrors={this.props.imagesMetadataErrors}
          onCollapsibleToggle={(openCollapsibleContent: boolean) => this.setState({openCollapsibleContent})}
        />
      );
      return [...acc, imageElement];
    }, []);
  };

  render() {
    return <div className="image-details-container">{this.getContent()}</div>;
  }
}
