import * as React from 'react';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {OverlayWrapper} from '../OverlayWrapper';
import {CustomImage} from 'components/CustomImage';

interface IImageOverlayProps {
  imageAsset: PlaylistAsset;
  open: boolean;
  closestBodyElement?: HTMLElement;
  onCancel: () => void;
}

interface IImageOverlayState {
  width: number;
  height: number;
  x: number;
}

const maxWidth = 1024;
const maxHeight = 600;

export class ImageOverlay extends React.PureComponent<IImageOverlayProps, IImageOverlayState> {
  backDropElement: HTMLDivElement;
  overlayContainerElement: HTMLDivElement;
  constructor(props) {
    super(props);

    this.state = {
      width: 480,
      height: null,
      x: null
    };
  }

  componentDidMount() {
    this.registerBackDropElement();
    this.centerHorizontallyOverlay();
  }

  componentDidUpdate(prevProps: IImageOverlayProps, prevState: IImageOverlayState) {
    // TODO: Replace this workaround for centering overlay with functionality that passes X prop
    // directly to the Overlay component from tt-components repo
    if (prevState.x !== this.state.x && this.state.x && this.overlayContainerElement) {
      this.overlayContainerElement.style.transform = `translate(${this.state.x}px, 0px)`;
    }
  }

  componentWillUnmount() {
    if (this.backDropElement) {
      this.backDropElement.removeEventListener('click', this.props.onCancel);
    }
  }

  registerBackDropElement = () => {
    if (!this.props.closestBodyElement) {
      return;
    }
    this.backDropElement = this.props.closestBodyElement.querySelector('.overlay-backdrop');
    if (this.backDropElement) {
      this.backDropElement.addEventListener('click', this.props.onCancel);
    }
    this.overlayContainerElement = this.props.closestBodyElement.querySelector('.image-overlay-container');
  };

  defineDimensionProps = () => {
    return {
      width: Math.min(this.state.width, maxWidth) || 'auto',
      height: 'auto'
    };
  };

  onImageFinishedLoading = (fetched: boolean, width?: number, height?: number) => {
    return this.setState({width: fetched ? width : 480, height}, this.centerHorizontallyOverlay);
  };

  centerHorizontallyOverlay = () => {
    let x: number;
    if (!this.props.closestBodyElement) {
      x = null;
    } else {
      const bodyWidth = this.props.closestBodyElement.getBoundingClientRect().width;
      x = (bodyWidth - (Math.min(this.state.width, maxWidth) as number)) / 2;
    }
    this.setState({x});
  };

  getImageDimensions = () => {
    if (!this.props.imageAsset) {
      return {};
    }
    const image =
      this.props.imageAsset.assetDetails.images &&
      Array.isArray(this.props.imageAsset.assetDetails.images) &&
      this.props.imageAsset.assetDetails.images.length
        ? this.props.imageAsset.assetDetails.images[0]
        : null;
    const height = image ? (image.frameSizeHeight ? +image.frameSizeHeight : null) : null;
    const width = image ? (image.frameSizeWidth ? +image.frameSizeWidth : null) : null;

    const metadataDimensions: any = {};

    if (height !== null && !isNaN(height)) {
      metadataDimensions.height = height;
    }
    if (width !== null && !isNaN(width)) {
      metadataDimensions.width = width;
    }

    return metadataDimensions;
  };

  getContent = () => {
    const scrollX = isNaN(this.state.width) ? `` : this.state.width > maxWidth ? ` scroll-horizontal` : ``;
    const scrollY = isNaN(this.state.height) ? `` : this.state.height > maxHeight ? ` scroll-vertical` : ``;
    const {width, height} = this.getImageDimensions();
    return (
      <div className={`image-overlay-container_content${scrollX}${scrollY}`} style={{maxWidth, maxHeight}}>
        <CustomImage
          url={this.props.imageAsset ? this.props.imageAsset.publicUrl || '' : ''}
          onLoaded={this.onImageFinishedLoading}
          {...(width ? {width} : {})}
          {...(height ? {height} : {})}
        />
      </div>
    );
  };

  getTitle = () => {
    if (!this.props.imageAsset) {
      return 'Image Expanded';
    }
    const hrId = this.props.imageAsset.assetDetails.hrId || this.props.imageAsset.assetId || '';
    const name = this.props.imageAsset.assetDetails.name || '';
    const metadataDimensions = this.getImageDimensions();
    const dimensions = Object.keys(metadataDimensions).length
      ? `Frame: (${metadataDimensions.width} x ${metadataDimensions.height})`
      : `Frame: Undefined`;
    return `${hrId}${name ? `${name ? `${hrId ? ` - ` : ``}${name}` : ``}` : ``}${`${name ? ` - ` : ``}${dimensions}`}`;
  };

  render() {
    return (
      <OverlayWrapper
        className="image-overlay-container"
        isOpen={this.props.open}
        title={this.getTitle()}
        onCancel={this.props.onCancel}
        centerX={false}
        blockBackground
        allowDrag={false}
        {...this.defineDimensionProps()}
      >
        {this.getContent()}
      </OverlayWrapper>
    );
  }
}
