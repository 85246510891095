import * as React from 'react';
import {IAppPlaylist} from '../../../../state/IAppState';
import {ITabElement, TabsComponent} from '../TabsComponent';
import {Details} from './components/Details';
import {Video} from '../Video';
import {Audio} from '../Audio';
import {Image} from '../Image';
import {NonMedia} from '../NonMedia';
import {Subtitles} from '../Subtitles';
import {IAudioMetadata, ITabs} from '../../interfaces';
import {IAudioChannelConfiguration} from '../../../../../@types/audioChannelConfiguration';
import {IEnums} from '../../../../state/IEnums';
import {IResponse} from '../../../../../@types/response';
import {IAssetDetails} from '../../../../../@types/assetDetails';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IMetadataErrors, IMetadataMassUpdateFields} from '../../interfaces/IMetadataTab';

export interface IMetadataProps {
  updatedAssetDetails: Partial<IAssetDetails>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  selectedAsset: PlaylistAsset;
  selectedTab: ITabs;
  selectMetadataTab: (tab: ITabs) => void;
  getEvents: () => void;
  updateAudioConfigurations: (confs: IAudioChannelConfiguration) => IResponse;
  metadataErrors: IMetadataErrors;
  audioMetadata: IAudioMetadata;
  enums: IEnums;
  data: IAssetDetails;
  playlist: IAppPlaylist;
  loading: boolean;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  setAudioMetaData: (data: Partial<IAssetDetails>, index: number) => void;
  tabsContainer: HTMLElement;
  curationModeEnabled: boolean;
  metadataMassUpdateFields: Array<IMetadataMassUpdateFields>;
  onMetadataMassUpdateField: (field: IMetadataMassUpdateFields, remove?: boolean) => void;
}

export class Metadata extends React.PureComponent<IMetadataProps> {
  getTabs = () => {
    const tabs: ITabElement[] = [
      {
        type: ITabs.DetailsMetadata,
        name: 'Details',
        element: Details,
        visible: true,
        errors: this.props.metadataErrors.metadataDetails.length
      },
      {
        type: ITabs.VideoMetadata,
        name: 'Video',
        element: Video,
        visible: true,
        errors: this.props.metadataErrors.metadataVideo.length
      },
      {
        type: ITabs.AudioMetadata,
        name: 'Audio',
        element: Audio,
        visible: true,
        errors: this.props.metadataErrors.metadataAudio.length
      },
      {
        type: ITabs.SubtitlesMetadata,
        name: 'Subtitles',
        element: Subtitles,
        visible: true,
        errors: this.props.metadataErrors.metadataSubtitles.length
      },
      {
        type: ITabs.ImageMetadata,
        name: 'Images',
        element: Image,
        visible: true,
        errors: this.props.metadataErrors.metadataImages.length
      },
      {
        type: ITabs.NonMediaMetadata,
        name: 'Non Media',
        element: NonMedia,
        visible: true,
        errors: this.props.metadataErrors.metadataNonMedia.length
      }
    ];
    return tabs;
  };

  renderContent = () => {
    const selectedTab = this.getTabs().find(({type}: ITabElement) => this.props.selectedTab === type);
    if (!selectedTab) {
      return;
    }
    const TabContent = selectedTab.element;
    // NOTE: The errorLogs will be available only in cases when the 'Edit Mode' is enabled for Tab module
    const errorLogs =
      this.props.selectedAsset && this.props.tabsDataInEditMode ? this.props.selectedAsset.assetDetails.errorLogs : [];
    let props = null;
    switch (this.props.selectedTab) {
      case ITabs.DetailsMetadata:
        props = {
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          errorLogs,
          updatedAssetDetails: this.props.updatedAssetDetails,
          selectedAsset: this.props.selectedAsset,
          assetStatusEnums: this.props.enums.assetStatus,
          fileWrapperEnums: this.props.enums.fileWrapper,
          functionEnums: this.props.enums.assetFunction,
          contentTypeEnums: this.props.enums.contentType,
          formatComplianceEnums: this.props.enums.formatCompliance,
          frameRateEnums: this.props.enums.frameRate,
          referencesNameEnums: this.props.enums.referencesName,
          referencesTypeEnums: this.props.enums.referencesType,
          closestBody: this.props.closestBody,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          tabsContainer: this.props.tabsContainer,
          curationModeEnabled: this.props.curationModeEnabled,
          detailsMetadataErrors: this.props.metadataErrors.metadataDetails,
          metadataMassUpdateFields: this.props.metadataMassUpdateFields,
          onMetadataMassUpdateField: this.props.onMetadataMassUpdateField
        };
        break;
      case ITabs.VideoMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          videoMetadataErrors: this.props.metadataErrors.metadataVideo,
          enums: this.props.enums,
          getEvents: this.props.getEvents,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody
        };
        break;
      case ITabs.AudioMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          processingMetadata: this.props.audioMetadata ? this.props.audioMetadata.metadataProcessing : false,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          updateAudioConfigurations: this.props.updateAudioConfigurations,
          loading: this.props.loading,
          error: this.props.playlist ? this.props.playlist.error : null,
          audioMetadataErrors: this.props.metadataErrors.metadataAudio,
          closestBody: this.props.closestBody
        };
        break;
      case ITabs.ImageMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody,
          imagesMetadataErrors: this.props.metadataErrors.metadataImages
        };
        break;
      case ITabs.NonMediaMetadata:
        props = {
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody,
          nonMediaMetadataErrors: this.props.metadataErrors.metadataNonMedia
        };
        break;
      case ITabs.SubtitlesMetadata:
        props = {
          playlist: this.props.playlist,
          selectedAsset: this.props.selectedAsset,
          errorLogs,
          updatePartialAssetDetails: this.props.updatePartialAssetDetails,
          updatedAssetDetails: this.props.updatedAssetDetails,
          enums: this.props.enums,
          tabsDataInEditMode: this.props.tabsDataInEditMode,
          closestBody: this.props.closestBody,
          subtitlesMetadataErrors: this.props.metadataErrors.metadataSubtitles
        };
        break;
      default:
        props = {content: ''};
    }

    return <TabContent {...props} />;
  };

  render() {
    return (
      <div className="metadata-container">
        <TabsComponent
          gridView
          tabsList={this.getTabs()}
          selectedTab={this.props.selectedTab}
          onTabChanged={(tab: ITabs) => this.props.selectMetadataTab(tab)}
          tabContent={this.renderContent()}
        />
      </div>
    );
  }
}
