import {connect} from 'react-redux';
import {Tabs} from './components/Tabs';
import {IAppState} from '../../state/IAppState';
import {ITabs, ITabsDispatchProps, ITabsOwnProps, ITabsProps, ICreateComment} from './interfaces';
import {IAssetDetails} from '../../../@types/assetDetails';
import {
  selectMetadataTab,
  selectTab,
  selectVersion,
  deleteComment,
  addCommentAndUpdateData,
  editComment,
  addReplyAndUpdateData,
  updatePartialAssetDetails,
  getPlayerCurrentTime,
  updateUseStartTimecodeFlag,
  updateMarkupsListOrder,
  updateMetadataMassUpdateField
} from './actions/tabs';
import {
  updateChangedEventGroup,
  updateSelectedEventGroup,
  setVideoFragmentInTimeByCurrentTime,
  setVideoFragmentOutTimeByCurrentTime,
  setVideoFragmentInTime,
  setVideoFragmentOutTime,
  goToLocation,
  updateAudioConfigurations,
  getVideoCurrentTime,
  getEventGroups,
  getEvents,
  updateUnknownAssetType
} from '../../actions/video';
import {addNewEventsGroup} from '../../data/atlasAPI';
import {IMarkupsEventGroup} from '../../state/IVideoState';
import {IAudioChannelConfiguration} from '../../../@types/audioChannelConfiguration';
import {IEventGroup} from '../../../@types/markupEvent';
import {getPlaylistSelectedAsset, getTabsConfigurations} from '../../selectors';
import {IAssetType} from '../../../@types/assetType';
import {IMarkupsOrder} from './interfaces/IMarkupsTab';
import {IMetadataMassUpdateFields} from './interfaces/IMetadataTab';

const mapStateToPropsFactory = () => {
  return (state: IAppState, ownProps: ITabsOwnProps): ITabsProps & ITabsOwnProps => {
    return {
      displayMediaTimeFormat: state.video.displayMediaTimeFormat,
      playlist: state.video.playlist,
      markupsErrors: state.video.markupsErrors,
      selectedEventGroup: state.video.selectedEventGroup,
      videoMetadata: state.video.metadata,
      changedEvents: state.video.changedEvents,
      eventGroups: state.video.eventGroups,
      eventCategories: state.video.categories,
      currentVideoFragment: state.video.currentVideoFragment,
      eventTypes: state.video.types,
      loadingPlayer: state.video.playlist.loading,
      loadingAsset: ownProps.loadingAsset,
      selectedAsset: getPlaylistSelectedAsset(state),
      configuration: getTabsConfigurations(state),
      tabs: state.tabs,
      closestBodyElement: ownProps.closestBodyElement,
      commentsTab: state.tabs.commentsTab,
      metadataTab: state.tabs.metadataTab,
      markupsTab: state.tabs.markupsTab,
      enums: state.video.enums,
      showingDropdownTimeout: state.configuration.showingDropdownTimeout,
      shortcutSubject: ownProps.shortcutSubject,
      inEditMode: state.tabs.inEditMode,
      onSeek: ownProps.onSeek
    };
  };
};

const mapDispatchToProps = (dispatch): ITabsDispatchProps => ({
  selectTab: (tab: ITabs) => dispatch(selectTab(tab)),
  selectVersion: (versionId: string) => dispatch(selectVersion(versionId)),
  selectMetadataTab: (tab: ITabs) => dispatch(selectMetadataTab(tab)),
  deleteComment: (id: number) => dispatch(deleteComment(id)),
  addComment: (comment: ICreateComment) => dispatch(addCommentAndUpdateData(comment)),
  editComment: (id: number, text: string) => dispatch(editComment(id, text)),
  addReply: (commentId: number, text: string) => dispatch(addReplyAndUpdateData(commentId, text)),
  updateSelectedEventGroup: (name: IMarkupsEventGroup) => dispatch(updateSelectedEventGroup(name)),
  getEvents: () => dispatch(getEvents()),
  addNewEventsGroup: (id: string) => {
    addNewEventsGroup(id).then(() => dispatch(getEventGroups()));
  },
  setVideoFragmentInTimeByCurrentTime: () => dispatch(setVideoFragmentInTimeByCurrentTime()),
  setVideoFragmentOutTimeByCurrentTime: () => dispatch(setVideoFragmentOutTimeByCurrentTime()),
  setVideoFragmentInTime: (timeIn: number) => dispatch(setVideoFragmentInTime(timeIn)),
  setVideoFragmentOutTime: (timeOut: number) => dispatch(setVideoFragmentOutTime(timeOut)),
  goToLocation: (time: number) => dispatch(goToLocation(time)),
  updateAudioConfigurations: (confs: Array<IAudioChannelConfiguration>) => dispatch(updateAudioConfigurations(confs)),
  getVideoCurrentTime: () => dispatch(getVideoCurrentTime()),
  updateChangedEventGroup: (changedEventGroup: IEventGroup) => dispatch(updateChangedEventGroup(changedEventGroup)),
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) =>
    dispatch(updatePartialAssetDetails(assetDetails)),
  getPlayerCurrentTime: () => dispatch(getPlayerCurrentTime()),
  updateUseStartTimecodeFlag: (flag: boolean) => dispatch(updateUseStartTimecodeFlag(flag)),
  updateUnknownAssetType: (type: IAssetType) => dispatch(updateUnknownAssetType(type)),
  onOrderChange: (order: IMarkupsOrder) => dispatch(updateMarkupsListOrder(order)),
  onMetadataMassUpdateField: (field: IMetadataMassUpdateFields, remove?: boolean) =>
    dispatch(updateMetadataMassUpdateField(field, remove))
});

export default connect(
  mapStateToPropsFactory,
  mapDispatchToProps
)(Tabs);
