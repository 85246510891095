import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Dropdown, Icon, TimePicker} from 'tt-components';
import {CustomCheckbox} from '../../../../../../components/CustomCheckbox';
import {IFrameRate} from 'tt-components/src/frameRate';
import {IMarkupEvent} from '../../../../../../../@types/markupEvent';
import {utils} from 'tt-components/src/Utils';
import {throttle} from '../../../../../../utils/utils';
import {compareStringArrays, deepCopy, clearProps} from '../../../../utils/helpers';
import {PlaylistAsset} from '../../../../../../models/PlaylistAsset/PlaylistAsset';

import {FilterIcon} from '../../../../../../assets/Icons/Filter';

export interface IFilterProps {
  events: Array<IMarkupEvent>;
  selectedEventGroup: string;
  types: {name: string; data: Array<string>};
  duration: number;
  categories: {name: string; data: Array<string>};
  frameRate: IFrameRate;
  onFilterEvents: (
    fromTime: number,
    toTime: number,
    filterTypes: Array<string>,
    filterCategories: Array<string>
  ) => void;
  closestBody: HTMLElement;
}

export interface IFilterState {
  fromTime: number;
  toTime: number;
  types: {};
  categories: {};
  [key: string]: any;
}

const templateColors = {
  main: '#ffffff',
  highlight: '#378fef',
  tooltipBackground: 'rgba(27, 30, 36, 0.89)'
};

const defaultPTTypes = PlaylistAsset.parsing.defaultPTTypes.map(type => type.type);

export class Filter extends React.PureComponent<IFilterProps, IFilterState> {
  constructor(props) {
    super(props);
    this.state = {
      fromTime: 0,
      toTime: 0,
      types: {},
      categories: {}
    };
  }

  componentDidMount() {
    let newCategories = {};
    let newTypes = {};
    this.props.categories.data.forEach(category => {
      let name = this.camelize(category);
      newCategories[name] = {name: category, count: 0, checked: false};
    });
    this.props.types.data.forEach(type => {
      let name = this.camelize(type);
      newTypes[name] = {name: type, count: 0, checked: false};
    });
    this.getData(newTypes, newCategories, this.props.duration);
  }

  componentDidUpdate(prevProps, prevState) {
    const eventsUpdated = this.areEventsUpdated(prevProps.events);
    if (
      prevProps.types.name !== this.props.types.name ||
      !compareStringArrays(prevProps.types.data, this.props.types.data) ||
      prevProps.categories.name !== this.props.categories.name ||
      prevProps.duration !== this.props.duration ||
      eventsUpdated
    ) {
      let newCategories = {};
      let newTypes = {};
      this.props.categories.data.forEach(category => {
        let name = this.camelize(category);
        newCategories[name] = {name: category, count: 0, checked: false};
      });
      this.props.types.data.forEach(type => {
        let name = this.camelize(type);
        newTypes[name] = {name: type, count: 0, checked: false};
      });
      this.getData(newTypes, newCategories, this.props.duration, eventsUpdated);
    }
  }

  areEventsUpdated = (prevEvents: Array<IMarkupEvent>) => {
    // NOTE: Remove hidden prop from the events because it's the field that
    // is updated on every triggered filtering so we avoid state looping
    const prevEventsCopy = clearProps(deepCopy([...prevEvents]), ['hidden']);
    const currentEventsCopy = clearProps(deepCopy([...this.props.events]), ['hidden']);

    return !isEqual(prevEventsCopy, currentEventsCopy);
  };

  camelize = str => {
    return (
      str &&
      str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) {
          return '';
        }
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      })
    );
  };

  getData = (newMarkupTypes, newCategories, duration, eventsUpdated: boolean = false) => {
    this.props.events.map(item => {
      const editType = this.camelize(item.type);
      const reason = this.camelize(item.category);
      if (newMarkupTypes[editType]) {
        let type = newMarkupTypes[editType];
        type.count += 1;
        type.checked = true;
      }
      if (newCategories[reason]) {
        let category = newCategories[reason];
        category.count += 1;
        category.checked = true;
      }
    });
    let types = {};
    let categories = {};
    Object.keys(newMarkupTypes).map(type => {
      types[type] = eventsUpdated ? this.updateTypeState(newMarkupTypes[type], type) : newMarkupTypes[type];
    });
    Object.keys(newCategories).map(category => {
      categories[category] = eventsUpdated
        ? this.updateCategoryState(newCategories[category], category)
        : newCategories[category];
    });
    this.setState({toTime: duration, types, categories}, () => this.triggerFilter(0));
  };

  updateTypeState = (newStateType: {count: number; checked: boolean}, type: string) => {
    const oldStateType = this.state.types[type] as {count: number; checked: boolean};
    const checked = oldStateType ? (oldStateType.count ? oldStateType.checked : newStateType.checked) : newStateType;
    return {...newStateType, checked};
  };

  updateCategoryState = (newStateCategory: {count: number; checked: boolean}, category: string) => {
    const oldStateCategory = this.state.categories[category] as {count: number; checked: boolean};
    const checked = oldStateCategory
      ? oldStateCategory.count
        ? oldStateCategory.checked
        : newStateCategory.checked
      : newStateCategory;
    return {...newStateCategory, checked};
  };

  triggerFilter = (fromTime: number = null) => {
    console.log('Filter initiated');
    const checkedTypes = this.getCheckedItems(this.state.types);
    const checkedCategories = this.getCheckedItems(this.state.categories);
    this.props.onFilterEvents(
      fromTime === null ? this.state.fromTime : fromTime,
      this.state.toTime,
      checkedTypes,
      checkedCategories
    );
  };

  getCheckedItems = data => {
    const filterRes = [];
    Object.keys(data).map(type => {
      if (data[type].checked === true) {
        filterRes.push(data[type].name);
      }
    });
    return filterRes;
  };

  fromTimeChange = changedTime => {
    const {onFilterEvents} = this.props;
    const {toTime, types, categories} = this.state;
    const filterTypes = this.getCheckedItems(types);
    const filterCategories = this.getCheckedItems(categories);
    onFilterEvents(changedTime, toTime, filterTypes, filterCategories);
    this.setState({fromTime: changedTime});
  };

  toTimeChange = changedTime => {
    const {onFilterEvents} = this.props;
    const {fromTime, types, categories} = this.state;
    const filterTypes = this.getCheckedItems(types);
    const filterCategories = this.getCheckedItems(categories);
    onFilterEvents(fromTime, changedTime, filterTypes, filterCategories);
    this.setState({toTime: changedTime});
  };

  onFromTimeChange = () => throttle(this.fromTimeChange, 1000);

  onToTimeChange = () => throttle(this.toTimeChange, 1000);

  onTypeChange = name => {
    const {onFilterEvents} = this.props;
    const {fromTime, toTime, types, categories} = this.state;
    const typesCopy = deepCopy({...types});
    const updatedMarkupTypes = {
      ...typesCopy,
      [name]: {
        ...typesCopy[name],
        checked: !typesCopy[name].checked
      }
    };
    const filterTypes = this.getCheckedItems(updatedMarkupTypes);
    const filterCategories = this.getCheckedItems(categories);
    onFilterEvents(fromTime, toTime, filterTypes, filterCategories);
    this.setState({
      types: updatedMarkupTypes
    });
  };

  onCategoryChange = name => {
    const {onFilterEvents} = this.props;
    const {fromTime, toTime, types, categories} = this.state;
    const categoriesCopy = deepCopy({...categories});
    const updatedCategories = {
      ...categoriesCopy,
      [name]: {
        ...categoriesCopy[name],
        checked: !categoriesCopy[name].checked
      }
    };
    const filterTypes = this.getCheckedItems(types);
    const filterCategories = this.getCheckedItems(updatedCategories);
    onFilterEvents(fromTime, toTime, filterTypes, filterCategories);
    this.setState({
      categories: updatedCategories
    });
  };

  getFilterContent = () => {
    const {fromTime, toTime, types, categories} = this.state;
    const {frameRate, selectedEventGroup} = this.props;
    return (
      <div className="filter-container">
        <div className="filter-time-pickers">
          <p>In Time Range</p>
          <div className="time-input">
            <label>From Timecode</label>
            <TimePicker
              changeTime={this.onFromTimeChange()}
              time={utils.formatting.getSmpteTimecode(fromTime, frameRate.frameRate)}
              disabled={false}
              frameRate={frameRate}
              userLastComma={false}
            />
          </div>
          <div className="time-input">
            <label>To Timecode</label>
            <TimePicker
              changeTime={this.onToTimeChange()}
              time={utils.formatting.getSmpteTimecode(toTime, frameRate.frameRate)}
              disabled={false}
              frameRate={frameRate}
              userLastComma={false}
            />
          </div>
        </div>
        <div className="filter-checkbox">
          <div className="filter-types">
            <p>Markups Type</p>
            {Object.keys(types).map((item, index) => {
              const type = types[item];
              return (
                <div key={index}>
                  <CustomCheckbox
                    key={index}
                    index={index}
                    id={type.name}
                    text=" "
                    checked={type.checked}
                    disabled={type.count === 0 || defaultPTTypes.indexOf((type.name || '').toLowerCase()) !== -1}
                    onChange={() => this.onTypeChange(item)}
                    templateColors={templateColors}
                  />
                  <span>{type.name}</span>
                  {type.count !== 0 && <span>({type.count})</span>}
                </div>
              );
            })}
          </div>
          {selectedEventGroup === 'Compliance Edits' && (
            <div className="filter-category">
              <p>Category</p>
              {Object.keys(categories).map((item, index) => {
                const category = categories[item];
                return (
                  <div key={index}>
                    <CustomCheckbox
                      key={index}
                      index={index}
                      id={category.name}
                      text=" "
                      checked={category.checked}
                      disabled={category.count === 0}
                      onChange={() => this.onCategoryChange(item)}
                      templateColors={templateColors}
                    />
                    <span>{category.name}</span>
                    {category.count !== 0 && <span>({category.count})</span>}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <Dropdown
        title="Filter"
        className="filter-drop-down"
        buttonComponent={() => <Icon icon={FilterIcon} color="#fff" size="20px" />}
        content={this.getFilterContent()}
        portalNode={this.props.closestBody}
      />
    );
  }
}
