import {Action, handleActions} from 'redux-actions';
import {ITabs, ITabsState} from '../interfaces/ITabsState';
import {
  SELECT_METADATA_TAB,
  SELECT_TAB,
  SELECT_VERSION,
  SET_COMMENTS,
  ADD_COMMENT,
  ADD_REPLY,
  PROCESSING_AUDIO_METADATA,
  SET_EDIT_MODE,
  SAVE_TABS_CONTENT,
  UPDATE_PARTIAL_ASSET_DETAILS,
  UPDATE_METADATA_ERRORS,
  UPDATE_USE_START_TIMECODE_FLAG,
  UPDATE_MARKUPS_LIST_ORDER,
  UPDATE_METADATA_MASS_UPDATE_FIELDS
} from '../actions/tabs';

export const DEFAULT_TABS_STATE: ITabsState = {
  selectedTab: ITabs.Empty,
  selectedVersion: '',
  inEditMode: false,
  commentsTab: {
    comments: [
      // {
      //   id: 3,
      //   comment: 'new test comment 3',
      //   createdBy: {
      //     displayName: 'contractor.oleksandr.horobets',
      //     avatar: '/static/img/default-avatar.png'
      //   },
      //   createdAt: new Date('2019-05-09 13:05:47 GMT+0000'),
      //   parent: null,
      //   inTime: 118,
      //   outTime: 885,
      //   externalId: '10',
      //   isCurrentUser: true
      // },
      // {
      //   id: 4,
      //   comment: 'Test reply 1',
      //   createdBy: {
      //     displayName: 'Another user',
      //     avatar: '/static/img/default-avatar.png'
      //   },
      //   createdAt: new Date('2019-05-09 13:44:14 GMT+0000'),
      //   parent: 3,
      //   inTime: 446,
      //   outTime: 998,
      //   externalId: '10',
      //   isCurrentUser: false
      // },
      // {
      //   id: 5,
      //   comment: 'Test reply 2',
      //   createdBy: {
      //     displayName: 'contractor.oleksandr.horobets',
      //     avatar: '/static/img/default-avatar.png'
      //   },
      //   createdAt: new Date('2019-05-09 13:44:20 GMT+0000'),
      //   parent: 3,
      //   inTime: 1190,
      //   outTime: 1466,
      //   externalId: '10',
      //   isCurrentUser: true
      // },
      // {
      //   id: 6,
      //   comment: 'Test reply 3',
      //   createdBy: {
      //     displayName: 'Another user',
      //     avatar: '/static/img/default-avatar.png'
      //   },
      //   createdAt: new Date('2019-05-09 13:44:24 GMT+0000'),
      //   parent: 3,
      //   inTime: 12,
      //   outTime: 19,
      //   externalId: '10',
      //   isCurrentUser: false
      // },
      // {
      //   id: 7,
      //   comment: 'new test comment 6',
      //   createdBy: {
      //     displayName: 'contractor.oleksandr.horobets',
      //     avatar: '/static/img/default-avatar.png'
      //   },
      //   createdAt: new Date('2019-05-09 13:51:23 GMT+0000'),
      //   parent: null,
      //   inTime: 47,
      //   outTime: 90,
      //   externalId: '10',
      //   isCurrentUser: true
      // },
      // {
      //   id: 2,
      //   comment: 'new test comment 8',
      //   createdBy: {
      //     displayName: 'Another user',
      //     avatar: '/static/img/default-avatar.png'
      //   },
      //   createdAt: new Date('2019-05-07 17:14:18 GMT+0000'),
      //   parent: null,
      //   inTime: 119,
      //   outTime: 119,
      //   externalId: '10',
      //   isCurrentUser: false
      // }
    ]
  },
  metadataTab: {
    selectedMetadataTab: ITabs.DetailsMetadata,
    audioMetadata: {
      metadataProcessing: false
    },
    metadataErrors: {
      metadataDetails: [],
      metadataVideo: [],
      metadataAudio: [],
      metadataNonMedia: [],
      metadataImages: [],
      metadataSubtitles: []
    },
    metadataMassUpdateFields: []
  },
  markupsTab: {
    useStartTimecode: true,
    markupsOrder: 'asc'
  },
  savingTabsContent: false,
  updatedAssetDetails: {}
};

export const tabs = handleActions<ITabsState, any>(
  {
    [SELECT_TAB]: (state: ITabsState, action: Action<SELECT_TAB>): ITabsState => {
      return {
        ...state,
        selectedTab: action.payload
      };
    },
    [SELECT_VERSION]: (state: ITabsState, action: Action<SELECT_VERSION>): ITabsState => {
      return {
        ...state,
        selectedVersion: action.payload
      };
    },
    [SELECT_METADATA_TAB]: (state: ITabsState, action: Action<SELECT_METADATA_TAB>): ITabsState => {
      return {
        ...state,
        metadataTab: {
          ...state.metadataTab,
          selectedMetadataTab: action.payload
        }
      };
    },
    [SET_COMMENTS]: (state: ITabsState, action: Action<SET_COMMENTS>): ITabsState => {
      return {
        ...state,
        commentsTab: {
          ...state.commentsTab,
          comments: action.payload
        }
      };
    },
    [ADD_COMMENT]: (state: ITabsState, action: Action<ADD_COMMENT>): ITabsState => {
      return {
        ...state,
        commentsTab: {
          ...state.commentsTab,
          comments: [...state.commentsTab.comments, action.payload]
        }
      };
    },
    [ADD_REPLY]: (state: ITabsState, action: Action<ADD_REPLY>): ITabsState => {
      const comments = state.commentsTab.comments.filter(comment => comment.id !== null);

      return {
        ...state,
        commentsTab: {
          ...state.commentsTab,
          comments: [...comments, action.payload]
        }
      };
    },
    [PROCESSING_AUDIO_METADATA]: (state: ITabsState, action: Action<PROCESSING_AUDIO_METADATA>): ITabsState => {
      return {
        ...state,
        metadataTab: {
          ...state.metadataTab,
          audioMetadata: {
            ...state.metadataTab.audioMetadata,
            metadataProcessing: action.payload
          }
        }
      };
    },
    [SET_EDIT_MODE]: (state: ITabsState, action: Action<SET_EDIT_MODE>): ITabsState => {
      return {
        ...state,
        inEditMode: action.payload
      };
    },
    [SAVE_TABS_CONTENT]: (state: ITabsState, action: Action<SAVE_TABS_CONTENT>): ITabsState => {
      return {
        ...state,
        savingTabsContent: action.payload
      };
    },
    [UPDATE_PARTIAL_ASSET_DETAILS]: (state: ITabsState, action: Action<UPDATE_PARTIAL_ASSET_DETAILS>): ITabsState => {
      return {
        ...state,
        updatedAssetDetails: action.payload
      };
    },
    [UPDATE_METADATA_ERRORS]: (state: ITabsState, action: Action<UPDATE_METADATA_ERRORS>): ITabsState => {
      return {
        ...state,
        metadataTab: {
          ...state.metadataTab,
          metadataErrors: {
            ...state.metadataTab.metadataErrors,
            ...action.payload
          }
        }
      };
    },
    [UPDATE_USE_START_TIMECODE_FLAG]: (
      state: ITabsState,
      action: Action<UPDATE_USE_START_TIMECODE_FLAG>
    ): ITabsState => {
      return {
        ...state,
        markupsTab: {
          ...state.markupsTab,
          useStartTimecode: action.payload
        }
      };
    },
    [UPDATE_MARKUPS_LIST_ORDER]: (state: ITabsState, action: Action<UPDATE_MARKUPS_LIST_ORDER>): ITabsState => {
      return {
        ...state,
        markupsTab: {
          ...state.markupsTab,
          markupsOrder: action.payload
        }
      };
    },
    [UPDATE_METADATA_MASS_UPDATE_FIELDS]: (
      state: ITabsState,
      action: Action<UPDATE_METADATA_MASS_UPDATE_FIELDS>
    ): ITabsState => {
      return {
        ...state,
        metadataTab: {
          ...state.metadataTab,
          metadataMassUpdateFields: action.payload
        }
      };
    }
  },
  DEFAULT_TABS_STATE
);
