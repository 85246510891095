import * as React from 'react';
import {ITemplateColors} from '../../../../@types/templateColors';
import {Icon} from 'tt-components/src/Icon';

import {VideoNotFoundSvgIcon} from '../../../assets/Icons/VideoNotFoundSvgIcon';
import Loading from '../../PlayerOverlays/Loading';

interface IPlaybackProps {
  showLoading?: boolean;
  hideIcon?: boolean;
  className?: string;
  templateColors: ITemplateColors;
  mainTitle?: string;
  descriptionContent?: JSX.Element | string;
  icon?: JSX.Element;
}

export const Playback = (props: IPlaybackProps) => (
  <div className={`playback-setup-overlay${props.className ? ` ${props.className}` : ``}`}>
    {!props.hideIcon && (
      <div className={`playback-setup-overlay_icon-holder${props.showLoading ? ` loading` : ``}`}>
        {props.showLoading ? (
          <Loading templateColors={props.templateColors} />
        ) : (
          props.icon || <Icon icon={VideoNotFoundSvgIcon} size="60px" color={props.templateColors.highlight} />
        )}
      </div>
    )}
    {!!props.mainTitle && (
      <div className="playback-setup-overlay_main-title" style={{color: props.templateColors.main}}>
        {props.mainTitle}
      </div>
    )}
    {!!props.descriptionContent && <div className="playback-setup-overlay_description">{props.descriptionContent}</div>}
  </div>
);
