import * as React from 'react';
import {Overlay, IOverlayProps} from 'tt-components/src/Overlays/Overlay';

interface IOverlayWrapperProps extends IOverlayProps {
  className?: string;
  isOpen: boolean;
  onCancel: () => void;
}

interface IOverlayWrapperState {
  isOpen: boolean;
}

export class OverlayWrapper extends React.PureComponent<IOverlayWrapperProps, IOverlayWrapperState> {
  static defaultProps = {
    width: `600px`,
    y: 0,
    centerY: false
  };

  static getDerivedStateFromProps(nextProps: IOverlayWrapperProps, prevState: IOverlayWrapperState) {
    if (nextProps.isOpen !== prevState.isOpen) {
      return {isOpen: nextProps.isOpen};
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  render() {
    const {className, isOpen, ...otherProps} = this.props;
    return (
      <Overlay
        isOpen={this.state.isOpen}
        className={`overlay-container${this.props.className ? ` ${this.props.className}` : ``}`}
        {...otherProps}
      >
        {this.props.children}
      </Overlay>
    );
  }
}
