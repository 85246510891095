import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const PopoverTailIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width="127px" height="90px" viewBox="0 0 127 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M152.328125,0.366215626 L126.569746,0.366215626 C122.476558,0.366215626 
        118.665686,2.45258711 116.460369,5.90088556 L75.5856122,69.8138376 C72.014909,75.3970924 
        64.5941598,77.0285879 59.010905,73.4578847 C57.7128881,72.6277537 56.5868831,71.5554184 
        55.6943998,70.2994571 L9.5878436,5.4152661 C7.33702525,2.24776586 3.69178255,0.366215626 
        -0.193991638,0.366215626 Z"
          id="Rectangle-17"
          fill={props.color}
        />
      </g>
    </svg>
  );
};
