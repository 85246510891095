import * as React from 'react';
import {CollapisbleWithEditModeToggle} from '../../../Collapsible';
import {TitleInfo} from './components/TitleInfo';
import {AssetInfo} from './components/AssetInfo';
import {IAssetDetails, IErrorLog} from '../../../../../../../@types/assetDetails';
import {IEnum} from '../../../../../../../@types/enum';
import {deepCopy, getTitleInfo} from '../../../../utils/helpers';
import {ISearchTitle} from '../../../../../../../@types/searchTitle';
import {PlaylistAsset} from '../../../../../../models/PlaylistAsset/PlaylistAsset';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';
import {IOneCustomer} from '../../../../../../../@types/oneCustomer';
import {getCustomerDataAPI, getCustomersListAPI} from '../../../../../../data/customerAPI';
import {IResponse} from '../../../../../../../@types/response';
import {IMetadataMassUpdateFields} from '../../../../interfaces/IMetadataTab';

interface IDetailsProps {
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  updatedAssetDetails: Partial<IAssetDetails>;
  selectedAsset: PlaylistAsset;
  assetStatusEnums: Array<IEnum>;
  fileWrapperEnums: Array<IEnum>;
  functionEnums: Array<IEnum>;
  contentTypeEnums: Array<IEnum>;
  formatComplianceEnums: Array<IEnum>;
  frameRateEnums: Array<IEnum>;
  referencesNameEnums: Array<IEnum>;
  referencesTypeEnums: Array<IEnum>;
  closestBody?: HTMLElement;
  tabsContainer?: HTMLElement;
  tabsDataInEditMode: boolean;
  curationModeEnabled: boolean;
  detailsMetadataErrors: Array<IMetadataError>;
  errorLogs: Array<IErrorLog>;
  metadataMassUpdateFields: Array<IMetadataMassUpdateFields>;
  onMetadataMassUpdateField: (field: IMetadataMassUpdateFields, remove?: boolean) => void;
}

interface IDetailsState {
  openTitleInfo: boolean;
  openAssetInfo: boolean;
  contentOwners: Array<IOneCustomer>;
  contentProviders: Array<IOneCustomer>;
  allContentOwners: Array<IOneCustomer>;
  distributors: Array<IOneCustomer>;
  role: string;
}

const updateId = (suffix: 'reference' | 'distributor') => (data: {[x: string]: any}, index: number) => {
  return {id: `${index}-${suffix}`, ...data};
};

export class Details extends React.PureComponent<IDetailsProps, IDetailsState> {
  constructor(props) {
    super(props);

    this.state = {
      openTitleInfo: this.props.tabsDataInEditMode,
      openAssetInfo: this.props.tabsDataInEditMode,
      contentOwners: [],
      allContentOwners: [],
      contentProviders: [],
      distributors: [],
      role: ''
    };
  }

  componentDidMount() {
    this.loadCustomerData();
  }

  componentDidUpdate(prevProps: IDetailsProps) {
    // Close collapsible components if selected asset is disabled
    if (!!prevProps.selectedAsset && !this.props.selectedAsset) {
      this.setState({openTitleInfo: false, openAssetInfo: false});
    }
  }

  getUserRole = () => {
    const cookiesData = decodeURIComponent(document.cookie);
    const data: any = cookiesData.split('; ').reduce((prev, current) => {
      const [name, value] = current.split('=');
      prev[name] = value;
      return prev;
    }, {});
    const userData: any = data && data.user ? JSON.parse(data.user) : {};
    return userData.groups ? userData.groups.role.name : '';
  };

  loadCustomerData = async () => {
    const customerData = (await getCustomerDataAPI(this.props.selectedAsset.assetDetails.contentOwnerId)) as IResponse;
    const contentOwnersResponse = (await getCustomersListAPI(
      'content_owner',
      customerData.data.customerGroup.externalId
    )) as IResponse;
    const allContentOwnersResponse = (await getCustomersListAPI('content_owner')) as IResponse;
    const contentProvidersResponse = (await getCustomersListAPI(
      'content_provider',
      customerData.data.customerGroup.externalId
    )) as IResponse;
    const distributorsResponse = (await getCustomersListAPI(
      'distributor',
      customerData.data.customerGroup.externalId
    )) as IResponse;
    const role = this.getUserRole();

    this.setState({
      contentOwners: contentOwnersResponse.data,
      allContentOwners: allContentOwnersResponse.data,
      contentProviders: contentProvidersResponse.data,
      distributors: distributorsResponse.data,
      role
    });
  };

  getTitleData = () => {
    const assetDetails = deepCopy({...this.getAssetData()});
    const titles = (assetDetails.titles || []).map(PlaylistAsset.parsing.parseSearchTitle);
    console.log('Titles from assetDetails', assetDetails.titles);
    const credentials = PlaylistAsset.parsing.parseCredentialsFromTitles(titles);
    console.log('Credentials', credentials);
    return getTitleInfo(credentials);
  };

  isRegistered = () => {
    return this.props.selectedAsset ? this.props.selectedAsset.isRegistered : true;
  };

  getAssetData = () => {
    const updatedAssetDetails = this.props.updatedAssetDetails || {};
    const currentAssetDetails =
      this.props.selectedAsset && this.props.selectedAsset.assetDetails
        ? deepCopy({...this.props.selectedAsset.assetDetails, ...updatedAssetDetails})
        : {};
    if (currentAssetDetails.references) {
      currentAssetDetails.references = currentAssetDetails.references.map(updateId('reference'));
    }

    if (currentAssetDetails.distributors) {
      currentAssetDetails.distributors = currentAssetDetails.distributors.map(updateId('distributor'));
    }
    return currentAssetDetails;
  };

  onTitleUpdate = (updatedTitle: ISearchTitle) => {
    const assetDetails = deepCopy({...this.getAssetData()});
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const isTitleUpdate = ['Feature', 'Series'].indexOf(updatedTitle.type) !== -1;
    console.log('Added titles', updatedTitle, assetDetails.titles);
    let titles = isTitleUpdate
      ? [updatedTitle]
      : [...(assetDetails.titles || [])]
          .reduce((acc: Array<ISearchTitle>, title: ISearchTitle) => {
            if (updatedTitle.type === title.type) {
              return [...acc, updatedTitle];
            }
            return [...acc, title];
          }, [])
          .filter((title: ISearchTitle) => title.id);
    if (!isTitleUpdate) {
      const isDefined = titles.find((record: ISearchTitle) => record.type === updatedTitle.type);
      if (!isDefined) {
        titles.push(updatedTitle);
      }
      const versionTypes = ['EpisodeVersion', 'SeasonVersion', 'SeriesVersion'];
      const conformanceTypes = ['EpisodeConformance', 'SeasonConformance', 'SeriesConformance'];
      const removeTypes =
        ['Season', 'Episode'].indexOf(updatedTitle.type) !== -1
          ? [...versionTypes, ...conformanceTypes, updatedTitle.type === 'Season' ? 'Episode' : ''].filter(
              token => token
            )
          : versionTypes.indexOf(updatedTitle.type) !== -1
          ? conformanceTypes
          : updatedTitle.type === 'FeatureVersion'
          ? ['FeatureConformance']
          : [];
      titles = titles.filter((record: ISearchTitle) => removeTypes.indexOf(record.type) === -1);
    }
    console.log('Updated titles', titles);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, titles});
  };

  onAssetUpdate = (field: string, value: any) => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    console.log(field, value);
    const updated = {...updatedAssetDetails, [field]: value};
    this.props.updatePartialAssetDetails(updated);

    console.log('Update assetDetails', updated);
  };

  getAssetInfoComponent = () => (
    <AssetInfo
      selectedAsset={this.props.selectedAsset}
      isAssetRegistered={this.isRegistered()}
      isAssetInfoOpen={this.state.openAssetInfo}
      assetDetails={this.getAssetData()}
      assetStatusEnums={this.props.assetStatusEnums}
      fileWrapperEnums={this.props.fileWrapperEnums}
      functionEnums={this.props.functionEnums}
      contentTypeEnums={this.props.contentTypeEnums}
      formatComplianceEnums={this.props.formatComplianceEnums}
      frameRateEnums={this.props.frameRateEnums}
      referencesNameEnums={this.props.referencesNameEnums}
      referencesTypeEnums={this.props.referencesTypeEnums}
      onAssetUpdate={this.onAssetUpdate}
      closestBody={this.props.closestBody}
      disabled={!this.props.tabsDataInEditMode}
      errors={this.props.detailsMetadataErrors}
      errorLogs={this.props.errorLogs}
      contentOwners={this.state.allContentOwners}
      contentProviders={this.state.contentProviders}
      distributors={this.state.distributors}
      role={this.state.role}
      metadataMassUpdateFields={this.props.metadataMassUpdateFields}
      onMetadataMassUpdateField={this.props.onMetadataMassUpdateField}
    />
  );

  getTitleInfoComponent = () => {
    const {versionId, conformanceGroupId, titleId, seasonId, episodeId, type} = this.getTitleData();
    const disabled = !this.props.tabsDataInEditMode;
    console.log('Title Info data', versionId, conformanceGroupId, titleId, seasonId, episodeId, type);
    return (
      <TitleInfo
        selectedType={type}
        assetId={this.props.selectedAsset ? this.props.selectedAsset.assetId : null}
        isTitleInfoOpen={this.state.openTitleInfo}
        versionId={versionId}
        conformanceGroupId={conformanceGroupId}
        titleId={titleId}
        seasonId={seasonId}
        episodeId={episodeId}
        closestBody={this.props.closestBody}
        disabled={disabled}
        onTitleInfoUpdated={this.onTitleUpdate}
        tabsContainer={this.props.tabsContainer}
        errors={this.props.detailsMetadataErrors}
        contentOwners={this.state.contentOwners}
        contentProviders={this.state.contentProviders}
        metadataMassUpdateFields={this.props.metadataMassUpdateFields}
        onMetadataMassUpdateField={this.props.onMetadataMassUpdateField}
      />
    );
  };

  onTitleToggle = (openTitleInfo: boolean) => {
    if (!this.props.selectedAsset) {
      return;
    }
    this.setState({openTitleInfo});
  };

  onAssetToggle = (openAssetInfo: boolean) => {
    if (!this.props.selectedAsset) {
      return;
    }
    this.setState({openAssetInfo});
  };

  getGeneralError = () => {
    const error = this.props.detailsMetadataErrors.find(
      (error: IMetadataError) => error.fieldName === 'AssetRegistrationPatch'
    );
    return error ? <div className="details-container_error">! {error.message}</div> : null;
  };

  render() {
    return (
      <div className="details-container">
        {this.getGeneralError()}
        <CollapisbleWithEditModeToggle
          tabsInEditMode={this.props.tabsDataInEditMode}
          open={this.state.openTitleInfo}
          title="Title Info"
          content={this.getTitleInfoComponent()}
          onToggle={this.onTitleToggle}
        />
        <CollapisbleWithEditModeToggle
          tabsInEditMode={this.props.tabsDataInEditMode}
          open={this.state.openAssetInfo}
          title="Asset Info"
          content={this.getAssetInfoComponent()}
          onToggle={this.onAssetToggle}
        />
      </div>
    );
  }
}
