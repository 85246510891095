import * as React from 'react';
import {PlaylistAsset} from '../../models/PlaylistAsset/PlaylistAsset';
import {Tt2b} from 'components/Typography';
import {XMLRenderer} from './components/XMLRenderer';
import {Button} from '../Button';

interface INonMediaProps {
  nonMediaAsset: PlaylistAsset;
  restoring: boolean;
  updateNonMediaRestoring: (restore: boolean) => void;
  onAssetRestore: () => void;
}

interface INonMediaState {
  showArchivedState: boolean;
}

type restoringTypes = 'Asset' | 'Vfs';

const PlaceholderContainer = (props: {text?: JSX.Element | string}) => (
  <div className="non-media-viewer-container_empty">{props.text || ''}</div>
);

export class NonMediaViewer extends React.PureComponent<INonMediaProps, INonMediaState> {
  constructor(props) {
    super(props);

    this.state = {
      showArchivedState: true
    };
  }

  componentDidUpdate(prevProps: INonMediaProps) {
    const prevNonMediaData = {
      assetId: prevProps.nonMediaAsset && prevProps.nonMediaAsset.assetId,
      state: prevProps.nonMediaAsset && prevProps.nonMediaAsset.vfsInfo.state
    };
    const currentNonMediaData = {
      assetId: this.props.nonMediaAsset && this.props.nonMediaAsset.assetId,
      state: this.props.nonMediaAsset && this.props.nonMediaAsset.vfsInfo.state
    };
    if (
      prevNonMediaData.assetId !== currentNonMediaData.assetId ||
      prevNonMediaData.state !== currentNonMediaData.state
    ) {
      this.setState({showArchivedState: true});
    }
    if (prevProps.restoring !== this.props.restoring && !this.props.restoring) {
      this.onRestoreCancel();
    }
  }

  onRestoreCancel = () => {
    this.setState({showArchivedState: false});
  };

  onRestoring = (type: restoringTypes) => {
    if (type === 'Vfs') {
      this.props.updateNonMediaRestoring(true);
    } else {
      this.props.onAssetRestore();
    }
  };

  getRestoreContent = (content: string | JSX.Element, type: restoringTypes = 'Vfs') => {
    return (
      <div className="non-media-viewer-container_archived">
        <div className="non-media-viewer-container_archived_wrapper">
          <Tt2b className="non-media-viewer-container_archived_wrapper_title" content={content} />
          <div className="non-media-viewer-container_archived_wrapper_buttons-container">
            <Button
              disabled={this.props.restoring}
              className="non-media-viewer-container_archived_wrapper_buttons-container_cancel"
              secondary
              content="Cancel"
              onClick={this.onRestoreCancel}
            />
            <Button
              disabled={this.props.restoring}
              content={this.props.restoring ? 'Restoring...' : 'Restore'}
              onClick={() => this.onRestoring(type)}
            />
          </div>
        </div>
      </div>
    );
  };

  getAssetStatus = () => {
    const assetStatus = this.props.nonMediaAsset ? (this.props.nonMediaAsset.assetStatus || '').toLowerCase() : '';
    // In case asset is on restoring state and we have shown restoring screen for the first time, then we need
    // to display options so the operator can select the further steps
    if (assetStatus === 'restoring' && this.state.showArchivedState) {
      return this.getRestoreContent(
        `Your asset needs to be restored in order to playback. Would you like to restore now?`,
        'Asset'
      );
    }
    const content =
      assetStatus === 'restoring' ? (
        <>
          <div>Your asset is currently in the process of being restored.</div>
          <div>This process may take 3 to 5 hours - please check back shortly.</div>
        </>
      ) : assetStatus === 'copying' ? (
        <>
          <div>Your asset is currently in the process of being copied.</div>
          <div>This process may take a few minutes - please check back shortly.</div>
        </>
      ) : assetStatus === 'error' ? (
        `Having issues loading this image asset, please try to select another one.`
      ) : (
        ``
      );
    if (content) {
      return <Tt2b className="non-media-viewer-container_content" content={content} />;
    }
    return null;
  };

  getVfsState = () => {
    const vfsState = this.props.nonMediaAsset ? (this.props.nonMediaAsset.vfsInfo.state || '').toLowerCase() : '';
    if (vfsState === 'archived' && this.state.showArchivedState) {
      return this.getRestoreContent(
        `File is currently in archived state. Do you want to restore the file from Glacier?`
      );
    }
    if (vfsState === 'restoring') {
      return (
        <Tt2b
          className="non-media-viewer-container_content"
          content="XML file is currently being restored from Glacier. Please wait..."
        />
      );
    }
    return null;
  };

  getContent = () => {
    const assetStatusContent = this.getAssetStatus();
    const vfsStateContent = this.getVfsState();
    if (assetStatusContent) {
      return assetStatusContent;
    }
    if (vfsStateContent) {
      return vfsStateContent;
    }
    const nonMediaData =
      this.props.nonMediaAsset.assetDetails.nonMedia &&
      Array.isArray(this.props.nonMediaAsset.assetDetails.nonMedia) &&
      this.props.nonMediaAsset.assetDetails.nonMedia.length
        ? this.props.nonMediaAsset.assetDetails.nonMedia[0]
        : null;
    const type = nonMediaData ? (nonMediaData.type || '').toUpperCase() : '';
    let rendererComponent = null;
    switch (type) {
      case 'XML':
        rendererComponent = (
          <XMLRenderer
            restoring={this.props.restoring}
            updateNonMediaRestoring={this.props.updateNonMediaRestoring}
            xmlPath={this.props.nonMediaAsset ? this.props.nonMediaAsset.publicUrl : ''}
          />
        );
        break;
      default:
        rendererComponent = null;
    }

    if (!rendererComponent) {
      return (
        <PlaceholderContainer text={<Tt2b content={`Asset of type ${type} is not supported from the Player`} />} />
      );
    }

    return rendererComponent;
  };

  render() {
    return <div className="non-media-viewer-container">{this.getContent()}</div>;
  }
}
