import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Icon} from 'tt-components/src/Icon/Icon';
import {IAudioDataTrackDetail, IErrorLog} from '../../../../../../../../../../../@types/assetDetails';
import {Tt1} from '../../../../../../../../../../components/Typography';
import {IEnum} from '../../../../../../../../../../../@types/enum';
import {Dropdown, IDropdownOption} from '../../../../../../../../../../components/Dropdown';
import {IMetadataError} from '../../../../../../../../../../../@types/metadataErrors';
import {getErrorLogByField} from '../../../../../../../../utils/helpers';

import {AudioPlay} from '../../../../../../../../../../assets/Icons/AudioPlayIcon';
import {UpArrowIcon} from '../../../../../../../../../../assets/Icons/UpArrow';
import {DownArrowIcon} from '../../../../../../../../../../assets/Icons/DownArrow';
import {TrachIcon} from '../../../../../../../../../../assets/Icons/Trash';

interface ITrackHeaderProps {
  track: IAudioDataTrackDetail;
  channelMapEnums: Array<IEnum>;
  breakUp?: boolean;
  style?: React.CSSProperties;
  audioMetadataErrors: Array<IMetadataError>;
  errorLogs?: Array<IErrorLog>;
  closestBody?: HTMLElement;
  onBreak: (breakUp: boolean) => void;
  onUpdateTrackProp: (prop: string, value: string | number) => void;
  onRemoveTrackConfiguration: () => void;
}

const convertEnums = (enums: Array<IEnum>): Array<IDropdownOption> =>
  enums.reduce((acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}], []);

export class TrackHeader extends React.Component<ITrackHeaderProps> {
  static defaultProps = {
    channelMapEnums: [],
    audioMetadataErrors: [],
    errorLogs: []
  };

  shouldComponentUpdate(nextProps: ITrackHeaderProps) {
    if (isEqual(nextProps.track, this.props.track)) {
      return false;
    }
    return true;
  }

  onBreak = () => {
    this.props.onBreak(this.props.breakUp);
  };

  concatenateMappings = () => {
    const mappings =
      this.props.track.channelMap && Array.isArray(this.props.track.channelMap) ? this.props.track.channelMap : [];
    if (!mappings.length) {
      return 'Empty channels mapping';
    }
    return mappings
      .map((mapping: string) => {
        return this.props.channelMapEnums.find((enumRecord: IEnum) => enumRecord.value === mapping);
      })
      .filter(mapping => mapping)
      .map((mapping: IEnum) => mapping.name || 'Undefined')
      .join(', ');
  };

  render() {
    return (
      <div className="track-header-container" style={this.props.style || {}}>
        <div className="track-header-container_item icon">
          <Icon icon={AudioPlay} size="24px" color="#FFFFFF" />
        </div>
        <div className="track-header-container_item title">
          <Tt1 className="track-header-container_item_title" content={`Channel ${this.props.track.track}`} />
        </div>
        <div className="track-header-container_item channel-mapping" title={this.concatenateMappings()}>
          <Dropdown
            field={{field: 'audio.trackDetail.channelMap', errors: this.props.audioMetadataErrors}}
            multiple
            search
            floatingTextNode
            options={convertEnums(this.props.channelMapEnums)}
            onSelected={value => this.props.onUpdateTrackProp('channelMap', value)}
            selected={this.props.track.channelMap}
            label="Channel Map"
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('audio.trackDetail.channelMap', this.props.errorLogs)}
          />
        </div>
        <div className="track-header-container_item remove-config">
          <div className="icon-inner-container" title="Remove channel" onClick={this.props.onRemoveTrackConfiguration}>
            <Icon icon={TrachIcon} size="17px" color="#43464D" />
          </div>
        </div>
        <div className="track-header-container_item break-icon" onClick={this.onBreak}>
          <Icon icon={this.props.breakUp ? UpArrowIcon : DownArrowIcon} size="18px" color="#43464D" />
        </div>
      </div>
    );
  }
}
