import * as React from 'react';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetType} from '../../../../../@types/assetType';
import {Container, Row, Column} from '../Grid';
import {Tt2b} from '../../../../components/Typography/Tt2b';
import {Dropdown, IDropdownOption} from '../../../../components/Dropdown';

interface IUnknownTabProps {
  selectedAsset: PlaylistAsset;
  unknownAssetTypes: IAssetType;
  closestBody?: HTMLElement;
  tabsInEditMode: boolean;
  updateUnknownAssetType: (type: IAssetType) => void;
}

const options: Array<IDropdownOption> = [
  {value: 'Video', label: 'Video'},
  {value: 'Audio', label: 'Audio'},
  {value: 'Subtitles', label: 'Subtitles'},
  {value: 'Image', label: 'Image'},
  {value: 'Non Media', label: 'Non Media'}
];

export class UnknownTab extends React.PureComponent<IUnknownTabProps> {
  getMessageContent = () => {
    const assetId = this.props.selectedAsset.assetDetails.hrId || this.props.selectedAsset.assetId || '';
    const name = this.props.selectedAsset.assetDetails.name || '';
    return `
      Asset${assetId ? ` (${assetId})` : ``}${name ? ` ~ ${name}` : ``} doesn't have a valid known type 
      that One Player can handle. Please select an acceptable asset type from the dropdown and save the 
      changes in order to be able to update related metadata:
    `;
  };

  render() {
    return (
      <Container className="unknown-tab-container">
        <Row className="unknown-tab-container_row">
          <Column className="unknown-tab-container_row_column">
            <Tt2b content={this.getMessageContent()} className="unknown-tab-container_row_column_text" />
          </Column>
        </Row>
        <Row className="unknown-tab-container_row">
          <Column className="unknown-tab-container_row_column">
            <Dropdown
              disabled={!this.props.tabsInEditMode}
              optionsSortCallback={(a: IDropdownOption, b: IDropdownOption) => 0}
              options={options}
              selected={this.props.unknownAssetTypes}
              onSelected={this.props.updateUnknownAssetType}
              portalNode={this.props.closestBody}
            />
          </Column>
          <Column className="unknown-tab-container_row_column" />
          <Column className="unknown-tab-container_row_column" />
        </Row>
      </Container>
    );
  }
}
