import {IAudioChannel, IAudioChannelConfiguration} from '../../../../@types/audioChannelConfiguration';
import {IMarkupsTypes} from '../../../state/IVideoState';
import {MarkupsTypesMapping} from '../constants/typesMapping';
import {IAssetCredentials, IFeatureCredentials, ISeriesCredentials} from '../../../state/IAppState';
import {IMarkupEvent, IEventGroup} from '../../../../@types/markupEvent';
import {PlaylistAsset} from '../../../models/PlaylistAsset/PlaylistAsset';
import {IMarkupsError} from '../../../../@types/markupsError';
import {IFrameRate} from 'tt-components';
import {formatting} from 'tt-components/src/Utils/formatting';
import {ITabs} from '../interfaces';
import {IErrorLog, IAudioData, IAudioDataTrackDetail, IAssetVideo} from '../../../../@types/assetDetails';
import {getTitleType} from '../../../utils/utils';
import {videoAutoPopulatingSettings} from '../constants/videoAutoPopulatingSettings';

export const parseAudioChannelsBasedOnMapping = (audioChannels: Array<IAudioChannel>, confId: string) => {
  const channels = [];
  audioChannels.forEach((audio: IAudioChannel) => {
    audio.channelMap.forEach((map: string, index) => {
      channels.push({
        ...audio,
        channelMap: [map],
        streamInFile: audio.track,
        averageBitRate: String(+audio.averageBitRate / audio.channelMap.length),
        confId
      } as IAudioChannel);
    });
    if (!audio.channelMap.length) {
      channels.push({...audio, streamInFile: audio.track, confId} as IAudioChannel);
    }
  });
  return channels;
};

export const parseCustomAudioChannel = (audio: IAudioChannel, confId: string) => {
  return {...audio, confId, streamInFile: audio.track};
};

export const genUniqueIdentifier = () => {
  return `${new Date().getTime()}`;
};

export const isChannelProperConfigured = ({channelMap}: IAudioChannel) => {
  const okChannelMap = Array.isArray(channelMap) && !!channelMap.filter((map: string) => map).length;
  return okChannelMap;
};

export const areChannelsConfigured = (channels: Array<IAudioChannel>) => {
  return channels.every(isChannelProperConfigured);
};

export const isConfigurationProperlyConfigured = (conf: IAudioChannelConfiguration) => {
  return !!conf.channelConfig && !!conf.type && !!conf.language;
};

export const areConfigurationsProperlyConfigured = (
  confs: Array<IAudioChannelConfiguration>,
  channels: Array<IAudioChannel>
) => {
  return confs.length
    ? confs.every((conf: IAudioChannelConfiguration) => {
        const confChannels = channels.filter((audio: IAudioChannel) => audio.confId === conf.id);
        return isConfigurationProperlyConfigured(conf) && areChannelsConfigured(confChannels);
      })
    : false;
};

export const deepCopy = (collection: {[x: string]: any} | Array<any>) => {
  return JSON.parse(JSON.stringify(collection));
};

export const clearProps = (obj: {[x: string]: any}, props: Array<string>) => {
  props.forEach((prop: string) => delete obj[prop]);
  return obj;
};

export const parseMarkupsTypes = (data: Array<any>): IMarkupsTypes => {
  const name = data.length ? (data[0].name ? MarkupsTypesMapping[data[0].name] : null) : null;
  if (!name) {
    console.log(`Couldn't find mapping for provided enums`, data);
    return null;
  }
  const types: IMarkupsTypes = {name, list: []};
  data.forEach(elem => {
    (elem.enums || []).forEach(item => {
      if (item.isActive) {
        types.list.push(item.name);
      }
    });
  });
  return types;
};

export const getTypesByEventGroup = (selectedEventGroup: string, types: Array<IMarkupsTypes>) => {
  const selectedTypes = types.find((type: IMarkupsTypes) => type.name === selectedEventGroup);
  if (selectedTypes) {
    return selectedTypes.list;
  }
  return [];
};

export const compareStringArrays = (arr1: Array<string>, arr2: Array<string>) => {
  arr1.sort();
  arr2.sort();
  const stringArr1 = arr1.map((item: string) => item.trim()).join(',');
  const stringArr2 = arr2.map((item: string) => item.trim()).join(',');

  return stringArr1 === stringArr2;
};

export const formatCommentTime = (date: Date) => {
  let hours = date.getHours();
  let minutes = `${date.getMinutes()}`;
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = +minutes < 10 ? '0' + minutes : minutes;
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let strTime = `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  return strTime;
};

/**
 * Parses numeric size in readable units
 *
 * @param size Size in bytes
 * @param unit Unit for parsing
 */
export const parseSize = (size: number, unit: 'k' | 'm' | 'g' = 'k') => {
  if (!size) {
    return `0 ${unit.toUpperCase()}B`;
  }

  switch (unit) {
    case 'g':
      return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
    case 'm':
      return (size / 1024 / 1024).toFixed(2) + ' MB';
    case 'k':
    default:
      return (size / 1024).toFixed(2) + ' KB';
  }
};

/**
 * Get array unique objects by field
 *
 * @param data Array of objects
 * @param field Field to do the comparison
 */
export const getUniqueArrayObjectsByField = (data: Array<any>, field: string) => {
  if (!field) {
    console.log('Field is required to do the filtering');
    return data;
  }

  return data.reduce((result, obj) => {
    const existing = result.find(record => record[field] === obj[field]);
    if (!existing) {
      result.push(obj);
    }
    return result;
  }, []);
};

/**
 * Parse season number from the name
 *
 * @param seasonName Name of season
 */
export const parseSeasonNumber = (seasonName: string): string => {
  const regExp = /[0-9]+$/;
  const result = regExp.exec(seasonName);
  if (result) {
    return result[0].startsWith('0') && result[0].length > 1 ? result[0].substring(1) : result[0];
  } else {
    return ``;
  }
};

export const getTitleInfo = (credentials: IAssetCredentials) => {
  const type = getTitleType(credentials);
  const {versionId, conformanceId: conformanceGroupId} = getTitleAssetLevelData(credentials);
  const titleId =
    type === 'Feature'
      ? (credentials.data as IFeatureCredentials).featureId
      : (credentials.data as ISeriesCredentials).seriesId;
  const seasonId = (credentials.data as ISeriesCredentials).seasonId || null;
  const episodeId = (credentials.data as ISeriesCredentials).episodeId || null;
  return {versionId, conformanceGroupId, titleId, seasonId, episodeId, type};
};

export const getTitleAssetLevelData = (credentials: IAssetCredentials) => {
  const type = getTitleType(credentials);
  const versionId =
    type === 'Feature'
      ? (credentials.data as IFeatureCredentials).featureVersionId
      : type === 'Episode'
      ? (credentials.data as ISeriesCredentials).episodeVersionId
      : type === 'Series'
      ? (credentials.data as ISeriesCredentials).seriesVersionId
      : (credentials.data as ISeriesCredentials).seasonVersionId;
  const conformanceId = credentials.conformanceGroupId;
  return {versionId, conformanceId};
};

export const filterDefaultTypes = (event: IMarkupEvent) => {
  // NOTE: For Program Timings we should remove the default types that are only for display purpose
  const isDefaultProgramTimingEvent = PlaylistAsset.parsing.isDefaultEvent(event);
  return isDefaultProgramTimingEvent ? false : true;
};

export const updateMarkupsErrors = (
  markupsErrors: Array<IMarkupsError>,
  groupEvent: string,
  addIds: Array<string>,
  error: string
) => {
  const copy = deepCopy([...markupsErrors]);
  const updated = copy.reduce((acc: Array<IMarkupsError>, errorObject: IMarkupsError) => {
    if (errorObject.group === groupEvent) {
      errorObject.eventsErrors = [...errorObject.eventsErrors, ...addIds.map(eventId => ({id: eventId, error}))];
    }
    return [...acc, errorObject];
  }, []);

  if (!updated.find((error: IMarkupsError) => error.group === groupEvent)) {
    return [
      ...updated,
      {
        group: groupEvent,
        eventsErrors: [...addIds.map(eventId => ({id: eventId, error}))]
      } as IMarkupsError
    ];
  }
  return updated;
};

export const updateEventGroup = (group: IEventGroup, events: Array<IMarkupEvent>) => {
  const copy = deepCopy({...group});
  copy.events = copy.events.map((event: IMarkupEvent) => {
    const exists = events.find((existEvent: IMarkupEvent) => existEvent.id === event.id);
    if (exists) {
      event = {...exists};
    }
    return event;
  });
  events.forEach((event: IMarkupEvent) => {
    const exists = copy.events.find((existEvent: IMarkupEvent) => existEvent.id === event.id);
    if (!exists) {
      copy.events.push(event);
    }
  });
  return copy;
};

export const updateEventsGroup = (groups: Array<IEventGroup>, groupName: string, events: Array<IMarkupEvent>) => {
  const updatedGroups = deepCopy([...groups]).reduce((acc: Array<IEventGroup>, group: IEventGroup) => {
    if (group.name === groupName) {
      group = updateEventGroup(group, events);
    }
    return [...acc, group];
  }, []);
  const exists = groups.find((group: IEventGroup) => group.name === groupName);
  if (!exists) {
    updatedGroups.push({name: groupName, events});
  }
  return updatedGroups;
};

export const mergeChangedEvents = (
  currentChangedEvents: Array<IEventGroup>,
  updatedChangedEvents: Array<IEventGroup>
) => {
  // NOTE: Will merge the not succeeded events with the new one that we get from the API response
  const mergedChangedEvents = currentChangedEvents.reduce((acc: Array<IEventGroup>, group: IEventGroup) => {
    const updatedGroup = updatedChangedEvents.find((updatedGroup: IEventGroup) => updatedGroup.name === group.name);
    if (updatedGroup) {
      group = updateEventGroup(group, updatedGroup.events);
    }
    return [...acc, group];
  }, []);
  // NOTE: In case the not succeeded group is not found in the API response we need to add it as it is
  updatedChangedEvents.forEach((group: IEventGroup) => {
    const existsGroup = mergedChangedEvents.find((merged: IEventGroup) => merged.name === group.name);
    if (!existsGroup) {
      mergedChangedEvents.push(group);
    }
  });
  return mergedChangedEvents;
};

export const eventsValidation = (eventGroupName: string, events: Array<IMarkupEvent>) => {
  let eventsErrors = {};
  eventsErrors[eventGroupName] = 0;
  events.map(event => {
    Object.keys(event).map(item => {
      if (!event[item] || event[item] === '') {
        eventsErrors[eventGroupName] += 1;
      }
    });
  });
  return eventsErrors[eventGroupName] === 0;
};

export const videoMetadataValidation = (selectedAsset: PlaylistAsset) => {
  const fieldsData = [
    {name: 'subType', isDetail: false},
    {name: 'videoCodec', isDetail: false},
    {name: 'encodeRate', isDetail: false},
    {name: 'pictureFormat', isDetail: false},
    {name: 'language', isDetail: false},
    {name: 'bitDepth', isDetail: true},
    {name: 'pixelAspect', isDetail: true},
    {name: 'displayAspectRatio', isDetail: true},
    {name: 'pictureAspectRatio', isDetail: true},
    {name: 'scanType', isDetail: true},
    /* {name: 'fieldOrder', isDetail: true},*/
    {name: 'colorSubSampling', isDetail: true}
    /* {name: 'colorType', isDetail: false}*/
  ];
  let validationObj = {};
  let isVideoMetadataValid = true;
  if (!!selectedAsset.assetDetails.videos[0]) {
    fieldsData.map(item => {
      const data = item.isDetail
        ? selectedAsset.assetDetails.videos[0].videoDetail[item.name]
        : selectedAsset.assetDetails.videos[0][item.name];
      if (!data) {
        validationObj[item.name] = true;
        isVideoMetadataValid = false;
      }
    });
  }
  return isVideoMetadataValid;
};

export const updateTimeOffset = (time: string, offset: number, frameRate: IFrameRate) => {
  const timeInSeconds = formatting.smpteTimecodeToSeconds(time, frameRate.frameRate, frameRate.dropFrame);
  return formatting.getSmpteTimecode(timeInSeconds + offset, frameRate.frameRate, frameRate.dropFrame);
};

export const compareSMPTETimecodes = (timecodeA: string, timecodeB: string, frameRate: IFrameRate) => {
  const timecodeASeconds = formatting.smpteTimecodeToSeconds(timecodeA, frameRate.frameRate, frameRate.dropFrame);
  const timecodeBSeconds = formatting.smpteTimecodeToSeconds(timecodeB, frameRate.frameRate, frameRate.dropFrame);
  if (timecodeASeconds > timecodeBSeconds) {
    return 1;
  } else if (timecodeASeconds < timecodeBSeconds) {
    return -1;
  } else {
    return 0;
  }
};

export const defineInitTab = appConfig => {
  const tabs = (appConfig.view && appConfig.view.tabs) || [];
  return tabs.length ? (tabs.indexOf(ITabs.Metadata) !== -1 ? ITabs.Metadata : tabs[0]) : '';
};

export const getErrorLogByField = (field: string, errorLogs: Array<IErrorLog> = []) => {
  return errorLogs.find((error: IErrorLog) => (error.fieldName || '').toLowerCase() === (field || '').toLowerCase());
};

export const updateAssetDetailsAudioProp = (
  audios: Array<IAudioData>,
  prop: string,
  value: string | number,
  audioConfigId: string
): Array<IAudioData> => {
  return (audios || []).reduce((acc: Array<IAudioData>, audio: IAudioData) => {
    if (audio.id === audioConfigId) {
      audio = {...audio, [prop]: value};
    }
    return [...acc, audio];
  }, []);
};

export const updateAssetDetailsAudioTrackProp = (
  assetDetails: Array<IAudioData>,
  prop: string,
  value: string | number,
  audioConfigId: string,
  trackId: string
): Array<IAudioData> => {
  return (assetDetails || []).reduce((acc: Array<IAudioData>, audio: IAudioData) => {
    if (audio.id === audioConfigId) {
      const trackDetail = (audio.trackDetail || []).map((track: IAudioDataTrackDetail) => {
        if (track.id === trackId) {
          track = {...track, [prop]: value};
        }
        return track;
      });
      audio = {...audio, trackDetail};
    }
    return [...acc, audio];
  }, []);
};

export const updateAssetDetailsSubtitleProp = (
  subtitles: Array<any>,
  prop: string,
  value: string | number,
  subtitleId: number
): Array<IAudioData> => {
  return (subtitles || []).reduce((acc: Array<any>, subtitle: any, index) => {
    if (index === subtitleId) {
      subtitle = {...subtitle, [prop]: value};
    }
    return [...acc, subtitle];
  }, []);
};

export const removeFieldBySuffix = (data: Array<any>, prop: string, suffix: string) => {
  return data.reduce((acc: Array<any>, item: any) => {
    if ((item[prop] || '').endsWith(suffix)) {
      delete item.id;
    }
    return [...acc, item];
  }, []);
};

export const getDeepPropertyValidation = (composedLabel: string, state: {[x: string]: string}): boolean => {
  const tokens = composedLabel.split('.');
  let reachedToken = tokens[0];
  let value: any = state[reachedToken];
  tokens.slice(1).forEach((token: string) => {
    if (Array.isArray(value)) {
      let tmp: any;
      value.forEach((item: any) => {
        if (!tmp && typeof tmp !== 'undefined') {
          return;
        }
        tmp = item[token];
      });
      value = tmp;
    } else {
      value = value ? value[token] : value;
    }
    reachedToken = typeof value !== 'undefined' ? token : reachedToken;
  });
  // NOTE: Check if the provided value is from the latest token that we need validate,
  // otherwise we will consider the validation successful as the field is not present
  // at all and user cannot access it to update accordingly
  if (reachedToken !== tokens[tokens.length - 1]) {
    return true;
  }
  return value ? (Array.isArray(value) ? !!value.length : !!value) : false;
};

export const autoPopulateVideoSettings = (videos: Array<IAssetVideo>) => {
  return [...videos].reduce((acc: Array<IAssetVideo>, video: IAssetVideo) => {
    const calculatingFields = (video: IAssetVideo): IAssetVideo => {
      const valid = !!(
        video.frameSizeHeight &&
        video.frameSizeWidth &&
        video.videoDetail &&
        video.videoDetail.displayAspectRatio &&
        video.videoDetail.pictureAspectRatio
      );
      if (!valid) {
        return {...video} as IAssetVideo;
      }
      const {pictureFormat, activeWidthPixels, activeHeightPixels, activeXoffsetPixels, activeYoffsetPixels} = [
        0
      ].reduce(
        (acc: any) => {
          const match = videoAutoPopulatingSettings.find(record => {
            return (
              record.frameSizeHeight === +video.frameSizeHeight &&
              record.frameSizeWidth === +video.frameSizeWidth &&
              record.displayAspectRatio === video.videoDetail.displayAspectRatio &&
              record.pictureAspectRatio === video.videoDetail.pictureAspectRatio
            );
          });
          return {
            pictureFormat: match ? match.pictureFormat : video.pictureFormat,
            activeWidthPixels: match ? match.activeWidthPixels : video.videoDetail.activeWidthPixels,
            activeHeightPixels: match ? match.activeHeightPixels : video.videoDetail.activeHeightPixels,
            activeXoffsetPixels: match ? match.activeXoffsetPixels : video.videoDetail.activeXoffsetPixels,
            activeYoffsetPixels: match ? match.activeYoffsetPixels : video.videoDetail.activeYoffsetPixels
          };
        },
        {} as any
      );
      return {
        ...video,
        pictureFormat,
        videoDetail: {
          ...video.videoDetail,
          activeWidthPixels,
          activeHeightPixels,
          activeXoffsetPixels,
          activeYoffsetPixels
        }
      };
    };
    return [...acc, {...calculatingFields(video)}];
  }, []);
};
