import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Container, Row, Column} from '../../../../../../../Grid';
import {Tt1, Tt2b} from '../../../../../../../../../../components/Typography';
import {Dropdown} from '../../../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../../../components/Toggle';
import {IAssetDetailsReference, IAssetDetails, IErrorLog} from '../../../../../../../../../../../@types/assetDetails';
import {IEnum} from '../../../../../../../../../../../@types/enum';
import {TextField} from '../../../../../../../../../../components/TextField';
import {deepCopy, getErrorLogByField} from '../../../../../../../../utils/helpers';
import {IMetadataError} from '../../../../../../../../../../../@types/metadataErrors';
import {Button} from '../../../../../../../../../../components/Button';
import {DeleteIcon} from '../../../../../../../../assets/Icons/DeleteIcon';

interface IReferencesProps {
  references: Array<IAssetDetailsReference>;
  referencesNameEnums: Array<IEnum>;
  referencesTypeEnums: Array<IEnum>;
  closestBody?: HTMLElement;
  disabled: boolean;
  errors: Array<IMetadataError>;
  errorLogs: Array<IErrorLog>;
  onReferenceUpdates: (references: Array<IAssetDetailsReference>) => void;
}

const getDefaultReference = (): IAssetDetailsReference => ({
  id: `${new Date().getTime()}-new-reference`,
  name: null,
  type: null,
  value: null,
  isPrimary: false
});

export class References extends React.PureComponent<IReferencesProps> {
  getReferenceContainer = (
    reference: IAssetDetailsReference,
    referencesNameEnums: Array<IEnum>,
    referencesTypeEnums: Array<IEnum>,
    key: number
  ) => {
    const nameList = referencesNameEnums
      .filter((record: IEnum) => record.isActive)
      .map((record: IEnum) => ({label: record.name, value: record.value}));
    const typeList = referencesTypeEnums
      .filter((record: IEnum) => record.isActive)
      .map((record: IEnum) => ({label: record.name, value: record.value}));
    const newReferenceRecord = (reference.id || '').endsWith('-new-reference');
    return (
      <Container className="reference-record-container" key={key}>
        <Row className="video-references-container_row">
          <Column className="video-references-container_row_column">
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Name"
              options={nameList}
              selected={reference.name}
              onSelected={(name: string) => this.onReferenceUpdates(reference.id, 'name', name)}
              portalNode={this.props.closestBody}
              errorLog={!newReferenceRecord && getErrorLogByField('references.name', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-references-container_row_column">
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Type"
              options={typeList}
              selected={reference.type}
              onSelected={(type: string) => this.onReferenceUpdates(reference.id, 'type', type)}
              portalNode={this.props.closestBody}
              errorLog={!newReferenceRecord && getErrorLogByField('references.type', this.props.errorLogs)}
            />
          </Column>
          <Column className={`video-references-container_row_column${this.props.disabled ? ` disabled` : ``}`}>
            <TextField
              label="Value"
              text={reference.value}
              editMode={true}
              onChange={(value: string) => this.onReferenceUpdates(reference.id, 'value', value)}
            />
          </Column>
          <Column className="video-references-container_row_column action-column remove-column">
            {!this.props.disabled && (
              <div
                className="video-references-container_row_column_remove"
                onClick={() => this.onRemoveReference(reference.id)}
              >
                <Icon icon={DeleteIcon} color="#1F72F2" size="18px" />
              </div>
            )}
          </Column>
        </Row>
        <Row className="video-references-container_row">
          <Column className="video-references-container_row_column">
            <Toggle
              disabled={this.props.disabled}
              label="Primary"
              toggled={reference.isPrimary}
              onToggle={(state: boolean) => this.onReferenceUpdates(reference.id, 'isPrimary', state)}
            />
          </Column>
        </Row>
      </Container>
    );
  };

  onReferenceUpdates = (referenceId: string, field: string, value: any) => {
    const references = deepCopy([...this.props.references]).map((reference: IAssetDetails) => {
      if (referenceId === reference.id) {
        reference[field] = value;
      }
      return reference;
    });
    this.props.onReferenceUpdates(references);
  };

  getContent = () => {
    if (!this.props.references.length) {
      return (
        <Row className="video-references-container_row">
          <Column className={`video-references-container_row_column${this.props.disabled ? ` disabled` : ``}`}>
            <Tt2b className="video-references-container_empty-text" content={`Video doesn't have references defined`} />
          </Column>
        </Row>
      );
    } else {
      return this.props.references.reduce(
        (acc: Array<JSX.Element>, reference: IAssetDetailsReference, index: number) => {
          return [
            ...acc,
            this.getReferenceContainer(reference, this.props.referencesNameEnums, this.props.referencesTypeEnums, index)
          ];
        },
        []
      );
    }
  };

  onAddReference = () => {
    if (this.props.disabled) {
      return;
    }
    const references = deepCopy([...this.props.references]);
    references.push(getDefaultReference());
    this.props.onReferenceUpdates(references);
  };

  onRemoveReference = (referenceId: string) => {
    if (this.props.disabled) {
      return;
    }
    const references = deepCopy([...this.props.references]).filter(
      (reference: IAssetDetailsReference) => reference.id !== referenceId
    );
    this.props.onReferenceUpdates(references);
  };

  getErrorRow = () => {
    const error = this.props.errors.find((error: IMetadataError) => error.fieldName === 'References');
    if (!error) {
      return null;
    }
    return <Row className="video-references-container_error">{`! ${error.message}`}</Row>;
  };

  render() {
    return (
      <Container className="video-references-container">
        <Row className="video-references-container_row">
          <Column className="video-references-container_row_column">
            <Tt1 content="References" className="video-references-container_row_title" />
          </Column>
        </Row>
        {this.getContent()}
        {!this.props.disabled && (
          <Row className="video-references-container_row">
            <Column className="video-references-container_row_column action-column">
              <Button content="+ Add Reference" small secondary onClick={this.onAddReference} />
            </Column>
          </Row>
        )}
        {this.getErrorRow()}
      </Container>
    );
  }
}
