import * as React from 'react';
import {CollapisbleWithEditModeToggle} from '../../../Collapsible';
import {VideoDetails} from '../VideoDetails';
import {IEnums} from '../../../../../../state/IEnums';
import {IAssetDetails, IAssetVideo, IErrorLog} from '../../../../../../../@types/assetDetails';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';
import {Tt2b} from 'components/Typography/Tt2b';
import {Icon} from 'tt-components/src/Icon/Icon';

import {CloseIcon} from '../../../../../../assets/Icons/Close';
import {deepCopy} from 'modules/Tabs/utils/helpers';

interface IVideoItemProps {
  updatedAssetDetails: Partial<IAssetDetails>;
  assetDetails: IAssetDetails;
  video: IAssetVideo;
  videoMetadataErrors: Array<IMetadataError>;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  openCollapsibleContent: boolean;
  getEvents: () => void;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  onCollapsibleToggle: (open: boolean) => void;
}

interface IVideoItemState {
  openVideoDetails: boolean;
}

export class VideoItem extends React.Component<IVideoItemProps, IVideoItemState> {
  constructor(props) {
    super(props);

    this.state = {
      openVideoDetails: false
    };
  }

  onRemoveVideoConfiguration = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.props.assetDetails});
    const videos = (assetDetails.videos || []).reduce((acc: Array<IAssetVideo>, video: IAssetVideo) => {
      if (video.id === this.props.video.id) {
        return [...acc];
      }
      return [...acc, video];
    }, []);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, videos});
  };

  getTitle = () => {
    return (
      <div className="metadata-video-item-container_collapsible-header">
        <Tt2b content="Video" className="metadata-video-item-container_collapsible-header_text" />
        {this.props.tabsDataInEditMode && (
          <div
            className="metadata-video-item-container_collapsible-header_close-icon"
            title="Remove video configuration"
            onClick={this.onRemoveVideoConfiguration}
          >
            <Icon icon={CloseIcon} size="12px" color="#ffffff" />
          </div>
        )}
      </div>
    );
  };

  getContent = () => {
    return (
      <VideoDetails
        updatedAssetDetails={this.props.updatedAssetDetails}
        isVideoDetailsOpen={this.props.openCollapsibleContent}
        updatePartialAssetDetails={this.props.updatePartialAssetDetails}
        assetDetails={this.props.assetDetails}
        video={this.props.video}
        videoMetadataErrors={this.props.videoMetadataErrors}
        enums={this.props.enums}
        getEvents={this.props.getEvents}
        tabsDataInEditMode={this.props.tabsDataInEditMode}
        closestBody={this.props.closestBody}
        errorLogs={this.props.errorLogs}
      />
    );
  };

  render() {
    return (
      <div className="metadata-video-item-container">
        <CollapisbleWithEditModeToggle
          tabsInEditMode={this.props.tabsDataInEditMode}
          open={this.props.openCollapsibleContent}
          title={this.getTitle()}
          blockTriggerOnSelectores={['.metadata-video-item-container_collapsible-header_close-icon']}
          onToggle={this.props.onCollapsibleToggle}
          content={this.getContent()}
        />
      </div>
    );
  }
}
