import * as React from 'react';
import {Container, Row, Column} from '../../../../../../../Grid';
import {Dropdown} from '../../../../../../../../../../components/Dropdown';
import {IPlaylistAtlas, IPlaylistTitleRecord} from '../../../../../../../../../../state/IAppState';
import {
  searchTitles,
  getSeasonsBySeriesId,
  getEpisodesBySeriesSeasonId,
  getTitleDataAPI,
  getTitleConformanceGroupsByVersion
} from '../../../../../../../../../../data/atlasAPI';
import {ISearchTitleParams, ISearchTitle} from '../../../../../../../../../../../@types/searchTitle';
import {deepCopy, getUniqueArrayObjectsByField} from '../../../../../../../../utils/helpers';
import {PlaylistAsset} from '../../../../../../../../../../models/PlaylistAsset/PlaylistAsset';
import {IMetadataError} from '../../../../../../../../../../../@types/metadataErrors';
import {IOneCustomer} from '../../../../../../../../../../../@types/oneCustomer';
import {parseTitleOptions} from '../../../../../../../../../../utils/utils';

interface ISeriesProps {
  assetId: string;
  isTitleInfoOpen: boolean;
  versionId: string;
  conformanceGroupId: string;
  seriesId: string;
  seasonId?: string;
  episodeId?: string;
  closestBody?: HTMLElement;
  disabled: boolean;
  tabsContainer: HTMLElement;
  errors: Array<IMetadataError>;
  contentOwners: Array<IOneCustomer>;
  contentProviders: Array<IOneCustomer>;
  onTitleInfoUpdated: (updatedTitles: ISearchTitle) => void;
}

interface ISeriesState {
  series: Array<ISearchTitle>;
  seasons: Array<ISearchTitle>;
  episodes: Array<ISearchTitle>;
  versions: Array<ISearchTitle>;
  conformanceGroups: Array<ISearchTitle>;
  asset: IPlaylistAtlas;
  loading: boolean;
  loadingSeasons: boolean;
  loadingVersions: boolean;
  loadingEpisodes: boolean;
  loadingConformanceGroups: boolean;
  seriesSearchString: string;
  seriesFiltering: boolean;
  seasonsSearchString: string;
  seasonsFiltering: boolean;
  episodeSearchString: string;
  episodeFiltering: boolean;
  versionSearchString: string;
  versionFiltering: boolean;
  conformanceGroupSearchString: string;
  conformanceGroupFiltering: boolean;
  hrIdSearch: boolean;
}

const mapToDropdownOptions = (record: ISearchTitle | IPlaylistTitleRecord) => ({
  label: `${record.name} (${record.hrId})`,
  value: record.id
});

export class Series extends React.PureComponent<ISeriesProps, ISeriesState> {
  isComponentMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      series: [],
      seasons: [],
      episodes: [],
      versions: [],
      conformanceGroups: [],
      asset: null,
      loading: true,
      loadingSeasons: true,
      loadingVersions: true,
      loadingEpisodes: true,
      loadingConformanceGroups: true,
      seriesSearchString: '',
      seriesFiltering: false,
      seasonsSearchString: '',
      seasonsFiltering: false,
      episodeSearchString: '',
      episodeFiltering: false,
      versionSearchString: '',
      versionFiltering: false,
      conformanceGroupSearchString: '',
      conformanceGroupFiltering: false,
      hrIdSearch: false
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  updateState = (stateObject, callBack: () => any = null) => {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(stateObject, callBack);
  };

  async componentDidUpdate(prevProps: ISeriesProps) {
    if (prevProps.seriesId !== this.props.seriesId) {
      this.fetchTitleInit();
    } else if (prevProps.seasonId !== this.props.seasonId) {
      await this.updateSeriesData();
      this.setState({loadingEpisodes: true}, async () => {
        await this.fetchEpisodes();
        await this.fetchVersions();
      });
    } else if (prevProps.episodeId !== this.props.episodeId) {
      await this.updateSeriesData();
      this.setState({loadingVersions: true}, () => this.fetchVersions());
    } else if (prevProps.versionId !== this.props.versionId) {
      await this.updateSeriesData();
      this.setState({loadingConformanceGroups: true}, () => this.fetchConformanceGroups());
    }
  }

  updateSeriesData = () => {
    return new Promise(resolve => {
      const asset = {
        seriesId: this.props.seriesId || '',
        seasonId: this.props.seasonId || '',
        episodeId: this.props.episodeId || '',
        assetId: this.props.assetId
      };
      this.updateState({asset}, resolve);
    });
  };

  init = () => {
    if (!this.props.seriesId) {
      this.updateState({
        loading: false,
        loadingSeasons: false,
        loadingEpisodes: false,
        loadingVersions: false,
        loadingConformanceGroups: false
      });
      return;
    }
    this.fetchTitleInit();
  };

  fetchTitleInit = () => {
    if (!this.props.seriesId) {
      this.updateState({
        series: [],
        seasons: [],
        episodes: [],
        versions: [],
        conformanceGroups: [],
        asset: null,
        seriesSearchString: '',
        seriesFiltering: false,
        seasonsSearchString: '',
        seasonsFiltering: false,
        episodeSearchString: '',
        episodeFiltering: false,
        versionSearchString: '',
        versionFiltering: false,
        conformanceGroupSearchString: '',
        conformanceGroupFiltering: false,
        hrIdSearch: false,
        loading: false,
        loadingSeasons: false,
        loadingEpisodes: false,
        loadingVersions: false,
        loadingConformanceGroups: false
      });
      return;
    }
    const params: ISearchTitleParams = {
      atlasId: this.props.seriesId,
      types: 'Series',
      size: 1
    };
    this.updateState({loading: true}, () => this.fetchTitle(params));
  };

  fetchTitle = async (params: ISearchTitleParams) => {
    const response = await searchTitles(params);
    const title = response.data.length ? response.data[0] : null;
    const titleSearch = getUniqueArrayObjectsByField([...this.state.series, title].filter(record => record), 'id');

    this.onTitleUpdate(title, titleSearch);
  };

  onTitleUpdate = (title: ISearchTitle, titleSearch: Array<ISearchTitle> = null, assetChanged: boolean = false) => {
    let asset: IPlaylistAtlas = null;
    if (title) {
      const assetData = {
        seriesId: title.id || '',
        seasonId: this.props.seasonId || '',
        episodeId: this.props.episodeId || '',
        assetId: this.props.assetId
      };
      asset = {
        ...assetData,
        assetId: this.props.assetId
      };
    }

    const stateObject: any = {
      series: titleSearch || this.state.series,
      asset,
      loading: false,
      loadingSeasons: true,
      loadingVersions: !!(asset && !asset.seasonId)
    };

    this.updateState(stateObject, async () => {
      if (!asset) {
        return;
      }
      await this.fetchSeasons(!!(asset && asset.seasonId));
      if (stateObject.loadingVersions) {
        this.fetchVersions(true);
      }
    });
  };

  updateTitle = (seriesId: string) => {
    const series = this.state.series.find((record: ISearchTitle) => record.id === seriesId);
    if (!series) {
      return;
    }
    this.props.onTitleInfoUpdated(series);
  };

  updateSeason = (seasonId: string) => {
    const season = this.state.seasons.find((record: ISearchTitle) => record.id === seasonId);
    if (!season) {
      return;
    }
    this.props.onTitleInfoUpdated(season);
  };

  updateEpisode = (episodeId: string) => {
    const episode = this.state.episodes.find((record: ISearchTitle) => record.id === episodeId);
    if (!episode) {
      return;
    }
    this.props.onTitleInfoUpdated(episode);
  };

  updateVersion = (versionId: string) => {
    const version = this.state.versions.find((record: ISearchTitle) => record.id === versionId);
    if (!version) {
      return;
    }
    const titleType = (suffix: 'Version' | 'Conformance') =>
      this.state.asset.seriesId && this.state.asset.seasonId && this.state.asset.episodeId
        ? `Episode${suffix}`
        : this.state.asset.seriesId && this.state.asset.seasonId
        ? `Season${suffix}`
        : `Series${suffix}`;
    this.props.onTitleInfoUpdated({...version, type: titleType('Version')});
  };

  updateConformanceGroup = (conformanceGroupId: string) => {
    const conformanceGroup = this.state.conformanceGroups.find(
      (record: ISearchTitle) => record.id === conformanceGroupId
    );
    if (!conformanceGroup) {
      return;
    }
    const conformanceType =
      this.state.asset.seriesId && this.state.asset.seasonId && this.state.asset.episodeId
        ? `EpisodeConformance`
        : this.state.asset.seriesId && this.state.asset.seasonId
        ? `SeasonConformance`
        : `SeriesConfromance`;
    this.props.onTitleInfoUpdated({...conformanceGroup, conformanceType});
  };

  fetchSeasons = async (cascade: boolean = false) => {
    const seasonsResponse = await getSeasonsBySeriesId(this.state.asset.seriesId);
    const seasons = seasonsResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    const loadingEpisodes = !!cascade;
    const loadingVersions = !!cascade;
    this.updateState(
      {
        seasons,
        loadingSeasons: false,
        loadingEpisodes,
        loadingVersions,
        episodes: [],
        versions: [],
        conformanceGroups: []
      },
      () => {
        if (loadingEpisodes) {
          this.fetchEpisodes(true);
        } else if (loadingVersions) {
          this.fetchVersions(true);
        }
      }
    );
  };

  fetchEpisodes = async (cascade: boolean = false) => {
    const episodesResponse = await getEpisodesBySeriesSeasonId(this.state.asset.seriesId, this.state.asset.seasonId);
    const episodes = episodesResponse.data.map(PlaylistAsset.parsing.parseSearchTitle);
    const loadingVersions = !!cascade;
    this.updateState(
      {
        episodes,
        loadingEpisodes: false,
        loadingVersions,
        versions: [],
        conformanceGroups: []
      },
      () => {
        if (loadingVersions) {
          this.fetchVersions(true);
        }
      }
    );
  };

  fetchVersions = async (cascade: boolean = false) => {
    const versionsAPI = await getTitleDataAPI(this.state.asset, 'Version');
    const versions = (versionsAPI.success ? (Array.isArray(versionsAPI.data) && versionsAPI.data) || [] : []).map(
      PlaylistAsset.parsing.parseSearchTitle
    );
    const loadingConformanceGroups = !!cascade;
    this.updateState(
      {
        versions,
        loadingVersions: false,
        loadingConformanceGroups,
        conformanceGroups: []
      },
      () => {
        if (loadingConformanceGroups) {
          this.fetchConformanceGroups();
        }
      }
    );
  };

  fetchConformanceGroups = async () => {
    const conformanceAPI = await getTitleConformanceGroupsByVersion(this.state.asset, this.props.versionId);
    const conformanceGroups = conformanceAPI.data;
    this.updateState({conformanceGroups, loadingConformanceGroups: false});
  };

  onSearch = async (searchString: string, fieldName: string) => {
    let data = [];
    let filterName = '';
    let type = '';
    let contentOwners = this.props.contentOwners.reduce((s, b, i) => s + (!i ? '' : ',') + b.externalId, '');
    switch (fieldName) {
      case 'series':
        this.updateState({seriesSearchString: searchString, seriesFiltering: true});
        data = this.state.series;
        filterName = 'seriesFiltering';
        type = 'Series';
        break;
      case 'seasons':
        this.updateState({seasonsSearchString: searchString, seasonsFiltering: true});
        data = this.state.seasons;
        filterName = 'seasonsFiltering';
        type = 'Season';
        break;
      case 'episodes':
        this.updateState({episodeSearchString: searchString, episodeFiltering: true});
        data = this.state.episodes;
        filterName = 'episodeFiltering';
        type = 'Episode';
        break;
      case 'versions':
        this.updateState({versionSearchString: searchString, versionFiltering: true});
        data = this.state.versions;
        filterName = 'versionFiltering';
        type = 'Version';
        break;
      case 'conformanceGroups':
        this.updateState({conformanceGroupSearchString: searchString, conformanceGroupFiltering: true});
        data = this.state.conformanceGroups;
        filterName = 'conformanceGroupFiltering';
        type = 'Conformance';
        break;
    }
    const paramsHrId: ISearchTitleParams = {
      hrId: searchString,
      page: 1,
      size: 20,
      exactSearch: false,
      excludeAssemblyTitles: false
    };
    const hrResults = await searchTitles(paramsHrId);
    const paramsName: ISearchTitleParams = {
      contentOwners,
      name: searchString,
      types: type,
      size: 50
    };
    const nameResults = await searchTitles(paramsName);

    const nameResultsId = deepCopy([...nameResults.data]).map((record: ISearchTitle) => record.id);
    const hrTitles = hrResults.data.filter((record: ISearchTitle) => {
      return nameResultsId.indexOf(record.id) === -1;
    });

    const selectedTitle = data.find((title: ISearchTitle) => title.id === this.props.seriesId);
    const updatedData = getUniqueArrayObjectsByField(
      [...hrTitles, ...nameResults.data, selectedTitle].filter(title => title),
      'id'
    );

    this.updateState({[fieldName]: updatedData, [filterName]: false, hrIdSearch: hrResults.data.length > 0});
  };

  onSeriesSearch = (searchString: string) => {
    this.onSearch(searchString, 'series');
  };

  onSeasonsSearch = (searchString: string) => {
    this.onSearch(searchString, 'seasons');
  };

  onEpisodeSearch = (searchString: string) => {
    this.onSearch(searchString, 'episodes');
  };

  onVersionSearch = (searchString: string) => {
    this.onSearch(searchString, 'versions');
  };

  onConformanceSearch = (searchString: string) => {
    this.onSearch(searchString, 'conformanceGroups');
  };

  getTitelRow = () => {
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            field={{field: 'titleId', errors: this.props.errors}}
            selectedPlaceholder="Select..."
            isOpen={this.props.isTitleInfoOpen}
            emptyPlaceholder={this.state.seriesFiltering ? 'Filtering...' : 'Empty'}
            fixedButtonWidth
            disabled={this.props.disabled}
            search
            searchOnEnter
            allowOpenAbove={false}
            contentListLimit={5}
            options={this.state.series.map((title: ISearchTitle) =>
              parseTitleOptions(title, this.props.contentOwners, this.props.contentProviders)
            )}
            selected={this.state.asset && this.state.asset.seriesId}
            label="Title Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateTitle}
            onSearch={this.onSeriesSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.seriesSearchString}
            disableSearchInput={this.state.seriesFiltering}
          />
        </Column>
      </Row>
    );
  };

  getSeasonEpisodeInfoRow = () => {
    const optionsSeason = this.state.seasons
      ? this.state.seasons.map((record: ISearchTitle) => ({label: `${record.name}`, value: record.id}))
      : [];
    const optionsEpisode = this.state.episodes
      ? this.state.episodes.map((record: any) => ({
          label: `${record.number ? `E${record.number} ${record.name ? ' - ' + record.name : ''}` : record.name}`,
          value: record.id
        }))
      : [];
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loadingSeasons ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            disabled={this.props.disabled || this.state.loadingSeasons || !this.props.seriesId}
            search
            searchOnEnter
            fixedButtonWidth
            label="Season # / Name"
            portalNode={this.props.closestBody}
            selected={this.state.asset && this.state.asset.seasonId}
            options={optionsSeason}
            onSelected={this.updateSeason}
            onSearch={this.onSeasonsSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.seasonsSearchString}
            disableSearchInput={this.state.seasonsFiltering}
          />
        </Column>
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loadingEpisodes ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            disabled={this.props.disabled || this.state.loadingEpisodes || !this.props.seasonId}
            search
            searchOnEnter
            fixedButtonWidth
            label="Episode"
            portalNode={this.props.closestBody}
            selected={this.state.asset && this.state.asset.episodeId}
            options={optionsEpisode}
            onSelected={this.updateEpisode}
            onSearch={this.onEpisodeSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.episodeSearchString}
            disableSearchInput={this.state.episodeFiltering}
          />
        </Column>
      </Row>
    );
  };

  getVersionConformanceRow = () => {
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            field={{field: 'versionId', errors: this.props.errors}}
            selectedPlaceholder={this.state.loadingVersions ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loadingVersions || !this.props.seriesId}
            search
            searchOnEnter
            contentListLimit={5}
            allowOpenAbove={false}
            options={this.state.versions.map(mapToDropdownOptions)}
            selected={this.props.versionId}
            label="Version Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateVersion}
            onSearch={this.onVersionSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.versionSearchString}
            disableSearchInput={this.state.versionFiltering}
          />
        </Column>
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loadingConformanceGroups ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loadingConformanceGroups || !this.props.versionId}
            search
            searchOnEnter
            contentListLimit={5}
            allowOpenAbove={false}
            options={this.state.conformanceGroups.map(mapToDropdownOptions)}
            selected={this.props.conformanceGroupId}
            label="Conformance Group Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateConformanceGroup}
            onSearch={this.onConformanceSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.conformanceGroupSearchString}
            disableSearchInput={this.state.conformanceGroupFiltering}
          />
        </Column>
      </Row>
    );
  };

  render() {
    return (
      <Container className="title-info-container">
        {this.getTitelRow()}
        {this.getSeasonEpisodeInfoRow()}
        {this.getVersionConformanceRow()}
      </Container>
    );
  }
}
