import * as React from 'react';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {CustomImage} from '../CustomImage';
import {Icon} from 'tt-components/src/Icon/Icon';
import {ExpandIcon} from '../../assets/Icons/Expand';
import {Tt2b} from '../Typography/Tt2b';
import {Button} from '../Button';

interface IImageViewerProps {
  imageAsset: PlaylistAsset;
  restoring?: boolean;
  hideExpandIcon?: boolean;
  updateImageRestoring: (restoring: boolean) => void;
  onAssetRestore: () => void;
  onImageExpanded: () => void;
}

interface IImageViewerState {
  showArchivedState: boolean;
}

type restoringTypes = 'Asset' | 'Vfs';

export class ImageViewer extends React.PureComponent<IImageViewerProps, IImageViewerState> {
  constructor(props) {
    super(props);

    this.state = {
      showArchivedState: true
    };
  }

  componentDidUpdate(prevProps: IImageViewerProps) {
    const prevImageAssetData = {
      assetId: prevProps.imageAsset && prevProps.imageAsset.assetId,
      state: prevProps.imageAsset && prevProps.imageAsset.vfsInfo.state
    };
    const currentImageAssetData = {
      assetId: this.props.imageAsset && this.props.imageAsset.assetId,
      state: this.props.imageAsset && this.props.imageAsset.vfsInfo.state
    };
    if (
      prevImageAssetData.assetId !== currentImageAssetData.assetId ||
      prevImageAssetData.state !== currentImageAssetData.state
    ) {
      this.setState({showArchivedState: true});
    }

    if (prevProps.restoring !== this.props.restoring && !this.props.restoring) {
      this.onRestoreCancel();
    }
  }

  onExpandIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onImageExpanded();
  };

  onRestoreCancel = () => {
    this.setState({showArchivedState: false});
  };

  onRestoring = (type: restoringTypes) => {
    if (type === 'Vfs') {
      this.props.updateImageRestoring(true);
    } else {
      this.props.onAssetRestore();
    }
  };

  getRestoreContent = (content: string | JSX.Element, type: restoringTypes = 'Vfs') => (
    <div className="image-viewer-container_archived">
      <div className="image-viewer-container_archived_wrapper">
        <Tt2b className="image-viewer-container_archived_wrapper_title" content={content} />
        <div className="image-viewer-container_archived_wrapper_buttons-container">
          <Button
            disabled={this.props.restoring}
            content="Cancel"
            className="image-viewer-container_archived_wrapper_buttons-container_cancel"
            secondary
            onClick={this.onRestoreCancel}
          />
          <Button
            disabled={this.props.restoring}
            onClick={() => this.onRestoring(type)}
            content={this.props.restoring ? 'Restoring...' : 'Restore'}
          />
        </div>
      </div>
    </div>
  );

  getAssetStatus = () => {
    const assetStatus = this.props.imageAsset ? (this.props.imageAsset.assetStatus || '').toLowerCase() : '';
    // In case asset is on restoring state and we have shown restoring screen for the first time, then we need
    // to display options so the operator can select the further steps
    if (assetStatus === 'restoring' && this.state.showArchivedState) {
      return this.getRestoreContent(
        `Your asset needs to be restored in order to playback. Would you like to restore now?`,
        'Asset'
      );
    }
    const content =
      assetStatus === 'restoring' ? (
        <>
          <div>Your asset is currently in the process of being restored.</div>
          <div>This process may take 3 to 5 hours - please check back shortly.</div>
        </>
      ) : assetStatus === 'copying' ? (
        <>
          <div>Your asset is currently in the process of being copied.</div>
          <div>This process may take a few minutes - please check back shortly.</div>
        </>
      ) : assetStatus === 'error' ? (
        `Having issues loading this image asset, please try to select another one.`
      ) : (
        ``
      );
    if (content) {
      return <Tt2b className="image-viewer-container_content" content={content} />;
    }
    return null;
  };

  getVfsState = () => {
    const vfsState = this.props.imageAsset ? (this.props.imageAsset.vfsInfo.state || '').toLowerCase() : '';
    if (vfsState === 'archived' && this.state.showArchivedState) {
      return this.getRestoreContent(
        `File is currently in archived state. Do you want to restore the file from Glacier?`
      );
    }
    if (vfsState === 'restoring') {
      return (
        <Tt2b
          className="image-viewer-container_content"
          content="Image file is currently being restored from Glacier. Please wait..."
        />
      );
    }
    return null;
  };

  getContent = () => {
    const assetStatusContent = this.getAssetStatus();
    const vfsStateContent = this.getVfsState();
    if (assetStatusContent) {
      return assetStatusContent;
    } else if (vfsStateContent) {
      return vfsStateContent;
    } else {
      return <CustomImage url={this.props.imageAsset ? this.props.imageAsset.publicUrl : ''} />;
    }
  };

  render() {
    return (
      <div className="image-viewer-container">
        {!this.props.hideExpandIcon && (
          <div className="image-viewer-container_expand-container" onClick={this.onExpandIconClick}>
            <Icon icon={ExpandIcon} size="20px" color="#FFFFFF" />
          </div>
        )}
        {this.getContent()}
      </div>
    );
  }
}
