import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Dropdown as TTDropdown} from 'tt-components/src/Dropdown';
import {Tt4a} from '../Typography/Tt4a';
import {FloatingWarning} from '../FloatingWarning';
import {IErrorLog} from '../../../@types/assetDetails';
import {ErrorPayload} from '../../models/ErrorPayload/ErrorPayload';
import {IFieldError, IField} from 'interfaces/IFieldError';
import {IMetadataError} from '../../../@types/metadataErrors';
import {wait} from '../../utils/utils';
import {Icon} from 'tt-components/src/Icon/Icon';

import {TriangleIcon} from '../../assets/Icons/Triangle';
import {CloseIcon} from '../../assets/Icons/Close';
import {SearchIcon} from '../../assets/Icons/Search';

export interface IDropdownOption {
  label: string;
  value: any;
  error?: boolean; // TODO: Remove error field and include styling in style prop
  style?: React.CSSProperties;
}

export interface IDropdownProps {
  width?: number;
  options: Array<IDropdownOption>;
  optionsSortCallback?: (a: IDropdownOption, b: IDropdownOption) => number;
  error?: string | boolean;
  field?: IField;
  selected: string | Array<string>;
  selectedPlaceholder?: string;
  emptyPlaceholder?: string;
  buttonContentStyles?: React.CSSProperties;
  onSelected?: (value: string) => void;
  label?: string;
  search?: boolean;
  isOpen?: boolean;
  placeholder?: string;
  triggerAction?: 'click' | 'hover';
  portalNode?: HTMLElement;
  borderless?: boolean;
  paddingLeft?: number;
  borderWidth?: number;
  iconColor?: string;
  iconOpacity?: string;
  disableMatchWidth?: boolean;
  disabled?: boolean;
  fixedButtonWidth?: boolean;
  floatingTextNode?: boolean;
  searchValue?: string; // Prop used in conjuction with searchOnEnter flag
  disableSearchInput?: boolean;
  onSearch?: (searchValue: string) => void;
  contentListLimit?: number;
  searchOnEnter?: boolean;
  allowOpenAbove?: boolean;
  relative?: boolean;
  toLowerCaseCompare?: boolean;
  errorLog?: IErrorLog;
  multiple?: boolean;
  hrIdSearch?: boolean;
}

interface IDropdownState {
  selected: string | Array<string>;
  searchValue: string;
  openAbove: boolean;
  disabled: boolean;
  showSearch: boolean;
}

const DROPDOWN_CONTENT_BOTTOM_OFFSET = -10;

export class Dropdown extends React.Component<IDropdownProps, IDropdownState> implements IFieldError {
  static defaultProps = {
    triggerAction: 'click',
    portalNode: null,
    paddingLeft: 15,
    borderWidth: 1,
    disabled: false,
    contentListLimit: 9,
    allowOpenAbove: true,
    optionsSortCallback: (a: IDropdownOption, b: IDropdownOption) => a.label.localeCompare(b.label)
  };

  static getDerivedStateFromProps(nextProps: IDropdownProps, prevState: IDropdownState) {
    if (!isEqual(nextProps.selected, prevState.selected)) {
      return {selected: nextProps.selected};
    }
    return null;
  }

  containerRef: HTMLDivElement;
  inputRef: HTMLInputElement;
  buttonRef: HTMLDivElement;
  contentRef: HTMLDivElement;
  onInputChange$: Subject<string>;
  ttDropdownRef: React.RefObject<TTDropdown>;
  $onInputChange: Subscription;

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      searchValue: '',
      openAbove: false,
      disabled: props.disabled,
      showSearch: false
    };
    this.ttDropdownRef = React.createRef();
  }

  componentDidMount() {
    this.onInputChange$ = new Subject();
    this.$onInputChange = this.onInputChange$.pipe(debounceTime(100)).subscribe(this.updateValue);
  }

  componentWillUnmount() {
    if (this.$onInputChange) {
      this.$onInputChange.unsubscribe();
    }
  }

  onItemSwitch = (value: string, doToggle: boolean = true) => {
    if (!this.props.onSelected) {
      return;
    }
    let updatedValue: any = value;
    if (this.props.multiple) {
      const selected = this.state.selected ? (Array.isArray(this.state.selected) ? [...this.state.selected] : []) : [];
      const exists = selected.find((token: string) => token === value);
      if (exists) {
        updatedValue = selected.filter((token: string) => token !== value);
      } else {
        updatedValue = [...selected, value];
      }
    }
    if (doToggle) {
      this.buttonRef.click();
    }
    this.props.onSelected(updatedValue);
  };

  componentDidUpdate(prevProps: IDropdownProps) {
    if (prevProps.search !== this.props.search && this.props.search) {
      this.setState({searchValue: ''});
    }
    if (this.state.disabled !== this.props.disabled) {
      if (
        !this.state.disabled &&
        this.ttDropdownRef &&
        this.ttDropdownRef.current &&
        this.ttDropdownRef.current.state.isOpened
      ) {
        this.buttonRef.click();
      }
      this.setState({disabled: this.props.disabled, showSearch: this.props.disabled ? false : this.state.showSearch});
    }
  }

  compareSelectedWithOption = (option: IDropdownOption) => {
    const optionValue = this.props.toLowerCaseCompare ? (option.value || '').toLowerCase() : option.value || '';
    let selected = this.state.selected
      ? Array.isArray(this.state.selected)
        ? [...this.state.selected]
            .filter(token => token)
            .map(token => (this.props.toLowerCaseCompare ? token.toLowerCase() : token))
        : this.props.toLowerCaseCompare
        ? (this.state.selected || '').toLowerCase()
        : this.state.selected
      : '';
    return Array.isArray(selected) ? selected.indexOf(optionValue) !== -1 : selected === optionValue;
  };

  getItem = (item: IDropdownOption, index: number) => {
    const selected = this.compareSelectedWithOption(item) ? ` selected` : ``;
    return (
      <div
        title={item.label}
        className={`dropdown-ui-container_dropdown_content_list_item${selected}${item.error ? ' error-msg' : ''}`}
        onClick={e => this.onItemSwitch(item.value)}
        style={item.style || {}}
        key={index}
      >
        {item.label}
      </div>
    );
  };

  updateValue = (value: string) => {
    if (this.props.searchOnEnter) {
      return;
    }
    this.updateSearchValue(value);
  };

  updateSearchValue = (searchValue: string, fromEnter?: boolean) => {
    this.setState({searchValue}, () => {
      if (this.props.onSearch) {
        if (this.props.searchOnEnter && !fromEnter) {
          return;
        }
        this.props.onSearch(searchValue);
      }
    });
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  onInputkeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.props.searchOnEnter && e.keyCode === 13) {
      this.updateSearchValue((e.target as HTMLInputElement).value, true);
    }
  };

  getPortalNode = () => {
    return this.props.triggerAction === 'click' ? this.props.portalNode : this.props.portalNode.closest('body');
  };

  getContent = () => {
    let items = this.props.options;
    // Sort options list based on provided sorting logic
    items.sort(this.props.optionsSortCallback);
    if (this.props.search && this.state.searchValue && !this.props.hrIdSearch) {
      items = items.filter((item: IDropdownOption) =>
        item.label.toLowerCase().includes(this.state.searchValue.toLowerCase())
      );
    }
    const scroll = items.length > this.props.contentListLimit ? ` scroll` : ``;
    const empty = !items.length ? ` empty` : ``;
    const height = scroll ? this.props.contentListLimit * 40 : empty ? 40 : 'auto';
    return (
      <div className="dropdown-ui-container_dropdown_content" ref={node => (this.contentRef = node)}>
        {this.props.search && this.props.multiple && this.getContentSearch()}
        <div className={`dropdown-ui-container_dropdown_content_list${scroll}${empty}`} style={{height}}>
          {items.map((item: IDropdownOption, index: number) => this.getItem(item, index))}
          {!!empty && <Tt4a content={this.props.emptyPlaceholder || 'Empty'} style={{color: '#fff'}} />}
        </div>
      </div>
    );
  };

  getSelected = (): IDropdownOption | Array<IDropdownOption> => {
    const selectedRecord = this.props.multiple
      ? this.props.options.filter(this.compareSelectedWithOption)
      : this.props.options.find(this.compareSelectedWithOption);
    return selectedRecord || null;
  };

  toggleSearch = async (show: boolean) => {
    if (!show) {
      await wait(400);
      this.setState({showSearch: false}, () => {
        if (this.ttDropdownRef && this.ttDropdownRef.current && this.ttDropdownRef.current.state.isOpened) {
          this.buttonRef.click();
        }
      });
    } else {
      if (this.props.search && !this.state.disabled) {
        await wait(400);
        this.setState({showSearch: true}, () => {
          this.updateSearchValue(null);
        });
      }
    }
  };

  onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  getSearchInput = (props: any = {}) => {
    return (
      <input
        ref={node => (this.inputRef = node)}
        type="text"
        className={`${this.props.disableSearchInput ? `disabled` : ``}`}
        disabled={this.props.disableSearchInput}
        placeholder={this.props.placeholder || ''}
        onChange={e => this.onInputChange$.next(e.target.value)}
        onKeyDown={this.onInputkeyDown}
        {...props}
      />
    );
  };

  getContentSearch = () => {
    return (
      <div className="dropdown-ui-container_dropdown_content_search-field">
        <div className="dropdown-ui-container_dropdown_content_search-field_wrapper">
          <Icon icon={SearchIcon} color="#fff" size="18px" />
          {this.getSearchInput()}
        </div>
      </div>
    );
  };

  getSingleSelectionContent = () => {
    const selectedRecord = this.getSelected() as IDropdownOption;
    const selectedRecordLabel = selectedRecord ? selectedRecord.label : this.state.selected ? '...' : null;
    const selected = selectedRecordLabel || this.props.selectedPlaceholder || 'Select...';
    const showSearch = this.state.showSearch && this.props.search;
    const floatingTextNode = !this.props.disableMatchWidth && this.props.floatingTextNode ? ` floating-text-node` : ``;
    // Include in the width calculation the width of the absolute positioned Icon
    const calculatedWidth = this.props.disableMatchWidth ? `auto` : `calc(100% - 30px)`;

    const controlledInputProps = this.props.searchOnEnter
      ? {defaultValue: this.props.searchValue || ''}
      : {value: this.state.searchValue === null ? selectedRecordLabel || '' : this.state.searchValue};
    const props = {
      onBlur: () => this.toggleSearch(false),
      onClick: this.onInputClick,
      autoFocus: true,
      style: {width: calculatedWidth, paddingLeft: this.props.paddingLeft},
      ...controlledInputProps
    };
    return (
      <>
        {showSearch ? (
          this.getSearchInput(props)
        ) : (
          <div
            className={`dropdown-ui-container_dropdown_button_content_text${floatingTextNode}`}
            style={{width: calculatedWidth, paddingLeft: this.props.paddingLeft}}
            onClick={e => this.toggleSearch(true)}
          >
            {selected}
          </div>
        )}
      </>
    );
  };

  onItemDeselect(value: string, e: React.MouseEvent<HTMLInputElement>) {
    this.onItemSwitch(value, false);
  }

  onPreventTagClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  getMultipleSelectionContent = () => {
    // Include in the width calculation the width of the absolute positioned Icon
    const calculatedWidth = this.props.disableMatchWidth ? `auto` : `calc(100% - 30px)`;
    const tags = (this.getSelected() as Array<IDropdownOption>).reduce(
      (acc: Array<JSX.Element>, option: IDropdownOption, index: number) => {
        const tag = (
          <div
            key={index}
            className="dropdown-ui-container_dropdown_button_content_tags_tag"
            onClick={this.onPreventTagClick}
          >
            <div className="dropdown-ui-container_dropdown_button_content_tags_tag_content">{option.label}</div>
            <div
              className="dropdown-ui-container_dropdown_button_content_tags_tag_icon"
              onClick={this.onItemDeselect.bind(this, option.value)}
            >
              <Icon icon={CloseIcon} color="#FFFFFF" size="10px" />
            </div>
          </div>
        );
        return [...acc, tag];
      },
      []
    );
    return (
      <div className="dropdown-ui-container_dropdown_button_content_tags" style={{width: calculatedWidth}}>
        {tags}
      </div>
    );
  };

  getButton = () => {
    const selectedRecord = this.getSelected();
    const style = {
      ...(this.props.buttonContentStyles || {}),
      ...(!this.props.multiple && selectedRecord && (selectedRecord as IDropdownOption).style
        ? (selectedRecord as IDropdownOption).style
        : {})
    };
    const disableMatchWidth = this.props.disableMatchWidth ? ` disable-match-width` : ``;
    const borderless = this.props.borderless ? ` borderless` : ``;
    const iconColor = this.props.iconColor ? this.props.iconColor : '#8194B5';
    const iconOpacity = this.props.iconOpacity ? this.props.iconOpacity : '0.25';
    return (
      <div className="dropdown-ui-container_dropdown_button" ref={node => (this.buttonRef = node)}>
        <div className={`dropdown-ui-container_dropdown_button_content${disableMatchWidth}${borderless}`} style={style}>
          {this.props.multiple ? this.getMultipleSelectionContent() : this.getSingleSelectionContent()}
        </div>
        <div className="dropdown-ui-container_dropdown_button_icon">
          <TriangleIcon opacity={iconOpacity} color={iconColor} />
        </div>
      </div>
    );
  };

  onMouseIn = () => {
    setTimeout(() => {
      if (this.props.search) {
        this.updateSearchValue(null);
      }
      if (this.contentRef && this.props.allowOpenAbove && this.props.triggerAction === 'click') {
        const {height, top} = this.contentRef.getBoundingClientRect();
        if (height + top + DROPDOWN_CONTENT_BOTTOM_OFFSET >= window.document.documentElement.offsetHeight) {
          this.setState({openAbove: true});
        }
      }
    }, 300);
  };

  onMouseOut = () => {
    this.setState({openAbove: false});
  };

  onOpen = (styles: React.CSSProperties) => {
    let computedStyles = this.computeStyles(styles);
    // Update top position based on scroll of the containing element
    if (this.props.triggerAction === 'click' && this.getPortalNode()) {
      const scrollTop = this.getPortalNode().scrollTop;
      let dimensions: any = {};

      // NOTE: We will use the 'relative' in cases we will need to define position of the Dropdown
      // relative to the provided portalNode so it can be included inside its borders
      if (this.props.relative) {
        const portalVPLeftOffset = this.getPortalNode().getBoundingClientRect().left;
        const portalVPTopOffset = this.getPortalNode().getBoundingClientRect().top;
        const conatinerVPLeftOffset = this.containerRef.getBoundingClientRect().left;
        const conatinerVPTopOffset = this.containerRef.getBoundingClientRect().top;
        const scrollLeft = this.getPortalNode().scrollLeft;
        dimensions = {
          top: conatinerVPTopOffset - portalVPTopOffset + this.containerRef.offsetHeight + scrollTop,
          left: conatinerVPLeftOffset - portalVPLeftOffset + scrollLeft
        };
      } else {
        dimensions = {
          top: typeof computedStyles.top !== 'undefined' ? (computedStyles.top as number) + scrollTop : null
        };
      }
      for (const prop in dimensions) {
        if (dimensions.hasOwnProperty(prop)) {
          if (!dimensions[prop]) {
            delete dimensions[prop];
          }
        }
      }
      computedStyles = {...computedStyles, ...dimensions};
    }
    // If openAbove criteria is met we need to update the top position
    if (this.state.openAbove && typeof computedStyles.top !== 'undefined') {
      const heightOffset = this.containerRef ? this.containerRef.getBoundingClientRect().height : 0;
      computedStyles = {...computedStyles, top: (computedStyles.top as number) - heightOffset};
    }
    return computedStyles;
  };

  computeStyles = (styles: React.CSSProperties) => {
    const computedStyles = {...styles, ...defaultStyle, marginLeft: -this.props.borderWidth};
    if (this.containerRef) {
      const width = this.getContainerWidth();
      return {...computedStyles, width: this.props.disableMatchWidth ? this.props.width || 200 : width};
    }
    return computedStyles;
  };

  defineError = () => {
    if (this.props.error) {
      return this.props.error;
    }
    if (this.props.field) {
      const error = ErrorPayload.getMetadataError(this.props.field.field, this.props.field.errors || []);
      return error || false;
    }
    return false;
  };

  getErrorMessage = () => {
    const error = this.defineError();
    if (error) {
      const content =
        typeof error === 'string' ? error : (error as IMetadataError).message ? (error as IMetadataError).message : ``;
      return content && <div className="dropdown-ui-container_error-message">{content}</div>;
    }
    return null;
  };

  showErrorLog = () => {
    const selected = this.getSelected();
    const isSelectedValid = selected ? (Array.isArray(selected) ? !!selected.length : true) : false;
    return !!this.props.errorLog && !isSelectedValid;
  };

  getLabel = () => {
    const showLabel = !!this.props.label || this.showErrorLog();
    const error = !!this.defineError() ? ' label-error' : '';
    return (
      showLabel && (
        <div className="dropdown-ui-container_label-container">
          {this.props.label && (
            <div className={`dropdown-ui-container_label-container_label${error}`}>{this.props.label}</div>
          )}
          {this.showErrorLog() && (
            <div className="dropdown-ui-container_label-container_error-log">
              <FloatingWarning
                message={this.props.errorLog}
                closestBody={this.props.portalNode ? this.props.portalNode.closest('body') : null}
              />
            </div>
          )}
        </div>
      )
    );
  };

  getRequiredCssClass = () => {
    if (!this.props.field) {
      return '';
    }
    return ErrorPayload.getRequiredCSSClass(this.props.field.field || '', this.props.field.selectedAsset || null)
      ? ` ${ErrorPayload.getRequiredCSSClass(this.props.field.field || '', this.props.field.selectedAsset || null)}`
      : ``;
  };

  getContainerWidth = () => {
    if (this.containerRef) {
      return this.containerRef.getBoundingClientRect().width;
    }
    return null;
  };

  composeContainerDropdownClassName = () => {
    const borderless = this.props.borderless ? ` borderless` : ``;
    const disableMatchWidth = this.props.disableMatchWidth ? ` disable-match-width` : ``;
    const searchInput = this.state.showSearch ? ` search-input` : ``;
    const fixedButtonWidth = this.props.fixedButtonWidth ? ` fixed-button-width` : ``;
    const multiple = this.props.multiple ? ` multiple` : ``;
    const error = !!this.defineError() ? ' dropdown-error' : '';
    return `${borderless}${disableMatchWidth}${searchInput}${fixedButtonWidth}${error}${multiple}`;
  };

  render() {
    const disabled = this.state.disabled ? ` disabled` : ``;
    const error = !!this.defineError() ? ' dropdown-error' : '';
    const width = this.props.disableMatchWidth
      ? 'unset'
      : this.props.width || (this.props.fixedButtonWidth ? this.getContainerWidth() || '100%' : '100%');
    const required = this.getRequiredCssClass();
    return (
      <div className={`dropdown-ui-container${disabled}${required}${error}`}>
        {this.getLabel()}
        <div
          className={`dropdown-ui-container_dropdown${this.composeContainerDropdownClassName()}`}
          ref={node => (this.containerRef = node)}
          style={{width, borderWidth: this.props.borderWidth}}
        >
          <TTDropdown
            ref={this.ttDropdownRef}
            isOpen={this.props.isOpen}
            disabled={this.state.disabled}
            triggerDropAction={this.props.triggerAction}
            content={this.getContent()}
            buttonComponent={() => this.getButton()}
            portalNode={this.getPortalNode()}
            onMouseIn={this.onMouseIn}
            onMouseOut={this.onMouseOut}
            onOpen={this.onOpen}
            openAbove={this.state.openAbove}
          />
        </div>
        {this.getErrorMessage()}
      </div>
    );
  }
}

const defaultStyle: React.CSSProperties = {
  // Add offset of the button element: paddingLeft + borderWidth
  // marginLeft: -16,
  padding: 0,
  border: 'none',
  backgroundColor: 'transparent',
  paddingTop: 8,
  boxSizing: 'border-box'
};
