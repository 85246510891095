import {IAppServices, IAppEnvironment} from '../../@types/appConfig';

const allowedEnvironments = Object.freeze(['dev', 'int', 'live'] as Array<IAppEnvironment>);

const mapExtensionToEnvironment = Object.freeze({
  dev: 'de',
  int: 'in',
  live: 'com'
});

const mapDomainToEnvironment = Object.freeze({
  dev: 'one-ui.dmlib',
  int: 'one-ui.dmlib',
  live: 'one.bydeluxe'
});

export class Hosts implements IAppServices {
  public apiTTmanHost: string = '';
  public apiAtlasHost: string = '';
  public apiOneUiHost: string = '';
  public oneUiHost: string = '';
  public onePlayerServiceHost: string = '';
  public oneCustomerHost: string = '';
  public vfsHost: string = '';
  public vsfHostDownload: string = '';

  constructor(environment: IAppEnvironment = 'dev') {
    this.updateHostsByEnvironment(environment);
  }

  updateHostsByEnvironment(environment: IAppEnvironment) {
    const parsedEnvironment = allowedEnvironments.indexOf(environment) !== -1 ? environment : 'dev';
    const extension = mapExtensionToEnvironment[parsedEnvironment];
    const oneUiDomain = mapDomainToEnvironment[parsedEnvironment];
    this.apiTTmanHost = `https://${oneUiDomain}.${extension}/api/deluxe/passthrough/ttman/v1/`;
    this.apiAtlasHost = `https://${oneUiDomain}.${extension}/api/deluxe/passthrough/atlas/v1/`;
    this.apiOneUiHost = `https://${oneUiDomain}.${extension}/api/deluxe/`;
    this.oneUiHost = `${window.location.protocol}://${window.location.host}`;
    this.onePlayerServiceHost = `https://${oneUiDomain}.${extension}/api/deluxe/passthrough/player/v1/`;
    this.oneCustomerHost = `https://${oneUiDomain}.${extension}/api/deluxe/passthrough/customers/v1/`;
    this.vfsHost = `https://${oneUiDomain}.${extension}/api/deluxe/passthrough/vfs/v1/`;
    this.vsfHostDownload = `https://${oneUiDomain}.${extension}/api/v2/deluxe/passthrough/vfs/v1/`;
  }
}
