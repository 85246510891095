import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {IAudioData, IErrorLog} from '../../../../../../../../../@types/assetDetails';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {IEnum} from '../../../../../../../../../@types/enum';
import {LanguageDropdown} from '../../../../../LanguageDropdown';
import {getErrorLogByField} from '../../../../../../utils/helpers';
import {IMetadataError} from '../../../../../../../../../@types/metadataErrors';

import {AudioPlay} from '../../../../../../../../assets/Icons/AudioPlayIcon';
import {CloseIcon} from '../../../../../../../../assets/Icons/Close';

interface IAudioConfigurationHeaderProps {
  audio: IAudioData;
  languageDialect?: Array<IEnum>;
  channelConfigEnums?: Array<IEnum>;
  closestBody?: HTMLElement;
  style?: React.CSSProperties;
  errorLogs?: Array<IErrorLog>;
  audioMetadataErrors: Array<IMetadataError>;
  onChannelConfigUpdated: (configId: string, config: string) => void;
  onChannelLanguageUpdated: (configId: string, language: string, country: string) => void;
  onRemoveAudioConfiguration: (configId: string) => void;
}

const convertEnums = (enums: Array<IEnum>): Array<IDropdownOption> =>
  enums.reduce((acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}], []);

export class AudioConfigurationHeader extends React.PureComponent<IAudioConfigurationHeaderProps> {
  static defaultProps = {
    channelConfigEnums: [],
    languageDialect: []
  };

  render() {
    return (
      <div className="audio-configuration-header-container" style={this.props.style || {}}>
        <div className="audio-configuration-header-container_item icon">
          <Icon icon={AudioPlay} size="24px" color="#FFFFFF" />
        </div>
        <div className="audio-configuration-header-container_item channel-config">
          <Dropdown
            field={{field: 'audio.channelConfig', errors: this.props.audioMetadataErrors}}
            disableMatchWidth
            borderless
            paddingLeft={0}
            borderWidth={0}
            search
            buttonContentStyles={{color: '#1F72F2'}}
            options={convertEnums(this.props.channelConfigEnums)}
            onSelected={this.props.onChannelConfigUpdated.bind(this, this.props.audio.id)}
            selected={this.props.audio.channelConfig}
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('audio.channelConfig', this.props.errorLogs)}
          />
        </div>
        <div className="audio-configuration-header-container_item">
          <LanguageDropdown
            field={{field: 'audio.language', errors: this.props.audioMetadataErrors}}
            disableMatchWidth
            borderless
            search
            buttonContentStyles={{color: '#1F72F2'}}
            toLowerCaseCompare
            paddingLeft={0}
            borderWidth={0}
            languageDialect={this.props.languageDialect}
            onLanguageSelected={this.props.onChannelLanguageUpdated.bind(this, this.props.audio.id)}
            language={this.props.audio.language}
            country={this.props.audio.country}
            portalNode={this.props.closestBody}
            errorLogs={this.props.errorLogs}
          />
        </div>
        <div className="audio-configuration-header-container_item remove-config">
          <div
            className="icon-inner-container"
            title="Remove audio configuration"
            onClick={() => this.props.onRemoveAudioConfiguration(this.props.audio.id)}
          >
            <Icon icon={CloseIcon} size="12px" color="#ffffff" />
          </div>
        </div>
      </div>
    );
  }
}
