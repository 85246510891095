import * as React from 'react';
import {IFrameRate} from 'tt-components/src/frameRate';
import {defineThumbnailStyleFromCue} from '../../utils/utils';
import {IParsedVTT} from '../../../@types/parsedVtt';
import {Smpte} from '../../models/Smpte/Smpte';

export interface IThumbnailPreviewData {
  leftPosition: number;
  // Indicates the relative left position between VideoPlayer container
  // and the range input of the progress bar
  mostLeftPosition: number;
  bottomPosition: number;
  timePosition: number;
  jsonVTT: Array<IParsedVTT>;
  seekBarWidth: number;
}

interface IThumbnailPreviewProps {
  data: IThumbnailPreviewData;
  frameRate: IFrameRate;
  isControlBarShown: boolean;
  duration: number;
  showingDropdownTimeout: number;
  startTimecodeOffset?: number;
}

interface IThumbnailTooltipState {
  isOpened: boolean;
  shouldComponentBeOpened: boolean;
}

const TAIL_HEIGHT = 28;
const TAIL_WIDTH = 30;
const X_OFFSET = 5;

export class ThumbnailTooltip extends React.Component<IThumbnailPreviewProps, IThumbnailTooltipState> {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {leftPosition, timePosition} = nextProps.data;
    const duration = nextProps.duration;

    const style: any = {
      left: leftPosition || -1
    };

    // Add support for Thumbnails generation to very start or very end of the playback
    const hideThumbnail =
      style.left < 0 || (timePosition !== null ? timePosition < 0 || timePosition > duration : !timePosition);

    if (hideThumbnail) {
      return {
        isOpened: false,
        shouldComponentBeOpened: false
      };
    } else {
      return {
        shouldComponentBeOpened: true
      };
    }
  }

  thumbnailElementRef: HTMLDivElement;
  showThumbnailTooltipTimeoutId: number;

  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      shouldComponentBeOpened: false
    };
  }

  componentDidUpdate() {
    const {showingDropdownTimeout} = this.props;
    const {isOpened, shouldComponentBeOpened} = this.state;
    if (!shouldComponentBeOpened) {
      clearTimeout(this.showThumbnailTooltipTimeoutId);
      this.showThumbnailTooltipTimeoutId = null;
    } else if (!this.showThumbnailTooltipTimeoutId && !isOpened) {
      this.showThumbnailTooltipTimeoutId = window.setTimeout(() => {
        const {leftPosition, timePosition} = this.props.data;
        const {duration} = this.props;
        const styleLeft = leftPosition || -1;
        const hideThumbnail =
          styleLeft < 0 || (timePosition !== null ? timePosition < 0 || timePosition > duration : !timePosition);
        this.setState({isOpened: !hideThumbnail});
      }, showingDropdownTimeout);
    }
  }

  defineThumbnailStyles() {
    const {leftPosition, timePosition, mostLeftPosition, seekBarWidth} = this.props.data;
    const duration = this.props.duration;

    const style: any = {
      left: leftPosition || -1
    };

    // Add support for Thumbnails generation to very start or very end of the playback
    const hideThumbnail =
      style.left < 0 || (timePosition !== null ? timePosition < 0 || timePosition > duration : !timePosition);

    if (hideThumbnail) {
      return style;
    }

    const mostLeftPositionForThumbnail = mostLeftPosition - TAIL_WIDTH / 2 - X_OFFSET;
    const mostRightPositionForThumbnail = mostLeftPosition + TAIL_WIDTH / 2 + X_OFFSET;

    if (this.thumbnailElementRef) {
      const {width} = this.thumbnailElementRef.getBoundingClientRect();

      style.left =
        style.left - width / 2 <= mostLeftPositionForThumbnail
          ? mostLeftPositionForThumbnail - 0.7
          : style.left + width / 2 >= seekBarWidth + mostRightPositionForThumbnail
          ? seekBarWidth + mostRightPositionForThumbnail - width - 1
          : style.left - width / 2;
    }

    return style;
  }

  defineTailStyles() {
    const {leftPosition, timePosition} = this.props.data;
    const duration = this.props.duration;

    const style: any = {
      left: leftPosition || -1,
      borderWidth: `${TAIL_HEIGHT}px ${TAIL_WIDTH / 2}px 0`
    };

    // Add support for Thumbnails generation to very start or very end of the playback
    const hideTail =
      style.left < 0 || (timePosition !== null ? timePosition < 0 || timePosition > duration : !timePosition);
    if (hideTail) {
      return style;
    }

    style.left = style.left - TAIL_WIDTH / 2;

    return style;
  }

  getThumbnailStyle() {
    const timePosition = (this.props.data.timePosition || 0) * 1000;

    const selectedCue = this.props.data.jsonVTT.find((record: IParsedVTT) => {
      return record.start <= timePosition && record.end >= timePosition && !!record.part;
    });

    if (!(selectedCue && selectedCue.part)) {
      return null;
    }

    const thumbnailStyle = defineThumbnailStyleFromCue(selectedCue) as React.CSSProperties;
    return thumbnailStyle;
  }

  toSMTPE() {
    const {timePosition} = this.props.data;
    // For negative values returned from the mouse position we will handle them as 0 values
    const time = timePosition ? (timePosition > 0 ? this.props.data.timePosition : 0) : 0;
    const smpte = Smpte.fromTimeWithAdjustments(time + (this.props.startTimecodeOffset || 0), {
      frameRate: this.props.frameRate.frameRate,
      dropFrame: this.props.frameRate.dropFrame
    });
    return smpte.toString();
  }

  render() {
    const {isControlBarShown} = this.props;
    const style = this.getThumbnailStyle();
    const thumbnailStyles = this.defineThumbnailStyles();
    const tailStyles = this.defineTailStyles();
    const {isOpened} = this.state;

    const thumbnailImage = [
      'thumbnail-tooltip-container_wrapper_image',
      !style && 'thumbnail-tooltip-container_wrapper_image--empty'
    ].filter(x => x);

    return (
      <div
        className={'thumbnail-tooltip ' + (isControlBarShown ? '' : 'op_thumbnail-tooltip_hidden')}
        style={{bottom: this.props.data.bottomPosition}}
      >
        <div className="thumbnail-tooltip-container">
          <div
            className="thumbnail-tooltip-container_wrapper"
            style={{...thumbnailStyles, visibility: isOpened ? 'visible' : 'hidden'}}
            ref={node => (this.thumbnailElementRef = node)}
          >
            <div className={thumbnailImage.join(' ')}>
              <div
                className="thumbnail-tooltip-container_wrapper_image_thumbnail"
                style={style || {width: 75, height: 1}}
              />
            </div>
            <div className="thumbnail-tooltip-container_wrapper_time">{this.toSMTPE()}</div>
          </div>
        </div>
        <div className="thumbnail-tooltip_tail" style={{...tailStyles, visibility: isOpened ? 'visible' : 'hidden'}} />
      </div>
    );
  }
}
