import {IServiceProvider} from './interfaces';

import {Video} from './video';
import {EventBus} from '../EventBus';
import {GoogleTagService} from './GoogleTagService';
import {Hosts} from './Hosts';

export const serviceProvider: IServiceProvider = {
  video: new Video(),
  eventBus: new EventBus(),
  gtm: new GoogleTagService(),
  hosts: new Hosts()
};
