import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Container, Row, Column} from '../../../../../../../Grid';
import {Tt1, Tt2b} from '../../../../../../../../../../components/Typography';
import {Dropdown, IDropdownOption} from '../../../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../../../components/Toggle';
import {IAssetDistributor, IErrorLog} from '../../../../../../../../../../../@types/assetDetails';
import {deepCopy, getErrorLogByField} from '../../../../../../../../utils/helpers';
import {Button} from '../../../../../../../../../../components/Button';
import {DeleteIcon} from '../../../../../../../../assets/Icons/DeleteIcon';

interface IDistributorsProps {
  distributors: Array<IAssetDistributor>;
  distributorsOptions: Array<IDropdownOption>;
  disabled: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  onDistributorUpdates: (distributors: Array<IAssetDistributor>) => void;
}

const getDefaultDistributor = (): IAssetDistributor => ({
  id: `${new Date().getTime()}-new-distributor`,
  distributorId: null,
  isExclusive: false
});

export class Distributors extends React.PureComponent<IDistributorsProps> {
  getDistributor = (distributor: IAssetDistributor, key: number) => {
    const newDistributorRecord = (distributor.id || '').endsWith('-new-distributor');
    return (
      <Container className="distributor-record-container" key={key}>
        <Row className="video-distributors-container_row">
          <Column className="video-distributors-container_row_column two-columns">
            <Dropdown
              disabled={this.props.disabled}
              fixedButtonWidth
              search
              label="Distributor Name / ID"
              options={this.props.distributorsOptions}
              selected={distributor.distributorId}
              onSelected={(distributorId: string) => this.onDistributorUpdates(distributor.id, distributorId)}
              portalNode={this.props.closestBody}
              errorLog={!newDistributorRecord && getErrorLogByField('distributors.distributorId', this.props.errorLogs)}
            />
          </Column>
          <Column className="video-distributors-container_row_column">
            <Toggle
              disabled={this.props.disabled}
              label="Exclusive"
              toggled={distributor.isExclusive}
              onToggle={(state: boolean) => this.onDistributorExlusiveUpdates(distributor.id, state)}
            />
          </Column>
          <Column className="video-distributors-container_row_column action-column remove-column">
            {!this.props.disabled && (
              <div
                className="video-distributors-container_row_column_remove"
                onClick={() => this.onRemoveDistributor(distributor.id)}
              >
                <Icon icon={DeleteIcon} color="#1F72F2" size="18px" />
              </div>
            )}
          </Column>
        </Row>
      </Container>
    );
  };

  getContent = () => {
    if (!this.props.distributors.length) {
      return (
        <Row className="video-distributors-container_row">
          <Column className={`video-distributors-container_row_column${this.props.disabled ? ` disabled` : ``}`}>
            <Tt2b
              className="video-distributors-container_empty-text"
              content={`Video doesn't have distributors defined`}
            />
          </Column>
        </Row>
      );
    } else {
      return this.props.distributors.reduce(
        (acc: Array<JSX.Element>, distributor: IAssetDistributor, index: number) => {
          return [...acc, this.getDistributor(distributor, index)];
        },
        []
      );
    }
  };

  onDistributorUpdates = (distributorId: string, value: any) => {
    const distributors = deepCopy([...this.props.distributors]).map((distributor: IAssetDistributor) => {
      if (distributorId === distributor.id) {
        distributor.distributorId = value;
      }
      return distributor;
    });
    this.props.onDistributorUpdates(distributors);
  };

  onDistributorExlusiveUpdates = (distributorId: string, value: any) => {
    const distributors = deepCopy([...this.props.distributors]).map((distributor: IAssetDistributor) => {
      if (distributorId === distributor.id) {
        distributor.isExclusive = value;
      }
      return distributor;
    });
    this.props.onDistributorUpdates(distributors);
  };

  onAddDistributor = () => {
    if (this.props.disabled) {
      return;
    }
    const distributors = deepCopy([...this.props.distributors]);
    distributors.push(getDefaultDistributor());
    this.props.onDistributorUpdates(distributors);
  };

  onRemoveDistributor = (distributorId: string) => {
    if (this.props.disabled) {
      return;
    }
    const distributors = deepCopy([...this.props.distributors]).filter(
      (distributor: IAssetDistributor) => distributor.id !== distributorId
    );
    this.props.onDistributorUpdates(distributors);
  };

  render() {
    return (
      <Container className="video-distributors-container">
        <Row className="video-distributors-container_row">
          <Column className="video-distributors-container_row_column">
            <Tt1 content="Distributors" className="video-distributors-container_row_title" />
          </Column>
        </Row>
        {this.getContent()}
        {!this.props.disabled && (
          <Row className="video-distributors-container_row">
            <Column className="video-distributors-container_row_column action-column">
              <Button content="+ Add Distributor" small secondary onClick={this.onAddDistributor} />
            </Column>
          </Row>
        )}
      </Container>
    );
  }
}
