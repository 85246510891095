import * as React from 'react';
import {Playback} from '../Playback';
import {ITemplateColors} from '../../../../@types/templateColors';
import {IAssetStatus} from '../../../../@types/assetStatus';
import {isBlockingState} from 'utils/utils';
import {Icon} from 'tt-components/src/Icon/Icon';
import {PlayerError} from '../../../models/PlayerError/PlayerError';

import {StagingIcon} from '../../../assets/Icons/Staging';
import {SubtitlesIcon} from '../../../assets/Icons/Subtitles';

interface IPlaybackSetupProps {
  templateColors: ITemplateColors;
  playlistError?: string;
  playlistLoading?: boolean;
  assetStatus?: IAssetStatus;
  selectedConfiguration: string;
  isProcessingAudio?: boolean;
  isProxy?: boolean;
  isContent?: boolean;
  error?: PlayerError;
  emptyAudioConfigurations?: (proxyValidation: boolean) => boolean;
}

const isLoadingStatusToHandle = (props: IPlaybackSetupProps) => {
  const markedAsBlockingState: Array<IAssetStatus> = ['Checking', 'Copying', 'Restoring', 'Initial', 'Error'];
  if (
    props.assetStatus &&
    isBlockingState(props.assetStatus) &&
    markedAsBlockingState.indexOf(props.assetStatus) !== -1
  ) {
    return true;
  }
  return false;
};

const createTitle = (props: IPlaybackSetupProps) => {
  if (props.playlistLoading) {
    return `Playlist loading, please wait...`;
  }

  if (props.isContent) {
    return null;
  }

  if (isLoadingStatusToHandle(props)) {
    return props.assetStatus === 'Restoring'
      ? `Asset Restore In Progress`
      : props.assetStatus === 'Copying'
      ? `Asset Copy In Progress`
      : props.assetStatus === 'Initial'
      ? `Acquiring Asset Status...`
      : props.assetStatus === 'Checking'
      ? `Loading Asset...`
      : props.isProxy
      ? `Proxy Not Found`
      : `Failed Starting Playback`;
  }

  if (props.playlistError) {
    return `Playlist Not Created`;
  }

  if (props.emptyAudioConfigurations && props.emptyAudioConfigurations(true)) {
    return `Empty Audio Configurations`;
  }

  if (!props.selectedConfiguration) {
    return `Playlist Loaded Successfully`;
  }

  if (props.isProcessingAudio) {
    return `Processing audio configuration...`;
  }

  if (props.error) {
    return `Player Error`;
  }

  return `Video Not Ready for Playback`;
};

const createContent = (props: IPlaybackSetupProps) => {
  if (props.playlistLoading) {
    return null;
  }

  if (props.isContent) {
    return null;
  }

  if (isLoadingStatusToHandle(props)) {
    // tslint:disable-next-line
    const restoringText = `Your asset is currently in the process of being restored. This process may take 3 to 5 hours - please check back shortly.`;
    // tslint:disable-next-line
    const copyingText = `Your asset is currently in the process of being copied. This process may take a few minutes - please check back shortly.`;
    const initialText = ``;
    const errorText = `Please try to select another configuration or wait few minutes.`;
    const checkingText = ``;
    const proxyNotFoundText =
      props.emptyAudioConfigurations && props.emptyAudioConfigurations(false)
        ? `There are not valid audio tracks to play for the generated playlist`
        : ``;

    const text =
      props.assetStatus === 'Restoring'
        ? restoringText
        : props.assetStatus === 'Copying'
        ? copyingText
        : props.assetStatus === 'Initial'
        ? initialText
        : props.assetStatus === 'Checking'
        ? checkingText
        : props.isProxy
        ? proxyNotFoundText
        : errorText;
    return text ? <div style={{color: props.templateColors.main, width: 335}}>{text}</div> : null;
  }

  if (props.playlistError) {
    return <span style={{color: props.templateColors.main}}>{props.playlistError}</span>;
  }

  if (props.emptyAudioConfigurations && props.emptyAudioConfigurations(true)) {
    return (
      <span style={{color: props.templateColors.main}}>
        There are not valid audio tracks to play for the generated playlist
      </span>
    );
  }

  if (!props.selectedConfiguration) {
    return (
      <div style={{color: props.templateColors.main, display: 'block', width: '455px'}}>
        <span>Please select one of the configurations to start the playback by hovering over the </span>
        <Icon icon={SubtitlesIcon} size="14px" color={props.templateColors.main} />
        <span> icon at bottom-right of the Player</span>
      </div>
    );
  }

  if (props.isProcessingAudio) {
    return null;
  }

  if (props.error) {
    return (
      <>
        <span style={{color: props.templateColors.main}}>{props.error.message}</span>
        <span style={{color: props.templateColors.main}}>{`CODE: ${props.error.code}`}</span>
      </>
    );
  }

  return (
    <>
      <span style={{color: props.templateColors.main}}>Your video is in the process of preparing for playback.</span>
      <span style={{color: props.templateColors.main}}>Please return shortly.</span>
    </>
  );
};

const showLoading = (props: IPlaybackSetupProps) => {
  return (
    props.playlistLoading ||
    (!isLoadingStatusToHandle(props) &&
      !props.playlistError &&
      !(props.emptyAudioConfigurations && props.emptyAudioConfigurations(true)) &&
      props.selectedConfiguration &&
      props.isProcessingAudio)
  );
};

const defineIcon = (props: IPlaybackSetupProps) => {
  if (isLoadingStatusToHandle(props)) {
    return props.assetStatus !== 'Error' ? (
      <Icon icon={StagingIcon} size="75px" color={props.templateColors.highlight} />
    ) : null;
  }
  return null;
};

const hideIcon = (props: IPlaybackSetupProps) => {
  const isCheckingProxyState = ['Checking'].indexOf(props.assetStatus) !== -1;
  const isPlaylistLoadedSuccessfully =
    !props.playlistLoading &&
    !isLoadingStatusToHandle(props) &&
    !props.playlistError &&
    !(props.emptyAudioConfigurations && props.emptyAudioConfigurations(true)) &&
    !props.selectedConfiguration;
  return isCheckingProxyState || isPlaylistLoadedSuccessfully;
};

export const PlaybackSetup = (props: IPlaybackSetupProps) => (
  <Playback
    templateColors={props.templateColors}
    mainTitle={createTitle(props)}
    descriptionContent={createContent(props)}
    showLoading={showLoading(props)}
    icon={defineIcon(props)}
    hideIcon={hideIcon(props)}
  />
);
