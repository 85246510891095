import {ICommentsTab} from './ICommentsTab';
import {IMetadataTab} from './IMetadataTab';
import {IAssetDetails} from '../../../../@types/assetDetails';
import {IMarkupsTab} from './IMarkupsTab';

export enum ITabs {
  Markups = 'Markups',
  Versions = 'Versions',
  Metadata = 'Metadata',
  Comments = 'Comments',
  History = 'History',
  Guidelines = 'Guidelines',
  DetailsMetadata = 'DetailsMetadata',
  VideoMetadata = 'VideoMetadata',
  AudioMetadata = 'AudioMetadata',
  SubtitlesMetadata = 'SubtitlesMetadata',
  ImageMetadata = 'ImageMetadata',
  NonMediaMetadata = 'NonMediaMetadata',
  Empty = 'Empty',
  Unknown = 'Unknown'
}

export interface ITabsState {
  selectedTab: ITabs;
  selectedVersion: string;
  inEditMode: boolean;
  commentsTab: ICommentsTab;
  metadataTab: IMetadataTab;
  savingTabsContent: boolean;
  updatedAssetDetails: Partial<IAssetDetails>;
  markupsTab: IMarkupsTab;
}
