import * as React from 'react';
import {TableRow, TableCell} from '../TableRow';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {Tt2b} from '../../../../../../../../components/Typography/Tt2b';
import {IMarkupEvent} from '../../../../../../../../../@types/markupEvent';
import {IMarkupsEventError} from '../../../../../../../../../@types/markupsError';
import {IFrameRate, Icon} from 'tt-components';
import {deepCopy, compareSMPTETimecodes} from '../../../../../../utils/helpers';
import {Input} from '../../../../../../../../components/Input';
import {ThumbnailDropdown} from '../../../ThumbnailDropdown';
import {IParsedVTT} from '../../../../../../../../../@types/parsedVtt';
import {PlaylistAsset} from '../../../../../../../../models/PlaylistAsset/PlaylistAsset';
import {PlayerTimePicker} from '../../../../../../../../components/PlayerTimePicker';

import {CommentIcon} from '../../../../../../assets/Icons/CommentIcon';
import {DeleteIcon} from '../../../../../../assets/Icons/DeleteIcon';
import {Smpte} from '../../../../../../../../models/Smpte/Smpte';

interface IEventRowProps {
  getPlayerCurrentTime: () => number;
  error: IMarkupsEventError;
  event: IMarkupEvent;
  duration: number;
  selectedGroup: string;
  enableCategory?: boolean;
  enableNotes?: boolean;
  types: Array<string>;
  categories: Array<string>;
  frameRate: IFrameRate;
  jsonVTT: Array<IParsedVTT>;
  closestBody?: HTMLElement;
  onTime: (time: number) => void;
  selectTab: (timeIn: string, timeOut: string) => void;
  removeMarkup: (eventId: string) => void;
  updateEvent: (event: IMarkupEvent) => void;
  addEvent: (id: string, name: string, events: string) => void;
  tabsInEditMode: boolean;
}

interface IEventRowState {
  editNote: boolean;
  notes: string;
  editTextlessType: boolean;
  textlessType: string;
}

export class EventRow extends React.Component<IEventRowProps, IEventRowState> {
  inputRef: HTMLElement;
  typeContainerRef: HTMLDivElement;
  timeInContainerRef: HTMLDivElement;
  timeInInputRefs: Array<HTMLInputElement>;
  timeOutInputRefs: Array<HTMLInputElement>;

  constructor(props) {
    super(props);

    this.state = {
      editNote: false,
      notes: '',
      editTextlessType: false,
      textlessType: ''
    };
  }

  componentDidMount() {
    const dropdownRef = this.getTypeDropdownRef();
    if (dropdownRef) {
      dropdownRef.addEventListener('click', this.onTypeClick);
    }
    const timeInPickerRef = this.geTimeInPickerRef();
    if (timeInPickerRef) {
      timeInPickerRef.addEventListener('click', this.onTypeClick);
    }
  }

  componentWillUnmount() {
    const dropdownRef = this.getTypeDropdownRef();
    if (dropdownRef) {
      dropdownRef.removeEventListener('click', this.onTypeClick);
    }
    const timeInPickerRef = this.geTimeInPickerRef();
    if (timeInPickerRef) {
      timeInPickerRef.removeEventListener('click', this.onTypeClick);
    }
  }

  getTypeDropdownRef = () => {
    if (this.typeContainerRef && this.typeContainerRef.querySelector('.dropdown-ui-container:first-child')) {
      return this.typeContainerRef.querySelector('.dropdown-ui-container:first-child');
    }
  };

  geTimeInPickerRef = () => {
    if (this.timeInContainerRef && this.timeInContainerRef.querySelector('.player-time-picker-container')) {
      return this.timeInContainerRef.querySelector('.player-time-picker-container');
    }
  };

  allowPlaybackJump = () =>
    !(this.props.tabsInEditMode || !this.props.onTime || PlaylistAsset.parsing.isDefaultEvent(this.props.event));

  onTypeClick = () => {
    if (!this.allowPlaybackJump()) {
      return;
    }
    this.props.onTime(this.convertToSecond(this.props.event.timeIn || '00:00:00:00'));
  };

  onTimeChanged = ({value, field}) => {
    if (field === 'timeIn') {
      (this.timeInInputRefs || []).forEach((element: HTMLInputElement) => element.blur());
    }
    if (field === 'timeOut') {
      (this.timeOutInputRefs || []).forEach((element: HTMLInputElement) => element.blur());
    }
    const time = this.convertToSMPTE(value);
    this.updateEvent(field, time);
  };

  isDefaultProgramTimingsType = () =>
    PlaylistAsset.parsing.isDefaultType(this.props.event) && this.props.selectedGroup === 'Program Timings';

  updateEvent = (fieldKey: string, value) => {
    console.log(fieldKey, value);
    const event = deepCopy({...this.props.event, [fieldKey]: value});
    if (
      fieldKey === 'timeIn' &&
      (compareSMPTETimecodes(value, event.timeOut || '00:00:00:00', this.props.frameRate) === 1 ||
        event.timeOut === null)
    ) {
      event.timeOut = value;
    }
    if (
      fieldKey === 'timeOut' &&
      compareSMPTETimecodes(value, event.timeIn || '00:00:00:00', this.props.frameRate) === -1
    ) {
      event.timeIn = value;
    }

    // Remove placeholder state of the event when timeOut is updated
    if (fieldKey === 'timeOut' && event.newRecord) {
      event.newRecord = false;
    }

    // Start Timecode event should keep same value of timeIn/timeOut fields when updated
    if (
      this.isDefaultProgramTimingsType() &&
      (event.type || '').toLowerCase() === 'start timecode' &&
      ['timeIn', 'timeOut'].indexOf(fieldKey) !== -1
    ) {
      event.timeIn = fieldKey === 'timeIn' ? event.timeIn : event.timeOut;
      event.timeOut = fieldKey === 'timeOut' ? event.timeOut : event.timeIn;
    }

    // In cases when we have defined TimeIn/TimeOut with different values for common events
    // and we switch to 'Start Timecode' we must make sure that they are equal
    if (
      this.props.selectedGroup === 'Program Timings' &&
      fieldKey === 'type' &&
      (value || '').toLowerCase() === 'start timecode' &&
      (this.props.event.type || '').toLowerCase() !== 'start timecode' &&
      event.timeIn !== event.timeOut
    ) {
      event.timeOut = event.timeIn;
    }

    this.props.updateEvent(event);
  };

  onInputBlur(field: string, value: string, flagProp: string) {
    this.updateEvent(field, value);
    this.setState({[flagProp]: false} as any);
  }

  onInputKeyDown(field: string, value: string, flagProp: string, e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 13) {
      this.onInputBlur(field, value, flagProp);
    }
  }

  getNotesComponent = () => {
    const {tabsInEditMode} = this.props;
    if (!this.props.enableNotes) {
      return null;
    }

    if (this.state.editNote) {
      return (
        <Input
          getRef={(node: HTMLElement) => node.focus()}
          value={this.state.notes}
          onBlur={this.onInputBlur.bind(this, 'notes', this.state.notes, 'editNote')}
          onKeyDown={this.onInputKeyDown.bind(this, 'notes', this.state.notes, 'editNote')}
          onChange={(notes: string) => this.setState({notes})}
        />
      );
    } else {
      let notes = '';
      let color = '';
      if (!tabsInEditMode) {
        if (this.props.event.notes) {
          notes = this.props.event.notes;
        } else {
          notes = 'No notes...';
        }
        color = '#8D919A';
      } else {
        if (this.props.event.notes) {
          notes = this.props.event.notes;
          color = '#8D919A';
        } else {
          notes = 'Add Notes';
          color = '#1F72F2';
        }
      }

      return (
        <Tt2b
          className="event-row_typeCategoryNotesCell_notes-text-field"
          style={{color, cursor: tabsInEditMode ? '' : 'default'}}
          content={notes}
          onClick={e => tabsInEditMode && this.setState({editNote: true, notes: this.props.event.notes})}
        />
      );
    }
  };

  getTypeCategoryNotesCell = () => {
    const {tabsInEditMode} = this.props;
    const typeOptions = (this.props.types || []).map((type: string) => ({label: type, value: type} as IDropdownOption));
    const categoryOptions = this.props.enableCategory
      ? (this.props.categories || []).map((category: string) => ({label: category, value: category} as IDropdownOption))
      : [];
    const isNewRecord = (this.props.event.id || '').endsWith('-new');
    const newProps = (selectedPlaceholder: string) =>
      isNewRecord
        ? {selectedPlaceholder, buttonContentStyles: {color: '#1F72F2'}}
        : selectedPlaceholder === 'Type' && this.allowPlaybackJump()
        ? {buttonContentStyles: {color: '#0AB3A0', textDecoration: 'underline'}}
        : {};
    const content = (
      <div
        className={`event-row_typeCategoryNotesCell${!tabsInEditMode ? ` not-edit` : ``}`}
        ref={node => (this.typeContainerRef = node)}
      >
        <Dropdown
          borderless
          search
          disableMatchWidth
          options={typeOptions}
          selected={this.props.event.type}
          paddingLeft={0}
          borderWidth={0}
          onSelected={type => this.updateEvent('type', type)}
          portalNode={this.props.closestBody}
          disabled={!tabsInEditMode}
          {...newProps('Type')}
        />
        {this.props.enableCategory && (
          <Dropdown
            borderless
            search
            disableMatchWidth
            options={categoryOptions}
            selected={this.props.event.category}
            paddingLeft={0}
            borderWidth={0}
            onSelected={category => this.updateEvent('category', category)}
            portalNode={this.props.closestBody}
            disabled={!tabsInEditMode}
            {...newProps('Category')}
          />
        )}
        {this.getNotesComponent()}
      </div>
    );
    return <TableCell className="min-width-cell" key={1} content={content} />;
  };

  convertToSMPTE(time: number) {
    return Smpte.fromTimeWithAdjustments(time, {
      frameRate: this.props.frameRate.frameRate,
      dropFrame: this.props.frameRate.dropFrame
    }).toString();
  }

  convertToSecond(time: string) {
    const smpte = new Smpte(time, {
      frameRate: this.props.frameRate.frameRate,
      dropFrame: this.props.frameRate.dropFrame
    });
    return smpte.toAdjustedTime();
  }

  getTimeInRef = node => {
    if (['Chapter', 'Textless'].indexOf(this.props.selectedGroup) !== -1) {
      this.timeInContainerRef = node;
    }
  };

  getTimecodeInputRefs = (instance: PlayerTimePicker, timecode: 'timeIn' | 'timeOut') => {
    const refs = [
      instance.hoursTextInput && instance.hoursTextInput.current,
      instance.minutesTextInput && instance.minutesTextInput.current,
      instance.secondsTextInput && instance.secondsTextInput.current,
      instance.frameTextInput && instance.frameTextInput.current,
      instance.millisecondsTextInput && instance.millisecondsTextInput.current
    ].filter(ref => ref);
    if (timecode === 'timeIn') {
      this.timeInInputRefs = refs;
    } else {
      this.timeOutInputRefs = refs;
    }
  };

  getTimeInCell = () => {
    const {tabsInEditMode, selectedGroup} = this.props;
    const isNewRecordPlaceholder = this.props.event.newRecord;
    const isTimeNull = this.props.event.timeIn === null;
    const time = this.props.event.timeIn || '00:00:00:00';
    const placeholder = isNewRecordPlaceholder ? ` placeholder` : ``;
    const clickable =
      this.allowPlaybackJump() && ['Chapter', 'Textless'].indexOf(selectedGroup) !== -1 ? ` clickable` : ``;
    const content = (
      <div className={`event-row_timepicker${placeholder}${clickable}`} ref={this.getTimeInRef}>
        {isTimeNull ? (
          <div
            className="event-row_timepicker_add-button"
            onClick={() =>
              tabsInEditMode ? this.onTimeChanged({value: this.props.getPlayerCurrentTime(), field: 'timeIn'}) : null
            }
          >
            <Tt2b content="Add" className="event-row_timepicker_add-button_text" />
          </div>
        ) : (
          <PlayerTimePicker
            triggerOnBlur
            time={this.convertToSecond(time)}
            onChangeTime={time => this.onTimeChanged({value: time, field: 'timeIn'})}
            frameRate={this.props.frameRate}
            disabled={!tabsInEditMode}
            onRef={(instance: PlayerTimePicker) => this.getTimecodeInputRefs(instance, 'timeIn')}
          />
        )}
        <ThumbnailDropdown
          time={this.convertToSecond(time)}
          jsonVTT={this.props.jsonVTT}
          onClick={this.props.onTime}
          closestBody={this.props.closestBody}
        />
      </div>
    );

    return <TableCell key={2} content={content} />;
  };

  getTimeOutCell = () => {
    const {tabsInEditMode} = this.props;
    const isNewRecordPlaceholder = this.props.event.newRecord;
    const time = this.props.event.timeOut || '00:00:00:00';
    const isTimeNull = this.props.event.timeOut === null;
    const content = (
      <div className={`event-row_timepicker${isNewRecordPlaceholder ? ` placeholder` : ``}`}>
        {isTimeNull ? (
          <div
            className="event-row_timepicker_add-button"
            onClick={() =>
              tabsInEditMode ? this.onTimeChanged({value: this.props.getPlayerCurrentTime(), field: 'timeOut'}) : null
            }
          >
            <Tt2b content="Add" className="event-row_timepicker_add-button_text" />
          </div>
        ) : (
          <PlayerTimePicker
            triggerOnBlur
            time={this.convertToSecond(time)}
            onChangeTime={time => this.onTimeChanged({value: time, field: 'timeOut'})}
            frameRate={this.props.frameRate}
            disabled={!tabsInEditMode}
            onRef={(instance: PlayerTimePicker) => this.getTimecodeInputRefs(instance, 'timeOut')}
          />
        )}
        <ThumbnailDropdown
          time={this.convertToSecond(time)}
          jsonVTT={this.props.jsonVTT}
          onClick={this.props.onTime}
          closestBody={this.props.closestBody}
        />
      </div>
    );

    return <TableCell key={3} content={content} />;
  };

  getRemoteAssetTimeInCell = () => {
    const {tabsInEditMode} = this.props;
    const isNewRecordPlaceholder = this.props.event.newRecord;
    const isTimeNull = this.props.event.remoteassettimein === null;
    const time = this.props.event.remoteassettimein || '00:00:00:00';
    const content = (
      <div className={`event-row_timepicker${isNewRecordPlaceholder ? ` placeholder` : ``}`}>
        {isTimeNull ? (
          <div
            className="event-row_timepicker_add-button"
            onClick={() => (tabsInEditMode ? this.onTimeChanged({value: 0, field: 'remoteassettimein'}) : null)}
          >
            <Tt2b content="Add" className="event-row_timepicker_add-button_text" />
          </div>
        ) : (
          <PlayerTimePicker
            triggerOnBlur
            time={this.convertToSecond(time)}
            onChangeTime={time => this.onTimeChanged({value: time, field: 'remoteassettimein'})}
            frameRate={this.props.frameRate}
            disabled={!tabsInEditMode}
          />
        )}
        <ThumbnailDropdown
          time={this.convertToSecond(time)}
          jsonVTT={this.props.jsonVTT}
          onClick={this.props.onTime}
          closestBody={this.props.closestBody}
        />
      </div>
    );

    return <TableCell key={4} content={content} />;
  };

  getRemoteAssetTimeOutCell = () => {
    const {tabsInEditMode} = this.props;
    const isNewRecordPlaceholder = this.props.event.newRecord;
    const time = this.props.event.remoteassettimeout || '00:00:00:00';
    const isTimeNull = this.props.event.remoteassettimeout === null;
    const content = (
      <div className={`event-row_timepicker${isNewRecordPlaceholder ? ` placeholder` : ``}`}>
        {isTimeNull ? (
          <div
            className="event-row_timepicker_add-button"
            onClick={() => (tabsInEditMode ? this.onTimeChanged({value: 0, field: 'remoteassettimeout'}) : null)}
          >
            <Tt2b content="Add" className="event-row_timepicker_add-button_text" />
          </div>
        ) : (
          <PlayerTimePicker
            triggerOnBlur
            time={this.convertToSecond(time)}
            onChangeTime={time => this.onTimeChanged({value: time, field: 'remoteassettimeout'})}
            frameRate={this.props.frameRate}
            disabled={!tabsInEditMode}
          />
        )}
        <ThumbnailDropdown
          time={this.convertToSecond(time)}
          jsonVTT={this.props.jsonVTT}
          onClick={this.props.onTime}
          closestBody={this.props.closestBody}
        />
      </div>
    );

    return <TableCell key={5} content={content} />;
  };

  getActionsCell = () => {
    const {tabsInEditMode} = this.props;
    const {id, timeIn, timeOut} = this.props.event;
    const isNewRecord = (this.props.event.id || '').endsWith('-new');

    const content = (
      <>
        {!isNewRecord && (
          <div onClick={() => this.props.selectTab(timeIn, timeOut)} className="event-row_actions_icon">
            <Icon icon={CommentIcon} color="#a1aab7" size="18px" />
          </div>
        )}
        {tabsInEditMode && (
          <div onClick={() => this.props.removeMarkup(id)} className="event-row_actions_icon">
            <Icon icon={DeleteIcon} color="#a1aab7" size="18px" />
          </div>
        )}
      </>
    );
    return <TableCell key={10} className="event-row_actions" content={content} />;
  };

  getCells = () => {
    const {selectedGroup} = this.props;
    const cells = [];
    switch (selectedGroup) {
      case 'Chapter':
      case 'Textless':
        cells.push(
          this.getTimeInCell(),
          this.getTimeOutCell(),
          this.getRemoteAssetTimeInCell(),
          this.getRemoteAssetTimeOutCell(),
          this.getActionsCell()
        );
        break;
      default:
        cells.push(this.getTypeCategoryNotesCell(), this.getTimeInCell(), this.getTimeOutCell(), this.getActionsCell());
    }
    return cells;
  };

  getErrorRow = () => {
    if (!this.props.error) {
      return null;
    }

    const content = (
      <div className="event-row_error_cell_content">{`! ${this.props.error.error || `Something went wrong`}`}</div>
    );
    const cell = <TableCell key={1} className="event-row_error_cell" content={content} />;
    return <TableRow className="event-row_error" cells={[cell]} />;
  };

  render() {
    const error = this.props.error ? ` error` : ``;
    return (
      <>
        <TableRow className={`event-row${error}`} cells={this.getCells()} />
        {this.getErrorRow()}
      </>
    );
  }
}
