import {apiRequest} from './index';
import {IComment} from '../modules/Tabs/interfaces';
import {serviceProvider} from '../services/serviceProvider';

export async function getCommentsByComplianceJobIdAPI() {
  if (appConfig.externalID) {
    const url = `${serviceProvider.hosts.apiOneUiHost}user/comment/item/${appConfig.externalID}/`;
    return apiRequest('GET', url);
  }
}

export async function deleteCommentByIdAPI(id: number) {
  const url = `${serviceProvider.hosts.apiOneUiHost}user/comment/${id}/`;
  return apiRequest('DELETE', url);
}

export async function editCommentByIdAPI(id: number, comment: IComment) {
  let body = {
    comment: comment.comment,
    in_time: String(comment.inTime),
    out_time: String(comment.outTime),
    id_type: appConfig.type
  };

  if (appConfig.client) {
    body['client'] = appConfig.client;
  }

  const url = `${serviceProvider.hosts.apiOneUiHost}user/comment/${id}/`;
  return apiRequest('POST', url, JSON.stringify(body));
}

export async function addCommentAPI(data) {
  let body = {
    comment: data.comment,
    external_id: appConfig.externalID,
    in_time: String(data.inTime),
    out_time: String(data.outTime),
    id_type: appConfig.type
  };

  if (appConfig.client) {
    body['client'] = appConfig.client;
  }

  const url = `${serviceProvider.hosts.apiOneUiHost}user/comment/`;
  return apiRequest('POST', url, JSON.stringify(body));
}

export async function addReplyAPI(data) {
  let body = {
    comment: data.text,
    external_id: appConfig.externalID,
    id_type: appConfig.type
  };

  if (appConfig.client) {
    body['client'] = appConfig.client;
  }

  const url = `${serviceProvider.hosts.apiOneUiHost}user/comment/${data.commentId}/reply/`;
  return apiRequest('POST', url, JSON.stringify(body));
}
