import {Observable} from 'tt-components/src/VideoPlayer/Observable';
import {IFrameRate} from 'tt-components/src/frameRate';
import {ITemplateColors} from '../../../@types/templateColors';
import {IShortcutsDefinitions, IShortcutType} from '../../constants/shortcuts';
import {IDisplayMediaTimeFormat} from '../../../@types/displayMediaTimeFormat';
import {IWatermarkPosition} from '../../../@types/watermarkPosition';
import {ILayersVisisbility} from '../../modules/Timeline/interfaces/ITimelineState';
import {ITimelineLayers} from '../../modules/Timeline/interfaces/ITimelineLayers';
import {IControlBarControls} from '../../../@types/controlBarControls';
import {IEnum} from '../../../@types/enum';
import {IAudioChannelConfiguration} from '../../../@types/audioChannelConfiguration';
import {IVideoAudioConfiguration, IContentAssetConfiguration} from '../../state/IVideoState';
import {ISubtitlesMetadata} from '../../../@types/subtitlesMetadata';
import {IPictureAspectRatioFrame} from '../../../@types/pictureAspectRatioFrame';
import {PlaylistAsset} from '../../models/PlaylistAsset/PlaylistAsset';

export interface IOnePlayerControlBarProps {
  /** Indicates if Next/Prev seek button is visible */
  showNextPrevSeekButtons?: boolean;

  keyboardShortcuts: Array<IShortcutsDefinitions>;

  keyboardShortcutsType: IShortcutType;

  /** Indicates if Position Button is visible */
  showNextPrevSubtitleButtons?: boolean;

  /** Indicates if Next/Prev frame button is visible */
  showNextPrevFrameButtons?: boolean;

  /** Indicates if control bar is enabled */
  enabled?: boolean;

  /** Indicates if video is playing */
  playing?: boolean;

  isFullScreen?: boolean;

  loop?: {
    loop;
    timeIn;
    timeOut;
  };

  /** Clock component */
  videoClock?: Observable<number>;

  /** Current frame rate */
  frameRate?: IFrameRate;

  /** Video duration */
  duration?: number;

  /**
   * Player's base time
   */
  baseTime?: number;

  /** Current video volume */
  volume?: number;

  /** Indicates if video is muted */
  mute?: boolean;

  /** Play or pause is clicked */
  onSwitchPlaying?: () => void;

  onSwitchFastFrame?: () => void;

  /** A seek button has been clicked */
  onSeek?: (value: number, type: SeekType, onSeekBarUpdated?: boolean) => void;

  /** Go To Location functionality is triggered */
  onGoTo?: (value: number) => void;

  /** Volume change event */
  onVolumeChanged?: (newValue: number) => void;

  /** Triggered when mute value changed */
  onMuteChanged?: (newValue: boolean) => void;

  onFullScreen?: (val: boolean) => void;

  /** Triggered text show on player */
  onTextToggle?: () => void;

  /** Triggered third indicator */
  onThirdIndicator?: () => void;

  /** Move to first frame if clicked */
  onFirstFrame?: () => void;

  onStart?: () => void;

  onEnd?: () => void;

  onFastForward?: () => void;

  onFastBackward?: () => void;

  switchLoop?: (val: object) => void;

  getShortcuts?: (name: IShortcutType) => void;

  setDefaultShortcuts?: () => void;

  changeShortcuts?: (shortcuts: Array<IShortcutsDefinitions>) => void;

  /** Stop is doubleClicked and will seek to begin of track */
  onDoubleClickStop?: () => void;

  /** Selected video playback rate */
  playbackRate?: number;

  forwardAndRewindRate?: number;

  /** On change video playback rate */
  onPlaybackRateChange?: (playbackRate: number) => void;

  onForwardAndRewindRateChange?: (forwardAndRewindRate: number) => void;

  /**
   * Shortcut labels to display in buttons, expected actions:
   * - fastBackward
   * - backward
   * - record
   * - pause
   * - play
   * - forward
   * - fastForward
   */
  buttonLabels?: {[index: string]: string};

  /** The track Id of the selected subtitle */
  selectedSub?: string;

  /** Embedded subtitles metadata received from one-player-service */
  embeddedSubtitlesMetadata: ISubtitlesMetadata[];

  /** External subtitles metadata received from one-player-service */
  externalSubtitlesMetadata: ISubtitlesMetadata[];

  /** Selects the defined track for rendering subtitles */
  onChangeVisibleSubtitle?: (subId: string) => void;

  /** Template colors needed for setting icon colors, popover text and background colors */
  templateColors: ITemplateColors;

  /** Triggers events that indicate the state of mouse over the seek bar */
  onSeekBarMovement?: (timePosition: number, cX: number, seekBarWidth: number, seekBarBoundingLeft: number) => void;

  /** Displaying media time format settings */
  displayMediaTimeFormat: IDisplayMediaTimeFormat;

  /** Change current media time format */
  changeMediaTimeFormat: (value: IDisplayMediaTimeFormat) => void;

  /** Watermark postion */
  watermarkPosition: IWatermarkPosition;

  /** Changing watermark position */
  changeWatermarkPosition: (value: IWatermarkPosition) => void;

  /** Indicates if control bar is shown or hidden */
  isControlBarShown: boolean;

  /** All Timeline module settings related with layers visibility */
  timelineVisibilitySettings: ILayersVisisbility;

  /** Change visibility for Timeline Layer */
  updateLayerVisibility: (layer: ITimelineLayers, visible: boolean) => void;

  /** Check if Timeline settings should be shown */
  timelineEnabled: boolean;

  /** Check Timeline visibility */
  showTimeline: boolean;

  /** Change Timeline visibility */
  updateTimelineView: (visible: boolean) => void;

  /** Control bar controls that are currently shown */
  controlBarControls: IControlBarControls[];

  /** Delay in milliseconds between mouse moved over control and respective dropdown is shown */
  showingDropdownTimeout: number;

  /** Video player container to add dropdown DOM nodes */
  videoPlayerContainer: HTMLElement;

  /** Closest body element to work inside iframe */
  closestBodyElement: HTMLElement;

  /** Array of configurations related with embedded audio assets */
  audioChannelConfigurations: Array<IAudioChannelConfiguration>;

  /** Array of configurations related with external audio assets */
  externalAudioChannelConfigurations: Array<IAudioChannelConfiguration>;

  /** Array of assets that hold image content */
  imageAssets: Array<PlaylistAsset>;

  /** Array of assets that hold non media content */
  nonMediaAssets: Array<PlaylistAsset>;

  /** List of enums with language info for audio asset */
  audioLanguageEnums: Array<IEnum>;

  /** List of enums with channel mapping for audio asset */
  audioChannelMapEnums: Array<IEnum>;

  /** List of enums with channel configuration type */
  audioChannelConfigTypeEnums: Array<IEnum>;

  languageDialectEnums: Array<IEnum>;

  audioConfiguration: IVideoAudioConfiguration;

  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;

  loadingPlaylist: boolean;

  loadingPlaylistError: string;

  loadingAsset: boolean;

  loadingAudioConfig: boolean;

  checkPlaybackProxy: () => void;

  proxyConfiguration: IAudioChannelConfiguration;

  videoConfiguration: IAudioChannelConfiguration;

  userEmail: string;

  userIp: string;

  isSettingsShow: boolean;

  isAudioSubsShow: boolean;

  isPlayerSpeedShow: boolean;

  isLoopControlShow: boolean;

  isVolumeControlShow: boolean;

  isGoToShow: boolean;

  onShowVolumeControlChange: (isOpen: boolean) => void;

  onShowGoToChange: (isOpen: boolean) => void;

  onShowLoopControlChange: (isOpen: boolean) => void;

  onShowSettingsChange: (isOpen: boolean) => void;

  onShowAudioSubsChange: (isOpen: boolean) => void;

  onShowPlayerSpeedChange: (isOpen: boolean) => void;

  curationModeEnabled?: boolean;

  pictureAspectRatioFrame?: IPictureAspectRatioFrame;

  updatePictureAspectRatioFrame: (aspectRatio: IPictureAspectRatioFrame) => void;

  pictureAspectRatioEnums: Array<IEnum>;

  videoPictureAsectRatio?: number;

  loopTimeInSelected: number;

  loopTimeOutSelected: number;

  contentAssetConfiguration: IContentAssetConfiguration;

  updateContentAssetConfiguration: (data: Partial<IContentAssetConfiguration>) => void;

  /** Flag to mark cases when One Player is displaying images content */
  contentMode?: boolean;

  /** Offset added by the Start Timecode event defined on asset Program Timings */
  startTimecodeOffset?: number;
}

export enum SeekType {
  timeLeap,
  frameLeap,
  toTime
}
