import {apiRequest} from './index';
import {IPlaybackBodyRequest} from '../state/IVideoState';
import {IResponse} from '../../@types/response';
import {IPlaylistContentResponse, IPlaylistAtlas, IThumbnailsUrlData} from '../state/IAppState';
import {IPlaylistRequestAsset} from '../../@types/playlistRequestAsset';
import {PlaylistAsset} from '../models/PlaylistAsset/PlaylistAsset';
import {serviceProvider} from '../services/serviceProvider';

export const getPlaybackUrlAPI = async (body: IPlaybackBodyRequest, playlistId: string = 'xyz'): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playback/${playlistId}`;
    const data = await apiRequest('POST', url, JSON.stringify(body));
    return {success: true, data: data.url};
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getSubtitlesUrlsAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playback/${playlistId}/subtitles`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: data && Array.isArray(data) ? data.map(PlaylistAsset.parsing.parseSubtitlesStageData) : []
    };
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getWaveformsUrlsAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playback/${playlistId}/waveforms`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: data && Array.isArray(data) ? data.map(PlaylistAsset.parsing.parseWaveformStageData) : []
    };
  } catch (error) {
    return {success: false, data: [], error};
  }
};

export const getThumbnailsUrlAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playback/${playlistId}/thumbnails`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: (data as Array<IThumbnailsUrlData>) || []
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const getPlaylistContentAPI = async (playlistId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playlist/${playlistId}`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: {
        id: data.id,
        assets: (data.assets || []).map(PlaylistAsset.parsing.parseAssetPlaylistInfo)
      } as IPlaylistContentResponse
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const getConformanceAssetsAPI = async (assetId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playlist/conformancegroup/${assetId}`;
    const data = await apiRequest('GET', url);
    return {success: true, data: data || []};
  } catch (error) {
    return {success: false, error};
  }
};

export const getAssetsPlaylistStatusAPI = async (assets: Array<IPlaylistRequestAsset>): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}playlist/assetstatus`;
    const data = await apiRequest('POST', url, JSON.stringify(assets));
    return {success: true, data: (data.data || []).map(PlaylistAsset.parsing.parseAssetStatusFromPlaylistData)};
  } catch (error) {
    return {success: false, error};
  }
};

export const createPlaylistByAssetsAPI = async (
  assets: Array<IPlaylistRequestAsset>,
  force: boolean = false
): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}stage${force ? `?force=true` : ``}`;
    const data = await apiRequest('POST', url, JSON.stringify({assets}));
    return {
      success: true,
      data: {
        id: data.id,
        assets: (data.assets || []).map(PlaylistAsset.parsing.parseAssetPlaylistInfo),
        assetDetails: data.searchAssets || []
      } as IPlaylistContentResponse
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const createAssetsPlaylistAPI = async (assetId: string, force: boolean = false): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.onePlayerServiceHost}stage/stageassetsfromconformance/${assetId}${
      force ? `?force=true` : ``
    }`;
    const data = await apiRequest('GET', url);
    return {
      success: true,
      data: {
        id: data.id,
        assets: (data.assets || []).map(PlaylistAsset.parsing.parseAssetPlaylistInfo),
        assetDetails: data.versionAssets || []
      } as IPlaylistContentResponse
    };
  } catch (error) {
    return {success: false, error};
  }
};

export const initPlaylistCreation = async (atlasAssets: Array<IPlaylistAtlas>, force: boolean = false) => {
  let requestAssets: Array<IPlaylistRequestAsset> = [].concat(
    atlasAssets.reduce((acc: Array<IPlaylistRequestAsset>, asset: IPlaylistAtlas) => {
      const requestAsset = {
        assetId: asset.assetId,
        isRegistered: typeof asset.isRegistered !== 'undefined' ? asset.isRegistered : true
      };
      return [...acc, requestAsset];
    }, [])
  );
  let result = await createPlaylistByAssetsAPI(requestAssets, force);
  return result;
};
