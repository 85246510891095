import * as React from 'react';
import {Container, Row, Column} from '../../../../../Grid';
import {AudioConfigurationEditableTrack} from '../AudioConfigurationEditableTrack';
import {Dropdown} from '../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../components/Toggle';
import {deepCopy, updateAssetDetailsAudioProp, updateAssetDetailsAudioTrackProp} from '../../../../../../utils/helpers';
import {
  IAudioData,
  IAssetDetails,
  IAudioDataTrackDetail,
  IErrorLog
} from '../../../../../../../../../@types/assetDetails';
import {IMetadataError} from '../../../../../../../../../@types/metadataErrors';
import {AudioSubTypes} from '../../../../../../../../utils/storage/AudioSubTypes';
import {wait} from '../../../../../../../../utils/utils';
import {Button} from '../../../../../../../../components/Button';
import {Tt2b} from '../../../../../../../../components/Typography/Tt2b';
import {PlaylistAsset} from '../../../../../../../../models/PlaylistAsset/PlaylistAsset';

interface IAudioConfigEditableProps {
  audio: IAudioData;
  assetDetails: IAssetDetails;
  isCollapseOpen: boolean;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: any;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  audioMetadataErrors: Array<IMetadataError>;
  updatePartialAssetDetails: (assetDetails: IAssetDetails) => void;
  onBreak: (breakUp: boolean, configId: string, trackIndex: number) => void;
}

interface IAudioConfigEditableState {
  subTypes: string;
}

export class AudioConfigurationEditable extends React.PureComponent<
  IAudioConfigEditableProps,
  IAudioConfigEditableState
> {
  constructor(props) {
    super(props);

    this.state = {
      subTypes: ''
    };
  }

  componentDidMount() {
    this.updateSubTypesFromStorage();
  }

  updateSubTypesFromStorage = async () => {
    const audioSubTypes = JSON.parse(sessionStorage.getItem('audioSubTypes'));
    await wait(300);
    const storage = new AudioSubTypes();
    if (audioSubTypes) {
      storage.set(audioSubTypes);
    }
    this.setState({subTypes: audioSubTypes || ''});
  };

  handleClick = (name: string, value: any) => {
    const data = value !== '' ? value : null;
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const audio = updateAssetDetailsAudioProp(this.props.assetDetails.audio, name, data, this.props.audio.id);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  convertEnums = enums => {
    let options = [];
    enums.map(item => {
      options.push({label: item.name, value: item.value});
    });
    return options;
  };

  onLanguageDubbedToggle = (toggleState: boolean) => {
    this.handleClick('audioChannel', toggleState);
  };

  onSubTypesSelected = (subTypes: string) => {
    const storage = new AudioSubTypes();
    this.setState({subTypes}, () => {
      storage.set(subTypes);
      let audio = deepCopy([...this.props.assetDetails.audio]);
      this.props.audio.trackDetail.forEach((track: IAudioDataTrackDetail) => {
        audio = updateAssetDetailsAudioTrackProp(audio, 'subType', subTypes, this.props.audio.id, track.id);
      });
      const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
      this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
    });
  };

  getTrackDetails = () => {
    const trackDetails = deepCopy([...this.props.audio.trackDetail]);
    trackDetails.sort((a: IAudioDataTrackDetail, b: IAudioDataTrackDetail) => {
      return +a.track - +b.track;
    });
    return (
      <Row className="audio-details-container_row channel-options-row channels-list">
        {!trackDetails.length ? (
          <Tt2b
            className="audio-details-container_row_empty"
            content="No channels are defined for this configuration"
          />
        ) : (
          trackDetails.map((track: IAudioDataTrackDetail, index) => {
            return (
              <AudioConfigurationEditableTrack
                key={index}
                isOpen={this.props.isCollapseOpen}
                index={index}
                track={track}
                audioConfigId={this.props.audio.id}
                assetDetails={this.props.assetDetails}
                updatedAssetDetails={this.props.updatedAssetDetails}
                enums={this.props.enums}
                closestBody={this.props.closestBody}
                errorLogs={this.props.errorLogs}
                audioMetadataErrors={this.props.audioMetadataErrors}
                updatePartialAssetDetails={this.props.updatePartialAssetDetails}
                onBreak={this.props.onBreak}
              />
            );
          })
        )}
      </Row>
    );
  };

  onAddChannel = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.props.assetDetails});
    const trackNumbers = (assetDetails.audio || []).reduce((acc: Array<number>, record: IAudioData) => {
      return [
        ...acc,
        ...(record.trackDetail || [])
          .map((track: IAudioDataTrackDetail) => track.track)
          .filter(track => track)
          .map(track => +track)
      ];
    }, []);
    const audio = (assetDetails.audio || []).reduce((acc: Array<IAudioData>, record: IAudioData) => {
      if (record.id === this.props.audio.id) {
        record.trackDetail = [
          ...record.trackDetail.reduce((acc: Array<IAudioDataTrackDetail>, track: IAudioDataTrackDetail) => {
            return [...acc, track];
          }, []),
          {...PlaylistAsset.defaults.audioTrackMetadataDefault, track: `${Math.max(...trackNumbers, 0) + 1}`}
        ];
      }
      return [...acc, record];
    }, []);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  getContent = () => {
    const audioConfigDataEntry = this.props.audio;
    return (
      <>
        <Row className="audio-details-container_row channel-options-row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              isOpen={this.props.isCollapseOpen}
              options={this.convertEnums(this.props.enums.audioSubType)}
              onSelected={this.onSubTypesSelected}
              selected={this.state.subTypes}
              label="Sub Type"
              portalNode={this.props.closestBody}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Toggle
              label="Audio Language Dubbed"
              toggled={!!audioConfigDataEntry.audioChannel}
              onToggle={this.onLanguageDubbedToggle}
            />
          </Column>
          <Column className="audio-details-container_row_column" />
        </Row>
        {this.getTrackDetails()}
        <Row className="audio-details-container_row channel-options-row add-channel-row">
          <Button content="+ Add Channel" small secondary onClick={this.onAddChannel} />
        </Row>
      </>
    );
  };

  render() {
    return <Container className="audio-details-container">{this.getContent()}</Container>;
  }
}
