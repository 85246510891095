import * as React from 'react';
import {ITemplateColors} from '../../../../../../@types/templateColors';
import {IEnum} from '../../../../../../@types/enum';
import {CustomRadioButton} from '../../../../CustomRadio';
import {IPictureAspectRatioFrame} from '../../../../../../@types/pictureAspectRatioFrame';
import {Tt4b} from '../../../../Typography/Tt4b';

interface IPictureAspectRatioSettings {
  templateColors: ITemplateColors;
  videoPictureAsectRatio?: number;
  pictureAspectRatioEnums: Array<IEnum>;
  pictureAspectRatioFrame: IPictureAspectRatioFrame;
  updatePictureAspectRatioFrame: (aspectRatio: IPictureAspectRatioFrame) => void;
}

export class PictureAspectRatioSettings extends React.PureComponent<IPictureAspectRatioSettings> {
  static defaultProps = {
    pictureAspectRatioEnums: []
  };
  getRadioButtons = () => {
    if (!this.props.videoPictureAsectRatio) {
      return (
        <Tt4b
          className="op_picture-aspect-ratio-settings_empty"
          content=" ... "
          style={{color: this.props.templateColors.main}}
        />
      );
    }
    const enumsRecords = [...(this.props.pictureAspectRatioEnums || [])].filter(
      (record: IEnum) => this.props.videoPictureAsectRatio <= +record.value
    );
    return enumsRecords.reduce(
      (acc: Array<JSX.Element>, record: IEnum, index: number) => {
        const radio = (
          <CustomRadioButton
            key={index}
            index={index}
            id={`aspect-ratio-${index}-${record.value}`}
            value={record.value || ''}
            text={record.name || 'Undefined'}
            selectedValue={this.props.pictureAspectRatioFrame}
            onChange={e => this.props.updatePictureAspectRatioFrame(e.target.value)}
            isUseDefaultColorForNotSelected
            templateColors={this.props.templateColors}
          />
        );
        return [...acc, radio];
      },
      [
        <CustomRadioButton
          key={enumsRecords.length}
          index={enumsRecords.length}
          id="aspect-ratio-none"
          value={null}
          text="None"
          selectedValue={this.props.pictureAspectRatioFrame}
          onChange={e => this.props.updatePictureAspectRatioFrame(null)}
          isUseDefaultColorForNotSelected
          templateColors={this.props.templateColors}
        />
      ]
    );
  };

  render() {
    return (
      <div className="op_picture-aspect-ratio-settings">
        <div className="op_picture-aspect-ratio-settings_title" style={{color: this.props.templateColors.main}}>
          Aspect Ratio Frame
        </div>
        {this.getRadioButtons()}
      </div>
    );
  }
}
