import {IErrorPayload, IErrorPayloadObject} from './IErrorPayload';
import {IMetadataError} from '../../../@types/metadataErrors';
import {FieldErrorMapping} from '../../modules/Tabs/constants/fieldErrorMapping';
import {PlaylistAsset} from '../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails} from '../../../@types/assetDetails';
import {getTitleInfo} from '../../modules/Tabs/utils/helpers';

const parseErrorsToMetadataErrorReduce = (acc: Array<IMetadataError>, errorEntry: any) => {
  return [...acc, {fieldName: errorEntry.fieldName || '', message: errorEntry.message || ''} as IMetadataError];
};

export class ErrorPayload extends Error implements IErrorPayload {
  static getMetadataError = (field: string, errors: Array<IMetadataError>) => {
    const errorMapping = FieldErrorMapping[field];
    if (!errorMapping) {
      return null;
    }
    return errors.find((errorEntry: IMetadataError) => errorMapping.errorLabels.indexOf(errorEntry.fieldName) !== -1);
  };

  static getNonNativeFields = (updatedAssetDetails: Partial<IAssetDetails>, asset: PlaylistAsset) => {
    // Get the value of the custom titleId field
    const titles = (updatedAssetDetails.titles || asset.assetDetails.titles || []).map(
      PlaylistAsset.parsing.parseSearchTitle
    );
    const credentials = PlaylistAsset.parsing.parseCredentialsFromTitles(titles);
    const {titleId} = getTitleInfo(credentials);

    // Get the value of the custom frameRate field
    const assetFrameRate = updatedAssetDetails.frameRate || asset.assetDetails.frameRate;
    const frameRate = assetFrameRate ? assetFrameRate.value : null;

    return {titleId, frameRate};
  };

  static isRequiredField = (field: string, asset?: PlaylistAsset) => {
    const errorMapping = FieldErrorMapping[field];
    if (!errorMapping) {
      return false;
    }
    if (typeof errorMapping.required === 'boolean') {
      return errorMapping.required;
    }
    if (asset) {
      return errorMapping.required(asset);
    }
    return false;
  };

  static getRequiredCSSClass = (field: string, asset: PlaylistAsset = null) => {
    return ErrorPayload.isRequiredField(field, asset) ? `required-asterisk` : ``;
  };

  static getFieldErrorByLabel = (label: string) => {
    let fieldError: any;
    Object.keys(FieldErrorMapping).forEach((field: string) => {
      if (fieldError) {
        return;
      }
      const record = FieldErrorMapping[field];
      if (record.errorLabels.indexOf(label) !== -1) {
        fieldError = record;
      }
    });
    return fieldError;
  };

  errorPayload: IErrorPayloadObject<IMetadataError>;

  constructor(errorPayload: IErrorPayloadObject<any>, ...params) {
    // Pass through all params used from standard Error class
    super(...params);

    this.errorPayload = this.parseErrorPayloadObject(errorPayload);
    this.name = 'PayloadError';
  }

  parseErrorPayloadObject = (errorPayload: IErrorPayloadObject<any>) => {
    return {
      error: errorPayload.error || 'Some error happened',
      metadataDetails: (errorPayload.metadataDetails || []).reduce(parseErrorsToMetadataErrorReduce, []),
      metadataVideo: (errorPayload.metadataVideo || []).reduce(parseErrorsToMetadataErrorReduce, []),
      metadataAudio: (errorPayload.metadataAudio || []).reduce(parseErrorsToMetadataErrorReduce, []),
      metadataNonMedia: (errorPayload.metadataNonMedia || []).reduce(parseErrorsToMetadataErrorReduce, []),
      metadataImages: (errorPayload.metadataImages || []).reduce(parseErrorsToMetadataErrorReduce, []),
      metadataSubtitles: (errorPayload.metadataSubtitles || []).reduce(parseErrorsToMetadataErrorReduce, []),
      reasons: (errorPayload.reasons || []).reduce(parseErrorsToMetadataErrorReduce, []),
      errorDetails: (errorPayload.errorDetails || []).reduce(parseErrorsToMetadataErrorReduce, [])
    } as IErrorPayloadObject<IMetadataError>;
  };

  isUpdateError = () => {
    return !!this.errorPayload.errorDetails.length;
  };
}
