import * as React from 'react';
import {Dropdown, Icon} from 'tt-components';
import {DropdownContent, defaultMenuControlBarStyles} from '../DropdownContent';
import {ReplayIcon} from '../../assets/Icons/Replay';
import {IFrameRate} from 'tt-components/src/frameRate';
import {throttle, calculateDropdownStyles} from '../../utils/utils';
import {ITemplateColors} from '../../../@types/templateColors';
import {CustomRadioButton} from '../CustomRadio';
import {PopoverTailIcon} from '../../assets/Icons/PopoverTail';
import {IDisplayMediaTimeFormat} from '../../../@types/displayMediaTimeFormat';
import {PlayerTimePicker} from '../PlayerTimePicker';

export type LoopState = 'off' | 'entireVideo' | 'segmentVideo';

interface ILoopControlBarState {
  loop?: LoopState;
  timeIn?: number;
  timeOut?: number;
  isTimeInSelected?: boolean;
  isTimeOutSelected?: boolean;
  startTimecodeOffset: number;
}
export interface ILoopControlBarProps {
  loop?: {
    loop;
    timeIn;
    timeOut;
  };
  maxTime?: number;
  frameRate?: IFrameRate;
  onSwitchLoop?: (loop: object) => void;
  templateColors: ITemplateColors;
  isControlBarShown: boolean;
  timeInSelected?: number;
  timeOutSelected?: number;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  disabled?: boolean;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  isOpen: boolean; // TODO: Implement approach to manually update popup visibility state
  startTimecodeOffset?: number;
  onShowLoopControlChange: (isOpen: boolean) => void;
}

export class LoopControlBar extends React.PureComponent<ILoopControlBarProps, ILoopControlBarState> {
  static getDerivedStateFromProps(nextProps: ILoopControlBarProps, prevState: ILoopControlBarState) {
    if (nextProps.startTimecodeOffset !== prevState.startTimecodeOffset) {
      return {
        timeIn: nextProps.loop.timeIn + (nextProps.startTimecodeOffset || 0),
        timeOut: nextProps.loop.timeOut + (nextProps.startTimecodeOffset || 0),
        startTimecodeOffset: nextProps.startTimecodeOffset
      };
    }
    const partial: Partial<ILoopControlBarState> = {};
    if (nextProps.loop.loop !== prevState.loop) {
      partial.loop = nextProps.loop.loop;
    }
    if (nextProps.loop.timeIn !== prevState.timeIn) {
      partial.timeIn = nextProps.loop.timeIn + (nextProps.startTimecodeOffset || 0);
    }
    if (nextProps.loop.timeOut !== prevState.timeOut) {
      partial.timeOut = nextProps.loop.timeOut + (nextProps.startTimecodeOffset || 0);
    }
    return Object.keys(partial).length ? partial : null;
  }

  loopDropdownButton: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      loop: this.props.loop.loop,
      timeIn: this.props.loop.timeIn || 0,
      timeOut: this.props.loop.timeOut || 0,
      isTimeInSelected: false,
      isTimeOutSelected: false,
      startTimecodeOffset: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timeInSelected !== this.props.timeInSelected && this.props.timeInSelected === 1) {
      this.setState({isTimeInSelected: true, isTimeOutSelected: false});
    }
    if (prevProps.timeOutSelected !== this.props.timeOutSelected && this.props.timeOutSelected === 1) {
      this.setState({isTimeOutSelected: true, isTimeInSelected: false});
    }
  }

  onSwitchLoop = params => {
    const {onSwitchLoop} = this.props;
    const {timeIn, timeOut} = this.state;
    const loop = params.target.value;
    const loopObj = {loop, timeIn, timeOut};
    this.setState({
      loop
    });
    onSwitchLoop(loopObj);
  };

  timeInChange = value => {
    const {onSwitchLoop} = this.props;
    const {loop, timeOut} = this.state;
    onSwitchLoop({
      loop,
      timeIn: Math.max(0, value - (this.props.startTimecodeOffset || 0)),
      timeOut: Math.max(0, (value > timeOut ? value : timeOut) - (this.props.startTimecodeOffset || 0))
    });
  };

  timeOutChange = value => {
    const {onSwitchLoop} = this.props;
    const {loop, timeIn} = this.state;
    onSwitchLoop({
      loop,
      timeIn: Math.max(0, (timeIn > value ? value : timeIn) - (this.props.startTimecodeOffset || 0)),
      timeOut: Math.max(0, value - (this.props.startTimecodeOffset || 0))
    });
  };

  onTimeInChange = () => throttle(this.timeInChange, 1000);

  onTimeOutChange = () => throttle(this.timeOutChange, 1000);

  getTimePicker = (time: number, type: 'timeIn' | 'timeOut', isSelected: boolean) => {
    const disabled = this.state.loop === 'off' || this.state.loop === 'entireVideo';

    if (this.props.displayMediaTimeFormat === 'FrameOnly') {
      return <div>Here should be frame-only component</div>;
    } else {
      const props: any =
        this.props.displayMediaTimeFormat === 'FrameBasedTimecode'
          ? {frameRate: this.props.frameRate}
          : this.props.displayMediaTimeFormat === 'MillisecondBasedTimecode'
          ? {milliseconds: true}
          : {};
      return (
        <PlayerTimePicker
          disabled={disabled}
          time={time}
          isSelected={isSelected}
          min={this.props.startTimecodeOffset || 0}
          max={
            typeof this.props.maxTime !== 'undefined'
              ? this.props.maxTime + (this.props.startTimecodeOffset || 0)
              : this.props.maxTime
          }
          {...props}
          onChangeTime={type === 'timeIn' ? this.onTimeInChange() : this.onTimeOutChange()}
        />
      );
    }
  };

  getLoopControlContent = () => {
    const {loop, timeIn, timeOut, isTimeInSelected, isTimeOutSelected} = this.state;
    const {main, tooltipBackground} = this.props.templateColors;
    const content = (
      <div className="op-loop-control-bar">
        <div className="title" style={{color: main}}>
          Loop
        </div>
        <ul>
          <li>
            <CustomRadioButton
              index={'off'}
              id={`loopOff`}
              value={'off'}
              text={'Off'}
              selectedValue={loop}
              onChange={this.onSwitchLoop}
              templateColors={this.props.templateColors}
            />
          </li>
          <li>
            <CustomRadioButton
              index={'entireVideo'}
              id={`loopEntireVideo`}
              value={'entireVideo'}
              text={'Entire Video'}
              selectedValue={loop}
              onChange={this.onSwitchLoop}
              templateColors={this.props.templateColors}
            />
          </li>
          <li>
            <CustomRadioButton
              index={'segmentVideo'}
              id={`loopSegmentVideo`}
              value={'segmentVideo'}
              text={'Video Segment'}
              selectedValue={loop}
              onChange={this.onSwitchLoop}
              templateColors={this.props.templateColors}
            />
          </li>
          <li>
            <div className="time-picker_title">Time in</div>
            {this.getTimePicker(timeIn, 'timeIn', isTimeInSelected)}
          </li>
          <li>
            <div className="time-picker_title">Time out</div>
            {this.getTimePicker(timeOut, 'timeOut', isTimeOutSelected)}
          </li>
        </ul>
      </div>
    );
    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={tooltipBackground} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onLoopOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, this.loopDropdownButton);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      onShowLoopControlChange,
      closestBodyElement
    } = this.props;
    const {main} = this.props.templateColors;
    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowLoopControlChange}
        title={'Loop Controls'}
        elemId="loopControlId"
        buttonComponent={() => (
          <div className="loop-control-dropdown-icon" ref={node => (this.loopDropdownButton = node)} id="loopControlId">
            <Icon icon={ReplayIcon} color={main} size="22px" />
          </div>
        )}
        content={this.getLoopControlContent()}
        openAbove={true}
        className={
          'ttc-control-bar-dropdown loop-control-bar-dropdown ' + (isControlBarShown ? '' : 'op_controls-bar_hidden')
        }
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onLoopOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  width: 175,
  padding: 5,
  borderWidth: 1,
  marginLeft: 0,
  boxSizing: 'content-box'
};
