import * as React from 'react';
import * as isEqual from 'deep-equal';
import {Subject, Subscription} from 'rxjs';
import {EventGroups} from './components/EventGroups';
import {OptionsGrid} from '../../../../components/OptionsGrid';
import {DownloadEdl} from './components/DownloadEdl';
import {Button} from '../../../../components/Button';
import {EventsTable} from './components/EventsTable';
import {Filter} from './components/Filter';
import {Guide} from './components/Guide';
import {IMarkupsEventGroup, IVideoFragment} from '../../../../state/IVideoState';
import {IAppPlaylist} from '../../../../state/IAppState';
import {IEventGroup, IMarkupEvent} from '../../../../../@types/markupEvent';
import {capitalizeFirstLetter} from '../../../../utils/utils';
import {IFrameRate, utils} from 'tt-components';
import {ITabs} from '../../interfaces/ITabsState';
import {deepCopy} from '../../utils/helpers';
import {SeekType} from '../../../../components/OnePlayerControlBar/onePlayerControlBarProps';
import {triggerNotification} from 'tt-components/src/Notifications/notifications';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IShortcutPush} from '../../../../../@types/shortcutPush';
import {IMarkupsError, IMarkupsEventError} from '../../../../../@types/markupsError';
import {StartTimecodeDropdown} from './components/StartTimecodeDropdown';
import {Smpte} from '../../../../models/Smpte/Smpte';
import {getMarkupsEvents, getGroupErrors} from '../../../../selectors';
import {IMarkupsOrder} from '../../interfaces/IMarkupsTab';

interface IMarkupsProps {
  selectedAsset: PlaylistAsset;
  changedEvents: Array<IEventGroup>;
  categories: any;
  types: any;
  eventGroups: Array<IMarkupsEventGroup>;
  selectedEventGroup: IMarkupsEventGroup;
  closestBody: HTMLElement;
  showingDropdownTimeout: number;
  framerate?: IFrameRate;
  currentVideoFragment: IVideoFragment;
  onEventGroupSelected: (name: IMarkupsEventGroup) => void;
  playlist: IAppPlaylist;
  shortcutSubject: Subject<IShortcutPush>;
  duration: number;
  markupsErrors: Array<IMarkupsError>;
  addNewEventsGroup: (id: string) => void;
  setVideoFragmentInTime: (timeIn) => void;
  setVideoFragmentOutTime: (timeOut) => void;
  setVideoFragmentInTimeByCurrentTime: () => void;
  setVideoFragmentOutTimeByCurrentTime: () => void;
  getVideoCurrentTime: () => number;
  selectTab: (tab: ITabs) => void;
  removeEvent: (eventId: string) => void;
  updateEvents: (id: string, name: string, events: string) => void;
  addNewEvents: (id: string, name: string, events: string) => void;
  onCountChanged: (count: number) => void;
  tabsInEditMode: boolean;
  updateChangedEventGroup: (changedEventGroup: IEventGroup) => void;
  getPlayerCurrentTime: () => number;
  useStartTimecode: boolean;
  markupsOrder: IMarkupsOrder;
  updateUseStartTimecodeFlag: (flag: boolean) => void;
  onSeek: (time: number, type: SeekType) => void;
  onOrderChange: (order: IMarkupsOrder) => void;
}

interface IMarkupsState {
  events: Array<IMarkupEvent>;
  isFirstLoad: boolean;
  duration: number;
}

const defaultPTTypes = PlaylistAsset.parsing.defaultPTTypes.map(type => type.type);

export class Markups extends React.Component<IMarkupsProps, IMarkupsState> {
  readonly programTimingsColumns = [
    {id: 'type', header: 'TYPE'},
    {id: 'timeIn', header: 'TIME IN'},
    {id: 'timeOut', header: 'TIME OUT'},
    {id: 'options', header: 'OPTIONS'}
  ];

  readonly complianceColumns = [
    {id: 'type', header: 'TYPE'},
    {id: 'timeIn', header: 'TIME IN'},
    {id: 'timeOut', header: 'TIME OUT'},
    {id: 'category', header: 'CATEGORY'},
    {id: 'notes', header: 'NOTES'},
    {id: 'options', header: 'OPTIONS'}
  ];

  readonly qualityControlColumns = [
    {id: 'type', header: 'TYPE'},
    {id: 'timeIn', header: 'TIME IN'},
    {id: 'timeOut', header: 'TIME OUT'},
    {id: 'notes', header: 'NOTES'},
    {id: 'options', header: 'OPTIONS'}
  ];

  $shortcutSubject: Subscription;
  filterRef;

  constructor(props) {
    super(props);

    this.state = {
      events: [],
      isFirstLoad: true,
      duration: 0
    };
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.init();
    this.$shortcutSubject = this.props.shortcutSubject && this.props.shortcutSubject.subscribe(this.onShortcut);
    this.props.onCountChanged(this.getGroupEvents().length);
  }

  componentWillUnmount() {
    if (this.$shortcutSubject) {
      this.$shortcutSubject.unsubscribe();
    }
    this.props.onCountChanged(this.getGroupEvents().length);
  }

  componentDidUpdate(prevProps: IMarkupsProps, prevState: IMarkupsState) {
    if (
      prevProps.selectedEventGroup !== this.props.selectedEventGroup ||
      !isEqual(prevProps.changedEvents, this.props.changedEvents)
    ) {
      this.init();
    }

    if (
      prevProps.currentVideoFragment.inTime !== this.props.currentVideoFragment.inTime &&
      this.props.currentVideoFragment.lastUpdated === 'InTime' &&
      !this.state.isFirstLoad
    ) {
      console.log('Updated inTime');
      this.addNewMarkup();
    } else if (
      prevProps.currentVideoFragment.outTime !== this.props.currentVideoFragment.outTime &&
      this.props.currentVideoFragment.lastUpdated === 'OutTime' &&
      !this.state.isFirstLoad
    ) {
      console.log('Updated outTime');
      this.updateNewMarkup();
    }

    if (prevState.events.length !== this.state.events.length) {
      this.props.onCountChanged(this.state.events.length);
    }

    // TODO: Check for proper solution to fix new added markup directly after mounting
    if (this.state.isFirstLoad) {
      this.setState({isFirstLoad: false});
    }

    // In case we have new markups errors we need to make sure that filter
    // is triggered in order to display those events with unvalid data
    if (
      !isEqual(this.props.markupsErrors, prevProps.markupsErrors) &&
      this.props.markupsErrors.length &&
      this.filterRef
    ) {
      this.filterRef.current.triggerFilter();
    }

    if (this.props.duration !== prevProps.duration) {
      this.durationUpdate();
    }

    if (prevProps.useStartTimecode !== this.props.useStartTimecode && this.props.tabsInEditMode) {
      this.toggleStartTimecodeOffset();
    }
  }

  init() {
    const events = this.getGroupEvents();
    this.setState({events}, () => {
      this.durationUpdate();
      // For the updated events check if they are between the minimum allowed limit in case Start Timecode is defined
      this.props.updateChangedEventGroup({
        name: this.props.selectedEventGroup,
        events: events.map((event: IMarkupEvent) => this.update(event))
      });
    });
  }

  durationUpdate = (defaultTimeIn: number = null) => {
    if (this.props.duration) {
      const startTimecodeTimeIn = defaultTimeIn !== null ? defaultTimeIn : this.getStartTimecodeEventTimeIn();
      this.setState({duration: startTimecodeTimeIn ? this.props.duration + startTimecodeTimeIn : this.props.duration});
    }
  };

  onShortcut = ({type}) => {
    console.log('Stream pushed', type);
    if (type === 'InTime') {
      this.addNewMarkup();
    } else {
      this.updateNewMarkup();
    }
  };

  defaultNewMarkup = (): Partial<IMarkupEvent> => ({
    id: `${new Date().getTime()}-new`,
    timeIn: this.convertToSMPTE(0),
    timeOut: this.convertToSMPTE(0),
    notes: '',
    type: null,
    newRecord: true,
    error: false
  });

  defaultNewComplianceMarkup = (): Partial<IMarkupEvent> => ({
    id: `${new Date().getTime()}-new`,
    timeIn: this.convertToSMPTE(0),
    timeOut: this.convertToSMPTE(0),
    category: '',
    notes: '',
    type: null,
    newRecord: true,
    error: false
  });

  defaultProgramTimingsNewMarkup = (): Partial<IMarkupEvent> => ({
    id: `${new Date().getTime()}-new`,
    timeIn: this.convertToSMPTE(0),
    timeOut: this.convertToSMPTE(0),
    type: null,
    newRecord: true,
    error: false
  });

  defaultChapterMarkup = (): Partial<IMarkupEvent> => ({
    id: `${new Date().getTime()}-new`,
    timeIn: this.convertToSMPTE(0),
    timeOut: this.convertToSMPTE(0),
    remoteassettimein: this.convertToSMPTE(0),
    remoteassettimeout: this.convertToSMPTE(0),
    type: 'Chapter',
    newRecord: true,
    error: false
  });

  defaultTextlessMarkup = (): Partial<IMarkupEvent> => ({
    id: `${new Date().getTime()}-new`,
    timeIn: this.convertToSMPTE(0),
    timeOut: this.convertToSMPTE(0),
    remoteassettimein: this.convertToSMPTE(0),
    remoteassettimeout: this.convertToSMPTE(0),
    type: null,
    newRecord: true,
    error: false
  });

  getStartTimecodeEvent = () => {
    return this.getGroupEvents('Program Timings').find((event: IMarkupEvent) => this.isStartTimecodeEvent(event));
  };

  toggleStartTimecodeOffset = () => {
    const event = this.getStartTimecodeEvent();
    if (!event) {
      return;
    }
    const startTimecodeDiff = this.props.useStartTimecode
      ? this.convertToSecond(event.timeIn)
      : this.convertToSecond('00:00:00:00') - this.convertToSecond(event.timeIn);
    this.updateEventsOffset(this.state.events, startTimecodeDiff);
    this.durationUpdate(!this.props.useStartTimecode ? 0 : this.convertToSecond(event.timeIn));
  };

  getStartTimecodeEventTimeIn = (): number => {
    // In case the use of 'Start Timecode' event is disabled then we need to return null
    if (!this.props.useStartTimecode) {
      return null;
    }
    return [this.getStartTimecodeEvent()]
      .filter(startTimecodeEvent => startTimecodeEvent)
      .reduce((timeIn: number, startTimecodeEvent) => this.convertToSecond(startTimecodeEvent.timeIn), null);
  };

  defineTime = (time: number | string, defaultTimeIn: number = null) => {
    let parsedTime = time;
    if (typeof time === 'string') {
      parsedTime = this.convertToSecond(parsedTime as string);
    }
    // NOTE: Start Timecode event from Program Timings group should be taken in consideration if it's defined
    // in all other events creation. Start Timecode should define the starting timecode value for the file and
    // should be the offset value for each time IN/OUT of other events
    const startTimecode = defaultTimeIn !== null ? defaultTimeIn : this.getStartTimecodeEventTimeIn();

    parsedTime = startTimecode !== null ? Math.max(0, (parsedTime as number) + startTimecode) : parsedTime;
    return this.convertToSMPTE(parsedTime as number);
  };

  checkTimeLimit = (time: string, checkStartTimecode: boolean = false): string => {
    const parsedTime = this.convertToSecond(time);
    // In cases we have defined start timecode we need to make sure that the updated timeIn/timeOut
    // doesn't breach the new limit that should be used as the default '00:00:00.00'
    const startTimecode = checkStartTimecode ? this.getStartTimecodeEventTimeIn() || 0 : 0;
    return parsedTime < startTimecode ? this.convertToSMPTE(startTimecode) : time;
  };

  updateEventByStartTimecode = (timeIn: number) => (event: IMarkupEvent) => {
    // NOTE: Start Timecode event should be excluded from the offset update logic
    if (this.isStartTimecodeEvent(event)) {
      return event;
    }
    return {...event, timeIn: this.defineTime(event.timeIn, timeIn), timeOut: this.defineTime(event.timeOut, timeIn)};
  };

  addNewMarkup = () => {
    if (!this.props.selectedAsset || !this.props.tabsInEditMode) {
      return;
    }
    let events = deepCopy([...this.state.events]);
    events = events.map((event: IMarkupEvent) => {
      event.newRecord = false;
      return event;
    });
    const time = this.props.currentVideoFragment.inTime < 0.07 ? 0 : this.props.currentVideoFragment.inTime;
    let defaultNewMarkup: Partial<IMarkupEvent>;
    switch (this.props.selectedEventGroup) {
      case 'Compliance Edits':
        defaultNewMarkup = this.defaultNewComplianceMarkup();
        break;
      case 'Program Timings':
        defaultNewMarkup = this.defaultProgramTimingsNewMarkup();
        break;
      case 'Chapter':
        defaultNewMarkup = this.defaultChapterMarkup();
        break;
      case 'Textless':
        defaultNewMarkup = this.defaultTextlessMarkup();
        break;
      default:
        defaultNewMarkup = this.defaultNewMarkup();
    }
    events.push({
      ...defaultNewMarkup,
      timeIn: this.defineTime(time),
      timeOut: this.defineTime(time)
    });
    this.props.updateChangedEventGroup({
      name: this.props.selectedEventGroup,
      events
    });
  };

  updateNewMarkup = () => {
    if (!this.props.selectedAsset || !this.props.tabsInEditMode) {
      return;
    }
    let events = deepCopy([...this.state.events]);
    const hasNewRecord = events.find((event: IMarkupEvent) => event.newRecord);
    if (!hasNewRecord) {
      // TODO: Add an alertify so user can now that Time Out cannot be updated
      return;
    }
    const convertTimeFunction = this.isStartTimecodeEvent(hasNewRecord) ? this.convertToSMPTE : this.defineTime;
    events = events.map((event: IMarkupEvent) => {
      if (event.id === hasNewRecord.id) {
        event.timeIn = convertTimeFunction(
          this.isStartTimecodeEvent(event)
            ? this.props.currentVideoFragment.outTime
            : this.props.currentVideoFragment.inTime
        );
        event.timeOut = convertTimeFunction(this.props.currentVideoFragment.outTime);
        event.newRecord = false;
      }
      return event;
    });
    this.props.updateChangedEventGroup({
      name: this.props.selectedEventGroup,
      events
    });
  };

  convertIMarkupEventToEdl = () => {
    const events = deepCopy([...this.state.events]);
    const edl = [];
    const columns = this.getColumns();
    const props = columns.map(column => column.id);
    events
      .filter((event: IMarkupEvent) => !event.hidden)
      .map((event: IMarkupEvent) => {
        const partial: Partial<IMarkupEvent> = {};
        props.forEach(prop => {
          partial[prop] = event[prop] || '';
        });
        return partial;
      })
      .forEach((event: Partial<IMarkupEvent>) => edl.push([...Object.values(event)]));
    return edl;
  };

  getHeadRowFields = () => {
    const columns = this.getColumns();
    const headRow = columns
      .map(column => capitalizeFirstLetter(column.header.toLowerCase()))
      .filter(column => column !== 'Options');
    return headRow;
  };

  getGroupEvents = (group: string = '') => {
    const events = this.props.changedEvents && Array.isArray(this.props.changedEvents) ? this.props.changedEvents : [];
    const groupEvents = events.find(
      (event: IEventGroup) => event.name === (group || this.props.selectedEventGroup)
    ) as IEventGroup;
    return groupEvents ? groupEvents.events || [] : [];
  };

  getCategories = () => {
    return this.props.categories['Asset.Compliance.ReasonCodes'] || [];
  };

  getColumns = () => {
    switch (this.props.selectedEventGroup) {
      case 'Program Timings':
        return this.programTimingsColumns;
      case 'Compliance Edits':
        return this.complianceColumns;
      case 'Quality Control':
      default:
        // Treat all the other rest of the Markups as 'Quality Control'
        // so we can handle type, time in/out and notes
        return this.qualityControlColumns;
    }
  };

  enableCategory = () => {
    return this.props.selectedEventGroup === 'Compliance Edits';
  };

  enableNotes = () => {
    return ['Compliance Edits', 'Quality Control'].indexOf(this.props.selectedEventGroup) !== -1;
  };

  addNewGroup = () => {
    if (this.props.selectedAsset && this.props.selectedAsset.assetId) {
      this.props.addNewEventsGroup(this.props.selectedAsset.assetId);
    } else {
      triggerNotification(
        {
          type: 'warning',
          title: 'Events Group',
          message: `Cannot perform event group add as asset not selected`,
          delay: 2500
        },
        null
      );
    }
  };

  onFilterEvents = (fromTime, toTime, types, categories) => {
    const events = deepCopy([...this.state.events]).map((event: IMarkupEvent) => {
      // Default Program Timings types should not be hidden, they will visible by default
      if (
        this.props.selectedEventGroup === 'Program Timings' &&
        defaultPTTypes.indexOf((event.type || '').toLowerCase()) !== -1
      ) {
        event.hidden = false;
        return event;
      }
      // NOTE: Because of inconsistencies between formatting functionalities we are adding a limit
      // for timeIn & timeOut values to not be greater than the duration value retrieved from Player
      const itemTimeIn = [utils.formatting.smpteTimecodeToSeconds(event.timeIn || '00:00:00')]
        .map((seconds: number) =>
          seconds > this.state.duration && this.state.duration ? this.state.duration : seconds
        )
        .reduce((acc: number, time: number) => time, 0);
      const itemTimeOut = [utils.formatting.smpteTimecodeToSeconds(event.timeOut || '00:00:00')]
        .map((seconds: number) =>
          seconds > this.state.duration && this.state.duration ? this.state.duration : seconds
        )
        .reduce((acc: number, time: number) => time, 0);
      // NOTE: Cases when timeIn and timeOut will be 00:00:00 should
      // be treated as a special case and events should be displayed
      const timeIntervalCriteria = this.state.duration ? itemTimeIn >= fromTime && itemTimeOut <= toTime : true;
      if (timeIntervalCriteria) {
        // Check if all filters are disabled
        const emptyFilters = !categories.length && !types.length;
        // NOTE: Event should be marked with matching category or type in cases
        // they match with the provided filters or in cases they are not defined at all
        // so we cannot apply any filtering logic and show to the user to update them
        const hasCategory = event.category
          ? categories.length
            ? categories.indexOf(event.category) !== -1
            : !emptyFilters
          : true;
        const hasType = event.type ? (types.length ? types.indexOf(event.type) !== -1 : !emptyFilters) : true;
        // NOTE: In case the event doesn't have category and type defined, it will be always visible
        // NOTE: Textless events are a special case, because they don't have a defined list of types as the other
        // groups and they will accept every valid string so we can't include them in the filter logic with types
        // and categories
        event.hidden = this.props.selectedEventGroup === 'Textless' ? false : !(hasCategory && hasType);
      } else {
        event.hidden = true;
      }

      // NOTE: In case we have event that it's hidden from filters condition but has unvalid values
      // we need to be able to show events so user can take actions
      if (event.hidden) {
        const error = [
          this.props.markupsErrors.find((error: IMarkupsError) => error.group === this.props.selectedEventGroup)
        ]
          .filter(group => group)
          .reduce((acc: Array<IMarkupsEventError>, group: IMarkupsError) => group.eventsErrors, [])
          .find((error: IMarkupsEventError) => error.id === event.id);
        event.hidden = !error;
      }

      return event;
    });
    this.setState({events});
  };

  getOptions = () => {
    return [
      <div className="markups-container_actions-row_options_option" key={0}>
        <StartTimecodeDropdown
          disabled={!this.props.tabsInEditMode || !this.getStartTimecodeEvent()}
          enabled={this.props.useStartTimecode}
          closestBody={this.props.closestBody}
          updateUseStartTimecodeFlag={this.props.updateUseStartTimecodeFlag}
        />
      </div>,
      <div className="markups-container_actions-row_options_filters" key={1}>
        <Filter
          ref={this.filterRef}
          events={this.getGroupEvents()}
          selectedEventGroup={this.props.selectedEventGroup}
          duration={this.state.duration}
          types={{name: this.props.selectedEventGroup, data: this.props.types}}
          categories={{name: this.props.selectedEventGroup, data: this.getCategories()}}
          closestBody={this.props.closestBody ? this.props.closestBody.closest('body') : null}
          onFilterEvents={this.onFilterEvents}
          frameRate={this.props.framerate}
        />
        <DownloadEdl
          edl={this.convertIMarkupEventToEdl()}
          closestBody={this.props.closestBody ? this.props.closestBody.closest('body') : null}
          showingDropdownTimeout={this.props.showingDropdownTimeout}
          headRow={this.getHeadRowFields()}
          selectedEventGroup={this.props.selectedEventGroup}
        />
      </div>
    ];
  };

  selectTab = (timeIn: string, timeOut: string) => {
    this.props.setVideoFragmentInTime(this.convertToSecond(timeIn));
    this.props.setVideoFragmentOutTime(this.convertToSecond(timeOut));
    this.props.selectTab(ITabs.Comments);
  };

  update = (event: IMarkupEvent) => {
    // In case we are updating not default Program Timings events we need to make sure
    // that we don't get negative timecodes and we are inside the Start Timecode limit if defined
    const isStartTimecodeEvent = this.isStartTimecodeEvent(event);
    const updatedEvent = deepCopy({
      ...event,
      timeIn: this.checkTimeLimit(event.timeIn, !isStartTimecodeEvent),
      timeOut: this.checkTimeLimit(event.timeOut, !isStartTimecodeEvent)
    });
    return updatedEvent;
  };

  isStartTimecodeEvent = (event: IMarkupEvent) => {
    return PlaylistAsset.parsing.isDefaultType(event) && (event.type || '').toLowerCase() === 'start timecode';
  };

  updateEvent = (event: IMarkupEvent) => {
    const eventWithSameDefaultType = [...this.state.events].find(
      (existingEvent: IMarkupEvent) =>
        existingEvent.id !== event.id &&
        (existingEvent.type || '').toLowerCase() === (event.type || '').toLowerCase() &&
        defaultPTTypes.indexOf((event.type || '').toLowerCase()) !== -1
    );
    if (eventWithSameDefaultType) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Markups',
          message: `Program Timings event of type ${event.type} already exists!`,
          delay: 2500
        },
        null
      );
      return;
    }
    const prevEvent = [...this.state.events].find((existingEvent: IMarkupEvent) => existingEvent.id === event.id);
    const events = deepCopy(this.state.events).map((existingEvent: IMarkupEvent) => {
      if (existingEvent.id === event.id) {
        return event;
      }
      return existingEvent;
    });
    // TODO: Check if this is a desired functionality to have
    // NOTE: In case the updated event is the Start Timecode event, than we need
    // to update all the other events to add the correct value of the offset
    // Also we need to check if we have switched from existing Start Timecode to another
    // type and to the recalculations for this type as well
    const removedStartTimecode = prevEvent
      ? this.isStartTimecodeEvent(prevEvent) && !this.isStartTimecodeEvent(event)
      : false;
    this.updateEventsByStartTimecode(
      events,
      removedStartTimecode ? deepCopy({...prevEvent}) : event,
      removedStartTimecode
    );
  };

  updateEventsByStartTimecode = (events: Array<IMarkupEvent>, event: IMarkupEvent, eventRemoved: boolean = false) => {
    const isStartTimecodeEvent = this.isStartTimecodeEvent(event);
    if (isStartTimecodeEvent && this.props.selectedEventGroup === 'Program Timings' && this.props.useStartTimecode) {
      // Get Start Timecode timeIn previous value
      const currentStartTimecodeTimeIn = this.getStartTimecodeEventTimeIn() || 0;
      // Get Start Timecode timeIn next value
      const updatedStartTimecodeTimeIn = eventRemoved ? 0 : this.convertToSecond(event.timeIn);
      // Get the diff that we need to add/subtract to current events
      const startTimecodeDiff = updatedStartTimecodeTimeIn - currentStartTimecodeTimeIn;
      // In case we have defined eventRemoved flag and event already exists on the list, then we don't need to update it
      const voidEvents = eventRemoved && !!events.find(record => record.id === event.id) ? [event.id] : [];
      this.updateEventsOffset(events, startTimecodeDiff, voidEvents);
      this.durationUpdate(eventRemoved ? 0 : updatedStartTimecodeTimeIn);
    } else {
      this.props.updateChangedEventGroup({
        name: this.props.selectedEventGroup,
        events
      });
    }
  };

  updateEventsOffset = (events: Array<IMarkupEvent>, startTimecodeDiff: number, voidEvents: Array<string> = []) => {
    const groups = [...this.props.eventGroups].filter((group: string) => group !== this.props.selectedEventGroup);
    // Update events for selected event group
    this.props.updateChangedEventGroup({
      name: this.props.selectedEventGroup,
      events: events.map((event: IMarkupEvent) => {
        if (voidEvents.indexOf(event.id) !== -1) {
          return event;
        }
        return this.updateEventByStartTimecode(startTimecodeDiff)(event);
      })
    });
    // Iterate to each event group and update related events
    groups.forEach((group: string) => {
      const groupEvents = deepCopy([...this.getGroupEvents(group)]);
      this.props.updateChangedEventGroup({
        name: group,
        events: groupEvents.map((event: IMarkupEvent) => {
          if (voidEvents.indexOf(event.id) !== -1) {
            return event;
          }
          return this.updateEventByStartTimecode(startTimecodeDiff)(event);
        })
      });
    });
  };

  convertToSecond = (time: string) => {
    const smpte = new Smpte(time, {
      frameRate: this.props.framerate.frameRate,
      dropFrame: this.props.framerate.dropFrame
    });
    return smpte.toAdjustedTime();
  };

  convertToSMPTE = (time: number) => {
    return Smpte.fromTimeWithAdjustments(time, {
      frameRate: this.props.framerate.frameRate,
      dropFrame: this.props.framerate.dropFrame
    }).toString();
  };

  onTimeIn = () => {
    const currentTimeInTime = this.props.getVideoCurrentTime();
    // NOTE: We do this check in order to see if the current time fo the player is the same
    // and we need to trigger markup creation manually and not listen the change of the prop
    if (currentTimeInTime === this.props.currentVideoFragment.inTime) {
      this.addNewMarkup();
    } else {
      this.props.setVideoFragmentInTimeByCurrentTime();
    }
  };

  onTimeOut = () => {
    const currentTimeInTime = this.props.getVideoCurrentTime();
    // NOTE: We do this check in order to see if the current time fo the player is the same
    // and we need to trigger markup creation manually and not listen the change of the prop
    if (currentTimeInTime === this.props.currentVideoFragment.outTime) {
      this.updateNewMarkup();
    } else {
      this.props.setVideoFragmentOutTimeByCurrentTime();
    }
  };

  removeMarkup = (eventId: string) => {
    const removedEvent = [...this.state.events].find((event: IMarkupEvent) => event.id === eventId);
    const events = deepCopy([...this.state.events]).filter((event: IMarkupEvent) => event.id !== eventId);
    // TODO: Check if this is a desired functionality to have
    // NOTE: In case the updated event is the Start Timecode event, than we need
    // to update all the other events to add the correct value of the offset
    if (removedEvent) {
      this.updateEventsByStartTimecode(events, deepCopy({...removedEvent}), true);
    }
  };

  getJsonVTT = () => {
    if (this.props.playlist.thumbnailTrack) {
      return this.props.playlist.thumbnailTrack.jsonVTT || [];
    }
    return [];
  };

  onTime = (time: number) => {
    // TODO: Remove this 'component level' approach to a more general 'action level' approach
    this.props.onSeek(Math.max(0, time - (this.getStartTimecodeEventTimeIn() || 0)), SeekType.toTime);
  };

  toggleUseStartTimecode = () => {
    this.props.updateUseStartTimecodeFlag(!this.props.useStartTimecode);
  };

  render() {
    const {selectedEventGroup} = this.props;
    return (
      <>
        <div className="markups-container_actions-row">
          <div className="markups-container_actions-row_markups-group">
            <EventGroups
              groups={this.props.eventGroups}
              selectedGroup={this.props.selectedEventGroup}
              errors={this.props.markupsErrors}
              addNewGroup={this.addNewGroup}
              onEventGroupSelected={this.props.onEventGroupSelected}
              closestBody={this.props.closestBody}
            />
          </div>
          <div className="markups-container_actions-row_options">
            <OptionsGrid options={this.getOptions()} />
          </div>
        </div>
        <div className="markups-container_timecode-row">
          <div className="markups-container_timecode-row_time-controls">
            <Button content="TIME IN" disabled={!this.props.tabsInEditMode} onClick={() => this.onTimeIn()} />
            <Button content="TIME OUT" disabled={!this.props.tabsInEditMode} onClick={() => this.onTimeOut()} />
            <Guide closestBody={this.props.closestBody ? this.props.closestBody.closest('body') : null} />
          </div>
        </div>
        <div className="markups-container_table-row">
          <EventsTable
            markupsOrder={this.props.markupsOrder}
            getPlayerCurrentTime={this.props.getPlayerCurrentTime}
            events={getMarkupsEvents(this.state.events)}
            duration={this.props.duration}
            selectedGroup={selectedEventGroup}
            types={this.props.types}
            categories={this.getCategories()}
            enableCategory={this.enableCategory()}
            enableNotes={this.enableNotes()}
            frameRate={this.props.framerate}
            selectTab={this.selectTab}
            removeMarkup={this.removeMarkup}
            addEvent={this.props.addNewEvents}
            updateEvent={this.updateEvent}
            jsonVTT={this.getJsonVTT()}
            onTime={this.onTime}
            closestBody={this.props.closestBody}
            tabsInEditMode={this.props.tabsInEditMode}
            eventsErrors={getGroupErrors({
              errors: this.props.markupsErrors,
              selectedEventGroup: this.props.selectedEventGroup
            })}
            onOrderChange={this.props.onOrderChange}
          />
        </div>
      </>
    );
  }
}
