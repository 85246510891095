import {IAssetType} from '../../../@types/assetType';
import {IAssetStatus} from '../../../@types/assetStatus';
import {IAssetDetails, IAssetFrameRate} from '../../../@types/assetDetails';
import {IAudioChannelConfiguration, IAudioChannel} from '../../../@types/audioChannelConfiguration';
import {ISubtitlesMetadata} from '../../../@types/subtitlesMetadata';
import {parsing} from './helpers/parsing';
import {filter} from './helpers/filter';
import {update} from './helpers/update';
import {defaults} from './helpers/defaults';
import {ISearchTitle} from '../../../@types/searchTitle';
import {IAssetCredentials, ISubtitlesUrlData, IWaveformsUrlData} from '../../state/IAppState';
import {IEventGroup, IMarkupEvent} from '../../../@types/markupEvent';
import {deepCopy} from '../../modules/Tabs/utils/helpers';
import {getFileInfoById, downloadFileById} from '../../data/vfsAPI';
import {IVfsFileInfo} from '../../../@types/vfsFileInfo';

export class PlaylistAsset {
  public static readonly parsing = parsing;
  public static readonly filter = filter;
  public static readonly update = update;
  public static readonly defaults = defaults;

  public static parseSubtitles(
    subtitles: Array<any>,
    assetId: string,
    hrId: string,
    assetName: string,
    assetFrameRate: IAssetFrameRate,
    assetStatus: IAssetStatus = 'Initial'
  ): Array<ISubtitlesMetadata> {
    return PlaylistAsset.parsing.createSubtitlesMetadata(
      `${assetId}-subtitles`,
      assetId,
      hrId,
      assetName,
      assetFrameRate,
      subtitles,
      assetStatus
    );
  }

  public static parseAudioConfiguration(
    audios: Array<any>,
    assetId: string,
    assetStatus: IAssetStatus = 'Initial',
    isRegistered: boolean,
    assetHrId: string
  ): Array<IAudioChannelConfiguration> {
    return audios.reduce((acc: Array<IAudioChannelConfiguration>, audio: any, index) => {
      const parsedAudio = PlaylistAsset.parsing.createAudioChannelConfiguration(
        audio,
        audio.id || `${assetId}-audio-${index}`,
        assetId,
        assetStatus || 'Initial',
        isRegistered,
        assetHrId
      );
      return [...acc, parsedAudio];
    }, []);
  }

  public static parseTitles(titles: Array<any>): Array<ISearchTitle> {
    return titles.reduce((acc: Array<ISearchTitle>, title: any) => {
      const parsedTitle = PlaylistAsset.parsing.parseSearchTitle(title);
      return [...acc, parsedTitle];
    }, []);
  }

  public assetId: string;
  public isHidden: boolean;
  public publicUrl: string;

  // tslint:disable-next-line
  private _assetStatus: IAssetStatus;
  // tslint:disable-next-line
  private _assetDetails: IAssetDetails = {};
  // tslint:disable-next-line
  private _parsedSubtitles: Array<ISubtitlesMetadata> = [];
  // tslint:disable-next-line
  private _parsedWaveforms: Array<IWaveformsUrlData>;
  // tslint:disable-next-line
  private _parsedAudioConfigurations: Array<IAudioChannelConfiguration>;
  // tslint:disable-next-line
  private _parsedTitles: Array<ISearchTitle>;
  // tslint:disable-next-line
  private _parsedEvents: Array<IEventGroup> = [];
  // tslint:disable-next-line
  private _assetType: IAssetType;
  // tslint:disable-next-line
  private _isRegistered: boolean;
  // tslint:disable-next-line
  private _vfsInfo: IVfsFileInfo = {};

  get assetDetails(): IAssetDetails {
    return this._assetDetails;
  }

  get assetStatus(): IAssetStatus {
    return this._assetStatus;
  }

  get subtitles(): Array<ISubtitlesMetadata> {
    return this._parsedSubtitles;
  }

  get audio(): Array<IAudioChannelConfiguration> {
    return this._parsedAudioConfigurations;
  }

  get events(): Array<IEventGroup> {
    return this._parsedEvents;
  }

  get titles(): Array<ISearchTitle> {
    return this._parsedTitles;
  }

  get isRegistered(): boolean {
    return this._isRegistered;
  }

  set isRegistered(flag: boolean) {
    this._isRegistered = flag;
    this.updateAssetRegisteredFlag();
  }

  get assetType(): IAssetType {
    return this.getAssetType();
  }

  get vfsInfo(): IVfsFileInfo {
    return this._vfsInfo;
  }

  constructor(
    assetStatus: IAssetStatus,
    assetDetails: IAssetDetails,
    isRegistered: boolean = true,
    events: Array<IEventGroup> = [],
    assetType?: IAssetType
  ) {
    this._assetStatus = assetStatus;
    this._isRegistered = isRegistered;
    this.isHidden = false;
    this.assetId = assetDetails.id;
    this._parsedEvents = events;
    this.updateAssetDetails(assetDetails);
    if (assetType) {
      this._assetType = assetType;
    }
  }

  public updateAssetDetails(partialAssetDetails: Partial<IAssetDetails>, completeUpdate: boolean = false): void {
    const partialAssetDetailsCopy = [deepCopy({...partialAssetDetails})].reduce(
      (partial: Partial<IAssetDetails>, details: Partial<IAssetDetails>) => {
        // NOTE: In case completeUpdate flag is defined we need to provide initial state for fields
        // that are used frequently in the Player logic and have impact on business logic
        if (completeUpdate) {
          return {
            ...details,
            audio: details.audio || [],
            subtitles: details.subtitles || [],
            titles: details.titles || []
          };
        } else {
          return {...details};
        }
      },
      {}
    );

    const assetDetails = {...this._assetDetails, ...partialAssetDetailsCopy};
    if (partialAssetDetailsCopy.audio) {
      assetDetails.audio = parsing.provideIdsForAudioRecords(partialAssetDetailsCopy.audio, this.assetId);
    }
    if (partialAssetDetailsCopy.images) {
      assetDetails.images = parsing.provideIdsForImageRecords(partialAssetDetailsCopy.images, this.assetId);
    }
    if (partialAssetDetailsCopy.nonMedia) {
      assetDetails.nonMedia = parsing.provideIdsForNonMediaRecords(partialAssetDetailsCopy.nonMedia, this.assetId);
    }
    if (partialAssetDetailsCopy.videos) {
      assetDetails.videos = parsing.provideIdsForVideoRecords(partialAssetDetailsCopy.videos, this.assetId);
    }
    if (partialAssetDetailsCopy.subtitles) {
      assetDetails.subtitles = parsing.provideIdsForSubtitlesRecords(partialAssetDetailsCopy.subtitles, this.assetId);
    }
    // Field errorLogs should be always an array for asset details
    assetDetails.errorLogs =
      assetDetails.errorLogs && Array.isArray(assetDetails.errorLogs) ? assetDetails.errorLogs : [];
    const {name, frameRate} = assetDetails;

    if (partialAssetDetailsCopy.audio) {
      // Parse Audio data to IAudioChannelConfiguration
      const parsedAudio = PlaylistAsset.parseAudioConfiguration(
        assetDetails.audio,
        this.assetId,
        this._assetStatus,
        this._isRegistered,
        assetDetails.hrId
      );
      this._parsedAudioConfigurations = parsedAudio;
    }

    const hasSubtitles =
      partialAssetDetailsCopy.subtitles &&
      Array.isArray(partialAssetDetailsCopy.subtitles) &&
      partialAssetDetailsCopy.subtitles.length;

    if (hasSubtitles) {
      // Parse Subtitles data to ISubtitlesMetadata
      const parsedSubtitles = PlaylistAsset.parseSubtitles(
        partialAssetDetailsCopy.subtitles,
        this.assetId,
        assetDetails.hrId,
        name,
        frameRate,
        this._assetStatus
      );
      this._parsedSubtitles = parsedSubtitles;
    }

    if (partialAssetDetailsCopy.titles) {
      // Parse Titles data to ISearchTitle
      const parsedTitles = PlaylistAsset.parseTitles(partialAssetDetailsCopy.titles);
      this._parsedTitles = parsedTitles;
    }

    // Update the assetDetails property
    this._assetDetails = {...assetDetails};
  }

  public updateSubtitlesUrl(subtitlesUrlData: Array<ISubtitlesUrlData>): void {
    if (!subtitlesUrlData.length) {
      console.log(`Couldn't update subtitles URL, there is not data retrieved from One Player service`);
      return;
    }

    if (!this._parsedSubtitles.length) {
      console.log(`Couldn't update subtitles URL, asset is not of type Subtitle: ${this.assetId}`);
      return;
    }

    this._parsedSubtitles = deepCopy([...this._parsedSubtitles]).reduce(
      (acc: Array<ISubtitlesMetadata>, subtitle: ISubtitlesMetadata) => {
        const nonZipSubtitle = (subtitle.name || '').indexOf('.zip') === -1;
        if (nonZipSubtitle) {
          const currentAssetId = this.assetId;
          const isExternal =
            this.assetType === 'Subtitles'
              ? subtitlesUrlData.find((subtitleData: ISubtitlesUrlData) => subtitleData.assetId === subtitle.assetId)
              : null;
          const isEmbedded = this.isEmbeddedSubtitle()
            ? subtitlesUrlData.find((subtitleData: ISubtitlesUrlData) => {
                // Check if current assetId matches with any of the provided parentAssetId(s)
                const isParentAssetId = subtitleData.parentAssetId === currentAssetId;
                // Generate token that will include the type of subtitle, language and country to properly map the file
                const token = `${subtitle.metadata.format ? `${subtitle.metadata.format.toLowerCase()}` : ``}${
                  subtitle.metadata.language ? `_${subtitle.metadata.language.toLowerCase()}` : ``
                }${subtitle.metadata.country ? `_${subtitle.metadata.country.toLowerCase()}` : ``}`;
                // Test the filename wwith created token to see we have any matching
                const isFilenameMatching = (subtitleData.filename ? subtitleData.filename.toLowerCase() : '').includes(
                  token
                );
                console.log(
                  'Embedded subtitle:',
                  token,
                  subtitleData.filename,
                  subtitleData.parentAssetId,
                  currentAssetId
                );
                return isParentAssetId && isFilenameMatching;
              })
            : null;
          console.log('External', isExternal, 'Embedded', isEmbedded);
          const urlData = isExternal ? isExternal : isEmbedded ? isEmbedded : null;
          subtitle.url = urlData ? urlData.url : null;
          console.log(`Subtitle URL updated: ${subtitle.assetId} - ${subtitle.url}`);
        }
        return [...acc, subtitle];
      },
      []
    );
  }

  public updateWaveformsUrl(waveformsUrlData: IWaveformsUrlData[]): void {
    if (!waveformsUrlData) {
      return;
    }
    this._parsedWaveforms = waveformsUrlData.map((data: IWaveformsUrlData) => {
      let matchGroups = data.filename.match(/.+_(.+)_(.+)/);
      return {
        ...data,
        track: matchGroups[1],
        channel: matchGroups[2]
      } as IWaveformsUrlData;
    });

    this._parsedAudioConfigurations = this._parsedAudioConfigurations.map(
      (audioConfiguration: IAudioChannelConfiguration) => {
        return {
          ...audioConfiguration,
          trackDetail: audioConfiguration.trackDetail.map((trackDetail: IAudioChannel) => {
            let waveformData = this._parsedWaveforms.find(
              wd => wd.channel === trackDetail.channelMap.join('') && trackDetail.track === wd.track
            );
            return {
              ...trackDetail,
              streamInFile: waveformData && waveformData.url
            };
          })
        };
      }
    );
  }

  public updateVfsFileInfoByRecordId = async (vfsRecordId: string) => {
    if (!vfsRecordId) {
      return console.log('Provided VFS record id is not valid', vfsRecordId);
    }
    const response = await getFileInfoById(vfsRecordId);
    if (!response.success) {
      return console.log('Failed getting file info from VFS service', response);
    }
    console.log('File VFS info retrieved from service', response);
    this._vfsInfo = response.data;
  };

  public updateVfsFileInfo = (vfsFileInfo: IVfsFileInfo) => {
    this._vfsInfo = vfsFileInfo;
  };

  public async updatePublicUrl() {
    // Property publicUrl will be used for assets such images and non media to be able rendering on the UI
    if (['Image', 'Non Media'].indexOf(this.assetType) === -1) {
      return console.log(`Couldn't update public url for a non Image/Non Media asset`, this.assetType, this.assetId);
    }
    if (!this._vfsInfo.id) {
      return console.log(`Couldn't find VFS record id`, this._vfsInfo);
    }
    const download = await downloadFileById(this._vfsInfo.id);
    console.log('Download asset from VFS', download);
    if (download.success) {
      this.publicUrl = download.data;
    }
    // NOTE: Remove comments only for cases when we need to test the functionality in local environment
    // if (this.assetType === 'Image') {
    //   this.publicUrl = 'http://localhost:8085/sailor.jpeg';
    // } else {
    //   this.publicUrl = 'http://localhost:8085/193351_ENG_JPN.xml';
    // }
  }

  public isVideoAsset(): boolean {
    return !!(
      this._assetDetails.videos &&
      Array.isArray(this._assetDetails.videos) &&
      this._assetDetails.videos.length
    );
  }

  public isAudioAsset(): boolean {
    return !!(this._assetDetails.audio && Array.isArray(this._assetDetails.audio) && this._assetDetails.audio.length);
  }

  public isSubtitlesAsset(): boolean {
    return !!(
      this._assetDetails.subtitles &&
      Array.isArray(this._assetDetails.subtitles) &&
      this._assetDetails.subtitles.length
    );
  }

  public isImageAsset(): boolean {
    return !!(
      this._assetDetails.images &&
      Array.isArray(this._assetDetails.images) &&
      this._assetDetails.images.length
    );
  }

  public isNonMediaAsset(): boolean {
    return !!(
      this._assetDetails.nonMedia &&
      Array.isArray(this._assetDetails.nonMedia) &&
      this._assetDetails.nonMedia.length
    );
  }

  public isEmbeddedAudio() {
    return this.isVideoAsset() && this.isAudioAsset();
  }

  public isEmbeddedSubtitle() {
    return this.isVideoAsset() && this.isSubtitlesAsset();
  }

  public getAssetType(): IAssetType {
    if (this.isVideoAsset()) {
      return 'Video';
    } else if (this.isAudioAsset()) {
      return 'Audio';
    } else if (this.isSubtitlesAsset()) {
      return 'Subtitles';
    } else if (this.isImageAsset()) {
      return 'Image';
    } else if (this.isNonMediaAsset()) {
      return 'Non Media';
    } else if (['Waveform', 'Thumbnails'].indexOf(this._assetType) !== -1) {
      return this._assetType;
    } else {
      return 'Unknown';
    }
  }

  public getTitleInfoForAsset(): IAssetCredentials {
    const titles = this._parsedTitles || [];
    return PlaylistAsset.parsing.parseCredentialsFromTitles(titles);
  }

  public updateAssetStatus(status: IAssetStatus) {
    this._assetStatus = status;
    if (this._parsedSubtitles.length) {
      this._parsedSubtitles = deepCopy([...this._parsedSubtitles]).reduce(
        (acc: Array<ISubtitlesMetadata>, subtitle: ISubtitlesMetadata) => {
          return [...acc, {...subtitle, assetStatus: status}];
        },
        []
      );
    }
    this._parsedAudioConfigurations = this._parsedAudioConfigurations.map((record: IAudioChannelConfiguration) => ({
      ...record,
      assetStatus: status
    }));
  }

  public updateAssetSubtitles(subtitles: Array<ISubtitlesMetadata>) {
    this._parsedSubtitles = [...subtitles];
  }

  public updateAssetAudioConfiguration(audio: Array<IAudioChannelConfiguration>) {
    this._parsedAudioConfigurations = [...audio];
  }

  public addEvents(eventsGroup: string, events: Array<IMarkupEvent>) {
    const currentEvents = deepCopy([...this._parsedEvents]);
    this._parsedEvents = currentEvents.reduce((acc: Array<IEventGroup>, group: IEventGroup) => {
      const currentGroup = {...group};
      if (group.name === eventsGroup) {
        currentGroup.events = [...currentGroup.events, ...events];
      }
      return [...acc, currentGroup];
    }, []);
    const groupAdded = this._parsedEvents.find((group: IEventGroup) => group.name === eventsGroup);
    // In case the Events Group is not defined in the existing list we need to add it to the new one
    if (!groupAdded) {
      this._parsedEvents = [...this._parsedEvents, {name: eventsGroup, events: [...events]}];
    }
  }

  public updateEvents(eventsGroup: string, events: Array<IMarkupEvent>) {
    const currentEvents = deepCopy([...this._parsedEvents]);
    this._parsedEvents = currentEvents.reduce((acc: Array<IEventGroup>, group: IEventGroup) => {
      const currentGroup = {...group};
      if (group.name === eventsGroup) {
        currentGroup.events = currentGroup.events.reduce((acc: Array<IMarkupEvent>, event: IMarkupEvent) => {
          const updateContent = events.find((updateEvent: IMarkupEvent) => updateEvent.id === event.id);
          if (updateContent) {
            return [...acc, updateContent];
          }
          return [...acc, event];
        }, []);
      }
      return [...acc, currentGroup];
    }, []);
    const groupAdded = this._parsedEvents.find((group: IEventGroup) => group.name === eventsGroup);
    // In case the Events Group is not defined in the existing list we need to add it to the new one
    if (!groupAdded) {
      this._parsedEvents = [...this._parsedEvents, {name: eventsGroup, events: [...events]}];
    }
  }

  public deleteEvent(eventId: string) {
    const currentEvents = deepCopy([...this._parsedEvents]);
    this._parsedEvents = currentEvents.reduce((acc: Array<IEventGroup>, group: IEventGroup) => {
      const currentGroup = {...group};
      currentGroup.events = currentGroup.events.filter((event: IMarkupEvent) => event.id === eventId);
      return [...acc, currentGroup];
    }, []);
  }

  public updateEventsProp(events: Array<IEventGroup>) {
    this._parsedEvents = [...events];
  }

  private updateAssetRegisteredFlag() {
    this._parsedAudioConfigurations = deepCopy([...this._parsedAudioConfigurations]).reduce(
      (acc: Array<IAudioChannelConfiguration>, config: IAudioChannelConfiguration) => {
        return [...acc, {...config, isRegistered: this._isRegistered}];
      },
      []
    );
  }
}
