import {createAction} from 'redux-actions';
import {ITabs} from '../interfaces/ITabsState';
import {IComment} from '../interfaces/ICommentsTab';
import {IAppState} from '../../../state/IAppState';
import {
  getCommentsByComplianceJobIdAPI,
  deleteCommentByIdAPI,
  addCommentAPI,
  editCommentByIdAPI,
  addReplyAPI
} from '../../../data/oneUIAPI';
import {addNewEvents, updateEvents, removeEvent, getSearchAsset, createConformanceGroup} from '../../../data/atlasAPI';
import {
  getEvents,
  setMarkupsErrors,
  updateAssetPartially,
  setChangedEvents,
  setDefaultDataForChangedEvents,
  updateChangedEventGroup,
  updateUnknownAssetType,
  updatePlaylistAssets,
  updateSelectedAssetId
} from '../../../actions/video';
import {IMetadataErrors, IMetadataErrorProps, IMetadataMassUpdateFields} from '../interfaces/IMetadataTab';
import {
  clearProps,
  deepCopy,
  filterDefaultTypes,
  updateMarkupsErrors,
  mergeChangedEvents,
  updateEventsGroup,
  getTypesByEventGroup,
  updateTimeOffset,
  removeFieldBySuffix,
  getDeepPropertyValidation,
  autoPopulateVideoSettings,
  getTitleAssetLevelData,
  getTitleInfo
} from '../utils/helpers';
import {IEventGroup, IMarkupEvent} from '../../../../@types/markupEvent';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IAssetDistributor} from '../../../../@types/assetDetails';
import {IUpdateEventsFormat} from '../../../../@types/updateEventsFormat';
import {IMarkupsError} from '../../../../@types/markupsError';
import {IResponse} from '../../../../@types/response';
import {triggerNotification} from 'tt-components/src/Notifications/notifications';
import {IServiceProvider} from '../../../services/interfaces';
import {IProgramTimingsAssetPut} from '../../../../@types/programTimingsAssetPut';
import {IChapterAssetPut} from '../../../../@types/chapterAssetPut';
import {IComplianceAssetPut} from '../../../../@types/complianceAssetPut';
import {IQualityControlLogAssetPut} from '../../../../@types/qualityControlLogAssetPut';
import {IQualityControl} from '../../../../@types/qualityControl';
import {ITextlessAssetPut} from '../../../../@types/textlessAssetPut';
import {IMetadataError} from '../../../../@types/metadataErrors';
import {ErrorPayload} from '../../../models/ErrorPayload/ErrorPayload';
import {has, wait} from '../../../utils/utils';
import {FieldErrorMapping} from '../constants/fieldErrorMapping';
import {IErrorPayloadObject} from 'models/ErrorPayload/IErrorPayload';
import {IMarkupsOrder} from '../interfaces/IMarkupsTab';
import {IConformanceGroupRequestData} from '../../../../@types/conformanceGroupPostData';
import {Smpte} from '../../../models/Smpte/Smpte';

export const SELECT_TAB = 'Tabs/SELECT_TAB';
export type SELECT_TAB = ITabs;
export const selectTab = createAction<SELECT_TAB, SELECT_TAB>(SELECT_TAB, (tab: ITabs) => tab);

export const SELECT_VERSION = 'Tabs/SELECT_VERSION';
export type SELECT_VERSION = string;
export const selectVersion = createAction<SELECT_VERSION, SELECT_VERSION>(
  SELECT_VERSION,
  (versionId: string) => versionId
);

export const SELECT_METADATA_TAB = 'Tabs/SELECT_METADATA_TAB';
export type SELECT_METADATA_TAB = ITabs;
export const selectMetadataTab = createAction<SELECT_METADATA_TAB, SELECT_METADATA_TAB>(
  SELECT_METADATA_TAB,
  (tab: ITabs) => tab
);

export const SET_COMMENTS = 'Video/SET_COMMENTS';
export type SET_COMMENTS = IComment[];
export const setComments = createAction<SET_COMMENTS, SET_COMMENTS>(SET_COMMENTS, (comments: SET_COMMENTS) => comments);

export const ADD_COMMENT = 'Video/ADD_COMMENT';
export type ADD_COMMENT = IComment;
export const addCommentToSrore = createAction<ADD_COMMENT, ADD_COMMENT>(ADD_COMMENT, (comment: ADD_COMMENT) => comment);

export const ADD_REPLY = 'Video/ADD_REPLY';
export type ADD_REPLY = IComment;
export const addReplyToStore = createAction<ADD_REPLY, ADD_REPLY>(ADD_REPLY, (reply: ADD_REPLY) => reply);

export const getComments = () => {
  return async (dispatch, getState: () => IAppState) => {
    const response = await getCommentsByComplianceJobIdAPI();

    if (response && response.success) {
      let comments = response.comments || [];

      // validate the result
      if (!Array.isArray(comments)) {
        console.warn('Comments: array is expected, got this instead:', comments);
        comments = [];
      }

      comments = comments.map(comment => {
        comment.inTime = parseFloat(comment.inTime) || 0;
        comment.outTime = parseFloat(comment.outTime) || 0;
        comment.createdAt = new Date(comment.createdAt + ' GMT+0000');
        return comment;
      });

      comments.sort((prevComment, nextComment) => {
        return prevComment.createdAt.getTime() - nextComment.createdAt.getTime();
      });

      dispatch({
        type: SET_COMMENTS,
        payload: comments
      });
    }
  };
};

export const deleteComment = (id: number) => {
  return async (dispatch, getState: () => IAppState) => {
    deleteCommentByIdAPI(id);

    const prevComments = getState().tabs.commentsTab.comments;
    let comments = prevComments.filter(comment => {
      return comment.id !== id;
    });

    dispatch({
      type: SET_COMMENTS,
      payload: comments
    });
  };
};

export const editComment = (id: number, text: string) => {
  return async (dispatch, getState: () => IAppState) => {
    const prevComments = getState().tabs.commentsTab.comments;
    let currComment = prevComments.find(comment => {
      return comment.id === id;
    });
    editCommentByIdAPI(id, {...currComment, comment: text});

    let comments = prevComments.map(comment => {
      if (id === comment.id) {
        comment.comment = text;
      }
      return comment;
    });

    dispatch({
      type: SET_COMMENTS,
      payload: comments
    });
  };
};

export const addCommentAndUpdateData = data => {
  return async (dispatch, getState: () => IAppState) => {
    const addCommentResponse = await addCommentAPI(data);

    let newComment: IComment = {
      id: null,
      comment: data.comment,
      createdBy: {
        displayName: 'Retrieving user name...',
        avatar: '/static/img/default-avatar.png'
      },
      createdAt: new Date(),
      parent: null,
      inTime: data.inTime,
      outTime: data.outTime,
      externalId: appConfig.externalID,
      isCurrentUser: true
    };

    dispatch({
      type: ADD_COMMENT,
      payload: newComment
    });

    if (addCommentResponse && addCommentResponse.success) {
      const response = await getCommentsByComplianceJobIdAPI();

      if (response && response.success) {
        let comments = response.comments || [];

        comments = comments.map(comment => {
          comment.inTime = parseFloat(comment.inTime) || 0;
          comment.outTime = parseFloat(comment.outTime) || 0;
          comment.createdAt = new Date(comment.createdAt + ' GMT+0000');
          return comment;
        });

        comments.sort((prevComment, nextComment) => {
          return prevComment.createdAt.getTime() - nextComment.createdAt.getTime();
        });

        dispatch({
          type: SET_COMMENTS,
          payload: comments
        });
      }
    }
  };
};

export const addReplyAndUpdateData = (commentId: number, text: string) => {
  return async (dispatch, getState: () => IAppState) => {
    const addReplyResponse = await addReplyAPI({commentId, text});

    let newComment: IComment = {
      id: null,
      comment: text,
      createdBy: {
        displayName: 'Retrieving user name...',
        avatar: '/static/img/default-avatar.png'
      },
      createdAt: new Date(),
      parent: commentId,
      inTime: null,
      outTime: null,
      externalId: appConfig.externalID,
      isCurrentUser: true
    };

    dispatch({
      type: ADD_COMMENT,
      payload: newComment
    });

    if (addReplyResponse && addReplyResponse.success !== false) {
      addReplyResponse.inTime = parseFloat(addReplyResponse.inTime) || 0;
      addReplyResponse.outTime = parseFloat(addReplyResponse.outTime) || 0;
      addReplyResponse.createdAt = new Date(addReplyResponse.createdAt + ' GMT+0000');

      dispatch({
        type: ADD_REPLY,
        payload: addReplyResponse
      });
    }
  };
};

export const PROCESSING_AUDIO_METADATA = 'Tabs/PROCESSING_AUDIO_METADATA';
export type PROCESSING_AUDIO_METADATA = boolean;
export const processingAudioMetadata = createAction<PROCESSING_AUDIO_METADATA, PROCESSING_AUDIO_METADATA>(
  PROCESSING_AUDIO_METADATA,
  (process: PROCESSING_AUDIO_METADATA) => process
);

export const getPlayerCurrentTime = () => {
  return (dispatch, getState: () => IAppState, services: IServiceProvider) => {
    return services.video.getCurrentTime();
  };
};

export const prepareEventsContentForAssetPut = (updatedAssetDetails: IAssetDetails) => {
  return async (dispatch, getState: () => IAppState) => {
    const {
      changedEvents,
      types,
      categories,
      playlist: {frameRate}
    } = getState().video;
    const {useStartTimecode} = getState().tabs.markupsTab;

    const startTimecodeEventTimeIn = [
      deepCopy([...changedEvents]).find((group: IEventGroup) => group.name === 'Program Timings')
    ]
      .filter(group => group)
      .reduce((timeIn: number, group: IEventGroup) => {
        const event = group.events.find((event: IMarkupEvent) => (event.type || '').toLowerCase() === 'start timecode');
        if (event) {
          const smpte = new Smpte(event.timeIn || 0, {frameRate: frameRate.frameRate, dropFrame: frameRate.dropFrame});
          return smpte.toAdjustedTime();
        }
        return null;
      }, null);

    let copyChangedEvents = deepCopy([...changedEvents]).map((group: IEventGroup) => {
      // NOTE: In case useStartTimecode is set to false we will need to update all events to
      // have the defined offset from the Start Timecode (if provided) so we know that every
      // event on markups load will include the Start Timecode offset
      if (!useStartTimecode && startTimecodeEventTimeIn) {
        group.events = group.events.map((event: IMarkupEvent) => {
          // Default types of Program Timings will not be included in the offset logic
          if (PlaylistAsset.parsing.isDefaultType(event)) {
            return {...event};
          }
          const timeIn = startTimecodeEventTimeIn
            ? updateTimeOffset(event.timeIn, startTimecodeEventTimeIn, frameRate)
            : event.timeIn;
          const timeOut = startTimecodeEventTimeIn
            ? updateTimeOffset(event.timeOut, startTimecodeEventTimeIn, frameRate)
            : event.timeOut;
          return {...event, timeIn, timeOut};
        });
        dispatch(updateChangedEventGroup(group));
      }
      return group;
    });

    dispatch(updateUseStartTimecodeFlag(true));

    let markupsErrors: Array<IMarkupsError> = [];
    const mustDefineDefaultEvents = PlaylistAsset.parsing.hasRequiredDefaultEvents(updatedAssetDetails);
    if (mustDefineDefaultEvents) {
      // Add default Program Timings events with default values if they are missing so they can go down
      // the validation process as added by the user
      copyChangedEvents = PlaylistAsset.parsing.parseEventsAfterAPIRequest(
        copyChangedEvents,
        types,
        updatedAssetDetails
      );
      // Update state to reflect changes to the UI
      [...copyChangedEvents].forEach((group: IEventGroup) => {
        if (group.name === 'Program Timings') {
          const defaultEvents = [...group.events].filter(
            (event: IMarkupEvent) => PlaylistAsset.parsing.isDefaultEvent(event) && (!event.timeIn || !event.timeOut)
          );
          defaultEvents.forEach((event: IMarkupEvent) => {
            markupsErrors = updateMarkupsErrors(
              markupsErrors,
              group.name,
              [event.id],
              `Event ${event.type} is required and should have valid Time In and Time Out values`
            );
          });
        }
        dispatch(updateChangedEventGroup(group));
      });
      // Wait in order to allow React to have enough time to update the UI
      await wait(250);
    }

    copyChangedEvents.forEach((group: IEventGroup) => {
      (group.events || []).forEach((event: IMarkupEvent) => {
        const groupTypes = getTypesByEventGroup(group.name, types);
        let error = '';
        if (!event.type && group.name !== 'Textless') {
          error = 'Type is missing';
        } else if (event.type && groupTypes.length && groupTypes.indexOf(event.type) === -1) {
          error = 'Type is invalid';
        } else if (!event.timeIn) {
          error = 'Time In is missing';
        } else if (!event.timeOut) {
          error = 'Time Out is missing';
        }
        if (group.name === 'Compliance Edits' && !error) {
          const groupCategories = categories['Asset.Compliance.ReasonCodes'] || [];
          error = !event.category
            ? 'Category is missing'
            : groupCategories.indexOf(event.category) === -1
            ? 'Category is invalid'
            : '';
        }
        if (error) {
          markupsErrors = updateMarkupsErrors(markupsErrors, group.name, [event.id], error);
        }
      });
    });

    if (markupsErrors.length) {
      dispatch(setMarkupsErrors(markupsErrors));
      throw new Error('Markups events have missing data');
    }

    const programTimings = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Program Timings')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IProgramTimingsAssetPut>, event: IMarkupEvent) => {
        return [...acc, {name: event.type, timeIn: event.timeIn, timeOut: event.timeOut} as IProgramTimingsAssetPut];
      }, []);

    const chapter = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Chapter')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IChapterAssetPut>, event: IMarkupEvent) => {
        const chapter: IChapterAssetPut = {timeIn: event.timeIn, timeOut: event.timeOut};
        if (event.remoteassettimein) {
          chapter.remoteAssetTimeIn = event.remoteassettimein;
        }
        if (event.remoteassettimeout) {
          chapter.remoteAssetTimeOut = event.remoteassettimeout;
        }
        return [...acc, chapter];
      }, []);

    const compliance = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Compliance Edits')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IComplianceAssetPut>, event: IMarkupEvent) => {
        return [
          ...acc,
          {
            editType: event.type,
            timeIn: event.timeIn,
            timeOut: event.timeOut,
            reason: event.category || '',
            notes: event.notes || ''
          } as IComplianceAssetPut
        ];
      }, []);

    const qualityControlLogs = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Quality Control')]
      .filter(group => group)
      .reduce((acc: Array<IMarkupEvent>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<IQualityControlLogAssetPut>, event: IMarkupEvent) => {
        return [
          ...acc,
          {
            qcType: event.type,
            timeIn: event.timeIn,
            timeOut: event.timeOut,
            notes: event.notes || ''
          } as IQualityControlLogAssetPut
        ];
      }, []);
    const qualityControl: Array<IQualityControl> = [];
    if (qualityControlLogs.length) {
      qualityControl.push({qcStatus: 'None', qcLog: qualityControlLogs});
    }

    const textless = [copyChangedEvents.find((group: IEventGroup) => group.name === 'Textless')]
      .filter(group => group)
      .reduce((acc: Array<ITextlessAssetPut>, group: IEventGroup) => group.events || [], [])
      .reduce((acc: Array<ITextlessAssetPut>, event: IMarkupEvent) => {
        const textless: ITextlessAssetPut = {timeIn: event.timeIn, timeOut: event.timeOut};
        if (event.remoteassettimein) {
          textless.remoteAssetTimeIn = event.remoteassettimein;
        }
        if (event.remoteassettimeout) {
          textless.remoteAssetTimeOut = event.remoteassettimeout;
        }
        return [...acc, textless];
      }, []);

    return {programTimings, chapter, compliance, qualityControl, textless};
  };
};

export const MARKUPS_SAVE_EVENT_CHANGES = 'Tabs/MARKUPS_SAVE_EVENT_CHANGES';
export const markupsSaveEventChanges = () => {
  return async (dispatch, getState: () => IAppState): Promise<IResponse> => {
    const {
      changedEvents,
      playlist: {assets, selectedAssetId}
    } = getState().video;
    const username = getState().configuration.userEmail;
    if (!username) {
      throw new Error('Username is missing from Player configuration');
    }
    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    // Reset markups error after each new save request for the events
    dispatch(setMarkupsErrors([]));

    dispatch({type: MARKUPS_SAVE_EVENT_CHANGES});

    const selectedAssetGroupEvents = selectedAsset ? selectedAsset.events : [];
    const copyChangedEvents = deepCopy([...changedEvents]).map((group: IEventGroup) => {
      if (group.name === 'Program Timings') {
        group.events = group.events.filter(filterDefaultTypes);
      }
      return group;
    });
    let markupsErrors: Array<IMarkupsError> = [];
    const eventsReadyToProcess = copyChangedEvents.reduce((acc: Array<IUpdateEventsFormat>, group: IEventGroup) => {
      // Prepare current events that are provided from the operator
      const currentEvents = (group.events ? (Array.isArray(group.events) ? group.events : []) : []).map(
        (event: IMarkupEvent) => clearProps(event, ['newRecord', 'error', 'hidden'])
      );
      // Retrieve existing events related with selected asset data
      const existingEvents = [
        selectedAssetGroupEvents.find((groupEvent: IEventGroup) => groupEvent.name === group.name)
      ]
        .filter(groupEvent => groupEvent)
        .reduce((acc: Array<IMarkupEvent>, groupEvent: IEventGroup) => {
          return [...acc, ...(groupEvent.events || [])];
        }, [])
        .filter(group.name === 'Program Timings' ? filterDefaultTypes : () => true);
      // Filter out the new events
      const newEvents = currentEvents.filter(
        (event: IMarkupEvent) => existingEvents.map((exEvent: IMarkupEvent) => exEvent.id).indexOf(event.id) === -1
      );
      // Filter out removed events
      const removedEvents = existingEvents.filter(
        (event: IMarkupEvent) => currentEvents.map((curEvent: IMarkupEvent) => curEvent.id).indexOf(event.id) === -1
      );
      // Filter out updated events
      const updatedEvents = currentEvents.filter(
        (curEvent: IMarkupEvent) => newEvents.map((newEvent: IMarkupEvent) => newEvent.id).indexOf(curEvent.id) === -1
      );
      return [...acc, {eventGroup: group.name, updatedEvents, newEvents, removedEvents} as IUpdateEventsFormat];
    }, []);
    let notAddedEvents: Array<IEventGroup> = [];
    const resolvePromise = eventsReadyToProcess.reduce(async (acc: Promise<any>, groupEvent: IUpdateEventsFormat) => {
      await acc;
      // Handle events update functionalities
      const updatedResponse = groupEvent.updatedEvents.length
        ? await updateEvents(selectedAssetId, groupEvent.eventGroup, username, JSON.stringify(groupEvent.updatedEvents))
        : {success: true};
      const updatedIds = groupEvent.updatedEvents.map((event: IMarkupEvent) => event.id);
      if (!updatedResponse.success) {
        markupsErrors = updateMarkupsErrors(
          markupsErrors,
          groupEvent.eventGroup,
          updatedIds,
          updatedResponse.error.message
        );
        notAddedEvents = updateEventsGroup(notAddedEvents, groupEvent.eventGroup, groupEvent.updatedEvents);
      }
      // Create a copy reference for the new events without the id field as it's needed from the API side
      const newEventsWithoutField = deepCopy([...groupEvent.newEvents]).map((event: IMarkupEvent) => {
        if (event.id) {
          delete event.id;
        }
        return event;
      });
      // Handle events creation functionalities
      const newResponse = groupEvent.newEvents.length
        ? await addNewEvents(selectedAssetId, groupEvent.eventGroup, username, JSON.stringify(newEventsWithoutField))
        : {success: true};
      const newIds = groupEvent.newEvents.map((event: IMarkupEvent) => event.id);
      if (!newResponse.success) {
        markupsErrors = updateMarkupsErrors(markupsErrors, groupEvent.eventGroup, newIds, newResponse.error.message);
        notAddedEvents = updateEventsGroup(notAddedEvents, groupEvent.eventGroup, groupEvent.newEvents);
      }
      // Handle events remove functionalities
      for (const removedEvent of groupEvent.removedEvents) {
        const removedResponse = await removeEvent(removedEvent.id, username);
        if (!removedResponse.success) {
          markupsErrors = updateMarkupsErrors(
            markupsErrors,
            groupEvent.eventGroup,
            [removedEvent.id],
            removedResponse.error.message
          );
        }
      }
      return Promise.resolve('');
    }, Promise.resolve(''));

    // Stop execution until all the group events have been processed and we are ready to fetch data from API
    await resolvePromise;

    await dispatch(getEvents());

    if (markupsErrors.length) {
      console.log('Errors in Markups updated', markupsErrors);
      dispatch(setMarkupsErrors(markupsErrors));
      const mergedChangedEvents = mergeChangedEvents(deepCopy([...getState().video.changedEvents]), notAddedEvents);
      dispatch(setChangedEvents(mergedChangedEvents));
      return Promise.resolve({success: false, error: 'Please check Markups tab for errors'});
    }
    return Promise.resolve({success: true});
  };
};

export const UPDATE_USE_START_TIMECODE_FLAG = 'Tabs/UPDATE_USE_START_TIMECODE_FLAG';
export type UPDATE_USE_START_TIMECODE_FLAG = boolean;
export const updateUseStartTimecodeFlag = createAction<UPDATE_USE_START_TIMECODE_FLAG, UPDATE_USE_START_TIMECODE_FLAG>(
  UPDATE_USE_START_TIMECODE_FLAG,
  (useStartTimecode: UPDATE_USE_START_TIMECODE_FLAG) => useStartTimecode
);

export const SAVE_TABS_CONTENT = 'Tabs/SAVE_TABS_CONTENT';
export type SAVE_TABS_CONTENT = boolean;
export const saveTabsContent = (registerAsset: boolean = false) => {
  return async (dispatch, getState: () => IAppState) => {
    const {
      playlist: {assets, selectedAssetId, unknownAssetTypes}
    } = getState().video;

    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    if (!selectedAsset) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Tabs',
          message: `Cannot proceed with saving content as no assets is selected!`,
          delay: 2500
        },
        null
      );
      return;
    }

    // NOTE: Unknown assets need firstly to have supported type assigned and then
    // we can follow the normal flow of SAVING/SUBMITTING data
    if (selectedAsset.assetType === 'Unknown') {
      if (!unknownAssetTypes) {
        triggerNotification(
          {
            type: 'error',
            title: 'Asset Type Update',
            message: 'Please select a valid asset type to assign to the Unknown asset.',
            delay: 2500
          },
          null
        );
        return;
      }
      const partial: Partial<IAssetDetails> =
        unknownAssetTypes === 'Video'
          ? {videos: [PlaylistAsset.defaults.videoMetadataDefault]}
          : unknownAssetTypes === 'Audio'
          ? {audio: [PlaylistAsset.defaults.audioMetadataDefault]}
          : unknownAssetTypes === 'Subtitles'
          ? {subtitles: [PlaylistAsset.defaults.subtitleMetadataDefault]}
          : unknownAssetTypes === 'Image'
          ? {images: [PlaylistAsset.defaults.imageMetadataDefault]}
          : unknownAssetTypes === 'Non Media'
          ? {nonMedia: [PlaylistAsset.defaults.nonMediaMetadataDefault]}
          : {};
      const updatedAssets = PlaylistAsset.update.updateAssetDetails(assets, partial, selectedAssetId);
      dispatch(updatePlaylistAssets(updatedAssets));
      dispatch(setEditMode(false));
      dispatch(updateSelectedAssetId(selectedAssetId));
      return;
    }

    dispatch(setEditMode(false));
    dispatch({type: SAVE_TABS_CONTENT, payload: true});

    try {
      const updateResponse = await dispatch(updateAssetDetailsData(registerAsset));
      if (!updateResponse.success) {
        const isWarningError =
          updateResponse.error.name === 'PayloadError' && !(updateResponse.error as ErrorPayload).isUpdateError();
        const message = isWarningError
          ? `Asset updated successfully, but some of the required fields are not provided`
          : updateResponse.error.message;
        const type = isWarningError ? `warning` : `error`;
        dispatch(setEditMode(true));
        triggerNotification(
          {
            type,
            title: null,
            message,
            delay: 2500
          },
          null
        );
      } else {
        // After success update we need to call events end-point to populate UI with latest data
        await dispatch(getEvents());
        const message = updateResponse.error
          ? `Asset updated successfully, but some of the required fields are not provided`
          : `Asset ${registerAsset ? `registered` : `updated`} successfully`;
        const type = updateResponse.error ? `warning` : `success`;
        triggerNotification(
          {
            type,
            title: null,
            message,
            delay: 2500
          },
          null
        );
        dispatch(updatePartialAssetDetails({}));
        dispatch(updateMetadataMassUpdateFields([]));
      }
      dispatch(parseAssetPutErrors(updateResponse.success ? [updateResponse.error] : updateResponse.data));
    } catch (error) {
      console.log('Error', error.message, error.stack);
      triggerNotification(
        {
          type: 'error',
          title: 'Tabs',
          message: error.message,
          delay: 2500
        },
        null
      );
      dispatch({type: SAVE_TABS_CONTENT, payload: false});
      dispatch(setEditMode(true));
    } finally {
      dispatch({type: SAVE_TABS_CONTENT, payload: false});
    }
  };
};

export const CANCEL_ASSET_EDIT = 'Tabs/CANCEL_ASSET_EDIT';
export const cancelTabsContent = () => {
  return dispatch => {
    dispatch(setDefaultDataForChangedEvents());
    dispatch(cancelSavingAssetDetailsData());
    dispatch(setEditMode(false));
    dispatch(updateUseStartTimecodeFlag(true));
    dispatch(resetMetadataErros());
    dispatch({type: CANCEL_ASSET_EDIT});
    dispatch(updateUnknownAssetType(null));
    dispatch(updateMetadataMassUpdateFields([]));
  };
};

export const SET_EDIT_MODE = 'Tabs/SET_EDIT_MODE';
export type SET_EDIT_MODE = boolean;
export const setEditMode = createAction<SET_EDIT_MODE, SET_EDIT_MODE>(
  SET_EDIT_MODE,
  (inEditMode: SET_EDIT_MODE) => inEditMode
);

export const START_ASSET_EDIT = 'Tabs/START_ASSET_EDIT';
export const startAssetEdit = () => {
  return (dispatch, getState: () => IAppState) => {
    const {selectedAssetId, assets} = getState().video.playlist;
    const {updatedAssetDetails} = getState().tabs;
    if (!selectedAssetId) {
      return;
    }
    const asset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    if (!asset) {
      return;
    }
    let assetDetails = {...updatedAssetDetails};
    // In case the asset is unregistered and it has not provided function we need to default it to 'Source'
    if (!asset.isRegistered && !asset.assetDetails.function) {
      assetDetails = {...assetDetails, function: 'Source'};
    }
    // We need to check if there are known defined sequences of fields so we can do some automatic updates
    if (asset.assetDetails.videos && Array.isArray(asset.assetDetails.videos) && asset.assetDetails.videos.length) {
      assetDetails = {...assetDetails, videos: autoPopulateVideoSettings(asset.assetDetails.videos)};
    }
    dispatch(updatePartialAssetDetails({...assetDetails}));
    dispatch(setEditMode(true));
    dispatch({type: START_ASSET_EDIT});
  };
};

export const checkPreSaveMetadataErrors = () => {
  return (dispatch, getState: () => IAppState) => {
    const metadataDetails: Array<IMetadataError> = [];
    const metadataVideo: Array<IMetadataError> = [];
    const metadataAudio: Array<IMetadataError> = [];
    const metadataNonMedia: Array<IMetadataError> = [];
    const metadataImages: Array<IMetadataError> = [];
    const metadataSubtitles: Array<IMetadataError> = [];

    const {updatedAssetDetails} = getState().tabs;
    const {selectedAssetId, assets} = getState().video.playlist;
    const asset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);

    if (!asset) {
      console.log(`Couldn't find selected asset for provided ID`, selectedAssetId);
      return;
    }

    // NOTE: In case the asset is not registered and the function is defined
    // we need to make sure that the submitted value is constrained
    const functionValue = updatedAssetDetails.function || asset.assetDetails.function;
    if (!asset.isRegistered && functionValue) {
      const isFunctionValueAllowed = ['Source', 'Proxy'].indexOf(functionValue) !== -1;
      if (!isFunctionValueAllowed) {
        metadataDetails.push({
          fieldName: 'AssetRegistrationPatch.Function',
          message: 'Allowed values are Source or Proxy'
        });
      }
    }

    const nonNativeFields = ErrorPayload.getNonNativeFields(updatedAssetDetails, asset);

    Object.keys(FieldErrorMapping).forEach((field: string) => {
      const requiredField =
        typeof FieldErrorMapping[field].required === 'boolean'
          ? FieldErrorMapping[field].required
          : FieldErrorMapping[field].required;
      const isRequired = typeof requiredField === 'boolean' ? requiredField : requiredField(asset);
      const isNative = FieldErrorMapping[field].native;
      const errorSection = FieldErrorMapping[field].errorSection;
      if (!isRequired) {
        return;
      }
      const mergedDetails = {...asset.assetDetails, ...updatedAssetDetails};
      // Get versionId field from the titles records updated through the UI with new data
      const titles = (mergedDetails.titles || []).map(PlaylistAsset.parsing.parseSearchTitle);
      const credentials = PlaylistAsset.parsing.parseCredentialsFromTitles(titles || []);
      const {versionId} = getTitleInfo(credentials);
      mergedDetails.versionId = versionId;

      const isNativeAssetFieldValueValid = isNative && getDeepPropertyValidation(field, mergedDetails as any);
      if (isNativeAssetFieldValueValid) {
        return;
      }
      const isNonNativeAssetFieldValueValid = !!(!isNative && nonNativeFields[field]);
      if (isNonNativeAssetFieldValueValid) {
        return;
      }
      const errorEntry = {fieldName: field, message: ''} as IMetadataError;
      switch (errorSection) {
        case 'metadataDetails':
          metadataDetails.push(errorEntry);
          break;
        case 'metadataVideo':
          metadataVideo.push(errorEntry);
          break;
        case 'metadataAudio':
          metadataAudio.push(errorEntry);
          break;
        case 'metadataNonMedia':
          metadataNonMedia.push(errorEntry);
          break;
        case 'metadataImages':
          metadataImages.push(errorEntry);
          break;
        case 'metadataSubtitles':
          metadataSubtitles.push(errorEntry);
          break;
      }
    });
    const hasError = [
      metadataDetails,
      metadataVideo,
      metadataAudio,
      metadataNonMedia,
      metadataImages,
      metadataSubtitles
    ].some(errorsList => !!errorsList.length);

    if (hasError) {
      return new ErrorPayload(
        {
          metadataDetails,
          metadataVideo,
          metadataAudio,
          metadataNonMedia,
          metadataImages,
          metadataSubtitles
        },
        'Some required fields are not provided'
      );
    }
    return null;
  };
};

export const parseAssetPutErrors = (errors: Array<Error | ErrorPayload>) => {
  return dispatch => {
    const errorPayload = errors
      .filter(error => error)
      .filter(error => error.name === 'PayloadError')
      .reduce(
        (acc: IErrorPayloadObject<IMetadataError>, error: ErrorPayload) => {
          acc.metadataDetails.push(...(error.errorPayload.metadataDetails || []));
          acc.metadataVideo.push(...(error.errorPayload.metadataVideo || []));
          acc.metadataAudio.push(...(error.errorPayload.metadataAudio || []));
          acc.metadataImages.push(...(error.errorPayload.metadataImages || []));
          acc.metadataNonMedia.push(...(error.errorPayload.metadataNonMedia || []));
          acc.metadataSubtitles.push(...(error.errorPayload.metadataSubtitles || []));
          return acc;
        },
        {
          metadataDetails: [],
          metadataVideo: [],
          metadataAudio: [],
          metadataImages: [],
          metadataNonMedia: [],
          metadataSubtitles: []
        }
      );
    // TODO: Find better approach to distribute reasons errors between metadata
    const updatedErrors = errors
      .filter(error => error)
      .filter(error => error.name === 'PayloadError')
      .reduce((acc: IErrorPayloadObject<IMetadataError>, error: ErrorPayload) => {
        const mergedErrors = [...(error.errorPayload.reasons || []), ...(error.errorPayload.errorDetails || [])];
        mergedErrors.forEach((errorRecord: IMetadataError) => {
          const fieldError = ErrorPayload.getFieldErrorByLabel(errorRecord.fieldName);
          if (!fieldError) {
            return;
          }
          const findFunc = (error: IMetadataError) => fieldError.errorLabels.indexOf(error.fieldName) !== -1;
          let existsField;
          switch (fieldError.errorSection) {
            case 'metadataDetails':
              existsField = acc.metadataDetails.find(findFunc);
              if (!existsField) {
                acc.metadataDetails.push(errorRecord);
              }
              break;
            case 'metadataVideo':
              existsField = acc.metadataVideo.find(findFunc);
              if (!existsField) {
                acc.metadataVideo.push(errorRecord);
              }
              break;
            case 'metadataAudio':
              existsField = acc.metadataAudio.find(findFunc);
              if (!existsField) {
                acc.metadataAudio.push(errorRecord);
              }
              break;
            case 'metadataImages':
              existsField = acc.metadataImages.find(findFunc);
              if (!existsField) {
                acc.metadataImages.push(errorRecord);
              }
              break;
            case 'metadataNonMedia':
              existsField = acc.metadataNonMedia.find(findFunc);
              if (!existsField) {
                acc.metadataNonMedia.push(errorRecord);
              }
              break;
            case 'metadataSubtitles':
              existsField = acc.metadataSubtitles.find(findFunc);
              if (!existsField) {
                acc.metadataSubtitles.push(errorRecord);
              }
              break;
          }
        });
        return acc;
      }, errorPayload);

    dispatch(updateMetadataErrors('metadataDetails', updatedErrors.metadataDetails));
    dispatch(updateMetadataErrors('metadataVideo', updatedErrors.metadataVideo));
    dispatch(updateMetadataErrors('metadataAudio', updatedErrors.metadataAudio));
    dispatch(updateMetadataErrors('metadataImages', updatedErrors.metadataImages));
    dispatch(updateMetadataErrors('metadataNonMedia', updatedErrors.metadataNonMedia));
    dispatch(updateMetadataErrors('metadataSubtitles', updatedErrors.metadataSubtitles));
  };
};

export const updateAssetInfo = (assetId: string, partialDetails: Partial<IAssetDetails>) => {
  return async (dispatch): Promise<IResponse> => {
    try {
      await dispatch(updateAssetPartially(assetId, partialDetails));
      return {success: true};
    } catch (error) {
      return {success: false, error};
    }
  };
};

export const UPDATE_CONFORMANCE_GROUP_ASSIGNMENTS = 'Tabs/UPDATE_CONFORMANCE_GROUP_ASSIGNMENTS';
export const updateConformanceGroupAssignments = (
  assetId: string,
  minimumRequirementsMet: boolean,
  prevIsRegisteredState: boolean,
  doImageUpdate: boolean = false
) => {
  return async (dispatch, getState: () => IAppState) => {
    dispatch({type: UPDATE_CONFORMANCE_GROUP_ASSIGNMENTS});
    // NOTE: This action will handle the update of conformance group for all assets present in the playlist
    // when submitting the video file of the group with or without a valid conformance group record. In case
    // conformance group is missing we will create a new one and assign accordingly. This will happen only if
    // curationModeEnabled flag (unregistered flow) is truthy.
    if (!getState().configuration.curationModeEnabled) {
      return false;
    }
    const asset = PlaylistAsset.filter.getPlaylistAsset(getState().video.playlist.assets, assetId);
    if (!asset) {
      console.log(`Update conformance group asset doesn't exist`);
      return false;
    }
    if (!minimumRequirementsMet) {
      console.log(`Update conformance group is not submit action`);
      return false;
    }
    const searchAssetRecord = await getSearchAsset(asset.assetId);
    if (!searchAssetRecord.success) {
      console.log(`Update conformance group search asset not found`);
      return false;
    }
    if (!(searchAssetRecord.data.meetsMinimumRequirements && !prevIsRegisteredState)) {
      console.log(`Update conformance group is not publish action`);
      return false;
    }
    if (asset.getAssetType() !== 'Video') {
      console.log(`Update conformance group is not video unregistered video asset`);
      return false;
    }
    const assetTitlesInfo = asset.getTitleInfoForAsset();
    // Prepare conformance group request data before making POST call
    const conformanceData: IConformanceGroupRequestData = {username: getState().configuration.userEmail};
    const name = PlaylistAsset.parsing.getConformanceGroupCreationName(asset.assetDetails);
    if (name) {
      conformanceData.name = name;
    }
    // Check if submitted asset has assigned an existing conformance group or we need to create a new one
    const conformanceGroup = assetTitlesInfo.conformanceGroupId
      ? {success: true, data: {id: assetTitlesInfo.conformanceGroupId}}
      : await createConformanceGroup(assetTitlesInfo, conformanceData);
    if (!conformanceGroup.success) {
      console.log(`Update conformance group failed creating conformance group`);
      return false;
    }
    // Update the left assets to have same conformance group and version as the updated video asset
    const assets = [...getState().video.playlist.assets]
      .filter((playlistAsset: PlaylistAsset) => (doImageUpdate ? true : playlistAsset.getAssetType() !== 'Image'))
      .reduce((acc: Array<string>, playlistAsset: PlaylistAsset) => {
        return [...acc, playlistAsset.assetId];
      }, []);
    const {versionId} = getTitleAssetLevelData(assetTitlesInfo);
    const update = assets.reduce(async (acc: Promise<Array<string>>, assetId: string) => {
      const failedAssets = await acc;
      const updateResponse = await dispatch(
        updateAssetInfo(assetId, {conformanceId: conformanceGroup.data.id, versionId})
      );
      const isWarningError =
        updateResponse.error && updateResponse.error.name === 'PayloadError' && !updateResponse.error.isUpdateError();
      if (!updateResponse.success && !isWarningError) {
        console.log('Update asset conformance group error', updateResponse.error);
        return Promise.resolve([...failedAssets, assetId]);
      }
      return Promise.resolve([...failedAssets]);
    }, Promise.resolve([]));

    const failedUpdates = await update;

    const conformanceGroupId = `${
      conformanceGroup.data.hrId ? ` ${conformanceGroup.data.hrId}` : ` ${conformanceGroup.data.id}` || ``
    }`;
    const message = `
      ${assetTitlesInfo.conformanceGroupId ? `` : `Conformance group created. `}
      ${
        failedUpdates.length
          ? `
              Some of the assets failed to be assigned to the video conformance group${conformanceGroupId}. 
              To update conformance group details, please refer to ATLAS. 
            `
          : `All assets where assigned to the video conformance group${conformanceGroupId}. `
      }
      Note that unregistered assets not yet submitted will not show under the conformance group in ATLAS.
    `;

    triggerNotification(
      {
        type: failedUpdates.length ? `warning` : `success`,
        title: `Assets Conformance Group Update`,
        message,
        delay: 2500
      },
      null
    );
    return true;
  };
};

export const massUpdateOfAssetsForSelectedFields = (isConformanceGroupAssignmentDone: boolean) => {
  return async (dispatch: (action: any) => any, getState: () => IAppState) => {
    const {
      metadataTab: {metadataMassUpdateFields}
    } = getState().tabs;
    const {selectedAssetId, assets} = getState().video.playlist;
    const asset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);
    if (!asset) {
      return console.log(`Couldn't find asset data from Playlist to perform the mass update`);
    }
    // Get copy of asset details object with recent update values
    const assetDetails = {...asset.assetDetails};
    // Get asset titles information as versionId and conformanceGroupId to update asset
    const assetTitlesInfo = asset.getTitleInfoForAsset();
    const {versionId, conformanceId} = getTitleAssetLevelData(assetTitlesInfo);
    // Define update object to do the mass updates to the other left unregistered assets opened in the player
    const partialUpdateObject: Partial<IAssetDetails> = [...metadataMassUpdateFields]
      .filter((field: IMetadataMassUpdateFields) => (isConformanceGroupAssignmentDone ? field !== 'titles' : true))
      .reduce(
        (acc: Partial<IAssetDetails>, field: IMetadataMassUpdateFields) => {
          if (field === 'titles') {
            acc = [versionId, conformanceId].reduce((data: Partial<IAssetDetails>, value: string, index: number) => {
              if (value && index === 0) {
                data.versionId = value;
              }
              if (value && index === 1) {
                data.conformanceId = value;
              }
              return data;
            }, {});
          } else if (field === 'contentType') {
            acc.contentType = assetDetails.contentType;
          }
          return acc;
        },
        {} as Partial<IAssetDetails>
      );
    if (!Object.keys(partialUpdateObject).length) {
      return console.log(`Partial object for mass update doesn't have any valid property`);
    }
    // Will do mass update only to the left unregistered assets that are opened in the player
    const unregisteredAssets = [...assets]
      .filter((record: PlaylistAsset) => !record.isRegistered)
      .filter((record: PlaylistAsset) => record.assetId !== asset.assetId);
    if (!unregisteredAssets.length) {
      return console.log(`There are no unregistered assets to perform the mass update`);
    }
    const updatePromise = unregisteredAssets.reduce(async (acc: Promise<Array<string>>, record: PlaylistAsset) => {
      const failed = await acc;
      const updateResponse = await dispatch(updateAssetInfo(record.assetId, partialUpdateObject));
      const isWarningError =
        updateResponse.error &&
        updateResponse.error.name === 'PayloadError' &&
        (updateResponse.error as ErrorPayload).errorPayload.reasons.length;
      if (!updateResponse.success && !isWarningError) {
        console.log('Update mass asset error', updateResponse.error);
        const assetHrId = record.assetDetails ? record.assetDetails.hrId || '' : record.assetId;
        return Promise.resolve([...failed, assetHrId]);
      }
      return Promise.resolve([...failed]);
    }, Promise.resolve([]));

    const failedAssets = await updatePromise;

    const message = `${
      failedAssets.length
        ? `These unregistered assets failed being updated: ${failedAssets.join(', ')}`
        : `All unregistered assets are updated successfully`
    }`;

    triggerNotification(
      {
        title: 'Asset Mass Update',
        type: failedAssets.length ? 'warning' : 'success',
        message,
        delay: 2500
      },
      null
    );
  };
};

export const UPDATE_ASSET_DETAILS_DATA = 'Tabs/UPDATE_ASSET_DETAILS_DATA';
export type UPDATE_ASSET_DETAILS_DATA = void;
export const updateAssetDetailsData = (minimumRequirementsMet: boolean) => {
  return async (dispatch, getState: () => IAppState): Promise<IResponse> => {
    dispatch({type: UPDATE_ASSET_DETAILS_DATA});

    const {assets, selectedAssetId} = getState().video.playlist;

    const selectedAsset = PlaylistAsset.filter.getPlaylistAsset(assets, selectedAssetId);

    const isRegistered = () => {
      return selectedAsset
        ? typeof selectedAsset.isRegistered !== 'undefined'
          ? selectedAsset.isRegistered
          : true
        : true;
    };

    // Reset Metadata tabs errors before the update call is performed
    dispatch(resetMetadataErros());
    // Reset markups error after each new save request for the events
    dispatch(setMarkupsErrors([]));

    let updatedAssetDetailsGeneralData: Partial<IAssetDetails> = getState().tabs.updatedAssetDetails;
    let assetDetails: IAssetDetails = selectedAsset.assetDetails;
    const events = await dispatch(
      prepareEventsContentForAssetPut({
        ...selectedAsset.assetDetails,
        ...updatedAssetDetailsGeneralData
      })
    );
    console.log('Updated events', events);
    const updatedFields = deepCopy({...updatedAssetDetailsGeneralData, ...events});
    // Clear reference records from temporary ID fields
    if (updatedFields.references) {
      updatedFields.references = removeFieldBySuffix(updatedFields.references, 'id', '-new-reference');
    }
    // Clear distributor records from temporary ID fields
    if (updatedFields.distributors) {
      updatedFields.distributors = removeFieldBySuffix(updatedFields.distributors, 'id', '-new-distributor').filter(
        (distributor: IAssetDistributor) => distributor.distributorId
      );
    }
    if (!isRegistered()) {
      const titles = (updatedAssetDetailsGeneralData.titles || assetDetails.titles || []).map(
        PlaylistAsset.parsing.parseSearchTitle
      );
      const credentials = PlaylistAsset.parsing.parseCredentialsFromTitles(titles);
      const {versionId, conformanceGroupId} = getTitleInfo(credentials);
      updatedFields.versionId = versionId;
      updatedFields.conformanceId = conformanceGroupId;
    }
    // Check if we have some unwanted data before pushing to ATLAS
    const errorPayload = dispatch(checkPreSaveMetadataErrors());
    try {
      if (minimumRequirementsMet && errorPayload) {
        throw new Error('Asset is missing fields in order to meet minimum requirements for processing');
      }
      await dispatch(updateAssetPartially(selectedAssetId, updatedFields, minimumRequirementsMet));
      // In case the titles option is selected as mass update field we need to make sure to update the Image assets
      // as well for the conformance group assignment when Video asset is submitted successfully
      const doImageUpdate = (getState().tabs.metadataTab.metadataMassUpdateFields || []).indexOf('titles') !== -1;
      const isConformanceGroupUpdateDone = await dispatch(
        updateConformanceGroupAssignments(
          selectedAssetId,
          minimumRequirementsMet,
          selectedAsset.isRegistered,
          doImageUpdate
        )
      );
      await dispatch(massUpdateOfAssetsForSelectedFields(isConformanceGroupUpdateDone));
      return Promise.resolve({success: true, error: errorPayload});
    } catch (error) {
      dispatch(setEditMode(true));
      return Promise.resolve({success: false, data: [error, errorPayload], error});
    }
  };
};

export const CANCEL_SAVING_ASSET_DETAILS_DATA = 'Tabs/CANCEL_SAVING_ASSET_DETAILS_DATA';
export type CANCEL_SAVING_ASSET_DETAILS_DATA = void;
export const cancelSavingAssetDetailsData = () => {
  return async (dispatch, getState: () => IAppState) => {
    dispatch(updatePartialAssetDetails({}));
  };
};

export const UPDATE_PARTIAL_ASSET_DETAILS = 'Tabs/UPDATE_PARTIAL_ASSET_DETAILS';
export type UPDATE_PARTIAL_ASSET_DETAILS = Partial<IAssetDetails>;
export const updatePartialAssetDetails = createAction<UPDATE_PARTIAL_ASSET_DETAILS, UPDATE_PARTIAL_ASSET_DETAILS>(
  UPDATE_PARTIAL_ASSET_DETAILS,
  (details: UPDATE_PARTIAL_ASSET_DETAILS) => details
);

export const UPDATE_METADATA_ERRORS = 'Tabs/UPDATE_METADATA_ERRORS';
export type UPDATE_METADATA_ERRORS = Partial<IMetadataErrors>;
export const updateMetadataErrors = (metadataErrorProps: IMetadataErrorProps, content: Array<IMetadataError>) => {
  return (dispatch, getState: () => IAppState) => {
    const errorsCopy = deepCopy({...getState().tabs.metadataTab.metadataErrors});
    if (!has(errorsCopy, metadataErrorProps)) {
      console.log('Metadata error type not found', metadataErrorProps);
      return;
    }
    dispatch({type: UPDATE_METADATA_ERRORS, payload: {[metadataErrorProps]: content}});
  };
};

export const resetMetadataErros = () => {
  return (dispatch, getState: () => IAppState) => {
    dispatch(updateMetadataErrors('metadataDetails', []));
    dispatch(updateMetadataErrors('metadataVideo', []));
    dispatch(updateMetadataErrors('metadataAudio', []));
    dispatch(updateMetadataErrors('metadataNonMedia', []));
    dispatch(updateMetadataErrors('metadataImages', []));
    dispatch(updateMetadataErrors('metadataSubtitles', []));
  };
};

export const UPDATE_MARKUPS_LIST_ORDER = 'Tabs/UPDATE_MARKUPS_LIST_ORDER';
export type UPDATE_MARKUPS_LIST_ORDER = IMarkupsOrder;
export const updateMarkupsListOrder = createAction<IMarkupsOrder, IMarkupsOrder>(
  UPDATE_MARKUPS_LIST_ORDER,
  (order: UPDATE_MARKUPS_LIST_ORDER) => order
);

export const UPDATE_METADATA_MASS_UPDATE_FIELDS = 'Tabs/UPDATE_METADATA_MASS_UPDATE_FIELDS';
export type UPDATE_METADATA_MASS_UPDATE_FIELDS = Array<IMetadataMassUpdateFields>;
export const updateMetadataMassUpdateFields = createAction<
  UPDATE_METADATA_MASS_UPDATE_FIELDS,
  UPDATE_METADATA_MASS_UPDATE_FIELDS
>(UPDATE_METADATA_MASS_UPDATE_FIELDS, (fields: UPDATE_METADATA_MASS_UPDATE_FIELDS) => fields);

export const updateMetadataMassUpdateField = (field: IMetadataMassUpdateFields, remove: boolean = false) => {
  return (dispatch: (action: any) => any, getState: () => IAppState) => {
    const {metadataMassUpdateFields} = getState().tabs.metadataTab;
    const payload = [...metadataMassUpdateFields, field].filter(
      (current: IMetadataMassUpdateFields, index: number, list: Array<IMetadataMassUpdateFields>) => {
        if (remove) {
          return field !== current;
        }
        if (current === field) {
          return list.indexOf(field) === index;
        }
        return current;
      }
    );
    dispatch(updateMetadataMassUpdateFields(payload));
  };
};
