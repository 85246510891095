import * as React from 'react';
import {Icon} from 'tt-components/src/Icon';
import {Dropdown} from 'tt-components/src/Dropdown';
import {SubsAssetsList} from './components/SubsAssetsList';
import {AudioAssetsList} from './components/AudioAssetsList';
import {defaultMenuControlBarStyles, DropdownContent} from '../../DropdownContent';
import {ITemplateColors} from '../../../../@types/templateColors';
import {PopoverTailIcon} from '../../../assets/Icons/PopoverTail';
import {IAudioChannelConfiguration} from '../../../../@types/audioChannelConfiguration';
import {IVideoAudioConfiguration, IContentAssetConfiguration} from '../../../state/IVideoState';
import {IEnum} from '../../../../@types/enum';
import {calculateDropdownStyles} from '../../../utils/utils';

import {SubtitlesIcon} from '../../../assets/Icons/Subtitles';
import {ISubtitlesMetadata} from '../../../../@types/subtitlesMetadata';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {ContentAssetsList} from './components/ContentAssetsList';

interface IAudioAndSubsProps {
  selectedSub?: string;
  onSubSelected: (trackId: string) => void;
  templateColors: ITemplateColors;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  audioLanguageEnums: Array<IEnum>;
  audioChannelMapEnums: Array<IEnum>;
  audioChannelConfigTypeEnums: Array<IEnum>;
  languageDialectEnums: Array<IEnum>;
  audioChannelConfigurations: Array<IAudioChannelConfiguration>;
  externalAudioChannelConfigurations: Array<IAudioChannelConfiguration>;
  audioConfiguration: IVideoAudioConfiguration;
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;
  loadingPlaylist: boolean;
  loadingPlaylistError: string;
  loadingAudioConfig: boolean;
  embeddedSubtitlesMetadata: Array<ISubtitlesMetadata>;
  externalSubtitlesMetadata: Array<ISubtitlesMetadata>;
  disabled?: boolean;
  isOpen: boolean; // TODO: Implement approach to manually update popup visibility state
  onShowAudioSubsChange: (isOpen: boolean) => void;
  checkPlaybackProxy: () => void;
  proxyConfiguration: IAudioChannelConfiguration;
  videoConfiguration: IAudioChannelConfiguration;
  imageAssets: Array<PlaylistAsset>;
  nonMediaAssets: Array<PlaylistAsset>;
  userEmail: string;
  userIp: string;
  curationModeEnabled: boolean;
  contentAssetConfiguration: IContentAssetConfiguration;
  updateContentAssetConfiguration: (data: Partial<IContentAssetConfiguration>) => void;
}

export class AudioAndSubs extends React.Component<IAudioAndSubsProps> {
  getSelectedImageAsset = () => {
    return this.props.contentAssetConfiguration.assetType === 'image'
      ? this.props.contentAssetConfiguration.selectedAssetId
      : '';
  };

  getContent = () => {
    const {tooltipBackground} = this.props.templateColors;
    const content = (
      <div className="audio-subtitles-dropdown_content">
        <AudioAssetsList
          title="Audio"
          audioLanguageEnums={this.props.audioLanguageEnums}
          audioChannelMapEnums={this.props.audioChannelMapEnums}
          embeddedAudioChannels={this.props.audioChannelConfigurations}
          externalAudioChannels={this.props.externalAudioChannelConfigurations}
          templateColors={this.props.templateColors}
          audioChannelConfigTypeEnums={this.props.audioChannelConfigTypeEnums}
          audioConfiguration={this.props.audioConfiguration}
          updateAudioConfig={this.props.updateAudioConfig}
          loadingPlaylist={this.props.loadingPlaylist}
          loadingPlaylistError={this.props.loadingPlaylistError}
          loadingAudioConfig={this.props.loadingAudioConfig}
          checkPlaybackProxy={this.props.checkPlaybackProxy}
          proxyConfiguration={this.props.proxyConfiguration}
          videoConfiguration={this.props.videoConfiguration}
          userEmail={this.props.userEmail}
          userIp={this.props.userIp}
          curationModeEnabled={this.props.curationModeEnabled}
          showConfigsAssetHrId
        />
        <div className="audio-subtitles-dropdown_content_delimiter" />
        <SubsAssetsList
          languageDialectEnums={this.props.languageDialectEnums}
          onSubSelected={this.props.onSubSelected}
          selectedSub={this.props.selectedSub}
          templateColors={this.props.templateColors}
          embeddedSubtitlesMetadata={this.props.embeddedSubtitlesMetadata}
          externalSubtitlesMetadata={this.props.externalSubtitlesMetadata}
        />
        <div className="audio-subtitles-dropdown_content_delimiter" />
        <ContentAssetsList
          imageAssets={this.props.imageAssets}
          nonMediaAssets={this.props.nonMediaAssets}
          contentAssetConfiguration={this.props.contentAssetConfiguration}
          templateColors={this.props.templateColors}
          curationModeEnabled={this.props.curationModeEnabled}
          loadingPlaylist={this.props.loadingPlaylist}
          loadingPlaylistError={this.props.loadingPlaylistError}
          loadingAudioConfig={this.props.loadingAudioConfig}
          updateContentAssetConfiguration={this.props.updateContentAssetConfiguration}
        />
      </div>
    );

    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent color={tooltipBackground} content={content} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onAudioSubsOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, null, 65);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      onShowAudioSubsChange
    } = this.props;
    const {main} = this.props.templateColors;
    const hideAudioSubtitlesDropdown = isControlBarShown ? '' : ' op_controls-bar_hidden';
    const closestBodyElement = (videoPlayerContainer && videoPlayerContainer.closest('body')) as HTMLElement;

    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowAudioSubsChange}
        title="Audio, Subtitles and Images"
        elemId="audioSubId"
        buttonComponent={() => (
          <div className="audio-subtitles-dropdown_icon" id="audioSubId">
            <Icon icon={SubtitlesIcon} color={main} size="25px" />
          </div>
        )}
        content={this.getContent()}
        className={`ttc-control-bar-dropdown audio-subtitles-dropdown${hideAudioSubtitlesDropdown}`}
        openAbove={true}
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onAudioSubsOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  minWidth: 170,
  padding: 5,
  width: 644,
  borderWidth: 1,
  marginLeft: 0,
  boxSizing: 'content-box'
};
