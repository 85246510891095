import * as React from 'react';
import XMLViewer from 'react-xml-viewer';
import {Tt2b} from '../../../Typography';
import {getXMLContentFromRemoteResource} from '../../../../utils/utils';

interface IXMLViewerProps {
  xmlPath: string;
  restoring: boolean;
  updateNonMediaRestoring: (restore: boolean) => void;
}

interface IXMLViewerState {
  loading: boolean;
  fetched: string;
}

export class XMLRenderer extends React.PureComponent<IXMLViewerProps, IXMLViewerState> {
  constructor(props: IXMLViewerProps) {
    super(props);

    this.state = {
      loading: false,
      fetched: null
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: IXMLViewerProps) {
    if (prevProps.xmlPath !== this.props.xmlPath) {
      this.init();
    }
  }

  init = async () => {
    await new Promise(resolve => this.setState({loading: true, fetched: null}, resolve));
    if (!this.props.xmlPath) {
      return this.setState({loading: false});
    }
    const fetched = await getXMLContentFromRemoteResource(this.props.xmlPath);
    this.setState({loading: false, fetched});
  };
  getContent = () => {
    if (this.state.loading) {
      return <Tt2b className="xml-viewer-container_text-content" content={`Fetching XML content...`} />;
    }
    if (!this.state.fetched) {
      return <Tt2b className="xml-viewer-container_text-content" content={`Couldn't fetch XML content`} />;
    }
    return (
      <div className="xml-viewer-container_plugin-container">
        <XMLViewer xml={this.state.fetched} theme={XMLViewerTheme} />
      </div>
    );
  };
  render() {
    return <div className={`xml-viewer-container${this.state.fetched ? ` fetched` : ``}`}>{this.getContent()}</div>;
  }
}

const XMLViewerTheme = {
  attributeKeyColor: '#a3c6e3',
  attributeValueColor: '#c2866d',
  tagColor: '#497fab',
  textColor: '#cfcfcf',
  separatorColor: '#808080'
};
