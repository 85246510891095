import * as React from 'react';
import {CollapisbleWithEditModeToggle} from '../../../Collapsible';
import {IErrorLog, IAssetNonMedia} from '../../../../../../../@types/assetDetails';
import {Row, Column, Container} from '../../../Grid';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';
import {getErrorLogByField} from '../../../../utils/helpers';
import {IEnums} from '../../../../../../state/IEnums';
import {LanguageDropdown} from '../../../LanguageDropdown';
import {IEnum} from '../../../../../../../@types/enum';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';

interface INonMediaRecordProps {
  openCollapsibleContent: boolean;
  nonMedia: IAssetNonMedia;
  enums: IEnums;
  closestBody?: HTMLElement;
  tabsDataInEditMode?: boolean;
  errorLogs: Array<IErrorLog>;
  nonMediaMetadataErrors: Array<IMetadataError>;
  updateProp: (nonMediaId: string, prop: string, value: string | number) => void;
  onCollapsibleToggle: (open: boolean) => void;
}

const convertEnums = (enumList: Array<IEnum>): Array<IDropdownOption> =>
  enumList.reduce(
    (acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}],
    []
  );

export class NonMediaRecord extends React.PureComponent<INonMediaRecordProps> {
  getTitle = () => {
    return this.props.nonMedia.type || '...';
  };

  onLanguageSelected = (language: string, country: string) => {
    setTimeout(() => this.props.updateProp(this.props.nonMedia.id, 'language', language), 200);
    setTimeout(() => this.props.updateProp(this.props.nonMedia.id, 'country', country), 200);
  };

  getContent = () => {
    const content = (
      <Row className="nonmedia-record-container_row">
        <Column className="nonmedia-record-container_row_column">
          <Dropdown
            field={{field: 'nonMedia.type', errors: this.props.nonMediaMetadataErrors}}
            search
            disabled={!this.props.tabsDataInEditMode}
            options={convertEnums(this.props.enums.nonMediaType)}
            onSelected={value => this.props.updateProp(this.props.nonMedia.id, 'type', value)}
            selected={this.props.nonMedia.type}
            label="Type"
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('nonMedia.type', this.props.errorLogs)}
          />
        </Column>
        <Column className="nonmedia-record-container_row_column">
          <LanguageDropdown
            field={{field: 'nonMedia.language', errors: this.props.nonMediaMetadataErrors}}
            search
            disabled={!this.props.tabsDataInEditMode}
            languageDialect={this.props.enums.languageDialect}
            onLanguageSelected={this.onLanguageSelected}
            language={this.props.nonMedia.language}
            country={this.props.nonMedia.country}
            label="Language"
            portalNode={this.props.closestBody}
            toLowerCaseCompare
            errorLogs={this.props.errorLogs}
          />
        </Column>
        <Column className="nonmedia-record-container_row_column">
          <Dropdown
            search
            disabled={!this.props.tabsDataInEditMode}
            options={convertEnums(this.props.enums.nonMediaSubType)}
            onSelected={value => this.props.updateProp(this.props.nonMedia.id, 'subType', value)}
            selected={this.props.nonMedia.subType}
            label="Sub Type"
            portalNode={this.props.closestBody}
            errorLog={getErrorLogByField('nonMedia.subType', this.props.errorLogs)}
          />
        </Column>
      </Row>
    );

    return <Container className="nonmedia-record-container">{content}</Container>;
  };

  render() {
    return (
      <CollapisbleWithEditModeToggle
        tabsInEditMode={this.props.tabsDataInEditMode}
        open={this.props.openCollapsibleContent}
        title={this.getTitle()}
        content={this.getContent()}
        onToggle={this.props.onCollapsibleToggle}
      />
    );
  }
}
