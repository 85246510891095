import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import {TriangleIcon} from 'assets/Icons/Triangle';
import {IMarkupsOrder} from '../../../../../../../../interfaces/IMarkupsTab';

interface IOrderCellProps {
  title: string;
  order: IMarkupsOrder;
  onOrderChange: (order: IMarkupsOrder) => void;
}

export class OrderCell extends React.PureComponent<IOrderCellProps> {
  onOrderChange = () => {
    this.props.onOrderChange(this.props.order === 'asc' ? 'desc' : 'asc');
  };

  render() {
    return (
      <div className="order-cell-container">
        <div className="order-cell-container_title">{this.props.title}</div>
        <div
          className={`order-cell-container_order-container${this.props.order === 'asc' ? ' up' : ' down'}`}
          onClick={this.onOrderChange}
        >
          <Icon icon={TriangleIcon} color="#8D919A" size="12px" />
        </div>
      </div>
    );
  }
}
