import * as React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {Icon} from 'tt-components/src/Icon/Icon';
import {Tt4a} from '../../../../components/Typography/Tt4a';
import {Button} from '../../../../components/Button';
import {MarkupsLayer} from '../MarkupsLayer';
import {ProgramTimingsLayer} from '../ProgramsTimingLayer';
import {SubtitlesLayer} from '../SubtitlesLayer';
import {AudioLayer} from '../AudioLayer';
import {ITooltipSegmentData} from '../../interfaces/ITimelineTooltipData';
import {IFrameRate} from 'tt-components';
import {IProgramTimingsInput} from '../../../../../@types/programTimingsInput';
import {ITemplateColors} from '../../../../../@types/templateColors';
import {IEnum} from '../../../../../@types/enum';
import {IVideoAudioConfiguration} from '../../../../state/IVideoState';
import {displayAssetInfo} from '../../../../utils/utils';

import {VideoIcon} from '../../../../assets/Icons/Video';
import {SubtitleIcon} from '../../../../assets/Icons/Subtitle';
import {AudioIcon} from '../../../../assets/Icons/Audio';
import {ImageIcon} from '../../../../assets/Icons/Image';
import {NonMediaIcon} from '../../../../assets/Icons/NonMedia';
import {CloseIcon} from '../../../../assets/Icons/Close';
import {VisibleIcon} from '../../../../assets/Icons/Visible';
import {TimelineRightArrowIcon} from '../../../../assets/Icons/TimelineRightArrow';

interface ITimelineInstanceInnerProps {
  asset: PlaylistAsset;
  programTimingsInput?: IProgramTimingsInput;
  showMarkupsLayer?: boolean;
  showProgramTimingsLayer?: boolean;
  showAudioLayer?: boolean;
  showSubtitlesLayer?: boolean;
  curationModeEnabled?: boolean;
  onAssetHidden: (assetId: string, isHidden: boolean) => void;
}

interface ITimelineInstanceLayersProps {
  secondUnits: number;
  contentWidth: number;
  scrollLeft: number;
  frameRate: IFrameRate;
  selectedSub?: string;
  templateColors?: ITemplateColors;
  onChangeVisibleSubtitle?: (subtitleId: string | number) => void;
  onElementIn: (data: ITooltipSegmentData) => void;
  onElementOut: () => void;
  onSegmentClicked: (time: number) => void;
  disableTimeline?: boolean;
  duration: number;
}

interface ITimelineInstanceAudioLayerProps {
  audioLanguageEnums: Array<IEnum>;
  audioChannelMapEnums: Array<IEnum>;
  audioChannelConfigTypeEnums: Array<IEnum>;
  languageDialectEnums: Array<IEnum>;
  audioConfiguration: IVideoAudioConfiguration;
  updateAudioConfig: (data: Partial<IVideoAudioConfiguration>, enableChannelsOnConfiguration?: boolean) => void;
  loadingPlaylist: boolean;
  loadingPlaylistError: string;
  loadingAudioConfig: boolean;
  checkPlaybackProxy: () => void;
  userEmail: string;
  userIp: string;
}

type ITimelineInstanceProps = ITimelineInstanceInnerProps &
  ITimelineInstanceLayersProps &
  ITimelineInstanceAudioLayerProps;

interface ITimelineInstanceState {
  isCollapsed: boolean;
}

export class TimelineInstance extends React.Component<ITimelineInstanceProps, ITimelineInstanceState> {
  static defaultProps = {
    showMarkupsLayer: true,
    showProgramTimingsLayer: true
  };

  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false
    };
  }

  onToggleContent = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({isCollapsed: !this.state.isCollapsed});
  };

  getTitle = () => {
    const assetType = this.props.asset.getAssetType();
    const icon =
      assetType === 'Video'
        ? VideoIcon
        : assetType === 'Audio'
        ? AudioIcon
        : assetType === 'Subtitles'
        ? SubtitleIcon
        : assetType === 'Image'
        ? ImageIcon
        : assetType === 'Non Media'
        ? NonMediaIcon
        : null;
    const unregistered = !this.props.asset.isRegistered && this.props.curationModeEnabled ? ` unregistered` : ``;
    const title = displayAssetInfo(this.props.asset);
    return (
      <div className="timeline-instance-container_header_title" title={title}>
        {icon && <Icon icon={icon} size="15px" color="#1F72F2" />}
        <Tt4a className={`timeline-instance-container_header_title_text${unregistered}`} content={title} />
        <CopyToClipboard text={this.props.asset.assetDetails.name}>
          <Button
            small
            content="Copy"
            className="timeline-instance-container_header_title_button"
            onClick={e => e.stopPropagation()}
          />
        </CopyToClipboard>
      </div>
    );
  };

  onHide = (e: React.MouseEvent<HTMLDivElement>) => {
    // NOTE: Use this approach if we need to not propagate click to toggle component
    if ((e.currentTarget as HTMLDivElement).dataset.actionIcon) {
      e.stopPropagation();
    }

    this.props.onAssetHidden(this.props.asset.assetId, true);
  };

  getActions = () => {
    const rotate = this.state.isCollapsed ? ` rotate` : ``;
    return (
      <div className="timeline-instance-container_header_actions">
        {this.props.curationModeEnabled && (
          <div
            data-action-icon="true"
            className="timeline-instance-container_header_actions_icon"
            onClick={this.onHide}
          >
            <Icon icon={CloseIcon} size="12px" color="#FFFFFF" />
          </div>
        )}
        {this.props.curationModeEnabled && (
          <div data-action-icon="true" className="timeline-instance-container_header_actions_icon">
            <Icon icon={VisibleIcon} data-action-icon size="18px" color="#FFFFFF" />
          </div>
        )}
        <div className={`timeline-instance-container_header_actions_icon${rotate}`}>
          <Icon icon={TimelineRightArrowIcon} size="12px" color="#FFFFFF" />
        </div>
      </div>
    );
  };

  defineProgramTimingsEventsList = () => {
    if (this.props.programTimingsInput && this.props.programTimingsInput === 'metadata') {
      // TODO: Manage case for Metadata Program Timings when implemented
      return [];
    } else {
      return this.props.asset.events || [];
    }
  };

  getContent = () => {
    if (this.allLayersDisabeld()) {
      return <Tt4a content="All layers are disabled" style={{color: '#FFFFFF'}} />;
    }
    const layers = [];
    if (this.props.showMarkupsLayer) {
      layers.push(
        <MarkupsLayer
          key={1}
          secondUnits={this.props.secondUnits}
          contentWidth={this.props.contentWidth}
          scrollLeft={this.props.scrollLeft}
          events={this.props.asset.events || []}
          frameRate={this.props.frameRate}
          onElementIn={this.props.onElementIn}
          onElementOut={this.props.onElementOut}
          onSegmentClicked={this.props.disableTimeline ? time => {} : this.props.onSegmentClicked}
        />
      );
    }
    if (this.props.showProgramTimingsLayer) {
      layers.push(
        <ProgramTimingsLayer
          key={2}
          secondUnits={this.props.secondUnits}
          contentWidth={this.props.contentWidth}
          scrollLeft={this.props.scrollLeft}
          events={this.defineProgramTimingsEventsList()}
          frameRate={this.props.frameRate}
          onElementIn={this.props.onElementIn}
          onElementOut={this.props.onElementOut}
          onSegmentClicked={this.props.disableTimeline ? time => {} : this.props.onSegmentClicked}
        />
      );
    }
    if (this.props.showAudioLayer && this.props.asset.isAudioAsset()) {
      layers.push(
        <AudioLayer
          key={4}
          assetId={this.props.asset.assetId}
          configurations={this.props.asset.audio}
          audioLanguageEnums={this.props.audioLanguageEnums}
          audioChannelMapEnums={this.props.audioChannelMapEnums}
          audioChannelConfigTypeEnums={this.props.audioChannelConfigTypeEnums}
          templateColors={this.props.templateColors}
          audioConfiguration={this.props.audioConfiguration}
          updateAudioConfig={this.props.updateAudioConfig}
          loadingPlaylist={this.props.loadingPlaylist}
          loadingPlaylistError={this.props.loadingPlaylistError}
          loadingAudioConfig={this.props.loadingAudioConfig}
          checkPlaybackProxy={this.props.checkPlaybackProxy}
          userEmail={this.props.userEmail}
          userIp={this.props.userIp}
          contentWidth={this.props.contentWidth}
          scrollLeft={this.props.scrollLeft}
          duration={this.props.duration}
          curationModeEnabled={this.props.curationModeEnabled}
        />
      );
    }
    if (this.props.showSubtitlesLayer && this.props.asset.isSubtitlesAsset()) {
      const onSubtitleSelected = this.props.onChangeVisibleSubtitle
        ? this.props.onChangeVisibleSubtitle
        : (subtitleId: number | string) => {};
      layers.push(
        <SubtitlesLayer
          key={3}
          languageDialect={this.props.languageDialectEnums}
          secondUnits={this.props.secondUnits}
          contentWidth={this.props.contentWidth}
          subtitles={this.props.asset.subtitles}
          selectedSub={this.props.selectedSub}
          templateColors={this.props.templateColors}
          onSubtitleSelected={onSubtitleSelected}
          scrollLeft={this.props.scrollLeft}
          onElementIn={this.props.onElementIn}
          onElementOut={this.props.onElementOut}
          onSegmentClicked={this.props.disableTimeline ? time => {} : this.props.onSegmentClicked}
        />
      );
    }
    return layers;
  };

  allLayersDisabeld = () => {
    return (
      !this.props.showMarkupsLayer &&
      !this.props.showProgramTimingsLayer &&
      !this.props.showAudioLayer &&
      !this.props.showSubtitlesLayer
    );
  };

  render() {
    const visible = this.state.isCollapsed ? ` visible` : ``;
    const empty = this.allLayersDisabeld() ? ` empty` : ``;
    return (
      <div className="timeline-instance-container">
        <div className="timeline-instance-container_header" onClick={this.onToggleContent}>
          {this.getTitle()}
          {this.getActions()}
        </div>
        <div className={`timeline-instance-container_content${visible}${empty}`}>{this.getContent()}</div>
      </div>
    );
  }
}
