import {createAction} from 'redux-actions';
import {IWatermarkPosition} from '../../@types/watermarkPosition';
import {IAppState} from 'state/IAppState';
import {IResponse} from '../../@types/response';
import {updateSwitchingCurrentTime, updatePlaylistUrl, switchPlaying, updatePlaybackProxyState} from './video';
import {generatePlaybackRequestBody, generateTextOverlay} from 'utils/utils';
import {triggerNotification} from 'tt-components/src/Notifications/notifications';
import {IAudioChannel} from '../../@types/audioChannelConfiguration';
import {onePlayerService} from 'data';
import LocalStorageService from '../services/LocalStorageService';
import {PlaylistAsset} from '../models/PlaylistAsset/PlaylistAsset';

const localStorageService = new LocalStorageService();
export const SHOW_DIAGNOSTICS_BOX = 'Overlay/SHOW_DIAGNOSTICS_BOX';
export type SHOW_DIAGNOSTICS_BOX = boolean;
export const showDiagnosticsBox = createAction<SHOW_DIAGNOSTICS_BOX, SHOW_DIAGNOSTICS_BOX>(
  SHOW_DIAGNOSTICS_BOX,
  (isBoxShow: SHOW_DIAGNOSTICS_BOX) => isBoxShow
);

export const SHOW_SETTINGS = 'Overlay/SHOW_SETTINGS';
export type SHOW_SETTINGS = boolean;
export const showSettings = createAction<SHOW_SETTINGS, SHOW_SETTINGS>(
  SHOW_SETTINGS,
  (isSettingsShow: SHOW_SETTINGS) => isSettingsShow
);

export const SHOW_VOLUME_CONTROL = 'Overlay/SHOW_VOLUME_CONTROL';
export type SHOW_VOLUME_CONTROL = boolean;
export const showVolumeControl = createAction<SHOW_VOLUME_CONTROL, SHOW_VOLUME_CONTROL>(
  SHOW_VOLUME_CONTROL,
  (isVolumeControlShow: SHOW_VOLUME_CONTROL) => isVolumeControlShow
);

export const SHOW_GO_TO = 'Overlay/SHOW_GO_TO';
export type SHOW_GO_TO = boolean;
export const showGoTo = createAction<SHOW_GO_TO, SHOW_GO_TO>(SHOW_GO_TO, (isGoToShow: SHOW_GO_TO) => isGoToShow);

export const SHOW_AUDIO_SUBS = 'Overlay/SHOW_AUDIO_SUBS';
export type SHOW_AUDIO_SUBS = boolean;
export const showAudioSubs = createAction<SHOW_AUDIO_SUBS, SHOW_AUDIO_SUBS>(
  SHOW_AUDIO_SUBS,
  (isAudioSubsShow: SHOW_AUDIO_SUBS) => isAudioSubsShow
);

export const SHOW_PLAYER_SPEED = 'Overlay/SHOW_PLAYER_SPEED';
export type SHOW_PLAYER_SPEED = boolean;
export const showPlayerSpeed = createAction<SHOW_PLAYER_SPEED, SHOW_PLAYER_SPEED>(
  SHOW_PLAYER_SPEED,
  (isPlayerSpeedShow: SHOW_PLAYER_SPEED) => isPlayerSpeedShow
);

export const SHOW_LOOP_CONTROL = 'Overlay/SHOW_LOOP_CONTROL';
export type SHOW_LOOP_CONTROL = boolean;
export const showLoopControl = createAction<SHOW_LOOP_CONTROL, SHOW_LOOP_CONTROL>(
  SHOW_LOOP_CONTROL,
  (isLoopControlShow: SHOW_LOOP_CONTROL) => isLoopControlShow
);

export const SET_LOOP_TIME_IN_SELECTED = 'Overlay/SET_LOOP_TIME_IN_SELECTED';
export const setLoopTimeInSelected = () => {
  return dispatch => {
    dispatch({type: SET_LOOP_TIME_IN_SELECTED});
  };
};

export const SET_LOOP_TIME_OUT_SELECTED = 'Overlay/SET_LOOP_TIME_OUT_SELECTED';
export const setLoopTimeOutSelected = () => {
  return dispatch => {
    dispatch({type: SET_LOOP_TIME_OUT_SELECTED});
  };
};

export const ENABLE_DIAGNOSTICS_PANEL = 'Overlay/ENABLE_DIAGNOSTICS_BOX';
export type ENABLE_DIAGNOSTICS_PANEL = boolean;
export const enableDiagnosticsPanel = createAction<ENABLE_DIAGNOSTICS_PANEL, ENABLE_DIAGNOSTICS_PANEL>(
  ENABLE_DIAGNOSTICS_PANEL,
  (isPanelEnabled: ENABLE_DIAGNOSTICS_PANEL) => isPanelEnabled
);

export const CHANGE_WATERMARK_POSITION = 'Overlay/CHANGE_WATERMARK_POSITION';
export type CHANGE_WATERMARK_POSITION = IWatermarkPosition;
export const changeWatermarkPosition = (watermarkPosition: CHANGE_WATERMARK_POSITION) => {
  return async (dispatch, getState: () => IAppState) => {
    const {id, url, proxy, assets, video} = getState().video.playlist;
    const {selectedConfig, checkedChannels} = getState().video.audioConfiguration;
    if (selectedConfig === null) {
      console.log('Selected configuration set to NULL');
      return;
    }
    const asset = PlaylistAsset.filter.findConfiguration(assets, selectedConfig, proxy, video);
    if (!asset) {
      triggerNotification(
        {
          type: 'warning',
          title: 'Playback Warning',
          message: `Sorry for the inconvenience, but couldn't found configuration data!`,
          delay: 2500
        },
        null
      );
      return;
    }

    let response: IResponse;
    dispatch(updateSwitchingCurrentTime(true));

    if (!asset.proxyUrl) {
      const isVideoAsset = asset.isVideoAudioConfiguration;
      const channels = checkedChannels[selectedConfig] || [];
      const audioChannels = asset.trackDetail.filter((audio: IAudioChannel) => channels.includes(audio.track));
      const bodyRequest = generatePlaybackRequestBody(
        audioChannels,
        isVideoAsset ? null : asset.assetId,
        generateTextOverlay(`${getState().configuration.userEmail}`, watermarkPosition),
        isVideoAsset ? asset.assetId : null
      );
      response = await onePlayerService.getPlaybackUrlAPI(bodyRequest, id);
    } else {
      response = {success: true, data: asset.proxyUrl};
    }
    if (response.success) {
      // Case we when we have configurations with already configured URL
      if (url === response.data) {
        triggerNotification(
          {
            type: 'info',
            title: 'Playback',
            message: `Configuration already defined!`,
            delay: 2500
          },
          null
        );
        dispatch(updateSwitchingCurrentTime(false));
        return;
      }
      localStorageService.set('watermarkPosition', watermarkPosition);
      dispatch({type: CHANGE_WATERMARK_POSITION, payload: watermarkPosition});
      dispatch(updatePlaylistUrl(response.data));
      dispatch(switchPlaying(false));
    } else {
      console.log(response.error);
      triggerNotification(
        {
          type: 'error',
          title: 'Playback Error',
          message: `Couldn't retrieve valid audio configuration!`,
          delay: 2500
        },
        null
      );
      dispatch(updateSwitchingCurrentTime(false));
      dispatch(updatePlaybackProxyState('Error'));
    }
    return;
  };
};

export const changeWatermarkUIPosition = (watermarkPosition: IWatermarkPosition) => {
  return (dispatch, getState: () => IAppState) => {
    dispatch({type: CHANGE_WATERMARK_POSITION, payload: watermarkPosition});
  };
};

export const SHOW_PLAYBACK_SETUP_OVERLAY = 'Overlay/SHOW_PLAYBACK_SETUP_OVERLAY';
export type SHOW_PLAYBACK_SETUP_OVERLAY = boolean;
export const showPlaybackSetupOverlay = createAction<SHOW_PLAYBACK_SETUP_OVERLAY, SHOW_PLAYBACK_SETUP_OVERLAY>(
  SHOW_PLAYBACK_SETUP_OVERLAY,
  (showOverlay: SHOW_PLAYBACK_SETUP_OVERLAY) => showOverlay
);

export const SHOW_CONFORMANCE_OVERLAY = 'Overlay/SHOW_CONFORMANCE_OVERLAY';
export type SHOW_CONFORMANCE_OVERLAY = boolean;
export const showConformanceOverlay = createAction<SHOW_CONFORMANCE_OVERLAY, SHOW_CONFORMANCE_OVERLAY>(
  SHOW_CONFORMANCE_OVERLAY,
  (show: SHOW_CONFORMANCE_OVERLAY) => show
);

export const UPDATE_TTMAN_FRAME_RATE_ASSET_ID = 'Overlay/UPDATE_TTMAN_FRAME_RATE_ASSET_ID';
export type UPDATE_TTMAN_FRAME_RATE_ASSET_ID = string;
export const updateTTmanFrameRateAssetId = createAction<
  UPDATE_TTMAN_FRAME_RATE_ASSET_ID,
  UPDATE_TTMAN_FRAME_RATE_ASSET_ID
>(UPDATE_TTMAN_FRAME_RATE_ASSET_ID, (assetId: UPDATE_TTMAN_FRAME_RATE_ASSET_ID) => assetId);

export const ENABLE_TTMAN_FRAME_RATE_UPDATING = 'Overlay/ENABLE_TTMAN_FRAME_RATE_UPDATING';
export type ENABLE_TTMAN_FRAME_RATE_UPDATING = boolean;
export const enableTTmanFrameRateUpdating = createAction<
  ENABLE_TTMAN_FRAME_RATE_UPDATING,
  ENABLE_TTMAN_FRAME_RATE_UPDATING
>(ENABLE_TTMAN_FRAME_RATE_UPDATING, (show: ENABLE_TTMAN_FRAME_RATE_UPDATING) => show);

export const UPDATE_TTMAN_DROP_FRAME_FORMAT = 'Overlay/UPDATE_TTMAN_DROP_FRAME_FORMAT';
export type UPDATE_TTMAN_DROP_FRAME_FORMAT = boolean;
export const updateTTmanDropFrameFormat = createAction<UPDATE_TTMAN_DROP_FRAME_FORMAT, UPDATE_TTMAN_DROP_FRAME_FORMAT>(
  UPDATE_TTMAN_DROP_FRAME_FORMAT,
  (show: UPDATE_TTMAN_DROP_FRAME_FORMAT) => show
);

export const clearTTmanFrameRateUpdate = () => {
  return dispatch => {
    dispatch(updateTTmanFrameRateAssetId(null));
    dispatch(enableTTmanFrameRateUpdating(false));
    dispatch(updateTTmanDropFrameFormat(false));
  };
};

export const enableTTmanFrameRateOverlay = (assetId: string, dropFrame: boolean) => {
  return dispatch => {
    dispatch(updateTTmanFrameRateAssetId(assetId));
    dispatch(enableTTmanFrameRateUpdating(dropFrame));
    dispatch(updateTTmanDropFrameFormat(false));
  };
};

export const ENABLE_IMAGE_OVERLAY = 'Overlay/ENABLE_IMAGE_OVERLAY';
export type ENABLE_IMAGE_OVERLAY = boolean;
export const enableImageOverlay = createAction<ENABLE_IMAGE_OVERLAY, ENABLE_IMAGE_OVERLAY>(
  ENABLE_IMAGE_OVERLAY,
  (show: boolean) => show
);
