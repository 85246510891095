import {IMetadataErrorProps} from '../interfaces/IMetadataTab';
import {PlaylistAsset} from '../../../models/PlaylistAsset/PlaylistAsset';

export type IAssetFieldErrorMapping = {
  [x: string]: {
    required?: ((asset: PlaylistAsset) => boolean) | boolean;
    native: boolean;
    errorSection: IMetadataErrorProps;
    errorLabels: Array<string>;
  };
};

const frameRateRequired = (asset: PlaylistAsset) => {
  return asset ? asset.isSubtitlesAsset() || asset.isVideoAsset() : false;
};

export const FieldErrorMapping = Object.freeze({
  titleId: {
    required: true,
    native: false,
    errorSection: 'metadataDetails',
    errorLabels: ['titleId']
  },
  versionId: {
    required: true,
    native: true,
    errorSection: 'metadataDetails',
    errorLabels: ['UnregisteredAssetPatch.VersionId', 'AssetRegistrationPatch.VersionId', 'versionId']
  },
  contentType: {
    required: true,
    native: true,
    errorSection: 'metadataDetails',
    errorLabels: ['contentType']
  },
  frameRate: {
    required: frameRateRequired,
    native: false,
    errorSection: 'metadataDetails',
    errorLabels: ['frameRate']
  },
  'videos.subType': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.subType']
  },
  'videos.videoCodec': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.videoCodec']
  },
  'videos.videoDetail.displayAspectRatio': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.videoDetail.displayAspectRatio']
  },
  'videos.videoDetail.pictureAspectRatio': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.videoDetail.pictureAspectRatio']
  },
  'videos.frameSizeHeight': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.frameSizeHeight']
  },
  'videos.frameSizeWidth': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.frameSizeWidth']
  },
  'videos.videoDetail.colorEncoding.primaries': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.videoDetail.colorEncoding.primaries']
  },
  'videos.videoDetail.st2086.primaries': {
    required: false,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['Videos[0].VideoDetails.ST2086.Primaries']
  },
  'videos.pictureFormat': {
    required: true,
    native: true,
    errorSection: 'metadataVideo',
    errorLabels: ['videos.pictureFormat']
  },
  'audio.channelConfig': {
    required: true,
    native: true,
    errorSection: 'metadataAudio',
    errorLabels: ['audio.channelConfig']
  },
  'audio.language': {
    required: true,
    native: true,
    errorSection: 'metadataAudio',
    errorLabels: ['audio.language']
  },
  'audio.trackDetail.channelMap': {
    required: true,
    native: true,
    errorSection: 'metadataAudio',
    errorLabels: ['audio.trackDetail.channelMap']
  },
  'audio.trackDetail.subType': {
    required: true,
    native: true,
    errorSection: 'metadataAudio',
    errorLabels: ['audio.trackDetail.subType']
  },
  'images.graphicsType': {
    required: true,
    native: true,
    errorSection: 'metadataImages',
    errorLabels: ['images.graphicsType']
  },
  'images.language': {
    required: true,
    native: true,
    errorSection: 'metadataImages',
    errorLabels: ['images.language']
  },
  'images.frameSizeHeight': {
    required: true,
    native: true,
    errorSection: 'metadataImages',
    errorLabels: ['images.frameSizeHeight']
  },
  'images.frameSizeWidth': {
    required: true,
    native: true,
    errorSection: 'metadataImages',
    errorLabels: ['images.frameSizeWidth']
  },
  'nonMedia.type': {
    required: true,
    native: true,
    errorSection: 'metadataNonMedia',
    errorLabels: ['nonMedia.type']
  },
  'nonMedia.language': {
    required: true,
    native: true,
    errorSection: 'metadataNonMedia',
    errorLabels: ['nonMedia.language']
  },
  'subtitles.language': {
    required: true,
    native: true,
    errorSection: 'metadataSubtitles',
    errorLabels: ['subtitles.language']
  }
} as IAssetFieldErrorMapping);
