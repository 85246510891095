import * as React from 'react';
import {VideoConfig} from './components/VideoConfigurations';
import {IEnums} from '../../../../state/IEnums';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IErrorLog, IAssetVideo} from '../../../../../@types/assetDetails';
import {Tt2b} from '../../../../components/Typography/Tt2b';
import {deepCopy} from '../../utils/helpers';
import {IMetadataError} from '../../../../../@types/metadataErrors';
import {FabButton} from '../../../../components/FabButton';

import {PlusIcon} from '../../../../assets/Icons/Plus';

interface IVideoProps {
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  getEvents: () => void;
  updatedAssetDetails: Partial<IAssetDetails>;
  selectedAsset: PlaylistAsset;
  videoMetadataErrors: Array<IMetadataError>;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
}

export class Video extends React.Component<IVideoProps> {
  getAssetData = () => {
    const updateAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const clonePlaylistAsset = this.props.selectedAsset
      ? PlaylistAsset.parsing.parseSinglePlainObjectToInstance(deepCopy({...this.props.selectedAsset}))
      : null;
    if (clonePlaylistAsset) {
      clonePlaylistAsset.updateAssetDetails(updateAssetDetails);
    }
    return clonePlaylistAsset ? clonePlaylistAsset.assetDetails : {...updateAssetDetails};
  };

  hasVideoConfigurations = () => {
    const assetDetails = this.getAssetData();
    return assetDetails.videos && Array.isArray(assetDetails.videos) ? !!assetDetails.videos.length : false;
  };

  onAddEmptyVideoConfiguration = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.getAssetData()});
    const videos = [
      ...(assetDetails.videos || []).reduce((acc: Array<IAssetVideo>, video: IAssetVideo) => {
        return [...acc, video];
      }, []),
      PlaylistAsset.defaults.videoMetadataDefault
    ];
    this.props.updatePartialAssetDetails({...updatedAssetDetails, videos});
  };

  render() {
    return (
      <div className="video-details-container">
        {this.hasVideoConfigurations() ? (
          <VideoConfig
            selectedAsset={this.props.selectedAsset}
            assetDetails={this.getAssetData()}
            updatedAssetDetails={this.props.updatedAssetDetails}
            updatePartialAssetDetails={this.props.updatePartialAssetDetails}
            enums={this.props.enums}
            getEvents={this.props.getEvents}
            videoMetadataErrors={this.props.videoMetadataErrors}
            tabsDataInEditMode={this.props.tabsDataInEditMode}
            closestBody={this.props.closestBody}
            errorLogs={this.props.errorLogs}
          />
        ) : (
          <Tt2b
            className="video-details-container_empty"
            content="No Video configurations are defined for this asset"
          />
        )}
        {this.props.tabsDataInEditMode && (
          <div className="video-details-container_add-button-container" title="Add new video configuration">
            <FabButton
              className="video-details-container_add-button-container_button"
              icon={PlusIcon}
              iconSize="15px"
              onClick={this.onAddEmptyVideoConfiguration}
            />
          </div>
        )}
      </div>
    );
  }
}
