import * as React from 'react';
import {ICollapsibleProps} from '../../components/Collapsible';

interface IWithEditModeToggleProps extends ICollapsibleProps {
  tabsInEditMode: boolean;
}

export const withEditModeToggle = (WrapperElement: React.ElementType) => {
  return class extends React.PureComponent<IWithEditModeToggleProps> {
    componentDidUpdate(prevProps: IWithEditModeToggleProps) {
      if (prevProps.tabsInEditMode !== this.props.tabsInEditMode && this.props.tabsInEditMode && this.props.onToggle) {
        this.props.onToggle(true);
      }
    }

    render() {
      const {tabsInEditMode, ...props} = this.props;
      return <WrapperElement {...props} />;
    }
  };
};
