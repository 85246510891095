import * as React from 'react';
import {AudioConfiguration} from './components/AudioConfiguration';
import {AudioConfigurationEditable} from './components/AudioConfigurationEditable';
import {CollapisbleWithEditModeToggle} from '../../../Collapsible';
import {defineAudioConfigurationTitle} from '../../../../../../utils/utils';
import {IAudioChannelConfiguration} from '../../../../../../../@types/audioChannelConfiguration';
import {IAssetDetails, IAudioData, IErrorLog, IAudioDataTrackDetail} from '../../../../../../../@types/assetDetails';
import {Tt2b} from '../../../../../../components/Typography';
import {IEnums} from 'state/IEnums';
import {AudioConfigurationHeader} from './components/AudioConfigurationHeader';
import {deepCopy, updateAssetDetailsAudioProp} from '../../../../utils/helpers';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';
import {PlaylistAsset} from '../../../../../../models/PlaylistAsset/PlaylistAsset';
import {FabButton} from '../../../../../../components/FabButton';
import {PlusIcon} from '../../../../../../assets/Icons/Plus';

interface IAudioConfigurationsProps {
  selectedAsset: PlaylistAsset;
  audioMetadata: Array<IAudioChannelConfiguration>;
  assetDetails: Partial<IAssetDetails>;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  loading: boolean;
  error: string;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  audioMetadataErrors: Array<IMetadataError>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

interface IAudioConfigurationsState {
  openAudioDetails: boolean;
}

export class AudioConfigurations extends React.PureComponent<IAudioConfigurationsProps, IAudioConfigurationsState> {
  constructor(props) {
    super(props);

    this.state = {
      openAudioDetails: this.props.tabsDataInEditMode
    };
  }
  updateAudioProp = (configId: string, name: string, value: string | number) => {
    const data = value !== '' ? value : null;
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const audio = updateAssetDetailsAudioProp(this.props.assetDetails.audio, name, data, configId);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  onCollapseToggle = (openAudioDetails: boolean) => {
    this.setState({openAudioDetails});
  };

  onChannelLanguageUpdated = (configId: string, language: string, country: string) => {
    setTimeout(() => this.updateAudioProp(configId, 'language', language), 200);
    setTimeout(() => this.updateAudioProp(configId, 'country', country), 200);
  };

  onChannelConfigUpdated = (configId: string, channelConfig: string) => {
    this.updateAudioProp(configId, 'channelConfig', channelConfig);
  };

  getAudioConfigs = () => {
    return this.props.audioMetadata.reduce((acc: Array<JSX.Element>, config: IAudioChannelConfiguration, index) => {
      const textTitle = defineAudioConfigurationTitle(
        config,
        this.props.enums.language,
        this.props.enums.channelConfigType
      );
      const {audio, content} = this.getConfigurationContent(config);
      const title = this.props.tabsDataInEditMode ? (
        <AudioConfigurationHeader
          audio={audio}
          languageDialect={this.props.enums.languageDialect}
          channelConfigEnums={this.props.enums.channelConfig}
          closestBody={this.props.closestBody}
          style={{flex: 1}}
          onChannelConfigUpdated={this.onChannelConfigUpdated}
          onChannelLanguageUpdated={this.onChannelLanguageUpdated}
          errorLogs={this.props.errorLogs}
          audioMetadataErrors={this.props.audioMetadataErrors}
          onRemoveAudioConfiguration={this.onRemoveAudioConfiguration}
        />
      ) : (
        textTitle
      );
      const blockTriggerOnSelectores = this.props.tabsDataInEditMode
        ? ['.dropdown-ui-container', '.dropdown-content', '.icon-inner-container']
        : [];
      const element = (
        <CollapisbleWithEditModeToggle
          tabsInEditMode={this.props.tabsDataInEditMode}
          blockTriggerOnSelectores={blockTriggerOnSelectores}
          title={title}
          content={content}
          onToggle={this.onCollapseToggle}
          open={this.state.openAudioDetails}
          key={index}
          initOpenState={this.props.tabsDataInEditMode}
        />
      );
      return [...acc, element];
    }, []);
  };

  onBreak = (breakUp: boolean, configId: string, trackIndex: number) => {
    let audioCopy = deepCopy([...(this.props.assetDetails.audio || [])]);
    const configIndex = audioCopy.findIndex((audio: IAudioData) => audio.id === configId);
    const currentAudioConfig = audioCopy[configIndex] || null;
    const prevAudioConfig = audioCopy[configIndex - 1] || null;
    const nextAudioConfig = audioCopy[configIndex + 1] || null;
    const tracksToMove: Array<IAudioDataTrackDetail> = [];
    if (!currentAudioConfig) {
      return console.log(`Couldn't find audioConfig from asset audio configuration: `, configId);
    }
    currentAudioConfig.trackDetail = currentAudioConfig.trackDetail.reduce(
      (acc: Array<IAudioDataTrackDetail>, track: IAudioDataTrackDetail, index: number) => {
        if ((breakUp && index === 0) || (!breakUp && index >= trackIndex)) {
          tracksToMove.push(track);
          return [...acc];
        }
        return [...acc, track];
      },
      []
    );
    let tracksMoved = false;
    audioCopy = audioCopy.reduce((acc: Array<IAudioData>, audio: IAudioData) => {
      if (audio.id === currentAudioConfig.id && !currentAudioConfig.trackDetail.length) {
        return [...acc];
      }
      if (breakUp && prevAudioConfig && prevAudioConfig.id === audio.id) {
        audio.trackDetail.push(...tracksToMove);
        tracksMoved = true;
      }
      if (!breakUp && nextAudioConfig && nextAudioConfig.id === audio.id) {
        audio.trackDetail.unshift(...tracksToMove);
        tracksMoved = true;
      }
      return [...acc, audio];
    }, []);

    // In case the tasks are not marked as moved that means that there are no prev/next configurations
    // so we need to append/prepend accordingly new audio configurations to the audio field
    if (!tracksMoved) {
      const newAudioConfig: IAudioData = {
        ...PlaylistAsset.defaults.audioMetadataDefault,
        trackDetail: tracksToMove
      };
      if (breakUp) {
        audioCopy.unshift(newAudioConfig);
      } else {
        audioCopy.push(newAudioConfig);
      }
    }
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio: audioCopy});
  };

  getConfigurationContent = (config: IAudioChannelConfiguration) => {
    const audio = (this.props.assetDetails.audio || []).find((audio: IAudioData) => audio.id === config.id);
    const content = this.props.tabsDataInEditMode ? (
      <AudioConfigurationEditable
        enums={this.props.enums}
        isCollapseOpen={this.state.openAudioDetails}
        audio={audio}
        assetDetails={this.props.assetDetails}
        updatePartialAssetDetails={this.props.updatePartialAssetDetails}
        updatedAssetDetails={this.props.updatedAssetDetails}
        closestBody={this.props.closestBody}
        errorLogs={this.props.errorLogs}
        onBreak={this.onBreak}
        audioMetadataErrors={this.props.audioMetadataErrors}
      />
    ) : (
      <AudioConfiguration data={config} enums={this.props.enums} />
    );
    return {audio, content};
  };

  renderComponentContent = () => {
    if (this.props.loading || this.props.error) {
      return (
        <Tt2b
          className="metadata-audio-configurations_data-tables_empty"
          content={this.props.loading ? 'Loading audio configurations from playlist...' : this.props.error}
        />
      );
    }

    if (this.props.audioMetadata.length) {
      return this.getAudioConfigs();
    }

    return (
      <Tt2b
        className="metadata-audio-configurations_data-tables_empty"
        content="No Audio Channel Configurations are defined"
      />
    );
  };

  onRemoveAudioConfiguration = (configId: string) => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.props.assetDetails});
    const audio = (assetDetails.audio || []).reduce((acc: Array<IAudioData>, audio: IAudioData) => {
      if (audio.id === configId) {
        return [...acc];
      }
      return [...acc, audio];
    }, []);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  onAddEmptyAudioConfiguration = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.props.assetDetails});
    const audio = [
      ...(assetDetails.audio || []).reduce((acc: Array<IAudioData>, audio: IAudioData) => {
        return [...acc, audio];
      }, []),
      PlaylistAsset.defaults.audioMetadataDefault
    ];
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  render() {
    return (
      <div className="metadata-audio-configurations">
        <div className="metadata-audio-configurations_data-tables">{this.renderComponentContent()}</div>
        {this.props.tabsDataInEditMode && (
          <div className="metadata-audio-configurations_add-button-container" title="Add new audio configuration">
            <FabButton
              className="metadata-audio-configurations_add-button-container_button"
              icon={PlusIcon}
              iconSize="15px"
              onClick={this.onAddEmptyAudioConfiguration}
            />
          </div>
        )}
      </div>
    );
  }
}
