import {createSelector} from 'reselect';
import {IAppState} from '../state/IAppState';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {IAppConfig} from '../../@types/appConfig';
import {IMarkupEvent} from '../../@types/markupEvent';
import {IMarkupsError} from '../../@types/markupsError';

export const getGroupErrorsSelector = (data: {
  errors: Array<IMarkupsError>;
  selectedEventGroup: string;
}): Array<IMarkupsError> => data.errors;
export const getSelectedEventGroupSelector = (data: {
  errors: Array<IMarkupsError>;
  selectedEventGroup: string;
}): string => data.selectedEventGroup;
export const getMarkupsEventsSelector = (events: Array<IMarkupEvent>): Array<IMarkupEvent> => events;
export const getPlaylistAssetsSelector = (state: IAppState): Array<PlaylistAsset> => state.video.playlist.assets;
export const getPlaylistSelectedAssetIdSelector = (state: IAppState): string => state.video.playlist.selectedAssetId;
export const getConfigurations = (state: IAppState) => state.configuration;
export const getTTmanSubtitleAssetId = (state: IAppState) =>
  state.overlays.ttManFrameRateOverlay.ttManFrameRateUpdateAssetId || null;

export const getPlaylistSelectedAsset = createSelector(
  [getPlaylistAssetsSelector, getPlaylistSelectedAssetIdSelector],
  (assets: Array<PlaylistAsset>, assetId: string) => PlaylistAsset.filter.getPlaylistAsset(assets, assetId)
);

export const getTTmanPlaylistAsset = createSelector(
  [getPlaylistAssetsSelector, getTTmanSubtitleAssetId],
  (assets: Array<PlaylistAsset>, assetId: string) =>
    assetId ? PlaylistAsset.filter.getPlaylistAsset(assets, assetId) : null
);

export const getTabsConfigurations = createSelector(
  [getConfigurations],
  (configurations: IAppConfig) => ({
    templateColors: configurations.templateColors,
    containerId: configurations.externalControlsElementId,
    curationModeEnabled: configurations.curationModeEnabled
  })
);

export const getEmbeddedAudioConfigurations = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getEmbeddedAudioConfigurations(assets)
);

export const getExternalAudioConfigurations = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getExternalAudioConfigurations(assets)
);

export const getEmbeddedSubtitles = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getEmbeddedSubtitles(assets)
);

export const getExternalSubtitles = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getExternalSubtitles(assets)
);

export const getImageAssets = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getAllImages(assets)
);

export const getNonMediaAssets = createSelector(
  [getPlaylistAssetsSelector],
  (assets: Array<PlaylistAsset>) => PlaylistAsset.filter.getAllNonMedias(assets)
);

export const getMarkupsEvents = createSelector(
  [getMarkupsEventsSelector],
  (events: Array<IMarkupEvent>) => events
);

export const getGroupErrors = createSelector(
  [getGroupErrorsSelector, getSelectedEventGroupSelector],
  (errors: Array<IMarkupsError>, selectedEventGroup: string) => {
    const groupErrors = (errors || []).find((error: IMarkupsError) => error.group === selectedEventGroup);
    return groupErrors && groupErrors.eventsErrors ? groupErrors.eventsErrors : [];
  }
);
