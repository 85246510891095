import * as React from 'react';
import {IAssetVideoSubtitleLanguage} from '../../../../../../../../../@types/assetDetails';
import {Container, Row, Column} from '../../../../../Grid';
import {Tt2b} from '../../../../../../../../components/Typography/Tt2b';
import {LanguageDropdown} from '../../../../../LanguageDropdown';
import {Toggle} from '../../../../../../../../components/Toggle';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {IEnums} from '../../../../../../../../state/IEnums';
import {IEnum} from '../../../../../../../../../@types/enum';
import {Button} from '../../../../../../../../components/Button';
import {deepCopy} from '../../../../../../utils/helpers';
import {Icon} from 'tt-components/src/Icon/Icon';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';

import {DeleteIcon} from '../../../../../../assets/Icons/DeleteIcon';

interface IVideoSubtitles {
  subtitles: Array<IAssetVideoSubtitleLanguage>;
  tabsDataInEditMode: boolean;
  isVideoDetailsOpen: boolean;
  closestBody?: HTMLElement;
  enums: IEnums;
  subtitleUpdate: (prop: string, value: string | number | boolean, subtitleId: string) => void;
  onSubtitlesUpdate: (subtitles: Array<IAssetVideoSubtitleLanguage>) => void;
}

export class VideoSubtitles extends React.PureComponent<IVideoSubtitles> {
  convertEnums = (records: Array<IEnum>) => {
    return [...records]
      .filter((record: IEnum) => record.isActive)
      .reduce((acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}], []);
  };
  onSubtitleLanguageSelected = (subId: string, language: string, country: string) => {
    setTimeout(() => this.props.subtitleUpdate('language', language, subId), 200);
    setTimeout(() => this.props.subtitleUpdate('country', country, subId), 200);
  };
  getContent = () => {
    if (!this.props.subtitles.length) {
      return (
        <Row className={`video-subtitles-container_row${!this.props.tabsDataInEditMode ? ` disabled` : ``}`}>
          <Column className="video-subtitles-container_row_column">
            <Tt2b
              className="video-subtitles-container_empty-text"
              content={`Video doesn't have subtitle languages defined`}
            />
          </Column>
        </Row>
      );
    }
    return this.props.subtitles.reduce(
      (acc: Array<JSX.Element>, subtitle: IAssetVideoSubtitleLanguage, index: number) => {
        const metadataRows = (
          <Container key={index} className="subtitles-language-container">
            <Row className="video-subtitles-container_row">
              <Column className="video-subtitles-container_row_column two-columns">
                <LanguageDropdown
                  search
                  isOpen={this.props.isVideoDetailsOpen}
                  languageDialect={this.props.enums.languageDialect}
                  onLanguageSelected={this.onSubtitleLanguageSelected.bind(this, subtitle.id)}
                  language={subtitle.language}
                  country={subtitle.country}
                  disabled={!this.props.tabsDataInEditMode}
                  label="Language"
                  portalNode={this.props.closestBody}
                  toLowerCaseCompare
                />
              </Column>
              <Column className="video-subtitles-container_row_column">
                <Toggle
                  label="Closed"
                  disabled={!this.props.tabsDataInEditMode}
                  toggled={subtitle.closed}
                  onToggle={value => this.props.subtitleUpdate('closed', value, subtitle.id)}
                />
              </Column>
              <Column className="video-subtitles-container_row_column action-column">
                {this.props.tabsDataInEditMode && (
                  <div
                    className="video-subtitles-container_row_column_remove"
                    onClick={() => this.onSubtitlesUpdate(subtitle.id)}
                  >
                    <Icon icon={DeleteIcon} color="#1F72F2" size="18px" />
                  </div>
                )}
              </Column>
            </Row>
            <Row className="video-subtitles-container_row">
              <Column className="video-subtitles-container_row_column">
                <Dropdown
                  search
                  isOpen={this.props.isVideoDetailsOpen}
                  width={206}
                  options={this.convertEnums(this.props.enums.subtitleType)}
                  onSelected={value => this.props.subtitleUpdate('type', value, subtitle.id)}
                  selected={subtitle.type}
                  disabled={!this.props.tabsDataInEditMode}
                  label="Type"
                  portalNode={this.props.closestBody}
                />
              </Column>
              <Column />
              <Column />
            </Row>
          </Container>
        );
        return [...acc, metadataRows];
      },
      []
    );
  };

  onSubtitlesUpdate = (removeSubtitleId?: string) => {
    const subtitlesCopy = deepCopy([...this.props.subtitles]);
    if (removeSubtitleId) {
      this.props.onSubtitlesUpdate(
        subtitlesCopy.reduce((acc: Array<IAssetVideoSubtitleLanguage>, subtitle: IAssetVideoSubtitleLanguage) => {
          if (removeSubtitleId === subtitle.id) {
            return [...acc];
          }
          return [...acc, subtitle];
        }, [])
      );
    } else {
      this.props.onSubtitlesUpdate([...subtitlesCopy, PlaylistAsset.defaults.videoSubtitleLanguageDefault]);
    }
  };

  render() {
    return (
      <Container className="video-subtitles-container">
        {this.getContent()}
        {this.props.tabsDataInEditMode && (
          <Row className="video-subtitles-container_row">
            <Column className="video-subtitles-container_row_column action-column">
              <Button content="+ Add Language" small secondary onClick={() => this.onSubtitlesUpdate()} />
            </Column>
          </Row>
        )}
      </Container>
    );
  }
}
