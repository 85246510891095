import * as React from 'react';
import {CustomRadioButton} from '../../../../CustomRadio';
import {ITemplateColors} from '../../../../../../@types/templateColors';
import {IWatermarkPosition} from '../../../../../../@types/watermarkPosition';

export interface IWatermarkSettingsProps {
  templateColors: ITemplateColors;
  changeWatermarkPosition: (value: IWatermarkPosition) => void;
  watermarkPosition: IWatermarkPosition;
}

export class WatermarkSettings extends React.PureComponent<IWatermarkSettingsProps> {
  changeWatermarkPosById(radioButtonId: string) {
    const {changeWatermarkPosition} = this.props;

    switch (radioButtonId) {
      case 'watermark-top-id':
        changeWatermarkPosition('top');
        break;
      case 'watermark-bottom-id':
        changeWatermarkPosition('bottom');
        break;
      case 'watermark-middle-id':
        changeWatermarkPosition('middle');
        break;
      case 'watermark-diagonal-id':
        changeWatermarkPosition('diagonal');
        break;
    }
  }

  getRadioButtonIdByWatermarkPos(watermarkPosition: IWatermarkPosition) {
    let id: string = '';
    switch (watermarkPosition) {
      case 'top':
        id = 'watermark-top-id';
        break;
      case 'bottom':
        id = 'watermark-bottom-id';
        break;
      case 'middle':
        id = 'watermark-middle-id';
        break;
      case 'diagonal':
        id = 'watermark-diagonal-id';
        break;
    }
    return id;
  }

  render() {
    const {templateColors, watermarkPosition} = this.props;
    const {main} = templateColors;
    const watermarkSettings = [
      {
        id: 'watermark-top-id',
        label: 'Top'
      },
      {
        id: 'watermark-diagonal-id',
        label: 'Diagonal'
      },
      {
        id: 'watermark-middle-id',
        label: 'Middle'
      },
      {
        id: 'watermark-bottom-id',
        label: 'Bottom'
      }
    ];

    return (
      <div className="op_watermark-settings">
        <div className="op_watermark-settings_title" style={{color: main}}>
          Watermark
        </div>
        {watermarkSettings.map((settingsOption, index) => (
          <CustomRadioButton
            key={index}
            index={index}
            id={settingsOption.id}
            value={settingsOption.id}
            text={settingsOption.label || 'Watermark Option'}
            selectedValue={this.getRadioButtonIdByWatermarkPos(watermarkPosition)}
            onChange={e => this.changeWatermarkPosById(e.target.value)}
            isUseDefaultColorForNotSelected={true}
            templateColors={this.props.templateColors}
          />
        ))}
      </div>
    );
  }
}
