import * as React from 'react';
import {Icon} from 'tt-components/src/Icon';
import {Dropdown} from 'tt-components/src/Dropdown';
import {CbiIcon} from '../../assets/Icons/Cbi';
import {DropdownContent, defaultMenuControlBarStyles} from '../DropdownContent';
import {ITemplateColors} from '../../../@types/templateColors';
import {IFrameRate} from 'tt-components/src/frameRate';
import {throttle, calculateDropdownStyles} from '../../utils/utils';
import {PopoverTailIcon} from '../../assets/Icons/PopoverTail';
import {IDisplayMediaTimeFormat} from '../../../@types/displayMediaTimeFormat';
import {PlayerTimePicker} from '../../components/PlayerTimePicker';

export interface IGoToLocationProps {
  onGoTo?: (time: number) => void;
  isOpen: boolean;
  onShowGoToChange: (isOpen: boolean) => void;
  templateColors: ITemplateColors;
  maxTime?: number;
  frameRate?: IFrameRate;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  disabled?: boolean;
  displayMediaTimeFormat: IDisplayMediaTimeFormat;
  startTimecodeOffset?: number;
}

export interface IGoToLocationState {
  time?: number;
}

export class GoToLocation extends React.PureComponent<IGoToLocationProps, IGoToLocationState> {
  goToLocationButton: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      time: 0
    };
  }

  timeChange = changedTime => {
    const {onGoTo} = this.props;
    const time = changedTime - (this.props.startTimecodeOffset || 0);
    this.setState({time: changedTime});
    onGoTo(time);
  };

  onTimeChange = () => throttle(this.timeChange, 1000);

  getLocationTime = () => {
    const {maxTime, frameRate, displayMediaTimeFormat} = this.props;
    const {main, tooltipBackground} = this.props.templateColors;
    const {time} = this.state;
    let timePickerComponent;

    if (displayMediaTimeFormat === 'FrameOnly') {
      timePickerComponent = <div>Here should be frame-only component</div>;
    } else {
      const props: any =
        displayMediaTimeFormat === 'FrameBasedTimecode'
          ? {frameRate}
          : displayMediaTimeFormat === 'MillisecondBasedTimecode'
          ? {milliseconds: true}
          : {};
      timePickerComponent = (
        <PlayerTimePicker
          min={this.props.startTimecodeOffset || 0}
          max={typeof maxTime !== 'undefined' ? maxTime + (this.props.startTimecodeOffset || 0) : maxTime}
          time={time}
          {...props}
          onChangeTime={this.onTimeChange()}
        />
      );
    }

    const content = (
      <div className="op-control-bar-goTo">
        <span style={{color: main}}>Go To Location</span>
        {timePickerComponent}
      </div>
    );

    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={tooltipBackground} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onGoToLocationOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, this.goToLocationButton);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      onShowGoToChange,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      closestBodyElement
    } = this.props;
    const {main} = this.props.templateColors;
    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowGoToChange}
        title="Go To Location"
        buttonComponent={() => (
          <div className="go-to-location-dropdown-icon" ref={node => (this.goToLocationButton = node)}>
            <Icon icon={CbiIcon} color={main} size="21px" />
          </div>
        )}
        content={this.getLocationTime()}
        className={
          'ttc-control-bar-dropdown go-to-location-dropdown ' + (isControlBarShown ? '' : 'op_controls-bar_hidden')
        }
        openAbove={true}
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onGoToLocationOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  width: 175,
  padding: 5,
  borderWidth: 0,
  marginLeft: 0,
  boxSizing: 'content-box'
};
