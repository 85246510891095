import * as screenfull from 'screenfull';
import {IParsedVTT} from '../../@types/parsedVtt';
import {
  IAssetCredentials,
  IFeatureCredentials,
  ISeriesCredentials,
  IPlaylistAtlas,
  IAppPlaylist,
  IPlaylistTitleRecord
} from '../state/IAppState';
import {IAudioChannelConfiguration, IAudioChannel} from '../../@types/audioChannelConfiguration';
import {IAssetStatus} from '../../@types/assetStatus';
import {
  IPlaybackBodyRequest,
  IChannelInput,
  IVideoAudioCheckedChannels,
  IVideoAudioConfiguration,
  ITextOverlay,
  IMarkupsTypes
} from '../state/IVideoState';
import {ISubtitlesMetadata} from '../../@types/subtitlesMetadata';
import {IStorageClasses} from './storage';
import {IEnum} from '../../@types/enum';
import {IEnumConstraints} from '../state/IEnums';
import {subsLanguages} from '../constants/subsLanguages';
import {ITitleUrls} from '../../@types/titleUrls';
import {IOneCustomer} from '../../@types/oneCustomer';
import {ISearchTitle} from '../../@types/searchTitle';
import {IQualityMetrics} from '../../@types/qualityMetrics';
import {
  getSearchTitleById,
  getProgramTimingTypes,
  getComplianceTypes,
  getQualityControlTypes,
  getChapterTypes
} from '../data/atlasAPI';
import {PlaylistAsset} from '../models/PlaylistAsset/PlaylistAsset';
import LocalStorageService from '../services/LocalStorageService';
import {IFrameRate} from 'tt-components/src/frameRate';
import {IVideoQuality, BitMovinPlayer} from 'tt-components/src/VideoPlayer/BitMovinPlayer';
import {IDropdownOption} from '../components/Dropdown';
import {channelsMapping} from '../constants/constants';
import {clearProps} from '../modules/Tabs/utils/helpers';
import {IPlayerType} from '../../@types/playerType';
import {IMarkupEvent} from '../../@types/markupEvent';
import {Smpte} from '../models/Smpte/Smpte';
import {serviceProvider} from '../services/serviceProvider';

export const throttle = (func, limit, context = null) => {
  let lastFunc;
  let lastRan;
  return (...args) => {
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const fullScreenHelper = {
  getFullScreenElement: () => {
    // @ts-ignore
    return screenfull.isFullscreen;
  },
  requestFullscreen: (element: HTMLDivElement) => {
    // @ts-ignore
    return screenfull.toggle(element);
  },
  exitFullscreen: () => {
    // @ts-ignore
    return screenfull.exit();
  }
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.substring(1);
};

export const isMac = navigator.appVersion.indexOf('Mac') !== -1;
export const isEdge = navigator.userAgent.indexOf('Edge') !== -1;

export const convertVttToJson = (vttFilePath: string) => {
  if (!vttFilePath) {
    return Promise.resolve([]);
  } else {
    return new Promise((resolve, reject) => {
      fetch(vttFilePath)
        .then(response => response.text())
        .then((content: string) => vttToJson(content))
        .then((jsonVTT: IParsedVTT[]) => resolve(jsonVTT))
        .catch(error => {
          console.log('VTT to JSON error', error);
          resolve([]);
        });
    });
  }
};

export const timeString2ms = (time: string) => {
  let a: any = time.split('.');
  const b = a[1] * 1 || 0;
  a = a[0].split(':');
  return b + (a[2] ? a[0] * 3600 + a[1] * 60 + a[2] * 1 : a[1] ? a[0] * 60 + a[1] * 1 : a[0] * 1) * 1e3;
};

export const cleanWord = (word: string) => {
  return word.replace(/[^0-9a-z'-]/gi, '').toLowerCase();
};

export const clone = obj => {
  if (null == obj || 'object' !== typeof obj) {
    return obj;
  }
  let copy = obj.constructor();
  for (let attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = obj[attr];
    }
  }
  return copy;
};

export const generateParsedVTT = (
  imageUrl: string,
  fileDuration: number,
  thumbRows?: number,
  thumbWidth?: number,
  thumbHeight?: number,
  thumbCols?: number
) => {
  const interval = 10;
  const thumbnailWidth = thumbWidth || 150;
  const thumbnailHeight = thumbHeight || 85;
  const thumbnailColumns = thumbCols || 20;
  const thumbnailsRows = thumbRows || Math.ceil(fileDuration / interval / thumbnailColumns);
  const spriteAbsoluteURL = imageUrl;

  let parsedVTTContent: IParsedVTT[] = [];
  let initXPosition = 0;
  let initYPosition = 0;
  let currentTime = 0;

  for (let rowIndex = 0; rowIndex < thumbnailsRows; rowIndex++) {
    initYPosition = rowIndex * thumbnailHeight;
    let cols = rowIndex >= thumbnailsRows - 1 ? (fileDuration / interval) % thumbnailColumns : thumbnailColumns;
    for (let columnIndex = 0; columnIndex < cols; columnIndex++) {
      let current: IParsedVTT;
      initXPosition = columnIndex * thumbnailWidth;
      const start = Math.trunc(currentTime * 1000);
      currentTime += interval;
      const end = Math.trunc(currentTime * 1000 - 1);

      current = {
        start,
        end,
        part: `${spriteAbsoluteURL}?xywh=-${initXPosition},-${initYPosition},${thumbnailWidth},${thumbnailHeight}`
      };
      parsedVTTContent.push(current);
    }
  }
  return parsedVTTContent;
};

export const vttToJson = (vttString: string) => {
  return new Promise((resolve, reject) => {
    let current: IParsedVTT;
    let sections = [];
    let start = false;
    let vttArray = vttString.split('\n');
    vttArray.forEach((line, index) => {
      if (line.replace(/<\/?[^>]+(>|$)/g, '') === ' ') {
        // TODO
      } else if (line.replace(/<\/?[^>]+(>|$)/g, '') === '') {
        // TODO
      } else if (line.indexOf('-->') !== -1) {
        start = true;

        if (current && current.start) {
          sections.push(clone(current));
        }

        current = {
          start: timeString2ms(
            line
              .split('-->')[0]
              .trim()
              .split(' ')
              .pop()
          ),
          end: timeString2ms(
            line
              .split('-->')[1]
              .trim()
              .split(' ')
              .shift()
          ),
          part: ''
        };
      } else if (line.replace(/<\/?[^>]+(>|$)/g, '') === '' || line.replace(/<\/?[^>]+(>|$)/g, '') === ' ') {
        // TODO
      } else {
        if (start) {
          if (sections.length !== 0) {
            if (
              sections[sections.length - 1].part.replace(/<\/?[^>]+(>|$)/g, '') === line.replace(/<\/?[^>]+(>|$)/g, '')
            ) {
              // TODO
            } else {
              if (current.part.length === 0) {
                current.part = line;
              } else {
                current.part = `${current.part} ${line}`;
              }
              // If it's the last line of the subtitles
              if (index === vttArray.length - 1) {
                sections.push(clone(current));
              }
            }
          } else {
            current.part = line;
            sections.push(clone(current));
            current.part = '';
          }
        }
      }
    });

    let regex = /(<([0-9:.>]+)>)/gi;
    sections.forEach(section => {
      const strs = section.part.split();
      let results = strs.map(s => {
        return s.replace(regex, n => {
          return n.split('').reduce((s, i) => `==${n.replace('<', '').replace('>', '')}`, 0);
        });
      });
      const cleanText = results[0].replace(/<\/?[^>]+(>|$)/g, '');
      const cleanArray = cleanText.split(' ');
      const resultsArray = [];
      cleanArray.forEach(item => {
        if (item.indexOf('==') > -1) {
          let pair = item.split('==');
          let key = pair[0];
          if (key === '' || key === '##') {
            return;
          }
          resultsArray.push({
            word: cleanWord(item.split('==')[0]),
            time: timeString2ms(item.split('==')[1])
          });
        } else {
          resultsArray.push({
            word: cleanWord(item),
            time: undefined
          });
        }
      });
      section.words = resultsArray;
      section.part = section.part.replace(/<\/?[^>]+(>|$)/g, '');
    });
    resolve(sections);
  });
};

export const defineThumbnailStyleFromCue = (cue: IParsedVTT, reduceFactor: number = null, spriteWidth: number = 0) => {
  const thumbnailStyle: any = {};

  // Remove query from URL (if exists) so we can use cached version of provided sprite
  thumbnailStyle.backgroundImage = `url(${cue.part.split('?xywh')[0]})`;

  let positionParamsTokens =
    cue.part.split('?xywh').length > 1
      ? cue.part.split('?xywh')[1].split('=').length > 1
        ? cue.part
            .split('?xywh')[1]
            .split('=')[1]
            .split(',')
        : []
      : [];

  const parsedTokens = positionParamsTokens.map(tokens => parseInt(tokens)).filter(tokens => !isNaN(tokens));

  // Check for x and y position values
  if (typeof parsedTokens[0] !== 'undefined' && typeof parsedTokens[1] !== 'undefined') {
    const xPos = reduceFactor ? parsedTokens[0] / reduceFactor : parsedTokens[0];
    const yPos = reduceFactor ? parsedTokens[1] / reduceFactor : parsedTokens[1];
    thumbnailStyle.backgroundPosition = `${xPos}px ${yPos}px`;
  }

  // Check for width value
  if (typeof parsedTokens[2] !== 'undefined') {
    const width = reduceFactor ? parsedTokens[2] / reduceFactor : parsedTokens[2];
    thumbnailStyle.width = width;
  }

  // Check for height value
  if (typeof parsedTokens[3] !== 'undefined') {
    const height = reduceFactor ? parsedTokens[3] / reduceFactor : parsedTokens[3];
    thumbnailStyle.height = height;
  }

  if (reduceFactor && spriteWidth) {
    thumbnailStyle.backgroundSize = `${spriteWidth / reduceFactor}px`;
  }

  return thumbnailStyle;
};

export const getCdnFromBucket = (path: string) => {
  if (path.includes('dlx-one-proxy-us-west-2-live')) {
    return 'https://d2detfmr8cx0ni.cloudfront.net/';
  }
  if (path.includes('dlx-one-proxy-us-west-2-test')) {
    return 'https://d2udddi81lxkgv.cloudfront.net/';
  }
  if (path.includes('dlx-one-proxy-us-west-2-int')) {
    return 'https://d2qnmbs71ieid3.cloudfront.net/';
  }
  return path;
};

export const generateAssetCredentials = (data: IPlaylistAtlas) => {
  let credentials: IAssetCredentials;
  const isRegistered = typeof data.isRegistered !== 'undefined' ? data.isRegistered : true;
  if (data.seriesId) {
    credentials = {
      data: {
        seriesId: data.seriesId || '',
        seriesVersionId: data.seriesVersionId || '',
        seasonId: data.seasonId || '',
        seasonVersionId: data.seasonVersionId || '',
        episodeId: data.episodeId || '',
        episodeVersionId: data.episodeVersionId || ''
      } as ISeriesCredentials,
      type: 'Series',
      isRegistered
    } as IAssetCredentials;
  } else {
    credentials = {
      data: {
        featureId: data.featureId,
        featureVersionId: data.featureVersionId
      } as IFeatureCredentials,
      type: 'Feature',
      isRegistered
    } as IAssetCredentials;
  }

  if (!credentials.isRegistered) {
    credentials.assetId = data.assetId;
  }

  return credentials;
};

export const generateAssetURL = (credentials: IAssetCredentials, assetId: string) => {
  const type = getTitleType(credentials);
  const {data} = credentials;
  if (type === 'Feature') {
    const featureData = data as IFeatureCredentials;
    // tslint:disable-next-line
    return serviceProvider.hosts.apiAtlasHost + 'feature/' + featureData.featureId + '/version/' + featureData.featureVersionId + '/asset/' + assetId;
  } else if (type === 'Episode') {
    const episodeData = data as ISeriesCredentials;
    // tslint:disable-next-line
    return serviceProvider.hosts.apiAtlasHost + 'series/' + episodeData.seriesId + '/season/' + episodeData.seasonId + '/episode/' + episodeData.episodeId + '/version/' + episodeData.episodeVersionId + '/asset/' + assetId;
  } else if (type === 'Season') {
    const seasonData = data as ISeriesCredentials;
    // tslint:disable-next-line
    return serviceProvider.hosts.apiAtlasHost + 'series/' + seasonData.seriesId + '/season/' + seasonData.seasonId + '/version/' + seasonData.seasonVersionId + '/asset/' + assetId;
  } else if (type === 'Series') {
    const seriesData = data as ISeriesCredentials;
    // tslint:disable-next-line
    return serviceProvider.hosts.apiAtlasHost + 'series/' + seriesData.seriesId + '/version/' + seriesData.seriesVersionId + '/asset/' + assetId;
  } else {
    return ``;
  }
};

export const getTitleType = ({type, data}: IAssetCredentials) => {
  const composedType =
    type === 'Feature'
      ? type
      : (data as ISeriesCredentials).seriesId &&
        (data as ISeriesCredentials).seasonId &&
        (data as ISeriesCredentials).episodeId
      ? 'Episode'
      : (data as ISeriesCredentials).seriesId && (data as ISeriesCredentials).seasonId
      ? 'Season'
      : 'Series';
  return composedType;
};

export const generateTitleDataURLs = (credentials: IAssetCredentials): ITitleUrls => {
  const urls: any = {};
  const composedType = getTitleType(credentials);
  const {data} = credentials;
  switch (composedType) {
    case 'Feature':
      const featureData = data as IFeatureCredentials;
      urls.title = `${serviceProvider.hosts.apiAtlasHost}feature/${featureData.featureId}`;
      urls.versions = `${serviceProvider.hosts.apiAtlasHost}feature/${featureData.featureId}/versions`;
      break;
    case 'Episode':
      const episodeData = data as ISeriesCredentials;
      // tslint:disable-next-line
      urls.title = serviceProvider.hosts.apiAtlasHost + 'series/' + episodeData.seriesId + '/season/' + episodeData.seasonId + '/episode/' + episodeData.episodeId;
      // tslint:disable-next-line
      urls.versions = serviceProvider.hosts.apiAtlasHost + 'series/' + episodeData.seriesId + '/season/' + episodeData.seasonId + '/episode/' + episodeData.episodeId + '/versions';
      break;
    case 'Season':
      const seasonData = data as ISeriesCredentials;
      urls.title = `${serviceProvider.hosts.apiAtlasHost}series/${seasonData.seriesId}/season/${seasonData.seasonId}`;
      urls.versions = `${serviceProvider.hosts.apiAtlasHost}series/${seasonData.seriesId}/season/${
        seasonData.seasonId
      }/versions`;
      break;
    case 'Series':
      const seriesData = data as ISeriesCredentials;
      urls.title = `${serviceProvider.hosts.apiAtlasHost}series/${seriesData.seriesId}`;
      urls.versions = `${serviceProvider.hosts.apiAtlasHost}series/${seriesData.seriesId}/versions`;
      break;
    default:
    // TODO: Define handler for default cases
  }

  return Object.keys(urls).length ? urls : null;
};

export const parseTitleRecord = (record: any): IPlaylistTitleRecord => ({
  id: record.id || null,
  hrId: record.hrId || null,
  name: record.name || null,
  type: record.type || null,
  isActive: (record.status || '').toLowerCase() === 'active'
});

export const generatePlaybackRequestBody = (
  channels: Array<IAudioChannel> = [],
  audioAssetId: string = null,
  textOverlay: ITextOverlay = null,
  videoAssetId: string = null,
  videoBitRate: number = 3000
): IPlaybackBodyRequest => {
  let playbackRequestBody: IPlaybackBodyRequest = {};

  const totalInputs: Array<IChannelInput> = channels.reduce((total: Array<IChannelInput>, channel: IAudioChannel) => {
    const inputs = channel.channelMap.map(
      (value: string, mapIndex: number): IChannelInput => {
        return {
          track: +channel.track - 1,
          channel: typeof channel.channel !== 'undefined' ? channel.channel : mapIndex,
          mapping: value
        };
      }
    );
    total.push(...inputs);
    return total;
  }, []);

  const channelsMappingResult = totalInputs.reduce(
    (requestBody, input: IChannelInput) => {
      // In cases we have a channel mapping that it's not defined will mark it as left input
      const isLeft = (channelsMapping[input.mapping.toLowerCase()] || 'left') === 'left';
      if (isLeft) {
        requestBody.leftChannelInputMapping.push(clearProps(input, ['mapping']) as IChannelInput);
      } else {
        requestBody.rightChannelInputMapping.push(clearProps(input, ['mapping']) as IChannelInput);
      }
      return requestBody;
    },
    {leftChannelInputMapping: [], rightChannelInputMapping: []}
  );

  if (channelsMappingResult.leftChannelInputMapping.length) {
    playbackRequestBody.leftChannelInputMapping = channelsMappingResult.leftChannelInputMapping;
  }

  if (channelsMappingResult.rightChannelInputMapping.length) {
    playbackRequestBody.rightChannelInputMapping = channelsMappingResult.rightChannelInputMapping;
  }

  if (audioAssetId) {
    playbackRequestBody.audioAssetId = audioAssetId;
  }
  if (videoBitRate) {
    playbackRequestBody.videoBitRate = videoBitRate;
  }
  if (videoAssetId) {
    playbackRequestBody.videoAssetId = videoAssetId;
  }
  //note(brett): dont send the text overlay right now -- just use the fake overlay
  // if (textOverlay) {
  //   playbackRequestBody.textOverlay = textOverlay;
  // }

  return playbackRequestBody;
};

export const generateTextOverlay = (text: string, position: string) => {
  position = position || 'diagonal';
  let options = {};
  switch (position) {
    case 'top':
      options = {
        position: 'top_center',
        orientation: 'horizontal'
      };
      break;
    case 'middle':
      options = {
        position: 'center_center',
        orientation: 'horizontal'
      };
      break;
    case 'bottom':
      options = {
        position: 'bottom_center',
        orientation: 'horizontal'
      };
      break;
    case 'diagonal':
      options = {
        position: 'center_center',
        orientation: 'diagonal'
      };
      break;
  }

  return {
    text,
    font_color: '#ffffff',
    font_size: 100,
    opacity: 0.6,
    word_wrap_mode: 'none',
    ...options
  };
};

export const preparePrePlaybackLoad = (audioConfiguration: IAudioChannelConfiguration) => {
  let checkedChannel = {};
  let selectedConfig = null;

  if (!audioConfiguration) {
    console.log('Audio configuration not defined');
    return {checkedChannel, selectedConfig};
  }

  selectedConfig = audioConfiguration.id;

  if (audioConfiguration.proxyUrl) {
    console.log('Audio Configuration is proxy');
    return {checkedChannel, selectedConfig};
  }

  if (!audioConfiguration.trackDetail.length) {
    console.log('Audio configuration has empty channels');
    return {checkedChannel, selectedConfig};
  }

  checkedChannel = {
    [audioConfiguration.id]: [
      ...audioConfiguration.trackDetail
        .filter((audio: IAudioChannel) => audio.channelMap.length)
        .map((audio: IAudioChannel) => audio.id)
    ]
  };
  return {checkedChannel, selectedConfig};
};

export const parseEnum = (value: string, collection: Array<IEnum>, defaultVal: string = '') => {
  const enumResult = [
    collection.find((record: IEnum) => (record.value || '').toLowerCase() === (value || '').toLowerCase())
  ]
    .filter(record => record)
    .reduce((acc: string, record: IEnum) => record.name, '');
  return enumResult ? enumResult : defaultVal ? defaultVal : value;
};

export const getEnumConstraints = (constraints: Array<IEnumConstraints>): {[x: string]: Array<string>} => {
  const parsedConstraints: any = {};
  constraints.forEach((constraint: IEnumConstraints) => {
    parsedConstraints[constraint.name] = constraint.values;
  });
  return parsedConstraints;
};

export const parseEnumValues = (data: any): IEnum => {
  const constraints = getEnumConstraints(data.constraints || []);

  return {
    name: data.name || '',
    value: data.value || '',
    isForAudio: !!(constraints['ForAudio'] && constraints['ForAudio'].includes('true')),
    isForText: !!(constraints['ForText'] && constraints['ForText'].includes('true')),
    isActive: data.isActive || false
  };
};

export const updateEnums = async (dispatch, apiCallFunction: () => any, storage: IStorageClasses, type: string) => {
  // Check if we have saved reference in local storage before reaching API
  let payload = storage.get();

  if (!payload.length) {
    const apiResponse = await apiCallFunction();
    payload = apiResponse.data.map(parseEnumValues);
    // Update local storage reference with provided data from API request
    storage.set(payload);
  }

  dispatch({
    type,
    payload
  });
};

export const defineAudioConfigurationTitle = (
  config: IAudioChannelConfiguration,
  languageCollection: Array<IEnum>,
  typeCollection: Array<IEnum>
) => {
  const language = languageCollection.find(
    (record: IEnum) => (record.value || '').toLowerCase() === (config.language || '').toLowerCase()
  ) as IEnum;
  const type = typeCollection.find(
    (record: IEnum) => (record.value || '').toLowerCase() === (config.type || '').toLowerCase()
  ) as IEnum;

  const title = [config.channelConfig, language ? language.name : config.language, type ? type.name : null]
    .filter((token: string) => token)
    .join(' ');
  return title || 'Not Configured';
};

export const defineChannelCheckboxLabel = (
  channelMaps: Array<string>,
  channelMapCollection: Array<IEnum>,
  delimiter: string = ' '
) => {
  if (!channelMaps.length) {
    return `Invalid Channel`;
  }
  return defineChannelTitle(channelMaps, channelMapCollection, delimiter);
};

export const defineChannelTitle = (
  channelMaps: Array<string>,
  channelMapCollection: Array<IEnum>,
  delimiter: string = ' '
) => {
  return channelMaps
    .map((channelMap: string) => {
      return parseEnum(channelMap, channelMapCollection);
    })
    .join(delimiter);
};

export const areSamePrimitiveArrays = (arr1: Array<string | number>, arr2: Array<string | number>) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const all1in2 = arr1.every((channel: string) => arr2.includes(channel));
  const all2in1 = arr2.every((channel: string) => arr1.includes(channel));
  if (!all1in2 || !all2in1) {
    return false;
  }

  return true;
};

export const areEqualObjectsWithPrimitiveKeys = (
  channelsA: IVideoAudioCheckedChannels,
  channelsB: IVideoAudioCheckedChannels
) => {
  const channelsAKeys = Object.keys(channelsA);
  const channelsBKeys = Object.keys(channelsB);

  const sameKeys = areSamePrimitiveArrays(channelsAKeys, channelsBKeys);
  if (!sameKeys) {
    return false;
  }

  for (const key of channelsAKeys) {
    const sameArrays = areSamePrimitiveArrays(channelsA[key], channelsB[key]);
    if (!sameArrays) {
      return false;
    }
  }

  return true;
};

export const getFullLanguageName = (shortLanguageName: string) => {
  let language = subsLanguages.find(el => {
    return el['ISO 639-2/5'].indexOf(shortLanguageName) !== -1;
  });

  return language && language['Language name'];
};

/**
 * TODO: Replace the playlist content API call with related service to check proxy state of the asset
 */
export const checkAssetProxyState = (assetOrderId: string, playlist: IAppPlaylist): IAssetStatus => {
  const conf = PlaylistAsset.filter.findConfiguration(
    playlist.assets,
    assetOrderId,
    playlist.proxy,
    playlist.video
  ) as IAudioChannelConfiguration;
  if (!conf) {
    return 'Failed';
  }

  switch ((conf.assetStatus || '').toLowerCase()) {
    case 'staged':
      return 'Staged';
    case 'copying':
      return 'Copying';
    case 'restoring':
      return 'Restoring';
    case 'initial':
      return 'Initial';
    case 'error':
      return 'Error';
    default:
      return 'Failed';
  }
};

export const isBlockingState = (state: IAssetStatus) => {
  const blockingStates: Array<IAssetStatus> = ['Copying', 'Restoring', 'Checking', 'Initial', 'Failed', 'Error'];
  const notBlockingStates: Array<IAssetStatus> = ['Staged'];

  if (!state || notBlockingStates.includes(state)) {
    return false;
  }

  if (blockingStates.includes(state)) {
    return true;
  }

  return true;
};

export const isBlockingAssetState = (audioConfig: IVideoAudioConfiguration, playlist: IAppPlaylist) => {
  return playlist.forcingStaging
    ? true
    : audioConfig.proxyState
    ? isBlockingState(audioConfig.proxyState)
    : !!playlist.error
    ? true
    : !audioConfig.selectedConfig;
};

export const areAllStaged = (data: Array<IAudioChannelConfiguration | ISubtitlesMetadata | PlaylistAsset>) => {
  return data.every(
    (config: IAudioChannelConfiguration | ISubtitlesMetadata | PlaylistAsset) =>
      config.assetStatus.toLowerCase() === 'staged'
  );
};

export const wait = (milliseconds: number = 500) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), milliseconds);
  });
};

export const hasProp = (obj: {[x: string]: any}, prop: string) => {
  if (obj.hasOwnProperty(prop)) {
    return true;
  }
  return false;
};

export const defineShortcutType = () => {
  const localStorageService = new LocalStorageService();
  let definedShortcut = localStorageService.get('shortcuts') || 'default';
  return definedShortcut;
};

export const calculateDropdownStyles = (
  styles: React.CSSProperties,
  isFullScreen: boolean,
  buttonRef: HTMLElement = null,
  offset: number = 64
): React.CSSProperties => {
  if (!(styles.width as number)) {
    // Function to work will need at minimum the width provided
    return {...styles};
  }

  // In order for this calculation to work properly the dropdown-content should have `box-sizing: content-box`
  let calcTotalContentWidth =
    (styles.width as number) + ((styles.borderWidth as number) || 0) * 2 + ((styles.padding as number) || 0) * 2;

  const buttonWidth = buttonRef ? buttonRef.getBoundingClientRect().width : null;
  const updatedStyles: React.CSSProperties = {
    ...styles,
    marginLeft: buttonWidth ? (buttonWidth - calcTotalContentWidth) / 2 : -calcTotalContentWidth + offset
  };
  // Small position update for Full Screen mode
  if (isFullScreen && typeof updatedStyles.top !== 'undefined') {
    const top = (styles.top as number) - 8;
    return {...updatedStyles, top};
  }

  return updatedStyles;
};

export const handleMediaErrorMessage = ({code}: MediaError, options: {[label: string]: boolean}) => {
  switch (code) {
    case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
      return options.isProxy ? `Proxy not found!` : `Source not supported!`;
    default:
      return `Something went wrong!`;
  }
};

export const isNaNHandler = (value): number => {
  return isNaN(value) ? 0 : value;
};

export const parsOneCustomer = (customer: any): IOneCustomer => ({
  id: customer.id || null,
  name: customer.name || '',
  externalId: customer.externalId || null,
  contactName: customer.contactName || null
});

export const remoteFileExists = (absoluteURL: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const headers = new Headers();
    // Add no-cache headers for failed HEAD requests
    // because of the cached content of file
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');
    fetch(absoluteURL, {method: 'HEAD', headers})
      .then(response => {
        if (response.status === 200) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => resolve(false));
  });
};

export const prepareQuery = (params: {[x: string]: string | number | boolean}) => {
  const queryTokens = [];
  for (const prop in params) {
    if (params.hasOwnProperty(prop)) {
      queryTokens.push(`${prop}=${params[prop]}`);
    }
  }
  return `?${encodeURI(queryTokens.join('&'))}`;
};

export const updateAssetCredentials = async (
  titles: Array<ISearchTitle>,
  versionId: string
): Promise<ISeriesCredentials | IFeatureCredentials> => {
  const titleOptions = ['Feature', 'Episode'];
  const title = titles.find((record: ISearchTitle) => titleOptions.indexOf(record.type) !== -1);
  if (!title) {
    return null;
  }

  if (title.type === 'Feature') {
    return {featureId: title.id, featureVersionId: versionId} as IFeatureCredentials;
  } else if (title.type === 'Episode') {
    const episode = await getSearchTitleById(title.id);
    const episodeAncestors = episode.data ? episode.data.ancestors || [] : [];

    const seriesAncestor = episodeAncestors.find((record: ISearchTitle) => record.type === 'Series');
    const seasonAncestor = episodeAncestors.find((record: ISearchTitle) => record.type === 'Season');

    return {
      episodeId: title.id,
      episodeVersionId: versionId,
      seriesId: seriesAncestor ? seriesAncestor.id : '',
      seasonId: seasonAncestor ? seasonAncestor.id : ''
    } as ISeriesCredentials;
  } else {
    return null;
  }
};

export const defaultFrameRate = (frameRateObj: IFrameRate): IFrameRate => {
  const defaultValue: IFrameRate = {frameRate: 24, dropFrame: false};
  const frameRateObjectUndefined = !frameRateObj;
  const frameRateValueUndefined = !frameRateObjectUndefined && !frameRateObj.frameRate;
  const frameRateDropFrameUndefined = !frameRateObjectUndefined && typeof frameRateObj.dropFrame === 'undefined';

  if (frameRateObjectUndefined || frameRateValueUndefined || frameRateDropFrameUndefined) {
    const errors = [];
    if (frameRateObjectUndefined) {
      errors.push(`Provided Frame Rate object is undefined. It will default to {frameRate: 24, dropFrame: false}.`);
    }
    if (frameRateValueUndefined) {
      errors.push(`Frame Rate value is undefined. It will default to 24.`);
    }
    if (frameRateDropFrameUndefined) {
      errors.push(`Frame Rate dropFrame value is undefined. It will default to false.`);
    }
    if (errors.length) {
      console.error(`[FRAME RATE ERROR] ${errors.join(' ')}`);
    }
  }

  const parsedFrameRate = frameRateObjectUndefined
    ? defaultValue
    : {
        ...frameRateObj,
        frameRate: !frameRateValueUndefined ? frameRateObj.frameRate : 24,
        dropFrame: !frameRateDropFrameUndefined ? frameRateObj.dropFrame : false
      };
  console.log('Player Parsed Frame Rate object', parsedFrameRate);
  return parsedFrameRate;
};

export const parseOptions = (title: ISearchTitle): IDropdownOption => ({label: title.name, value: title.id});

export const parseTitleOptions = (
  title: ISearchTitle,
  contentOwners: Array<IOneCustomer> = [],
  contentProviders: Array<IOneCustomer> = []
): IDropdownOption => {
  const mappedContentOwner = contentOwners.find((owner: IOneCustomer) => owner.externalId === title.contentOwnerId);
  const mappedContentProvider = contentProviders.find(
    (provider: IOneCustomer) => provider.externalId === title.contentProviderId
  );
  const label = `${title.name || 'Undefined'}${title.releaseYear ? ` - ${title.releaseYear}` : ``}${
    title.type ? ` - ${title.type}` : ``
  }${mappedContentOwner ? ` - ${mappedContentOwner.name}` : ``}${
    mappedContentProvider ? ` - ${mappedContentProvider.name}` : ``
  }`;
  return {label, value: title.id};
};

export const parseWithIdOptions = (title: ISearchTitle): IDropdownOption => ({
  label: `${title.name || `Undefined`}${title.hrId ? ` (${title.hrId})` : ``}`,
  value: title.id
});

export const parseSeasonOptions = (title: ISearchTitle): IDropdownOption => ({
  label: `S${title.sequence || ''} - ${title.name}`,
  value: title.id
});

export const parseEpisodeOptions = (title: ISearchTitle): IDropdownOption => ({
  label: `E${title.sequence || ''} - ${title.name}`,
  value: title.id
});

export const secondsToHHmmss = (duration: number): string => {
  let hours = duration ? Math.floor(duration / 3600) : 0;
  let minutes = duration ? Math.floor((duration - hours * 3600) / 60) : 0;
  let seconds = duration ? Math.round(duration - hours * 3600 - minutes * 60) : 0;

  // NOTE: Add exception for seconds limit because function will be used as support
  // of an observable response and the next trigger will be sometimes slow
  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  // NOTE: Add exception for minutes limit because function will be used as support
  // of an observable response and the next trigger will be sometimes slow
  if (minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  return [hours, minutes, seconds].map(digit => `${digit < 10 ? `0` : ``}${digit}`).join(':');
};

export const secondsToMillisecondsTimecode = (timestamp: number, delimiter: string = '.') => {
  let time = timestamp;
  const hh = Math.floor(time / 3600);
  time -= hh * 3600;
  const mm = Math.floor(time / 60);
  time -= mm * 60;
  const ss = Math.floor(time);
  const milliseconds = Math.round((time - ss) * 1000);

  return `${Smpte.padNum(hh)}:${Smpte.padNum(mm)}:${Smpte.padNum(ss)}${delimiter}${Smpte.padNum(milliseconds, true)}`;
};

export const parseTimeComponent = (
  component: string,
  truncateLimit: number = null,
  isMilliseconds: boolean = false
) => {
  return (
    [component]
      .map(hoursComp => parseInt(hoursComp))
      .filter(hoursComp => !isNaN(hoursComp))
      .map(hoursComp => (truncateLimit ? Math.min(truncateLimit, hoursComp) : hoursComp))
      .map(hoursComp => Smpte.padNum(hoursComp, isMilliseconds))
      .join('') || (isMilliseconds ? '00' : '000')
  );
};

export const getTimeComponents = (time: string, frameRate: number = null) => {
  const placeholder = ['00', '00', '00', frameRate ? '00' : '000'];
  return [time || placeholder.join('')].reduce((acc: Array<string>, token: string) => {
    const components = token.split(/[\:\.]/);
    return acc.map((component: string, index) =>
      parseTimeComponent(
        components[index] || component,
        index !== 0 ? (index === 3 ? frameRate || 999 : 59) : null,
        index === 3 && !frameRate
      )
    );
  }, placeholder);
};

export const hhmmssToSeconds = (time: string) => {
  return getTimeComponents(time)
    .map(component => (!isNaN(parseInt(component)) ? parseInt(component) : 0))
    .reduce((sum, component, index) => {
      return sum + component * (index === 0 ? 3600 : index === 1 ? 60 : 1);
    }, 0);
};

export const fromSMPTETokensToSeconds = (
  hours: string = '00',
  minutes: string = '00',
  seconds: string = '00',
  frames: string = '00',
  frameRate: number = 24,
  dropFrame: boolean = false
) => {
  const smpte = new Smpte(`${hours}:${minutes}:${seconds}:${frames}`, {frameRate, dropFrame});
  return smpte.toAdjustedTime();
};

export const title = (text: string, enabled: boolean) => enabled && {title: text};

export const escapeNonNumeric = (value: string) => value.replace(/\D/gi, '');

export const has = (obj: {[x: string]: any}, prop: string) => {
  return typeof obj[prop] !== 'undefined';
};

export const getAudioByChannelConfig = (configs: Array<IAudioChannelConfiguration>) => {
  const audioConfig20 = configs.find((config: IAudioChannelConfiguration) => config.channelConfig === '2.0');
  const audioConfig51 = configs.find((config: IAudioChannelConfiguration) => config.channelConfig === '5.1');
  return audioConfig20 || audioConfig51 || configs[0];
};

export const parseTextToJson = (jsonToken: string) => {
  let parsed: any;
  try {
    parsed = JSON.parse(jsonToken);
  } catch (error) {
    parsed = jsonToken;
  }
  return parsed;
};

export const parseTextResponse = async (promise: Promise<string>) => {
  const text = await promise;
  return parseTextToJson(text);
};

export const parseQualityLevelMetrics = (qualityMetrics: IQualityMetrics) => {
  const loadedLevel: IVideoQuality = qualityMetrics.loadedLevel || null;
  return {
    // currentBitRate: loadedLevel ? +(loadedLevel.bitrate / 1000 / 1000).toFixed(1) : 0,
    currentResolution:
      loadedLevel && loadedLevel.width && loadedLevel.height ? `${loadedLevel.width}x${loadedLevel.height}` : null,
    frameRate: null, // IVideoQuality interface from Bitmovin doesn't provide frameRate information,
    currentDroppedFrames: qualityMetrics.currentDropped || 0,
    totalDroppedFrames: qualityMetrics.totalDroppedFrames || 0
  };
};

export const displayAssetInfo = (asset: PlaylistAsset) => {
  const type = asset.getAssetType();
  const hrId = asset.assetDetails.hrId ? ` (${asset.assetDetails.hrId})` : ``;
  const name = asset.assetDetails.name ? ` ~ ${asset.assetDetails.name}` : ``;

  return `${type}${hrId}${name}`;
};

export const getPlayerType = (url: string): IPlayerType => {
  if (url.match(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/)) {
    return 'YouTube';
  } else if (BitMovinPlayer.playableSource(url)) {
    return 'Bitmovin';
  } else {
    return 'Shallow';
  }
};

export const getMarkupsTypes = async () => {
  const programTimingsTypes = await getProgramTimingTypes();
  const complianceTypes = await getComplianceTypes();
  const qualityControlTypes = await getQualityControlTypes();
  const chapterTypes = await getChapterTypes();
  return [programTimingsTypes.data, complianceTypes.data, qualityControlTypes.data, chapterTypes.data].filter(
    (types: IMarkupsTypes) => types
  );
};

export const normalizeTypes = (events: Array<IMarkupEvent>, group: string, types: Array<IMarkupsTypes> = []) => {
  const groupTypes = [types.find((data: IMarkupsTypes) => data.name === group)]
    .filter(data => data)
    .reduce((acc: Array<string>, data: IMarkupsTypes) => data.list, []);
  if (!groupTypes.length) {
    return events;
  }
  return events.reduce((acc: Array<IMarkupEvent>, event: IMarkupEvent) => {
    const type = groupTypes.find((type: string) => (type || '').toLowerCase() === (event.type || '').toLowerCase());
    return [...acc, {...event, type: type || event.type}];
  }, []);
};

export const getRemoteImageWidth = (url: string): Promise<number> => {
  return new Promise(resolve => {
    const img = new Image();
    // img.crossOrigin = "Anonymous";
    img.addEventListener('load', () => {
      resolve(img.width);
    });
    img.addEventListener('error', () => {
      resolve(0);
    });
    img.src = url;
  });
};

export const mapSubtitleLanguageCountry = (
  language: string,
  country: string,
  languageDialect: Array<IEnum>,
  defaultVal: string = 'Undefined'
) => {
  const value = [language, country].filter(opt => opt).join('-');
  return parseEnum(value, languageDialect, defaultVal);
};

export const roundTwoDecimal = (numb: number) => {
  // @ts-ignore
  return +(Math.round(numb + 'e+2') + 'e-2');
};

export const getOnRange = (value: number, min?: number, max?: number): number => {
  if (min) {
    value = Math.max(value, min);
  }
  if (max) {
    value = Math.min(value, max);
  }
  return value;
};

export const getXMLContentFromRemoteResource = async (absoluteUrl: string): Promise<string> => {
  const contentPromise = new Promise(resolve => {
    fetch(absoluteUrl)
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/xml')) {
          return response.text();
        }
        return Promise.resolve(null);
      })
      .then((content: string) => resolve(content))
      .catch(error => {
        console.log('Error on fetching XML content', error);
        return resolve(null);
      });
  });
  const data = (await contentPromise) as string;
  return data;
};

export const isBlob = (variable: any): boolean => {
  // Check if Blob constructor is supported
  if (typeof Blob === 'undefined') {
    return false;
  }
  // Check if provided variable is instance of Blob constructor
  return variable instanceof Blob || Object.prototype.toString.call(variable) === '[object Blob]';
};
