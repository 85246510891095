export const videoAutoPopulatingSettings = Object.freeze([
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 2880,
    activeHeightPixels: 2160,
    activeXoffsetPixels: 480,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.37',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 2960,
    activeHeightPixels: 2160,
    activeXoffsetPixels: 440,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.5',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 3240,
    activeHeightPixels: 2160,
    activeXoffsetPixels: 300,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.66',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 3600,
    activeHeightPixels: 2160,
    activeXoffsetPixels: 120,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.78',
    pictureFormat: 'Full',
    activeWidthPixels: 3840,
    activeHeightPixels: 2160,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.85',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 2076,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 42
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.9',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 2020,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 70
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 1920,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 120
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.2',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 1744,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 208
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.35',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 1634,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 263
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.39',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 1606,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 277
  },
  {
    frameSizeHeight: 2160,
    frameSizeWidth: 3840,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.4',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 3840,
    activeHeightPixels: 1600,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 280
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 1440,
    activeHeightPixels: 1080,
    activeXoffsetPixels: 240,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.37',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 1480,
    activeHeightPixels: 1080,
    activeXoffsetPixels: 220,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.5',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 1620,
    activeHeightPixels: 1080,
    activeXoffsetPixels: 150,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.66',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 1800,
    activeHeightPixels: 1080,
    activeXoffsetPixels: 60,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.78',
    pictureFormat: 'Full',
    activeWidthPixels: 1920,
    activeHeightPixels: 1080,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.85',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 1038,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 21
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.9',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 1010,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 35
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.0',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 960,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 60
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.2',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 872,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 104
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.35',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 818,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 131
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.39',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 804,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 138
  },
  {
    frameSizeHeight: 1080,
    frameSizeWidth: 1920,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '2.4',
    pictureFormat: 'Letterbox',
    activeWidthPixels: 1920,
    activeHeightPixels: 800,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 140
  },
  {
    frameSizeHeight: 480,
    frameSizeWidth: 720,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 540,
    activeHeightPixels: 480,
    activeXoffsetPixels: 90,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 480,
    frameSizeWidth: 720,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.78',
    pictureFormat: 'Full',
    activeWidthPixels: 720,
    activeHeightPixels: 480,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 480,
    frameSizeWidth: 720,
    displayAspectRatio: '4:3',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Full',
    activeWidthPixels: 720,
    activeHeightPixels: 480,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 486,
    frameSizeWidth: 720,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 540,
    activeHeightPixels: 486,
    activeXoffsetPixels: 90,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 486,
    frameSizeWidth: 720,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.78',
    pictureFormat: 'Full',
    activeWidthPixels: 720,
    activeHeightPixels: 486,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 486,
    frameSizeWidth: 720,
    displayAspectRatio: '4:3',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Full',
    activeWidthPixels: 720,
    activeHeightPixels: 486,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 576,
    frameSizeWidth: 720,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Pillarbox',
    activeWidthPixels: 540,
    activeHeightPixels: 576,
    activeXoffsetPixels: 90,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 576,
    frameSizeWidth: 720,
    displayAspectRatio: '16:9',
    pictureAspectRatio: '1.78',
    pictureFormat: 'Full',
    activeWidthPixels: 720,
    activeHeightPixels: 576,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 576,
    frameSizeWidth: 720,
    displayAspectRatio: '4:3',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Full',
    activeWidthPixels: 720,
    activeHeightPixels: 576,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  },
  {
    frameSizeHeight: 480,
    frameSizeWidth: 640,
    displayAspectRatio: '4:3',
    pictureAspectRatio: '1.33',
    pictureFormat: 'Full',
    activeWidthPixels: 640,
    activeHeightPixels: 480,
    activeXoffsetPixels: 0,
    activeYoffsetPixels: 0
  }
]);
