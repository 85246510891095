import * as React from 'react';
import {TableRow, TableCell} from '../TableRow';
import {Tt4a} from '../../../../../../../../components/Typography/Tt4a';
import {OrderCell} from './components/OrderCell';
import {IMarkupsOrder} from '../../../../../../interfaces/IMarkupsTab';

const getCells = (props: IRemoteHeaderRowProps) => [
  <TableCell
    key={2}
    content={<OrderCell title="Timecode In" order={props.markupsOrder} onOrderChange={props.onOrderChange} />}
  />,
  <TableCell key={2} content={<Tt4a style={{color: '#8D919A'}} content="Timecode Out" />} />,
  <TableCell key={3} content={<Tt4a style={{color: '#8D919A'}} content="Remote Asset Time In" />} />,
  <TableCell key={4} content={<Tt4a style={{color: '#8D919A'}} content="Remote Asset Time Out" />} />,
  <TableCell className="header-row_action-cell" key={5} content={<Tt4a style={{color: '#8D919A'}} content="" />} />
];

interface IRemoteHeaderRowProps {
  markupsOrder: IMarkupsOrder;
  onOrderChange: (order: IMarkupsOrder) => void;
}

export const RemoteHeaderRow = (props: IRemoteHeaderRowProps) => (
  <TableRow className="header-row" cells={getCells(props)} />
);
