import * as React from 'react';
import {Icon} from 'tt-components/src/Icon/Icon';
import CustomButton from '../../../../../../components/CustomButton';
import {TimelineSettings} from '../../../TimelineSettings';
import {ILayersVisisbility, ITimelineLayers} from '../../../../interfaces';
import {ITemplateColors} from '../../../../../../../@types/templateColors';

import {SettingsIcon} from '../../../../../../assets/Icons/Settings';
import {PlusIcon} from '../../../../../../assets/Icons/Plus';
import {MinusIcon} from '../../../../../../assets/Icons/Minus';

interface IScaleLabelProps {
  onIncrease: () => void;
  onDecrease: () => void;
  disableDecreaseScale?: boolean;
  scale: number;
  templateColors: ITemplateColors;
  layersVisibility: ILayersVisisbility;
  onUpdateLayerVisibility: (layer: ITimelineLayers, visible: boolean) => void;
  disableTimeline: boolean;
}

interface IScaleLabelState {
  showSettings: boolean;
}

export class ScaleLabel extends React.PureComponent<IScaleLabelProps, IScaleLabelState> {
  constructor(props) {
    super(props);

    this.state = {
      showSettings: false
    };
  }

  render() {
    return (
      <div className="timescale-label-container">
        {this.state.showSettings && (
          <TimelineSettings
            templateColors={this.props.templateColors}
            layersVisibility={this.props.layersVisibility}
            onUpdateLayerVisibility={this.props.onUpdateLayerVisibility}
            clickedOutside={() => this.setState({showSettings: false})}
          />
        )}
        <div
          className="timescale-label-container_left"
          onClick={() => this.setState({showSettings: !this.state.showSettings})}
        >
          <Icon icon={SettingsIcon} color={'#FFF'} size="20px" />
        </div>
        {/* <div className="timescale-label-container_scale">x{this.props.scale}</div> */}
        <div className="timescale-label-container_right">
          <CustomButton
            disabled={this.props.disableDecreaseScale || this.props.disableTimeline}
            icon={<Icon icon={MinusIcon} color="#FFF" size="8px" />}
            style={buttonStyle}
            onClick={this.props.onDecrease}
          />
          <CustomButton
            disabled={this.props.disableTimeline}
            icon={<Icon icon={PlusIcon} color="#FFF" size="8px" />}
            style={buttonStyle}
            onClick={this.props.onIncrease}
          />
        </div>
      </div>
    );
  }
}

const buttonStyle: React.CSSProperties = {
  borderRadius: '50%',
  width: 20,
  height: 20,
  padding: 0,
  minWidth: 0
};
