import * as React from 'react';

interface IPictureAspectRatioFrame {
  pictureAspectRatioFrame: string;
  videoFrameDimensions: {
    width: number;
    height: number;
  };
  isFullScreen?: boolean;
}

export class PictureAspectRatioFrame extends React.PureComponent<IPictureAspectRatioFrame> {
  getCalculatedStyles = () => {
    const aspectRatio = +this.props.pictureAspectRatioFrame;
    const height = this.props.videoFrameDimensions.width / aspectRatio;
    const top = (this.props.videoFrameDimensions.height - height) / 2;
    const bottom = this.props.videoFrameDimensions.height - (height + top);
    return {height: Math.max(height || 0, 0), top: Math.max(top || 0, 0), bottom: Math.max(bottom || 0, 0)};
  };
  render() {
    const {height, top, bottom} = this.getCalculatedStyles();
    return this.props.pictureAspectRatioFrame ? (
      <div className="picture-aspect-ratio-frame">
        <div className="picture-aspect-ratio-frame_matte" style={{height: `${top}px`}} />
        <div className="picture-aspect-ratio-frame_container" style={{height: `${height}px`}}>
          <div className={`picture-aspect-ratio-frame_container_label${this.props.isFullScreen ? ` fullscreen` : ``}`}>
            {this.props.pictureAspectRatioFrame}
          </div>
        </div>
        <div className="picture-aspect-ratio-frame_matte" style={{height: `${bottom}px`}} />
      </div>
    ) : null;
  }
}
