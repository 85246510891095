import {apiRequest} from './index';
import {IResponse} from '../../@types/response';
import {PlaylistAsset} from '../models/PlaylistAsset/PlaylistAsset';
import {isBlob} from '../utils/utils';
import {serviceProvider} from '../services/serviceProvider';

export const getFileInfoById = async (vfsRecordId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.vfsHost}file/${vfsRecordId}`;
    const response = await apiRequest('GET', url);
    return {success: true, data: PlaylistAsset.parsing.parseVfsFileInfo(response || {})};
  } catch (error) {
    return {success: false, error};
  }
};

export const downloadFileById = async (vfsRecordId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.vsfHostDownload}download/${vfsRecordId}`;
    const response = await apiRequest('GET', url);
    const data = isBlob(response)
      ? window.URL.createObjectURL(response)
      : typeof response === 'string'
      ? window.URL.createObjectURL(new Blob([response], {type: 'application/xml'}))
      : '';
    return {success: true, data};
  } catch (error) {
    return {success: false, error};
  }
};

export const restoreFileById = async (vfsRecordId: string): Promise<IResponse> => {
  try {
    const url = `${serviceProvider.hosts.vfsHost}restore/${vfsRecordId}`;
    await apiRequest('POST', url);
    return {success: true};
  } catch (error) {
    return {success: false, error};
  }
};
