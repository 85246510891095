import * as React from 'react';
import {AudioConfigurations} from './components/AudioConfigurations';
import {IAudioChannelConfiguration} from '../../../../../@types/audioChannelConfiguration';
import {IEnums} from '../../../../state/IEnums';
import {IResponse} from '../../../../../@types/response';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IAssetDetails, IErrorLog} from '../../../../../@types/assetDetails';
import {deepCopy} from '../../utils/helpers';
import {IMetadataError} from '../../../../../@types/metadataErrors';

export interface IAudioProps {
  selectedAsset: PlaylistAsset;
  updatedAssetDetails: Partial<IAssetDetails>;
  processingMetadata: boolean;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  loading: boolean;
  error: string;
  closestBody?: HTMLElement;
  audioMetadataErrors: Array<IMetadataError>;
  errorLogs: Array<IErrorLog>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
  updateAudioConfigurations: (confs: Array<IAudioChannelConfiguration>) => IResponse;
}

export class Audio extends React.PureComponent<IAudioProps> {
  getAssetData = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const clonePlaylistAsset: PlaylistAsset = this.props.selectedAsset
      ? PlaylistAsset.parsing.parseSinglePlainObjectToInstance(deepCopy({...this.props.selectedAsset}))
      : null;
    if (clonePlaylistAsset) {
      clonePlaylistAsset.updateAssetDetails(updatedAssetDetails);
    }

    return clonePlaylistAsset
      ? {assetDetails: clonePlaylistAsset.assetDetails, audioMetadata: clonePlaylistAsset.audio}
      : {assetDetails: {}, audioMetadata: []};
  };

  render() {
    const {assetDetails, audioMetadata} = this.getAssetData();

    return (
      <div className="metadata-audio-container">
        <AudioConfigurations
          selectedAsset={this.props.selectedAsset}
          loading={this.props.loading}
          error={this.props.error}
          tabsDataInEditMode={this.props.tabsDataInEditMode}
          enums={this.props.enums}
          updatedAssetDetails={this.props.updatedAssetDetails}
          assetDetails={assetDetails}
          audioMetadata={audioMetadata}
          updatePartialAssetDetails={this.props.updatePartialAssetDetails}
          closestBody={this.props.closestBody}
          errorLogs={this.props.errorLogs}
          audioMetadataErrors={this.props.audioMetadataErrors}
        />
      </div>
    );
  }
}
