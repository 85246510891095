import * as React from 'react';
import {ScrollContainer} from '../../../ScrollContainer';
import {utils} from 'tt-components/src/Utils';
import {IFrameRate} from 'tt-components';

interface IScaleContentProps {
  secondUnits: number;
  width: number;
  scrollLeft: number;
  duration: number;
  frameRate: IFrameRate;
  scale: number;
}

interface IScaleContentState {
  diffInSeconds: number;
}

export class ScaleContent extends React.PureComponent<IScaleContentProps, IScaleContentState> {
  constructor(props) {
    super(props);

    this.state = {
      diffInSeconds: 60
    };
  }

  defineScaleDelimiters = (second: number) => {
    const intervalsArray = [];
    let isIntervalSecond = false;
    let isMidIntervalSecond = false;
    for (let index = 1, length = this.props.scale; index <= length; index++) {
      const lastAddedInterval = intervalsArray.length
        ? intervalsArray[intervalsArray.length - 1]
        : this.state.diffInSeconds;
      const newInterval = lastAddedInterval / index;
      intervalsArray.push(newInterval);

      // We should allow Timeline granularity until to 5 seconds and then
      // show all the seconds between this interval with the UI elements
      if (newInterval === 10) {
        break;
      }
    }

    for (let index = 0, length = intervalsArray.length; index < length; index++) {
      if (second % intervalsArray[index] === 0) {
        isIntervalSecond = true;
        break;
      }
      if (second % (intervalsArray[index] / 2) === 0) {
        isMidIntervalSecond = true;
        break;
      }
    }

    return {isIntervalSecond, isMidIntervalSecond};
  };

  renderSecond(index: number) {
    const {isIntervalSecond, isMidIntervalSecond} = this.defineScaleDelimiters(index);
    const style: React.CSSProperties = {
      height: isIntervalSecond ? 12 : isMidIntervalSecond ? 10 : 7,
      width: this.props.secondUnits,
      display: 'block'
    };
    const timeStyle =
      isIntervalSecond || isMidIntervalSecond ? {top: isIntervalSecond ? -15 : isMidIntervalSecond ? -17 : 0} : {};

    const {frameRate, dropFrame} = this.props.frameRate;
    return (
      <div key={index} className="timescale-content_content_second" style={style}>
        {(isIntervalSecond || isMidIntervalSecond) && !!index && (
          <span className="timescale-content_content_second_time" style={timeStyle}>
            {utils.formatting.getSmpteTimecode(index, frameRate, dropFrame)}
          </span>
        )}
      </div>
    );
  }

  renderSeconds() {
    const seconds = [];
    for (let index = 0, length = Math.ceil(this.props.duration); index <= length; index++) {
      seconds.push(this.renderSecond(index));
    }
    return seconds;
  }

  render() {
    const contentStyle: React.CSSProperties = {width: this.props.width};

    return (
      <ScrollContainer
        containerClassName="timescale-content_container"
        contentClassName="timescale-content_content"
        scrollLeft={this.props.scrollLeft}
        contentStyle={contentStyle}
      >
        {this.renderSeconds()}
      </ScrollContainer>
    );
  }
}
