import * as React from 'react';
import {PlaylistAsset} from 'models/PlaylistAsset/PlaylistAsset';
import {ITemplateColors} from '../../../../../../@types/templateColors';
import {CustomRadioButton} from '../../../../CustomRadio';
import {IAssetImages, IAssetNonMedia} from '../../../../../../@types/assetDetails';
import {IContentAssetConfiguration} from '../../../../../state/IVideoState';
import {CollapseConfigs} from '../../../../../components/CollapseConfigs';

interface IImageAssetsListProps {
  imageAssets: Array<PlaylistAsset>;
  nonMediaAssets: Array<PlaylistAsset>;
  contentAssetConfiguration: IContentAssetConfiguration;
  title?: string;
  templateColors: ITemplateColors;
  curationModeEnabled?: boolean;
  loadingPlaylist: boolean;
  loadingPlaylistError: string;
  loadingAudioConfig: boolean;
  updateContentAssetConfiguration: (data: Partial<IContentAssetConfiguration>) => void;
}

export class ContentAssetsList extends React.PureComponent<IImageAssetsListProps> {
  getSelectedAsset = (assetType: 'image' | 'nonMedia' = 'image') => {
    const assetId =
      this.props.contentAssetConfiguration.assetType === assetType
        ? this.props.contentAssetConfiguration.selectedAssetId
        : '';
    const restoring =
      this.props.contentAssetConfiguration.assetType === assetType
        ? this.props.contentAssetConfiguration.restoring
        : false;
    return {assetId, restoring};
  };
  isContentAssetRestoring = () => {
    return !!(this.props.contentAssetConfiguration.assetType && this.props.contentAssetConfiguration.restoring);
  };
  getAssetsList = (assetType: 'image' | 'nonMedia' = 'image') => {
    const error = this.getGenericErrorMessage(assetType);
    if (error) {
      return <div className="status-label">{error}</div>;
    }
    return [...((assetType === 'image' ? this.props.imageAssets : this.props.nonMediaAssets) || [])].reduce(
      (acc: Array<JSX.Element>, asset: PlaylistAsset, index: number) => {
        const assets: Array<JSX.Element> = [];
        const isStaged = asset.assetStatus.toLowerCase() === 'staged';
        const isAvailable = asset.vfsInfo && (asset.vfsInfo.state || '').toLowerCase() === 'available' ? true : false;
        const title = `${(asset.assetDetails && asset.assetDetails.hrId) || asset.assetId}${
          asset.assetDetails && asset.assetDetails.name ? ` - ${asset.assetDetails.name}` : ``
        }`;
        (asset.assetDetails[assetType === 'image' ? 'images' : 'nonMedia'] as Array<
          IAssetImages | IAssetNonMedia
        >).forEach((data: IAssetImages | IAssetNonMedia, index: number) => {
          const classes = [
            isStaged && isAvailable ? `` : `not-staged`,
            !asset.isRegistered && this.props.curationModeEnabled ? `not-registered` : ``
          ]
            .filter(token => token)
            .join(' ');
          const identifier = `${asset.assetId}_${data.id}_${index}`;
          assets.push(
            <CustomRadioButton
              className={classes}
              disabled={this.props.loadingAudioConfig || this.isContentAssetRestoring()}
              key={identifier}
              index={identifier}
              id={identifier}
              value={asset.assetId}
              text={title}
              selectedValue={this.getSelectedAsset(assetType).assetId || ''}
              onChange={e => this.props.updateContentAssetConfiguration({selectedAssetId: e.target.value, assetType})}
              templateColors={this.props.templateColors}
            />
          );
        });
        return [...acc, ...assets];
      },
      []
    );
  };

  getGenericErrorMessage = (assetType: 'image' | 'nonMedia' = 'image') => {
    return this.props.loadingPlaylist
      ? 'Loading playlist...'
      : this.props.loadingPlaylistError
      ? this.props.loadingPlaylistError
      : !(assetType === 'image' ? this.props.imageAssets.length : this.props.nonMediaAssets.length)
      ? `Empty ${assetType === 'image' ? `Images` : `Non Medias`}`
      : '';
  };

  render() {
    return (
      <div className="assets-list image-assets-container">
        <div className="assets-list_title" style={{color: this.props.templateColors.main}}>
          {this.props.title || 'Images/Non Media'}
        </div>
        <div className="assets-list_content">
          <div className="assets-list_content_checkboxes">
            <CollapseConfigs label="Images" collapsed>
              {this.getAssetsList()}
            </CollapseConfigs>
            <CollapseConfigs label="Non Media" collapsed>
              {this.getAssetsList('nonMedia')}
            </CollapseConfigs>
          </div>
        </div>
      </div>
    );
  }
}
