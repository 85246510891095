import {
  IAssetImages,
  IAssetNonMedia,
  ISubtitlesData,
  IAssetVideo,
  IAudioData,
  IAudioDataTrackDetail,
  IAssetVideoSubtitleLanguage
} from '../../../../@types/assetDetails';

const imageMetadataDefault: IAssetImages = Object.freeze({
  description: null,
  frameSizeHeight: null,
  frameSizeWidth: null,
  graphicsType: null,
  imageCodec: null,
  language: null,
  country: null
});

const nonMediaMetadataDefault: IAssetNonMedia = Object.freeze({
  description: null,
  type: null,
  language: null,
  country: null,
  subType: null
});

const subtitleMetadataDefault: ISubtitlesData = Object.freeze({
  description: null,
  embedType: null,
  subtitleFormat: null,
  format: null,
  coding: null,
  language: null,
  country: null,
  cardsetList: null
});

const videoMetadataDefault: IAssetVideo = Object.freeze({
  description: null,
  type: null,
  subType: null,
  isGoldenMaster: null,
  videoCodec: null,
  encodeRate: null,
  subtitleLanguage: null,
  colorType: null,
  pictureFormat: null,
  captureMethod: null,
  bitc: null,
  language: null,
  country: null,
  cardsetList: null,
  frameSizeHeight: null,
  frameSizeWidth: null,
  videoDetail: {
    bitDepth: null,
    mpegProfile: null,
    mpegLevel: null,
    mpeg2: null,
    st2086: null,
    avc: null,
    colorModel: null,
    peakBitRate: null,
    dynamicRange: null,
    signalRange: null,
    hevc: null,
    j2k: null,
    maxfall: null,
    maxcll: null,
    averageBitRate: null,
    actualLength: null,
    pixelAspect: null,
    displayAspectRatio: null,
    pictureAspectRatio: null,
    activeWidthPixels: null,
    activeHeightPixels: null,
    activeXoffsetPixels: null,
    activeYoffsetPixels: null,
    whitePoint: null,
    frameRate: null,
    scanType: null,
    fieldOrder: null,
    colorSubSampling: null,
    colorimetry: null,
    colorEncoding: null
  }
});

const audioMetadataDefault: IAudioData = Object.freeze({
  description: null,
  type: null,
  audioLanguage: {dubbed: false, forced: false},
  channelConfig: null,
  language: null,
  country: null,
  trackDetail: []
});

const audioTrackMetadataDefault: IAudioDataTrackDetail = Object.freeze({
  averageBitRate: null,
  bitDepth: null,
  bitRateMode: null,
  dbLevel: null,
  elementType: null,
  audioCodec: null,
  peakBitRate: null,
  sampleRate: null,
  track: null,
  subType: null,
  watermark: null,
  channelMap: [],
  actualLength: null
});

const videoSubtitleLanguageDefault: IAssetVideoSubtitleLanguage = Object.freeze({
  language: null,
  country: null,
  closed: false,
  type: null
});

export const defaults = {
  imageMetadataDefault,
  nonMediaMetadataDefault,
  subtitleMetadataDefault,
  videoMetadataDefault,
  audioMetadataDefault,
  audioTrackMetadataDefault,
  videoSubtitleLanguageDefault
};
