import * as React from 'react';
import {HeaderRow} from './components/HeaderRow/HeaderRow';
import {RemoteHeaderRow} from './components/HeaderRow/RemoteHeaderRow';
import {IMarkupEvent} from '../../../../../../../@types/markupEvent';
import {IFrameRate} from 'tt-components';
import {EventRow} from './components/EventRow';
import {Tt2b} from '../../../../../../components/Typography/Tt2b';
import {IParsedVTT} from '../../../../../../../@types/parsedVtt';
import {IMarkupsEventError} from '../../../../../../../@types/markupsError';
import {deepCopy} from '../../../../utils/helpers';
import {Smpte} from '../../../../../../models/Smpte/Smpte';
import {IMarkupsOrder} from '../../../../interfaces/IMarkupsTab';
import {random} from 'tt-components/src/Utils/random';

interface IEventsTableProps {
  markupsOrder: IMarkupsOrder;
  events: Array<IMarkupEvent>;
  duration: number;
  selectedGroup: string;
  enableCategory?: boolean;
  enableNotes?: boolean;
  types: Array<string>;
  categories: Array<string>;
  frameRate: IFrameRate;
  jsonVTT: Array<IParsedVTT>;
  closestBody?: HTMLElement;
  onTime: (time: number) => void;
  removeMarkup: (eventId: string) => void;
  selectTab: (timeIn: string, timeOut: string) => void;
  updateEvent: (event: IMarkupEvent) => void;
  addEvent: (id: string, name: string, events: string) => void;
  tabsInEditMode: boolean;
  eventsErrors: Array<IMarkupsEventError>;
  getPlayerCurrentTime: () => number;
  onOrderChange: (order: IMarkupsOrder) => void;
}

export class EventsTable extends React.PureComponent<IEventsTableProps> {
  convertToSecond = (time: string) => {
    const smpte = new Smpte(time, {
      frameRate: this.props.frameRate.frameRate,
      dropFrame: this.props.frameRate.dropFrame
    });
    return smpte.toAdjustedTime();
  };

  getContent() {
    if (!this.props.events.length) {
      return <Tt2b content="There are no events to show" style={{color: '#fff'}} />;
    }

    const events = deepCopy([...this.props.events]);

    events.sort((a: IMarkupEvent, b: IMarkupEvent) => {
      const timeInASeconds = this.convertToSecond(a.timeIn || '00:00:00:00');
      const timeInBSeconds = this.convertToSecond(b.timeIn || '00:00:00:00');
      return this.props.markupsOrder === 'asc' ? timeInASeconds - timeInBSeconds : timeInBSeconds - timeInASeconds;
    });

    events.sort((a: IMarkupEvent, b: IMarkupEvent) => {
      return a.newRecord ? -1 : 1;
    });

    return events
      .filter((event: IMarkupEvent) => !event.hidden)
      .map((event: IMarkupEvent, index: number) => {
        const error = (this.props.eventsErrors || []).find((error: IMarkupsEventError) => error.id === event.id);
        return (
          <EventRow
            getPlayerCurrentTime={this.props.getPlayerCurrentTime}
            error={error}
            key={`${random.string(10, 'event-row-')}`}
            event={event}
            duration={this.props.duration}
            selectedGroup={this.props.selectedGroup}
            enableCategory={this.props.enableCategory}
            enableNotes={this.props.enableNotes}
            types={this.props.types}
            categories={this.props.categories}
            frameRate={this.props.frameRate}
            selectTab={this.props.selectTab}
            removeMarkup={this.props.removeMarkup}
            updateEvent={this.props.updateEvent}
            jsonVTT={this.props.jsonVTT}
            onTime={this.props.onTime}
            addEvent={this.props.addEvent}
            closestBody={this.props.closestBody}
            tabsInEditMode={this.props.tabsInEditMode}
          />
        );
      });
  }

  render() {
    const empty = !this.props.events.length ? ` empty` : ``;
    const isRemoteMarkup = ['Chapter', 'Textless'].indexOf(this.props.selectedGroup) !== -1;
    return (
      <div className={`events-table-container${isRemoteMarkup ? ` remote-content` : ``}`}>
        {isRemoteMarkup ? (
          <RemoteHeaderRow markupsOrder={this.props.markupsOrder} onOrderChange={this.props.onOrderChange} />
        ) : (
          <HeaderRow markupsOrder={this.props.markupsOrder} onOrderChange={this.props.onOrderChange} />
        )}
        <div className={`events-table-container_content${empty}`}>{this.getContent()}</div>
      </div>
    );
  }
}
