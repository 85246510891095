import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon';

export const VolumeBarTailIcon = (props: Partial<IIconProps>) => {
  return (
    <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M29.6679687,0.716581119 L26.6156873,0.716581119 C24.8657033,0.716581119 23.3834054,2.00637899
          23.1415268,3.73956646 L18.5317029,36.7713867 C18.1438832,39.5503195 15.5767231,41.4886997
          12.7977903,41.1008799 C10.5494119,40.7871028 8.7820742,39.0197652 8.4682971,36.7713867
          L3.87656023,3.86916983 C3.6243117,2.06167603 2.0784638,0.716581119 0.253453351,0.716581119 Z"
          id="Rectangle-17"
          fill={props.color}
        />
      </g>
    </svg>
  );
};
