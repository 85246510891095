import * as React from 'react';
import {
  IAudioDataTrackDetail,
  IAssetDetails,
  IErrorLog,
  IAudioData
} from '../../../../../../../../../@types/assetDetails';
import {Collapsible} from '../../../../../Collapsible';
import {TrackHeader} from './components/TrackHeader';
import {IEnums} from '../../../../../../../../state/IEnums';
import {IDropdownOption, Dropdown} from '../../../../../../../../components/Dropdown';
import {IEnum} from '../../../../../../../../../@types/enum';
import {getErrorLogByField, deepCopy, updateAssetDetailsAudioTrackProp} from '../../../../../../utils/helpers';
import {Row, Column, Container} from '../../../../../Grid';
import {Tt1} from '../../../../../../../../components/Typography';
import {TextField} from '../../../../../../../../components/TextField';
import {IMetadataError} from '../../../../../../../../../@types/metadataErrors';

interface IAudioConfigurationEditableTrackProps {
  index: number;
  isOpen: boolean;
  track: IAudioDataTrackDetail;
  audioConfigId: string;
  assetDetails: IAssetDetails;
  updatedAssetDetails: Partial<IAssetDetails>;
  enums: IEnums;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  audioMetadataErrors: Array<IMetadataError>;
  updatePartialAssetDetails: (assetDetails: IAssetDetails) => void;
  onBreak: (breakUp: boolean, configId: string, trackIndex: number) => void;
}

interface IAudioConfigurationEditableTrackState {
  openTrackDetails: boolean;
}

const convertEnums = (enums: Array<IEnum>): Array<IDropdownOption> =>
  enums.reduce((acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}], []);

export class AudioConfigurationEditableTrack extends React.PureComponent<
  IAudioConfigurationEditableTrackProps,
  IAudioConfigurationEditableTrackState
> {
  constructor(props) {
    super(props);

    this.state = {
      openTrackDetails: false
    };
  }

  onCollapseToggle = val => {
    this.setState({
      openTrackDetails: val
    });
  };

  updateTrackProp = (name: string, value: string | number) => {
    const data = value !== '' ? value : null;
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const audio = updateAssetDetailsAudioTrackProp(
      this.props.assetDetails.audio,
      name,
      data,
      this.props.audioConfigId,
      this.props.track.id
    );
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  onBreak = (breakUp: boolean) => {
    this.props.onBreak(breakUp, this.props.audioConfigId, this.props.index);
  };

  onRemoveTrackConfiguration = () => {
    const updatedAssetDetails = deepCopy({...this.props.updatedAssetDetails});
    const assetDetails = deepCopy({...this.props.assetDetails});
    const audio = (assetDetails.audio || []).reduce((acc: Array<IAudioData>, record: IAudioData) => {
      if (record.id === this.props.audioConfigId) {
        record.trackDetail = record.trackDetail.reduce(
          (acc: Array<IAudioDataTrackDetail>, track: IAudioDataTrackDetail) => {
            if (track.id === this.props.track.id) {
              return [...acc];
            }
            return [...acc, track];
          },
          []
        );
      }
      return [...acc, record];
    }, []);
    this.props.updatePartialAssetDetails({...updatedAssetDetails, audio});
  };

  getPanelTitle = () => {
    return (
      <TrackHeader
        channelMapEnums={this.props.enums.channelMap}
        track={this.props.track}
        breakUp={this.props.index === 0}
        style={{flex: 1}}
        audioMetadataErrors={this.props.audioMetadataErrors}
        errorLogs={this.props.errorLogs}
        closestBody={this.props.closestBody}
        onBreak={this.onBreak}
        onUpdateTrackProp={this.updateTrackProp}
        onRemoveTrackConfiguration={this.onRemoveTrackConfiguration}
      />
    );
  };

  getContent = () => {
    const trackDetailEntry = this.props.track;
    const content = (
      <div className="audio-config-container">
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Tt1 content={`Track ${trackDetailEntry.track}`} className="audio-details-container_row_label" />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              field={{field: 'audio.trackDetail.channelMap', errors: this.props.audioMetadataErrors}}
              multiple
              search
              isOpen={this.props.isOpen && this.state.openTrackDetails}
              floatingTextNode
              options={convertEnums(this.props.enums.channelMap)}
              onSelected={value => this.updateTrackProp('channelMap', value)}
              selected={this.props.track.channelMap}
              label="Channel Map"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.channelMap', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <TextField
              label="Average Bit Rate"
              text={trackDetailEntry.averageBitRate || ''}
              editMode
              onChange={value => this.updateTrackProp('averageBitRate', value)}
              onlyNumbers
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              isOpen={this.props.isOpen && this.state.openTrackDetails}
              floatingTextNode
              options={convertEnums(this.props.enums.audioBitDepth)}
              onSelected={value => this.updateTrackProp('bitDepth', value)}
              selected={trackDetailEntry.bitDepth}
              label="Bit Depth"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.bitDepth', this.props.errorLogs)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              isOpen={this.props.isOpen && this.state.openTrackDetails}
              floatingTextNode
              options={convertEnums(this.props.enums.bitRateMode)}
              onSelected={value => this.updateTrackProp('bitRateMode', value)}
              selected={trackDetailEntry.bitRateMode}
              label="Bit Rate Mode"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.bitRateMode', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <TextField
              label="db Level"
              text={trackDetailEntry.dbLevel || ''}
              editMode
              onChange={value => this.updateTrackProp('dbLevel', value)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <TextField
              label="Element Type"
              text={trackDetailEntry.elementType || ''}
              editMode
              onChange={value => this.updateTrackProp('elementType', value)}
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <Dropdown
              search
              isOpen={this.props.isOpen && this.state.openTrackDetails}
              floatingTextNode
              options={convertEnums(this.props.enums.audioCodec)}
              onSelected={name => this.updateTrackProp('audioCodec', name)}
              selected={trackDetailEntry.audioCodec}
              label="Audio Codec"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.audioCodec', this.props.errorLogs)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <TextField
              label="Peak Bit Rate"
              text={trackDetailEntry.peakBitRate || ''}
              editMode
              onChange={value => this.updateTrackProp('peakBitRate', value)}
              onlyNumbers
            />
          </Column>
          <Column className={`audio-details-container_row_column `}>
            <TextField
              label="Sample Rate"
              text={trackDetailEntry.sampleRate || ''}
              editMode
              onChange={value => this.updateTrackProp('sampleRate', value)}
              onlyNumbers
            />
          </Column>
          <Column className="audio-details-container_row_column">
            <TextField
              label="Actual Length"
              text={trackDetailEntry.actualLength || ''}
              editMode
              onChange={value => this.updateTrackProp('actualLength', value)}
            />
          </Column>
        </Row>
        <Row className="audio-details-container_row">
          <Column className="audio-details-container_row_column">
            <Dropdown
              field={{field: 'audio.trackDetail.subType', errors: this.props.audioMetadataErrors}}
              search
              isOpen={this.props.isOpen && this.state.openTrackDetails}
              floatingTextNode
              options={convertEnums(this.props.enums.audioSubType)}
              onSelected={value => this.updateTrackProp('subType', value)}
              selected={trackDetailEntry.subType}
              label="Sub Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('audio.trackDetail.subType', this.props.errorLogs)}
            />
          </Column>
          <Column className="audio-details-container_row_column" />
          <Column className="audio-details-container_row_column" />
        </Row>
      </div>
    );

    return <Container className="audio-details-container">{content}</Container>;
  };

  render() {
    return (
      <Collapsible
        blockTriggerOnSelectores={[
          '.dropdown-ui-container',
          '.dropdown-content',
          '.break-icon',
          '.icon-inner-container'
        ]}
        className="audio-editable-track-container"
        title={this.getPanelTitle()}
        onToggle={this.onCollapseToggle}
        open={this.state.openTrackDetails}
        content={this.getContent()}
      />
    );
  }
}
