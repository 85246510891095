import * as React from 'react';
import {Container, Row, Column} from '../../../../../Grid';
import {Dropdown, IDropdownOption} from '../../../../../../../../components/Dropdown';
import {Toggle} from '../../../../../../../../components/Toggle';
import {Feature} from './components/Feature';
import {Series} from './components/Series';
import {ISearchTitle} from '../../../../../../../../../@types/searchTitle';
import {IMetadataError} from '../../../../../../../../../@types/metadataErrors';
import {IOneCustomer} from '../../../../../../../../../@types/oneCustomer';
import {IMetadataMassUpdateFields} from '../../../../../../interfaces/IMetadataTab';

interface ITitleInfoProps {
  selectedType: 'Feature' | 'Series' | 'Season' | 'Episode' | string;
  assetId: string;
  isTitleInfoOpen: boolean;
  versionId: string;
  conformanceGroupId: string;
  titleId: string;
  seasonId: string;
  episodeId: string;
  closestBody?: HTMLElement;
  disabled: boolean;
  tabsContainer: HTMLElement;
  errors: Array<IMetadataError>;
  contentOwners: Array<IOneCustomer>;
  contentProviders: Array<IOneCustomer>;
  metadataMassUpdateFields: Array<IMetadataMassUpdateFields>;
  onTitleInfoUpdated: (updatedTitles: ISearchTitle) => void;
  onMetadataMassUpdateField: (field: IMetadataMassUpdateFields, remove?: boolean) => void;
}

interface ITitleInfoState {
  selectedType: any;
}

export class TitleInfo extends React.PureComponent<ITitleInfoProps, ITitleInfoState> {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: ''
    };
  }

  componentDidMount() {
    this.onTypeSelected(this.defineSelectedType(this.props));
  }

  componentDidUpdate(prevProps: ITitleInfoProps, prevState: ITitleInfoState) {
    if (
      this.state.selectedType !== this.props.selectedType &&
      this.props.selectedType === prevProps.selectedType &&
      this.state.selectedType !== prevState.selectedType
    ) {
      this.onTypeSelected(this.state.selectedType);
    } else if (this.state.selectedType !== this.props.selectedType) {
      this.onTypeSelected(this.defineSelectedType(this.props));
    }
  }

  defineSelectedType = (props: ITitleInfoProps) => {
    return ['Series', 'Season', 'Episode'].indexOf(props.selectedType) !== -1
      ? !props.titleId
        ? ''
        : 'Series'
      : !props.titleId
      ? ''
      : props.selectedType;
  };

  onTypeSelected = (type: string) => {
    this.setState({selectedType: type});
  };

  onPropagateTitleInfoToggle = () => {
    const toggled = this.props.metadataMassUpdateFields.indexOf('titles') !== -1;
    this.props.onMetadataMassUpdateField('titles', toggled);
  };

  getTypeDropdownContainer = () => {
    const options: Array<IDropdownOption> = [{label: 'Feature', value: 'Feature'}, {label: 'Series', value: 'Series'}];
    return (
      <Row className="title-info-container_type-options-row">
        <Column className="title-info-container_type-options-row_column">
          <Dropdown
            label="Type"
            disabled={this.props.disabled}
            selectedPlaceholder="Select type..."
            selected={this.state.selectedType}
            options={options}
            onSelected={this.onTypeSelected}
          />
        </Column>
        <Column className="title-info-container_type-options-row_column">
          <Toggle
            label="Propagate Title Info"
            disabled={this.props.disabled || !this.state.selectedType}
            toggled={this.props.metadataMassUpdateFields.indexOf('titles') !== -1}
            onToggle={this.onPropagateTitleInfoToggle}
          />
        </Column>
        <Column className="title-info-container_type-options-row_column" />
      </Row>
    );
  };

  getContent = () => {
    let section: JSX.Element;
    switch (this.state.selectedType) {
      case 'Feature':
        const featureTitleData =
          this.defineSelectedType(this.props) === 'Feature'
            ? {
                featureId: this.props.titleId,
                versionId: this.props.versionId,
                conformanceGroupId: this.props.conformanceGroupId
              }
            : {
                featureId: '',
                versionId: '',
                conformanceGroupId: ''
              };
        section = (
          <Feature
            assetId={this.props.assetId}
            isTitleInfoOpen={this.props.isTitleInfoOpen}
            {...featureTitleData}
            closestBody={this.props.closestBody}
            disabled={this.props.disabled || !this.state.selectedType}
            tabsContainer={this.props.tabsContainer}
            errors={this.props.errors}
            contentOwners={this.props.contentOwners}
            contentProviders={this.props.contentProviders}
            onTitleInfoUpdated={this.props.onTitleInfoUpdated}
          />
        );
        break;
      case 'Series':
        const seriesTitleData =
          this.defineSelectedType(this.props) === 'Series'
            ? {
                versionId: this.props.versionId,
                conformanceGroupId: this.props.conformanceGroupId,
                seriesId: this.props.titleId,
                seasonId: this.props.seasonId,
                episodeId: this.props.episodeId
              }
            : {
                versionId: '',
                conformanceGroupId: '',
                seriesId: '',
                seasonId: '',
                episodeId: ''
              };
        section = (
          <Series
            assetId={this.props.assetId}
            isTitleInfoOpen={this.props.isTitleInfoOpen}
            {...seriesTitleData}
            closestBody={this.props.closestBody}
            disabled={this.props.disabled || !this.state.selectedType}
            tabsContainer={this.props.tabsContainer}
            errors={this.props.errors}
            contentOwners={this.props.contentOwners}
            contentProviders={this.props.contentProviders}
            onTitleInfoUpdated={this.props.onTitleInfoUpdated}
          />
        );
        break;
      default:
        section = null;
    }
    return (
      <>
        {this.getTypeDropdownContainer()}
        {section}
      </>
    );
  };

  render() {
    return <Container className="title-info-container">{this.getContent()}</Container>;
  }
}
