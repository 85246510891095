import * as React from 'react';
import {CollapisbleWithEditModeToggle} from '../../../Collapsible';
import {ISubtitlesData, IErrorLog} from '../../../../../../../@types/assetDetails';
import {IEnums} from '../../../../../../state/IEnums';
import {mapSubtitleLanguageCountry} from '../../../../../../utils/utils';
import {Container, Row, Column} from '../../../Grid';
import {IEnum} from '../../../../../../../@types/enum';
import {IDropdownOption, Dropdown} from '../../../../../../components/Dropdown';
import {LanguageDropdown} from '../../../LanguageDropdown';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';
import {getErrorLogByField} from '../../../../utils/helpers';
import {TextField} from '../../../../../../components/TextField';

interface ISubtitleRecordProps {
  subtitle: ISubtitlesData;
  tabsDataInEditMode: boolean;
  subtitlesMetadataErrors: Array<IMetadataError>;
  enums: IEnums;
  openCollapsibleContent: boolean;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  onCollapsibleToggle: (open: boolean) => void;
  updateProp: (subtitleId, prop: string, value: string | number) => void;
}

const convertEnums = (enumList: Array<IEnum>): Array<IDropdownOption> =>
  enumList.reduce(
    (acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}],
    []
  );

export class SubtitleRecord extends React.PureComponent<ISubtitleRecordProps> {
  getTitle = () => {
    const languageCountry = mapSubtitleLanguageCountry(
      this.props.subtitle.language,
      this.props.subtitle.country,
      this.props.enums.languageDialect,
      ''
    );
    return `${languageCountry || ``}${
      this.props.subtitle.format
        ? languageCountry
          ? ` - ${this.props.subtitle.format}`
          : this.props.subtitle.format
        : languageCountry
        ? ``
        : `...`
    }`;
  };

  onLanguageSelected = (language: string, country: string) => {
    setTimeout(() => this.props.updateProp(this.props.subtitle.id, 'language', language), 200);
    setTimeout(() => this.props.updateProp(this.props.subtitle.id, 'country', country), 200);
  };

  getContent = () => {
    const content = (
      <>
        <Row className="subtitle-record-container_row">
          <Column className="subtitle-record-container_row_column">
            <LanguageDropdown
              disabled={!this.props.tabsDataInEditMode}
              field={{field: 'subtitles.language', errors: this.props.subtitlesMetadataErrors}}
              search
              languageDialect={this.props.enums.languageDialect}
              onLanguageSelected={this.onLanguageSelected}
              language={this.props.subtitle.language}
              country={this.props.subtitle.country}
              label="Language"
              portalNode={this.props.closestBody}
              toLowerCaseCompare
              errorLogs={this.props.errorLogs}
            />
          </Column>
          <Column className="subtitle-record-container_row_column">
            <Dropdown
              disabled={!this.props.tabsDataInEditMode}
              search
              options={convertEnums(this.props.enums.subtitleFormat)}
              onSelected={value => this.props.updateProp(this.props.subtitle.id, 'format', value)}
              selected={this.props.subtitle.format}
              label="Format"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('subtitle.format', this.props.errorLogs)}
            />
          </Column>
          <Column
            className={`subtitle-record-container_row_column${!this.props.tabsDataInEditMode ? ` disabled` : ``}`}
          >
            <TextField
              label="Coding"
              text={this.props.subtitle.coding || ''}
              editMode={true}
              onChange={value => this.props.updateProp(this.props.subtitle.id, 'coding', value)}
            />
          </Column>
          <Column
            className={`subtitle-record-container_row_column${!this.props.tabsDataInEditMode ? ` disabled` : ``}`}
          >
            <TextField label="Embed Type" text={this.props.subtitle.embedType || ''} />
          </Column>
        </Row>
      </>
    );

    return <Container className="subtitle-record-container">{content}</Container>;
  };

  render() {
    return (
      <CollapisbleWithEditModeToggle
        tabsInEditMode={this.props.tabsDataInEditMode}
        open={this.props.openCollapsibleContent}
        title={this.getTitle()}
        content={this.getContent()}
        onToggle={this.props.onCollapsibleToggle}
      />
    );
  }
}
