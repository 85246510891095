import * as React from 'react';
import * as isEqual from 'deep-equal';
import {ISearchTitle} from '../../../@types/searchTitle';
import {T1, Tt3, Tt2b} from '../Typography';
import {parseSeasonNumber} from '../../modules/Tabs/utils/helpers';
import {PlaylistAsset} from '../../models/PlaylistAsset/PlaylistAsset';

interface ITitleHeaderProps {
  selectedAsset: PlaylistAsset;
}

interface ITitleHeaderState {
  seriesContent: string;
  seasonContent: string;
  titleContent: string;
  versionContent: string;
}

export class TitleHeader extends React.Component<ITitleHeaderProps, ITitleHeaderState> {
  constructor(props) {
    super(props);

    this.state = {
      seriesContent: '',
      seasonContent: '',
      titleContent: '',
      versionContent: ''
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: ITitleHeaderProps) {
    const prevAsset = prevProps.selectedAsset ? prevProps.selectedAsset.getTitleInfoForAsset() : null;
    const currentAsset = this.props.selectedAsset ? this.props.selectedAsset.getTitleInfoForAsset() : null;
    if (!isEqual(prevAsset, currentAsset) && currentAsset) {
      this.init();
    }
  }

  getEpisodeElement = () => {
    const content = [];
    if (this.state.seriesContent) {
      content.push(`${this.state.seriesContent}`);
    }
    if (this.state.seasonContent) {
      content.push(`S${parseSeasonNumber(this.state.seasonContent)} - ${this.state.seasonContent}`);
    }
    return content.length ? <Tt2b content={content.join(' | ')} className="title-header-container_episode" /> : null;
  };

  getTitleElement = () => {
    return this.state.titleContent ? (
      <T1 content={this.state.titleContent} className="title-header-container_title" />
    ) : null;
  };

  getVersionElement = () => {
    return this.state.versionContent ? (
      <Tt3 content={this.state.versionContent || 'VERSION NAME'} className="title-header-container_version" />
    ) : null;
  };

  init = async () => {
    let seriesContent = '';
    let seasonContent = '';
    let titleContent = '';
    let versionContent = '';

    if (this.props.selectedAsset) {
      const titles = this.props.selectedAsset.titles || [];
      const title = titles.find(
        (title: ISearchTitle) => ['Feature', 'Series', 'Season', 'Episode'].indexOf(title.type) !== -1
      );
      titleContent = title ? title.name || '' : '';

      const version = titles.find(
        (title: ISearchTitle) => ['FeatureVersion', 'EpisodeVersion'].indexOf(title.type) !== -1
      );
      versionContent = version ? version.name || '' : '';
      const series = titles.find((title: ISearchTitle) => title.type === 'Series');
      seriesContent = series ? series.name || '' : '';
      const season = titles.find((title: ISearchTitle) => title.type === 'Season');
      seasonContent = season ? season.name || '' : '';
    }

    this.setState({seriesContent, seasonContent, titleContent, versionContent});
  };

  render() {
    return (
      <div className="title-header-container">
        {this.getEpisodeElement()}
        {this.getTitleElement()}
        {this.getVersionElement()}
      </div>
    );
  }
}
