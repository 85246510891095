import * as React from 'react';
import {Icon, IIconProps} from 'tt-components/src/Icon';

interface IFabButtonProps {
  icon: React.ComponentType<IIconProps>;
  className?: string;
  iconColor?: string;
  iconSize?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export class FabButton extends React.PureComponent<IFabButtonProps> {
  static defaultProps = {
    iconColor: '#fff',
    iconSize: '22px'
  };

  render() {
    return (
      <button
        disabled={this.props.disabled}
        className={`fab-button-container${this.props.className ? ` ${this.props.className}` : ``}`}
        onClick={e => (this.props.onClick ? this.props.onClick() : null)}
      >
        <Icon icon={this.props.icon as any} color={this.props.iconColor} size={this.props.iconSize} />
      </button>
    );
  }
}
