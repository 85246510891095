import * as React from 'react';
import {Tt4a, Tt2b} from '../Typography';
import {escapeNonNumeric} from '../../utils/utils';
import {IField, IFieldError} from '../../interfaces/IFieldError';
import {ErrorPayload} from '../../models/ErrorPayload/ErrorPayload';
import {IMetadataError} from '../../../@types/metadataErrors';

interface ITextFieldProps {
  label: string;
  text: string | number;
  field?: IField;
  editMode?: boolean;
  onlyNumbers?: boolean;
  error?: string | boolean;
  onChange?: (value: string | number) => void;
}

export class TextField extends React.PureComponent<ITextFieldProps> implements IFieldError {
  parseValue = (value: string) => {
    if (this.props.onlyNumbers) {
      const parsed = parseInt(escapeNonNumeric(value));
      return isNaN(parsed) ? (value === '' ? value : 0) : parsed;
    }
    return value;
  };

  getContent = () => {
    const readOnlyText = <Tt2b content={this.props.text} className="text-field-container_content_text" />;
    const inputField = (
      <input
        type="text"
        className="text-field-container_content_input"
        value={this.props.text}
        onChange={e => (this.props.onChange ? this.props.onChange(this.parseValue(e.target.value)) : null)}
      />
    );
    return (
      <div className={`text-field-container_content${this.props.editMode ? ` edit` : ``}`}>
        {this.props.editMode ? inputField : readOnlyText}
      </div>
    );
  };

  getErrorMessage = () => {
    const error = this.defineError();
    if (error) {
      const content =
        typeof error === 'string' ? error : (error as IMetadataError).message ? (error as IMetadataError).message : ``;
      return content && <div className="text-field-container_error-row">{content}</div>;
    }
    return null;
  };

  defineError = () => {
    if (this.props.error) {
      return this.props.error;
    }
    if (this.props.field) {
      const error = ErrorPayload.getMetadataError(this.props.field.field, this.props.field.errors || []);
      return error || false;
    }
    return false;
  };

  getRequiredCssClass = () => {
    if (!this.props.field) {
      return '';
    }
    return ErrorPayload.getRequiredCSSClass(this.props.field.field || '', this.props.field.selectedAsset || null)
      ? ` ${ErrorPayload.getRequiredCSSClass(this.props.field.field || '', this.props.field.selectedAsset || null)}`
      : ``;
  };

  render() {
    const error = !!this.defineError() ? ` error` : ``;
    const required = this.getRequiredCssClass();
    return (
      <div className={`text-field-container${error}${required}`}>
        {!!this.props.label && <Tt4a content={this.props.label} className="text-field-container_label" />}
        {this.getContent()}
        {this.getErrorMessage()}
      </div>
    );
  }
}
