import * as React from 'react';
import {deepCopy} from '../../utils/helpers';
import {IAssetDetails, IErrorLog, ISubtitlesData} from '../../../../../@types/assetDetails';
import {PlaylistAsset} from '../../../../models/PlaylistAsset/PlaylistAsset';
import {IEnums} from '../../../../state/IEnums';
import {Tt2b} from '../../../../components/Typography';
import {IMetadataError} from '../../../../../@types/metadataErrors';
import {SubtitleRecord} from './components/SubtitleRecord';

interface ISubtitlesProps {
  selectedAsset: PlaylistAsset;
  enums: IEnums;
  tabsDataInEditMode: boolean;
  updatedAssetDetails: Partial<IAssetDetails>;
  error: string;
  closestBody?: HTMLElement;
  errorLogs: Array<IErrorLog>;
  subtitlesMetadataErrors: Array<IMetadataError>;
  updatePartialAssetDetails: (assetDetails: Partial<IAssetDetails>) => void;
}

interface ISubtitlesState {
  openCollapsibleContent: boolean;
}

export class Subtitles extends React.Component<ISubtitlesProps, ISubtitlesState> {
  constructor(props) {
    super(props);

    this.state = {
      openCollapsibleContent: this.props.tabsDataInEditMode
    };
  }
  getAssetData(): IAssetDetails {
    const currentAssetDetails =
      this.props.selectedAsset && this.props.selectedAsset.assetDetails ? this.props.selectedAsset.assetDetails : {};
    const updatedAssetDetails = this.props.updatedAssetDetails || {};
    return deepCopy({...currentAssetDetails, ...updatedAssetDetails});
  }

  handlePropUpdate = (subtitleId: string, prop: string, value: string | number) => {
    const currentAssetDetails = this.getAssetData();
    const subtitles = (currentAssetDetails.subtitles || []).reduce(
      (acc: Array<ISubtitlesData>, subtitle: ISubtitlesData) => {
        if (subtitle.id === subtitleId) {
          subtitle = {...subtitle, [prop]: value};
        }
        return [...acc, subtitle];
      },
      []
    );
    this.props.updatePartialAssetDetails({...this.props.updatedAssetDetails, subtitles});
  };

  getContent = () => {
    const subtitles = this.getAssetData().subtitles || [];
    if (!subtitles.length) {
      return (
        <Tt2b
          className="metadata-subtitles-container_empty"
          content="No Subtitle configurations are defined for this asset"
        />
      );
    }
    return subtitles.reduce((acc: Array<JSX.Element>, subtitle: ISubtitlesData, index: number) => {
      const subtitleElement = (
        <SubtitleRecord
          key={index}
          tabsDataInEditMode={this.props.tabsDataInEditMode}
          subtitle={subtitle}
          subtitlesMetadataErrors={this.props.subtitlesMetadataErrors}
          enums={this.props.enums}
          openCollapsibleContent={this.state.openCollapsibleContent}
          closestBody={this.props.closestBody}
          errorLogs={this.props.errorLogs}
          onCollapsibleToggle={(openCollapsibleContent: boolean) => this.setState({openCollapsibleContent})}
          updateProp={this.handlePropUpdate}
        />
      );
      return [...acc, subtitleElement];
    }, []);
  };

  render() {
    return <div className="metadata-subtitles-container">{this.getContent()}</div>;
  }
}
