import * as React from 'react';
import {defaultMenuControlBarStyles, DropdownContent} from '../../DropdownContent/index';
import {Dropdown, Icon} from 'tt-components';
import {SettingsIcon} from '../../../assets/Icons/Settings';
import {PopoverTailIcon} from '../../../assets/Icons/PopoverTail';
import {IShortcutsDefinitions, IShortcutType} from '../../../constants/shortcuts';
import {ITemplateColors} from '../../../../@types/templateColors';
import {KeyboardShortcuts} from './components/KeyboardShortcutsSettings';
import {WatermarkSettings} from './components/WatermarkSettings';
import {IWatermarkPosition} from '../../../../@types/watermarkPosition';
import {calculateDropdownStyles} from '../../../utils/utils';
import {PictureAspectRatioSettings} from './components/PictureAspectRatioSettings';
import {IPictureAspectRatioFrame} from '../../../../@types/pictureAspectRatioFrame';
import {IEnum} from '../../../../@types/enum';

export interface IGeneralSettingsProps {
  getShortcuts: (name: IShortcutType) => void;
  setDefaultShortcuts: () => void;
  changeShortcuts: (shortcuts: Array<IShortcutsDefinitions>) => void;
  keyboardShortcuts: Array<IShortcutsDefinitions>;
  keyboardShortcutsType: IShortcutType;
  templateColors: ITemplateColors;
  watermarkPosition: IWatermarkPosition;
  changeWatermarkPosition: (value: IWatermarkPosition) => void;
  isControlBarShown: boolean;
  showingDropdownTimeout: number;
  isFullScreen: boolean;
  videoPlayerContainer: HTMLElement;
  closestBodyElement: HTMLElement;
  disabled?: boolean;
  isOpen: boolean; // TODO: Implement approach to manually update popup visibility state
  onShowSettingsChange: (isOpen: boolean) => void;
  pictureAspectRatioFrame: IPictureAspectRatioFrame;
  updatePictureAspectRatioFrame: (aspectRatio: IPictureAspectRatioFrame) => void;
  pictureAspectRatioEnums: Array<IEnum>;
  videoPictureAsectRatio?: number;
}

export class GeneralSettings extends React.PureComponent<IGeneralSettingsProps> {
  getGeneralSettingsContent = () => {
    const {
      getShortcuts,
      setDefaultShortcuts,
      changeShortcuts,
      keyboardShortcuts,
      keyboardShortcutsType,
      templateColors,
      watermarkPosition,
      changeWatermarkPosition
    } = this.props;
    const {tooltipBackground} = templateColors;
    const content = (
      <div className="op_general-settings-content">
        <div className="op_general-settings-content_column op_watermark_aspect-ratio-frame-column">
          <WatermarkSettings
            templateColors={templateColors}
            watermarkPosition={watermarkPosition}
            changeWatermarkPosition={changeWatermarkPosition}
          />
          <PictureAspectRatioSettings
            templateColors={this.props.templateColors}
            videoPictureAsectRatio={this.props.videoPictureAsectRatio}
            pictureAspectRatioFrame={this.props.pictureAspectRatioFrame}
            pictureAspectRatioEnums={this.props.pictureAspectRatioEnums}
            updatePictureAspectRatioFrame={this.props.updatePictureAspectRatioFrame}
          />
        </div>
        <div className="op_general-settings-content_delimiter" />
        <div className="op_general-settings-content_column op_keyboard-shortcuts-settings-container">
          <KeyboardShortcuts
            getShortcuts={getShortcuts}
            setDefaultShortcuts={setDefaultShortcuts}
            changeShortcuts={changeShortcuts}
            keyboardShortcuts={keyboardShortcuts}
            keyboardShortcutsType={keyboardShortcutsType}
            templateColors={templateColors}
          />
        </div>
      </div>
    );
    return (
      <div className="dropdown-content-wrapper">
        <DropdownContent content={content} color={tooltipBackground} />
        <Icon icon={PopoverTailIcon} color={tooltipBackground} />
      </div>
    );
  };

  onGeneralSettingsOpen = (styles: React.CSSProperties) => {
    return calculateDropdownStyles(styles, this.props.isFullScreen, null, 65);
  };

  render() {
    const {
      isControlBarShown,
      isOpen,
      onShowSettingsChange,
      showingDropdownTimeout,
      videoPlayerContainer,
      isFullScreen,
      closestBodyElement
    } = this.props;
    const {main} = this.props.templateColors;
    const controlBarHiddenClass = isControlBarShown ? '' : ' op_controls-bar_hidden';

    return (
      <Dropdown
        disabled={this.props.disabled}
        isOpen={isOpen}
        elemDiff={true}
        onCloseHotKey={onShowSettingsChange}
        title="Settings"
        elemId="settingsId"
        buttonComponent={() => (
          <div className="general-settings-dropdown_icon" id="settingsId">
            <Icon icon={SettingsIcon} color={main} size="25px" />
          </div>
        )}
        content={this.getGeneralSettingsContent()}
        className={`ttc-control-bar-dropdown general-settings-dropdown${controlBarHiddenClass}`}
        openAbove={true}
        style={menuStyles}
        showingDropdownTimeout={showingDropdownTimeout}
        portalNode={isFullScreen ? videoPlayerContainer : closestBodyElement}
        onOpen={this.onGeneralSettingsOpen}
      />
    );
  }
}

const menuStyles: React.CSSProperties = {
  ...defaultMenuControlBarStyles,
  width: 800,
  marginLeft: 0,
  padding: 5,
  borderWidth: 1,
  boxSizing: 'content-box'
};
