import * as React from 'react';
import {Row, Column} from '../../../../../../../Grid';
import {Dropdown} from '../../../../../../../../../../components/Dropdown';
import {IPlaylistAtlas, IPlaylistTitleRecord} from '../../../../../../../../../../state/IAppState';
import {
  searchTitles,
  getTitleDataAPI,
  getTitleConformanceGroupsByVersion
} from '../../../../../../../../../../data/atlasAPI';
import {ISearchTitleParams, ISearchTitle} from '../../../../../../../../../../../@types/searchTitle';
import {deepCopy, getUniqueArrayObjectsByField} from '../../../../../../../../utils/helpers';
import {PlaylistAsset} from '../../../../../../../../../../models/PlaylistAsset/PlaylistAsset';
import {IMetadataError} from '../../../../../../../../../../../@types/metadataErrors';
import {IOneCustomer} from '../../../../../../../../../../../@types/oneCustomer';
import {parseTitleOptions} from '../../../../../../../../../../utils/utils';

interface IFeaturesProps {
  assetId: string;
  isTitleInfoOpen: boolean;
  versionId: string;
  conformanceGroupId: string;
  featureId: string;
  closestBody?: HTMLElement;
  disabled: boolean;
  tabsContainer: HTMLElement;
  errors: Array<IMetadataError>;
  contentOwners: Array<IOneCustomer>;
  contentProviders: Array<IOneCustomer>;
  onTitleInfoUpdated: (updatedTitles: ISearchTitle) => void;
}

interface IFeaturesAsset extends IPlaylistAtlas {
  type?: string;
}

interface IFeaturesState {
  conformanceGroups: Array<ISearchTitle>;
  versions: Array<ISearchTitle>;
  features: Array<ISearchTitle>;
  asset: IFeaturesAsset;
  loading: boolean;
  loadingVersions: boolean;
  loadingConformanceGroups: boolean;
  featureSearchString: string;
  featureFiltering: boolean;
  versionSearchString: string;
  versionFiltering: boolean;
  conformanceGroupSearchString: string;
  conformanceGroupFiltering: boolean;
  hrIdSearch: boolean;
}

const mapToDropdownOptions = (record: ISearchTitle | IPlaylistTitleRecord) => ({
  label: `${record.name} (${record.hrId})`,
  value: record.id
});

export class Feature extends React.PureComponent<IFeaturesProps, IFeaturesState> {
  isComponentMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      conformanceGroups: [],
      versions: [],
      features: [],
      asset: null,
      loading: true,
      loadingVersions: true,
      loadingConformanceGroups: true,
      featureSearchString: '',
      featureFiltering: false,
      versionSearchString: '',
      versionFiltering: false,
      conformanceGroupSearchString: '',
      conformanceGroupFiltering: false,
      hrIdSearch: false
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  updateState = (stateObject, callBack: () => any = null) => {
    if (!this.isComponentMounted) {
      return;
    }
    this.setState(stateObject, callBack);
  };

  componentDidUpdate(prevProps: IFeaturesProps) {
    if (prevProps.featureId !== this.props.featureId) {
      this.fetchTitleInit();
    } else if (
      prevProps.versionId !== this.props.versionId ||
      (prevProps.conformanceGroupId !== this.props.conformanceGroupId &&
        !this.state.conformanceGroups.find(
          (conformance: ISearchTitle) => conformance.id === this.props.conformanceGroupId
        ))
    ) {
      this.updateState({loadingConformanceGroups: true}, () => this.fetchConformanceGroups());
    }
  }

  init = () => {
    if (!this.props.featureId) {
      this.updateState({
        loading: false,
        loadingVersions: false,
        loadingConformanceGroups: false
      });
      return;
    }
    this.fetchTitleInit();
  };

  fetchTitleInit = () => {
    if (!this.props.featureId) {
      this.updateState({
        conformanceGroups: [],
        versions: [],
        features: [],
        asset: null,
        featureSearchString: '',
        versionSearchString: '',
        conformanceGroupSearchString: ''
      });
      return;
    }
    const params: ISearchTitleParams = {
      atlasId: this.props.featureId,
      types: 'Feature',
      size: 1
    };
    this.updateState({loading: true}, () => this.fetchTitle(params));
  };

  fetchTitle = async (params: ISearchTitleParams) => {
    const response = await searchTitles(params);
    const title = response.data.length ? response.data[0] : null;
    const titleSearch = getUniqueArrayObjectsByField([...this.state.features, title].filter(record => record), 'id');

    this.onTitleUpdate(title, titleSearch);
  };

  onTitleUpdate = (title: ISearchTitle, titleSearch: Array<ISearchTitle> = null, assetChanged: boolean = false) => {
    let asset: IFeaturesAsset = null;
    if (title) {
      const assetData = {featureId: title.id, featureVersionId: ''};
      asset = {
        ...assetData,
        type: title.type,
        assetId: this.props.assetId
      };
    }

    const stateObject: any = {
      features: titleSearch || this.state.features,
      asset,
      loading: false,
      loadingVersions: !!asset
    };

    this.updateState(stateObject, () => {
      console.log('Asset', asset);
      if (asset) {
        this.fetchVersions();
      }
    });
  };

  fetchVersions = async () => {
    const versionsAPI = await getTitleDataAPI(this.state.asset, 'Version');
    console.log('Version', versionsAPI);
    const versions = (versionsAPI.success ? (Array.isArray(versionsAPI.data) && versionsAPI.data) || [] : []).map(
      PlaylistAsset.parsing.parseSearchTitle
    );
    this.updateState({versions, loadingVersions: false, loadingConformanceGroups: true}, () =>
      this.fetchConformanceGroups()
    );
  };

  fetchConformanceGroups = async () => {
    const conformanceAPI = await getTitleConformanceGroupsByVersion(this.state.asset, this.props.versionId);
    const conformanceGroups = conformanceAPI.data;
    this.updateState({conformanceGroups, loadingConformanceGroups: false});
  };

  onSearch = async (searchString: string, fieldName: string) => {
    let data = [];
    let filterName = '';
    let type = '';
    let contentOwners = this.props.contentOwners.reduce((s, b, i) => s + (!i ? '' : ',') + b.externalId, '');
    switch (fieldName) {
      case 'features':
        this.updateState({featureSearchString: searchString, featureFiltering: true});
        data = this.state.features;
        filterName = 'featureFiltering';
        type = 'Feature';
        break;
      case 'versions':
        this.updateState({versionSearchString: searchString, versionFiltering: true});
        data = this.state.versions;
        filterName = 'versionFiltering';
        type = 'Version';
        break;
      case 'conformanceGroups':
        this.updateState({conformanceGroupSearchString: searchString, conformanceGroupFiltering: true});
        data = this.state.conformanceGroups;
        filterName = 'conformanceGroupFiltering';
        type = 'Conformance';
        break;
    }
    const paramsHrId: ISearchTitleParams = {
      hrId: searchString,
      page: 1,
      size: 20,
      exactSearch: false,
      excludeAssemblyTitles: false
    };
    const hrResults = await searchTitles(paramsHrId);
    const paramsName: ISearchTitleParams = {
      contentOwners,
      name: searchString,
      types: type,
      size: 50
    };
    const nameResults = await searchTitles(paramsName);

    const nameResultsId = deepCopy([...nameResults.data]).map((record: ISearchTitle) => record.id);
    const hrTitles = hrResults.data.filter((record: ISearchTitle) => {
      return nameResultsId.indexOf(record.id) === -1;
    });

    const selectedTitle = data.find((title: ISearchTitle) => title.id === this.props.featureId);
    const updatedData = getUniqueArrayObjectsByField(
      [...hrTitles, ...nameResults.data, selectedTitle].filter(title => title),
      'id'
    );

    this.updateState({[fieldName]: updatedData, [filterName]: false, hrIdSearch: hrResults.data.length > 0});
  };

  onFeatureSearch = (searchString: string) => {
    this.onSearch(searchString, 'features');
  };

  onVersionSearch = (searchString: string) => {
    this.onSearch(searchString, 'versions');
  };

  onConformanceSearch = (searchString: string) => {
    this.onSearch(searchString, 'conformanceGroups');
  };

  updateTitle = (featureId: string) => {
    const title = this.state.features.find((record: ISearchTitle) => record.id === featureId);
    if (!title) {
      return;
    }
    this.props.onTitleInfoUpdated(title);
  };

  updateVersion = (versionId: string) => {
    const version = this.state.versions.find((record: ISearchTitle) => record.id === versionId);
    if (!version) {
      return;
    }
    const type = 'FeatureVersion';
    this.props.onTitleInfoUpdated({...version, type});
  };

  updateConformanceGroup = (conformanceGroupId: string) => {
    const conformanceGroup = this.state.conformanceGroups.find(
      (record: ISearchTitle) => record.id === conformanceGroupId
    );
    if (!conformanceGroup) {
      return;
    }
    const conformanceType = 'FeatureConformance';
    this.props.onTitleInfoUpdated({...conformanceGroup, conformanceType});
  };

  getTitelRow = () => {
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            field={{field: 'featureId', errors: this.props.errors}}
            selectedPlaceholder={this.state.loading ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            emptyPlaceholder={this.state.featureFiltering ? 'Filtering...' : 'Empty'}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loading}
            search
            searchOnEnter
            allowOpenAbove={false}
            contentListLimit={5}
            options={this.state.features.map((title: ISearchTitle) =>
              parseTitleOptions(title, this.props.contentOwners, this.props.contentProviders)
            )}
            selected={this.props.featureId}
            label="Title Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateTitle}
            onSearch={this.onFeatureSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.featureSearchString}
            disableSearchInput={this.state.featureFiltering}
          />
        </Column>
      </Row>
    );
  };

  getVersionConformanceRow = () => {
    return (
      <Row className="title-info-container_version-confromance-row">
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            field={{field: 'versionId', errors: this.props.errors}}
            selectedPlaceholder={this.state.loadingVersions ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loadingVersions || !this.props.featureId}
            search
            searchOnEnter
            contentListLimit={5}
            allowOpenAbove={false}
            options={this.state.versions.map(mapToDropdownOptions)}
            selected={this.props.versionId}
            label="Version Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateVersion}
            onSearch={this.onVersionSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.versionSearchString}
            disableSearchInput={this.state.versionFiltering}
          />
        </Column>
        <Column className="title-info-container_version-confromance-row_column">
          <Dropdown
            selectedPlaceholder={this.state.loadingConformanceGroups ? 'Loading...' : 'Select...'}
            isOpen={this.props.isTitleInfoOpen}
            fixedButtonWidth
            disabled={this.props.disabled || this.state.loadingConformanceGroups || !this.props.versionId}
            search
            searchOnEnter
            contentListLimit={5}
            allowOpenAbove={false}
            options={this.state.conformanceGroups.map(mapToDropdownOptions)}
            selected={this.props.conformanceGroupId}
            label="Conformance Group Name / ID"
            portalNode={this.props.tabsContainer}
            relative
            onSelected={this.updateConformanceGroup}
            onSearch={this.onConformanceSearch}
            hrIdSearch={this.state.hrIdSearch}
            searchValue={this.state.conformanceGroupSearchString}
            disableSearchInput={this.state.conformanceGroupFiltering}
          />
        </Column>
      </Row>
    );
  };

  render() {
    return (
      <>
        {this.getTitelRow()}
        {this.getVersionConformanceRow()}
      </>
    );
  }
}
