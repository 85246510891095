import * as React from 'react';
import {IIconProps} from 'tt-components/src/Icon/Icon';

export const AudioPlay = (props: Partial<IIconProps>) => {
  return (
    <svg
      width={props.size || '24px'}
      height={props.size || '24px'}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>icons/listen_active</title>
      <desc>Created with Sketch.</desc>
      <g id="icons/listen_active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(3.000000, 3.000000)" fill={props.color || '#FFFFFF'}>
          <path
            d="M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,
            9 C18,13.9705627 13.9705627,18 9,18 Z M7.35714286,13 C7.43285714,13 7.50857143,12.9761985 7.57142857,
            12.9299957 L11.8571429,9.7798009 C11.9471429,9.71399683 12,9.60969038 12,9.49978359 C12,
            9.38987679 11.9471429,9.28557034 11.8571429,9.21976627 L7.57142857,6.0695715 C7.46285714,
            5.99046661 7.31785714,5.97786583 7.19714286,6.03666947 C7.07642857,6.0954731 7,6.21728064 7,
            6.34958882 L7,12.6499784 C7,12.7822865 7.07642857,12.903394 7.19714286,12.9628977 C7.24785714,
            12.9880993 7.30285714,13 7.35714286,13 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  );
};
