import * as React from 'react';

interface IImageProps {
  url: string;
  alt?: string;
  crossOrigin?: 'anonymous' | 'use-credentials';
  width?: number;
  height?: number;
  onLoaded?: (fetched: boolean, width?: number, height?: number) => void;
}

interface IImageState {
  loaded: boolean;
  error: string;
}

export class CustomImage extends React.PureComponent<IImageProps, IImageState> {
  static defaultProps = {
    alt: 'Image'
  };
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      error: null
    };
  }

  componentDidMount() {
    this.init(this.props.url);
  }

  componentDidUpdate(prevProps: IImageProps) {
    if (prevProps.url !== this.props.url) {
      this.init(this.props.url);
    }
  }

  init = async (url: string) => {
    await new Promise(resolve => this.setState({loaded: false, error: null}, resolve));
    const img = new Image();
    img.addEventListener('load', () => {
      this.setState({loaded: true, error: ''}, () => {
        if (this.props.onLoaded) {
          this.props.onLoaded(true, img.width, img.height);
        }
      });
    });
    img.addEventListener('error', () => {
      this.setState({error: `Couldn't fetch image`}, () => {
        if (this.props.onLoaded) {
          this.props.onLoaded(false);
        }
      });
    });
    if (this.props.crossOrigin) {
      img.crossOrigin = this.props.crossOrigin;
    }
    if (this.props.width) {
      img.width = this.props.width;
    }
    if (this.props.height) {
      img.height = this.props.height;
    }
    img.src = url;
  };

  getImageElementProps = () => {
    const props: any = {};
    if (this.props.crossOrigin) {
      props.crossOrigin = this.props.crossOrigin;
    }
    if (this.props.width) {
      props.width = this.props.width;
    }
    if (this.props.height) {
      props.height = this.props.height;
    }
    return props;
  };

  render() {
    return this.state.loaded ? (
      <img
        className={`custom-image${this.props.height ? `` : ` full-height`}`}
        src={this.props.url}
        alt={this.props.alt}
        {...this.getImageElementProps()}
      />
    ) : (
      <div className="image-container">
        {this.state.error === null ? <span>Fetching image...</span> : <span>{this.state.error}</span>}
      </div>
    );
  }
}
