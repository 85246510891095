import {IPlayerObservable, IVideoPlayer, Observable} from 'tt-components';
import {IBitMovinPlayer} from 'tt-components/src/VideoPlayer/BitMovinPlayer';
import {Observable as RxObservable} from 'rxjs';
import {ClockPurpose, IVideo} from './interfaces';

const schedule: Map<ClockPurpose, number> = new Map<ClockPurpose, number>([
  [ClockPurpose.animationFrame, 0],
  [ClockPurpose.playerCue, 100],
  [ClockPurpose.ttGrid, 120],
  [ClockPurpose.playScheduler, 120],
  [ClockPurpose.playerLoop, 100],
  [ClockPurpose.playerExposedEvent, 100]
] as [ClockPurpose, number][]);

export class Video implements IVideo {
  private player: IVideoPlayer | IBitMovinPlayer;
  private clocks: Map<ClockPurpose, IPlayerObservable> = new Map<ClockPurpose, IPlayerObservable>();

  init(player: IVideoPlayer | IBitMovinPlayer) {
    this.clear();
    this.player = player;
  }

  clear() {
    this.clocks.forEach(clock => clock.dispose());
    this.clocks = new Map<ClockPurpose, IPlayerObservable>();
    this.player = null;
  }

  getVideoClock(purpose?: ClockPurpose): RxObservable<number> {
    purpose = purpose || ClockPurpose.animationFrame;
    if (!this.player) {
      return Observable.emptyPlayer();
    }

    if (this.clocks.has(purpose)) {
      return this.clocks.get(purpose);
    }

    const newClock = Observable.fromPlayer(this.player, schedule.get(purpose));
    this.clocks.set(purpose, newClock);
    return newClock;
  }

  getCurrentTime(): number {
    return this.player ? this.player.getCurrentTime() : 0;
  }

  seekTo(second: number) {
    return this.player && this.player.seekTo(second);
  }

  pause() {
    if (this.player) {
      this.player.pause();
    }
  }

  isReady() {
    return this.player && this.player.isReady();
  }

  subtitles() {
    return this.player && (this.player as any).isBitmovinPlayer ? (this.player as IBitMovinPlayer).subtitles() : null;
  }

  getDroppedFrames() {
    return this.player && (this.player as any).isBitmovinPlayer
      ? (this.player as IBitMovinPlayer).getDroppedVideoFrames()
      : 0;
  }
}
