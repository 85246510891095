import * as React from 'react';
import {CollapisbleWithEditModeToggle} from '../../../Collapsible';
import {IAssetImages, IErrorLog} from '../../../../../../../@types/assetDetails';
import {Row, Column, Container} from '../../../Grid';
import {Dropdown, IDropdownOption} from '../../../../../../components/Dropdown';
import {TextField} from '../../../../../../components/TextField';
import {getErrorLogByField} from '../../../../utils/helpers';
import {IEnums} from '../../../../../../state/IEnums';
import {LanguageDropdown} from '../../../LanguageDropdown';
import {IEnum} from '../../../../../../../@types/enum';
import {IMetadataError} from '../../../../../../../@types/metadataErrors';

interface ImageRecordProps {
  openCollapsibleContent: boolean;
  image: IAssetImages;
  enums: IEnums;
  closestBody?: HTMLElement;
  tabsDataInEditMode?: boolean;
  errorLogs: Array<IErrorLog>;
  imagesMetadataErrors: Array<IMetadataError>;
  updateProp: (imageId: string, prop: string, value: string | number) => void;
  onCollapsibleToggle: (open: boolean) => void;
}

const convertEnums = (enumList: Array<IEnum>): Array<IDropdownOption> =>
  enumList.reduce(
    (acc: Array<IDropdownOption>, record: IEnum) => [...acc, {label: record.name, value: record.value}],
    []
  );

export class ImageRecord extends React.PureComponent<ImageRecordProps> {
  getTitle = () => {
    return this.props.image.graphicsType || '...';
  };

  onLanguageSelected = (language: string, country: string) => {
    setTimeout(() => this.props.updateProp(this.props.image.id, 'language', language), 200);
    setTimeout(() => this.props.updateProp(this.props.image.id, 'country', country), 200);
  };

  getContent = () => {
    const content = (
      <>
        <Row className="image-record-container_row">
          <Column className="image-record-container_row_column">
            <Dropdown
              field={{field: 'images.graphicsType', errors: this.props.imagesMetadataErrors}}
              search
              disabled={!this.props.tabsDataInEditMode}
              options={convertEnums(this.props.enums.graphicsType)}
              onSelected={value => this.props.updateProp(this.props.image.id, 'graphicsType', value)}
              selected={this.props.image.graphicsType}
              label="Graphics Type"
              portalNode={this.props.closestBody}
              errorLog={getErrorLogByField('images.graphicsType', this.props.errorLogs)}
            />
          </Column>
          <Column className={`image-record-container_row_column${!this.props.tabsDataInEditMode ? ` disabled` : ``}`}>
            <TextField
              label="Image Codec"
              text={this.props.image.imageCodec || ''}
              editMode={true}
              onChange={value => this.props.updateProp(this.props.image.id, 'imageCodec', value)}
            />
          </Column>
          <Column className="image-record-container_row_column">
            <LanguageDropdown
              field={{field: 'images.language', errors: this.props.imagesMetadataErrors}}
              search
              disabled={!this.props.tabsDataInEditMode}
              languageDialect={this.props.enums.languageDialect}
              onLanguageSelected={this.onLanguageSelected}
              language={this.props.image.language}
              country={this.props.image.country}
              label="Language"
              portalNode={this.props.closestBody}
              toLowerCaseCompare
              errorLogs={this.props.errorLogs}
            />
          </Column>
        </Row>
        <Row className="image-record-container_row">
          <Column className={`image-record-container_row_column${!this.props.tabsDataInEditMode ? ` disabled` : ``}`}>
            <TextField
              field={{field: 'images.frameSizeHeight', errors: this.props.imagesMetadataErrors}}
              label="Frame Size Height"
              text={this.props.image.frameSizeHeight || ''}
              editMode={true}
              onlyNumbers
              onChange={value => this.props.updateProp(this.props.image.id, 'frameSizeHeight', `${value}`.slice(0, 4))}
            />
          </Column>
          <Column className={`image-record-container_row_column${!this.props.tabsDataInEditMode ? ` disabled` : ``}`}>
            <TextField
              field={{field: 'images.frameSizeWidth', errors: this.props.imagesMetadataErrors}}
              label="Frame Size Width"
              text={this.props.image.frameSizeWidth || ''}
              editMode={true}
              onlyNumbers
              onChange={value => this.props.updateProp(this.props.image.id, 'frameSizeWidth', `${value}`.slice(0, 4))}
            />
          </Column>
          <Column />
        </Row>
      </>
    );

    return <Container className="image-record-container">{content}</Container>;
  };

  render() {
    return (
      <CollapisbleWithEditModeToggle
        tabsInEditMode={this.props.tabsDataInEditMode}
        open={this.props.openCollapsibleContent}
        title={this.getTitle()}
        content={this.getContent()}
        onToggle={this.props.onCollapsibleToggle}
      />
    );
  }
}
